import React, {Component} from "react";
import {MdArrowRight, MdArrowRightAlt, MdOutlineAccessTime} from "react-icons/md";
import sourcersimage from "../../assets/Group 90070.png"
import green from "../../assets/Group 89973.png";
import blue from "../../assets/Group 89971.png";
import peach from "../../assets/Group 89972.png";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import {getSuggestions, getSuggestionsAdmin} from "../../User/Components/Landing/MainFile/MainFunctions";

class CollectionsLanding extends Component{
    state = {
        q:"",
        suggestion:[]
    }
    handleSearch = async (e) =>{
        e.preventDefault();
        await this.handleSuggestion(this.state.q);
    }
    handleInput = async (e) => {

        //Get search suggestions as the user types
        this.setState({
            activeinput:true
        })


        if(e.target.value.length > 0){
            await this.setState({
                [e.target.name]: e.target.value,
                q:e.target.value,
            })
            //Search from RB collections
            getSuggestionsAdmin(e,this.state.token,   async (res)=>{
                await this.setState({
                    suggestion:res.data.suggestionGroups[0].searchSuggestions,
                    showsuggest:true
                })
            }, (error)=>{
            })
        } else {
            await this.setState({
                showsuggest:false,
                q:""
            })
        }



    }
    handleSuggestion = async (item)=>{
        this.setState({
            q:item,
            suggestion:[]
        })
        localStorage.setItem('searchquery', this.state.q)
        window.location.assign(`/admin-collections?q=${this.state.q}`)
    }

    handleCollectionsList = () => {
        window.location.assign("/admin-collections-list")
    }
    render() {
        return (
            <div style={{backgroundColor:"#F9F8FF"}}>
                <div className={"container pt-3 d-flex justify-content-end"}>
                    <button onClick={this.handleCollectionsList} className={"btn "} style={{backgroundColor:"#E0DAFF", color:'#522FFF', padding:"0.75rem 1.375rem", borderRadius:"200px"}}>Go to my collections <MdArrowRightAlt /></button>
                </div>
                <div className={"container d-flex justify-content-center"}>
                    <img src={sourcersimage}/>
                </div>
                <div className={"container d-flex justify-content-center"}>
                    <div className="container" style={{marginTop:"3.125rem"}}>
                        <form onSubmit={this.handleSearch} autoComplete="off">
                            <div className="container">
                                <div className={"row"} style={{zIndex:"1000", position:"relative"}}>
                                    <div className={"col-md-10 col-10 p-0 m-0"}>
                                        <input className="search-field-text" type="text" name="q" onChange={this.handleInput} onClick={this.handleInput} value={this.state.q} onBlur={this.handleLeaveInput}
                                               placeholder="Search for papers, courses, communities..." style={this.state.suggestion.length  ? {borderRadius:"2rem 0 0 0"} : {borderRadius:"100px 0px 0px 100px"}}/>
                                    </div>
                                    <div className={"col-md-2 col-2 p-0 m-0"}>
                                        <button onTouchStart={this.handleSearch} onClick={this.handleSearch} className="btn btn-search" style={{borderRadius:"0px 6px 6px 0px"}}>Search</button>
                                    </div>
                                    <div className={"col-md-10 col-10 p-0 m-0 position-relative"}>
                                        <div className={"position-absolute col-12 col-md-12"}>

                                            <div className="form-input1 col-md-12" style={{marginTop:"-.2rem", background:"white", position:"relative"}}>

                                                {
                                                    this.state.q  && this.state.suggestion.map((suggest, i)=>(
                                                        <div className="suggest-option" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleSuggestion(suggest.displayText)}> {suggest.displayText}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export  default CollectionsLanding