import React, {Component} from "react";
import Header from "../Header";
import LoginModal from "../../User/Components/Modals/LoginModal";
import searchicon from "../../assets/Frame 39.png";
import AlertComponent from "../../User/Components/ResultsCategories/AlertComponent";
import {MdArrowForward, MdOutlineRemoveRedEye} from "react-icons/md";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";

class CollectionsList extends Component {
    state = {
        collections:[],
    }
    componentDidMount() {
        axios.get(buildAPIUrl('v1/collections/group/'))
            .then((res)=>{
                console.log(res.data)
                this.setState({
                    collections:res.data
                })
                window.scrollTo(0,0)
            }).catch((error)=>{
        })
    }
    handleSub = (id, name) => {
        localStorage.setItem('collectionid', id)
        localStorage.setItem('collectionname', name)
        window.location.assign("/admin-subcollections")
    }
    render() {
        return (
            <div>
                <Header />
                <div className={"container-fluid"} style={{backgroundColor:"#F9F8FF"}}>
                    <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop:"1rem", background:"#F9F8FF"}}>
                        <div className="col-md-1"></div>
                        <div className="col-md-11 col-12">
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-12 col-md-11">
                                        <div className="search-section col-12 col-md-9">
                                            <div className="row d-flex align-items-center ">
                                                <div className=" col-12 col-md-12 d-flex justify-content-center position-relative p-0">
                                                    <form onSubmit={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                        <div className="form-input border" style={this.props.device==="mobile" ? {border: " 1px solid rgba(13, 0, 76, 0.08)", borderRadius:"200px", paddingLeft:"1rem", paddingRight:"1.5rem", boxShadow:"0px 3px 4px rgba(0, 0, 0, 0.07)"} : { borderRadius:"100px", paddingLeft:"40px", paddingRight:"1.5rem", boxShadow:"rgba(0, 0, 0, 0.07) 0px 3px 4px"}}>
                                                            <span className="input-group-addon d-flex align-items-center">  <img src={searchicon}/></span>
                                                            <input className={this.props.device==="mobile" ? "search-field-text1mob" : this.props.device==="tablet" ? "search-field-text1tablet" :  "search-field-text1"} type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                                   style={{borderRadius:"6px", width:"100%"}} placeholder="Search collections"/>

                                                        </div>
                                                    </form>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="row mt-5">
                                <div className=" col-12 p-0 col-md-11" style={{overflow: "hidden"}}>
                                    <div className="container mt-2">
                                        <div className={"col-md-3"}>
                                            <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                            existstatus={this.state.exists} type={"General"}/>
                                        </div>


                                    </div>
                                    <div className={"container"}>
                                        <div className="categories border-0" style={{padding:"0"}}>
                                            <div className="row mb-5">
                                                {
                                                    this.state.collections.map((collection)=>(
                                                        <>
                                                            <div className="col-md-3 col-6 mt-5">
                                                                <a onClick={()=>this.handleSub(collection.id, collection.name)} style={{textDecoration:"none", cursor:'pointer'}}>
                                                                    <div className={"container bg-white h-100 d-flex justify-content-center pb-5"}>
                                                                        <div className="categories-content-main  h-100 text-center">

                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem"}}/>
                                                                            <div style={{height:"30%"}}>
                                                                                <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                    {collection.name}
                                                                                </h6>
                                                                            </div>
                                                                            <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                                                <a style={{ color:"#00944D"}} href={collection.url}>Explore <MdArrowForward /></a>
                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </>

                                                    ))
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-1"}></div>
                                <div className={"col-md-4 "} style={{marginTop:"3.125rem"}}>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
export default CollectionsList