import React, {Component} from "react";
import Header from "../Header";
import Footer from "../Footer";
import "./features.css"
import banner1 from "../../../../assets/Features/Group 89871.png"
import banner1mobile from "../../../../assets/Features/Group 89884.png"
import banner2 from "../../../../assets/Features/Group 89713.webp"
import banner3 from "../../../../assets/Features/Group 89949.png"
import banner4 from "../../../../assets/Features/Group 89716.webp"
import banner5 from "../../../../assets/Features/Group 89718.webp"
import banner6 from "../../../../assets/Features/Group 89719.webp"
import banner7 from "../../../../assets/Features/Group 89720.png"
import banner8 from "../../../../assets/Features/Group 89722.webp"
import banner9 from "../../../../assets/Features/Group 89724.webp"
import banner10 from "../../../../assets/Features/Group 89725.webp"
import banner11 from "../../../../assets/Features/Group 89727.webp"
import banner12 from "../../../../assets/Features/Group 89743.png"
import banner13 from "../../../../assets/Features/Group 89746.png"
import banner14 from "../../../../assets/Features/Group 89715.webp"
import RightImageComponent from "./RightImageComponent";
import LeftImageComponent from "./LeftImageComponent";
import MediaQuery from "react-responsive";
import SeoMetaData from "../../RBResourceTables/SeoMetaData";
class Features extends Component{
    render() {
        const text1 = "Semantic search seeks to improve search accuracy by understanding the searcher's intent and the contextual meaning of terms as they appear in the searchable space. With Research Bookmark, you don’t have to add “UX research” to the end of every search term. Just one term is enough to find what you are looking for."
        const title1="Semantic Search"
        const text2="All our search results are UX-related, as it’s our mission to bring you information specific to the field. Whether you are searching for a term, tool, or method, we have it all."
        const title2="UX Research Related Results"
        const text3="With more and more users using video content to learn, we have incorporated video search results. Looking to learn how to use a certain tool or a qualitative research method? We’ve got you!"
        const title3="Video Search Results"
        const text4="A lot of conversations (even UX conversations) are taking place on social media platforms. Wouldn’t you like to know what people are talking about on LinkedIn or Reddit? Our platform populates results from social media, so you can stay in the know."
        const title4="Social Search Results"
        const text5=" Yes, we finally did it! For users who come across interesting search results on our platform, you can now save content to your account and share with your network."
        const title5="Saving & Sharing Search Results"
        const text6="We do not include advertisements in our search results. We only feature resources that we deem useful and related to what you could be looking for."
        const title6="Ad-free"
        const text7="We do not track users’ activities on Research Bookmark. One can safely browse on our platform."
        const title7="No Tracking"
        const text8="Users can now compare multiple search terms with their main search term and refine their search to get more relevant results."
        const title8="Related Searches"
        const text9="We have an ever-growing list of resources that span from, tools, communities, conferences, jobs, books, podcasts, research methods, courses, and more."
        const title9="Quick Links"
        const text10="For all newbies, transitioners, junior and mid-level UX researchers, we have selected and recommended articles that speak on the 4 main phases of the research process (starting research, conducting research, analyzing research, and presenting research)."
        const title10="Handpicked Articles"
        const text11="A chrome extension that enables our users to search and save important UX research resources whenever and wherever they are. With the RB Research Assistant, one can save articles accessed both within and outside our platform. A Medium article you came across on Twitter is now possible to save to your RB account using our Research Assistant! The RB Research Assistant can also help you complete functions like quickly finding the meaning of a term in a project brief."
        const title11="RB Research Assistant (Chrome Extension)"
        const text12="As a member of Research Bookmark, you will get access to discounted prices for your favorite tools and resources like guides, toolkits, and templates."
        const title12="Discounted Products"
        const text13="As a pro user of Research Bookmark, you will get a chance to receive free accounts to try out different tools that you’ve come across and would like to experiment with."
        const title13="Free Accounts to Other Products"
        const chromeexturl = "https://chrome.google.com/webstore/detail/rb-research-assistant/lopemdiebdhmfpofjommgpfoiiocgbin"
        const semanticurl = "https://www.researchbookmark.io/all-results?q=portfolios"
        const startingresearch = "https://www.researchbookmark.io/articles/2/starting-research"
        const quicklinks = "https://www.researchbookmark.io/collections"

        return (
            <>
                <SeoMetaData title={"Features"} />
                <Header pageType={"Free"} />
                <MediaQuery minWidth={1200}>
                    <div className={"container features-container"}>
                        <img src={banner1}/>
                        <RightImageComponent image={banner2} text={text1} title={title1} url={semanticurl} type={"blue"} urltext={"Try it out now"} />
                        <LeftImageComponent image={banner3} text={text2} title={title2} />
                        <RightImageComponent image={banner4} text={text3} title={title3} />
                        <LeftImageComponent image={banner14} text={text4} title={title4} />
                        <RightImageComponent image={banner5} text={text5} title={title5} url={semanticurl} type={"blue"} urltext={"Start sharing"} />
                        <LeftImageComponent image={banner6} text={text6} title={title6} />
                        <RightImageComponent image={banner8} text={text7} title={title7} />
                        <LeftImageComponent image={banner7} text={text8} title={title8} />
                    </div>
                    <div className={"container-fluid p-0"} style={{background:"#F4F1FF", marginTop:"9.188rem"}}>
                        <div className={"container"} style={{ paddingTop:"2.5rem", paddingBottom:"3.5rem"}}>
                            <RightImageComponent image={banner9} text={text9} title={title9} url={quicklinks} type={"blue"} urltext={"Check them out"} />
                            <LeftImageComponent image={banner10} text={text10} title={title10} url={startingresearch} type={"blue"} urltext={"Start reading"} />
                        </div>
                    </div>
                    <div className={"container-fluid p-0"} style={{background:"#6B4EFF"}}>
                        <div className={"container"} style={{ paddingTop:"2.5rem", paddingBottom:"3.5rem"}}>
                            <RightImageComponent type={"white"} buttontext={"See how it works"} image={banner11} text={text11} title={title11} url={chromeexturl} />
                        </div>
                    </div>
                   {/* <div className={"container"} style={{marginTop:"4.75rem"}}>
                        <LeftImageComponent image={banner13} text={text12} title={title12} />
                        <RightImageComponent image={banner12} text={text11} title={title11} />

                    </div>*/}
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"mobile"}>
                        <div className={"container-fluid px-0"} style={{background:"#F7F4FF", paddingTop:"4.813rem"}}>
                            <h5 style={{fontSize:"30px", fontWeight:"700", textAlign:"center"}}>Why use RB?</h5>
                            <img src={banner1mobile}/>
                        </div>
                        <div className={"container-fluid p-0 features-container"}>

                            <LeftImageComponent device={"mobile"} image={banner2} text={text1} title={title1} url={semanticurl} type={"blue"} urltext={"Try it out now"} />
                            <LeftImageComponent device={"mobile"} image={banner3} text={text2} title={title2} />
                            <LeftImageComponent device={"mobile"}  image={banner4} text={text3} title={title3} />
                            <LeftImageComponent device={"mobile"}  image={banner14} text={text4} title={title4} />
                            <LeftImageComponent device={"mobile"} image={banner5} text={text5} title={title5} url={semanticurl} type={"blue"} urltext={"Start sharing"} />
                            <LeftImageComponent device={"mobile"} image={banner6} text={text6} title={title6} />
                            <LeftImageComponent device={"mobile"} image={banner8} text={text7} title={title7} />
                            <LeftImageComponent device={"mobile"} image={banner7} text={text8} title={title8} />
                        </div>
                        <div className={"container-fluid p-0"} style={{background:"#F4F1FF", marginTop:"9.188rem"}}>
                            <div className={"container"} style={{ paddingTop:"2.5rem", paddingBottom:"3.5rem"}}>
                                <LeftImageComponent device={"mobile"}  image={banner9} text={text9} title={title9}  url={quicklinks} type={"blue"} urltext={"Check them out"}  />
                                <LeftImageComponent device={"mobile"} image={banner10} text={text10} title={title10} url={startingresearch} type={"blue"} urltext={"Start reading"} />
                            </div>
                        </div>
                        <div className={"container-fluid p-0"} style={{background:"#6B4EFF"}}>
                            <div className={"container"} style={{ paddingTop:"2.5rem", paddingBottom:"3.5rem"}}>
                                <LeftImageComponent type={"white"} url={chromeexturl} buttontext={"See how it works"} url={chromeexturl} image={banner11} text={text11} title={title11} />
                            </div>
                        </div>
                      {/*  <div className={"container"} style={{marginTop:"4.75rem"}}>
                            <RightImageComponent image={banner13} text={text12} title={title12} />
                            <RightImageComponent image={banner12} text={text11} title={title11} />

                        </div>*/}
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className={"tablet"}>
                        <div className={"container features-container"}>
                            <img src={banner1}/>
                            <RightImageComponent image={banner2} text={text1} title={title1} url={semanticurl} type={"blue"} urltext={"Try it out now"} />
                            <LeftImageComponent image={banner3} text={text2} title={title2} />
                            <RightImageComponent image={banner4} text={text3} title={title3} />
                            <LeftImageComponent image={banner14} text={text4} title={title4} />
                            <RightImageComponent image={banner5} text={text5} title={title5} url={semanticurl} type={"blue"} urltext={"Start sharing"} />
                            <LeftImageComponent image={banner6} text={text6} title={title6} />
                            <RightImageComponent image={banner8} text={text7} title={title7} />
                            <LeftImageComponent image={banner7} text={text8} title={title8} />
                        </div>
                        <div className={"container-fluid p-0"} style={{background:"#F4F1FF", marginTop:"9.188rem"}}>
                            <div className={"container"} style={{ paddingTop:"2.5rem", paddingBottom:"3.5rem"}}>
                                <RightImageComponent image={banner9} text={text9} title={title9}  url={quicklinks} type={"blue"} urltext={"Check them out"}  />
                                <LeftImageComponent image={banner10} text={text10} title={title10} url={startingresearch} type={"blue"} urltext={"Start reading"} />
                            </div>
                        </div>
                        <div className={"container-fluid p-0"} style={{background:"#6B4EFF"}}>
                            <div className={"container"} style={{ paddingTop:"2.5rem", paddingBottom:"3.5rem"}}>
                                <RightImageComponent url={chromeexturl} type={"white"} buttontext={"See how it works"} image={banner11} text={text11} title={title11} />
                            </div>
                        </div>
                        {/*<div className={"container"} style={{marginTop:"4.75rem"}}>
                            <LeftImageComponent image={banner13} text={text12} title={title12} />
                            <RightImageComponent image={banner12} text={text11} title={title11} />

                        </div>*/}
                    </div>
                </MediaQuery>

                <Footer />
            </>
        );
    }
}
export default Features