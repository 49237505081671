import React, {useCallback, useEffect, useRef, useState} from "react";
import "./certificate.css"
import Header from "../../Landing/Header";
import Footer from "../../Landing/Footer";
import Ux from "../../../../assets/Group 9005223.png"
import Ya from "../../../../assets/Group 8980323.png"
import QRCode from 'qrcode.react';
import {useReactToPrint} from "react-to-print";
import {toJpeg, toPng} from 'html-to-image';
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import poster from "../../../../assets/April Workshop cover page.png"
import Calendar from "../../../../assets/Calendar.png";
import {MdAccessTime} from "react-icons/md";
import {Dropdown} from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {HiOutlineDownload} from "react-icons/hi";
import pdf from "../../../../assets/Frame 90035.png";
import jpg from "../../../../assets/Frame 900351.png";
import png from "../../../../assets/Frame 90036.png";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";
import MediaQuery from "react-responsive";
export default function Workshop2023(){
    const componentref = useRef()
    const[print, setPrint]=useState(false)
    const [fullname, setFullName]=useState("")
    const [refnumber, setRefnumber]=useState("")
    const [email, setEmail]=useState("")
    const [certificateDetails, setCertificateDetails] = useState([])
    const handlePrinttoPdf = useReactToPrint({
        content:()=>componentref.current
    })

    async function getCertificateDetails () {
       const getCertificate= await axios.get(buildAPIUrl('v1/ux_certificates/?year=YEAR_2023'), {
           headers: {
               Authorization: `Bearer ${localStorage.getItem('token')}`
           }
       })
        await setFullName(getCertificate.data[0].full_name)
        await setEmail(getCertificate.data[0].email)
        await setRefnumber(getCertificate.data[0].ref_number)
        console.log(getCertificate)
    }
    const generatePDF = async () => {
       await setPrint(true)
       await html2canvas(document.querySelector('#report'),
        ).then(canvas => {
            const pdf = new jsPDF('l', 'mm', 'a4');
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 298, 211);
            pdf.save('report');
        });
        await setPrint(false)
    }

    const handlePrintToImage = useCallback(async ()=>{
        await setPrint(true)
       await toPng(componentref.current)
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = `${localStorage.getItem('username') + ".png"}`
                link.href = dataUrl
                link.click()
            })
            await setPrint(false)

    }, [componentref])
    const handlePrintToImagejpg = useCallback(async ()=>{
        await setPrint(true)
       await toJpeg(componentref.current)
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = `${localStorage.getItem('username') + ".jpg"}`
                link.href = dataUrl
                link.click()
            })
        await setPrint(false)
    }, [componentref])

    useEffect(()=>{
       getCertificateDetails()
        window.scrollTo(0,0)
    }, [])
    return(
        <>
            <Header />
           <MediaQuery minWidth={1200}>
               <div className={"container"} style={{marginTop:"7rem", marginBottom:"2rem"}}>
                   <div className={"row"}>
                       <div className={"col-md-8"}>
                           <div className={"container"} style={{background:"#F5F3FF", padding:"1.813rem 2.375rem"}}>
                               <div className={"row d-flex justify-content-lg-center" }>
                                   <div className={"certificate-preview col-md-12 "}>
                                       <div className={"certificate-info-preview"}>
                                           <h3>
                                               Certificate awarded to
                                           </h3>
                                           <h2>
                                               {fullname}
                                           </h2>
                                           <hr />
                                           <h5>Reference No: {refnumber}</h5>
                                           <h5 style={{marginTop:"1.375rem"}}>
                                               For successfully completing
                                           </h5>
                                           <img src={Ux} style={{width:"40%"}}/>

                                           <div className={"row"}>
                                               <div className={"col-md-9 align-items-center"}>
                                                   <img src={Ya} style={{marginTop:"1.063rem", width:"60%"}}/>
                                               </div>
                                               <div className={"col-md-3 d-flex justify-content-end align-items-center"}>
                                                   <QRCode value={`https://researchbookmark.io/certificate-verification/${refnumber}`} renderAs="canvas" style={{height:"50px", width:"50px"}} />
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className={"col-md-4"} style={{paddingLeft:"3.125rem"}}>
                           <h6 style={{fontSize:"18px", fontWeight:"500"}}>
                               Certificate awarded to:
                           </h6>
                           <div className={"container-fluid p-0 d-flex align-items-center"} style={{marginTop:"1rem"}}>
                               <div className={"avatar-holder"} style={{display:"inline-block", height:"3.875rem", width:"3.875rem", background:"#AB9AFF"}}></div>
                               <h6 style={{display:"inline-block", marginLeft:"0.688rem"}}>{fullname}</h6>
                           </div>
                           <h6 style={{marginTop:"1.313rem"}}>
                               For this course:
                           </h6>
                           <img src={poster} height="12%"/>
                           <h6 style={{marginTop:"1rem", fontSize:"18px", fontWeight:"500"}}>UX Research Skillshop</h6>
                           <div className={"row "} style={{marginTop:"0.563rem"}}>
                               <div className={"col-md-6 d-flex align-items-center "}>
                                   <div>
                                       <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>April 14 & 15</span>
                                   </div>

                               </div>
                               <div className={"col-md-6"}>
                                   <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>10AM-2PM (EST)</span>
                               </div>
                           </div>
                           <div className={"container-fluid p-0 workshop"} style={{marginTop:"2rem"}}>
                               <Dropdown>
                                   <DropdownToggle style={{background:"white", width:"100%", border:"none", textAlign:"left"}}>
                                       <div className={"btn"} style={{background:"#070707", color:"white", borderRadius:"200px"}} >Download</div>
                                   </DropdownToggle>

                                   <Dropdown.Menu>
                                       <Dropdown.Item>
                                           <button onClick={generatePDF} className={"btn"} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                               <img  src={pdf}/>
                                           </button>

                                       </Dropdown.Item>
                                       <Dropdown.Item >
                                           <button className={"btn"} onClick={handlePrintToImagejpg} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                               <img src={jpg}/>
                                           </button>
                                       </Dropdown.Item>
                                       <Dropdown.Item>
                                           <button className={"btn"} onClick={handlePrintToImage} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                               <img  src={png}/>
                                           </button>

                                       </Dropdown.Item>
                                   </Dropdown.Menu>
                               </Dropdown>
                           </div>

                       </div>
                   </div>
                   <div className={print ? "" : "d-none"}>
                       <div className={"row d-flex justify-content-lg-center" } id={"report"}  ref={componentref} style={{width:"1280px"}} >
                           <div className={"certificate col-md-12 "}>
                               <div className={"certificate-info"}>
                                   <h3>
                                       Certificate awarded to
                                   </h3>
                                   <h2>
                                       {fullname}
                                   </h2>
                                   <hr />
                                   <h5>Reference No: {refnumber}</h5>
                                   <h5 style={{marginTop:"3.375rem"}}>
                                       For successfully completing
                                   </h5>
                                   <img src={Ux} style={{width:"40%"}}/>

                                   <div className={"row"}>
                                       <div className={"col-md-9"}>
                                           <img src={Ya} style={{marginTop:"4.063rem", width:"60%"}}/>
                                       </div>
                                       <div className={"col-md-3 d-flex justify-content-end"}>
                                           <QRCode value={`https://researchbookmark.io/certificate-verification/${refnumber}`}  renderAs="canvas" />
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>

               </div>
           </MediaQuery>
           <MediaQuery maxWidth={460}>
               <div className={"container"} style={{marginTop:"7rem", marginBottom:"2rem"}}>
                   <div className={"row"}>
                       <div className={"col-md-8"}>
                           <div className={"container"} style={{background:"#F5F3FF", padding:"1.813rem 2.375rem"}}>
                               <div className={"row d-flex justify-content-lg-center" }>
                                   <div className={"certificate-preview col-md-12 "}>
                                       <div className={"certificate-info-preview"}>
                                           <h3>
                                               Certificate awarded to
                                           </h3>
                                           <h2>
                                               {fullname}
                                           </h2>
                                           <hr />
                                           <h5>Reference No: {refnumber}</h5>
                                           <h5 style={{marginTop:"1.375rem"}}>
                                               For successfully completing
                                           </h5>
                                           <img src={Ux} style={{width:"40%"}}/>

                                           <div className={"row"}>
                                               <div className={"col-md-9 align-items-center"}>
                                                   <img src={Ya} style={{marginTop:"1.063rem", width:"60%"}}/>
                                               </div>
                                               <div className={"col-md-3 d-flex justify-content-end align-items-center"}>
                                                   <QRCode value={`https://researchbookmark.io/certificate-verification/${refnumber}`}  renderAs="canvas" style={{height:"50px", width:"50px"}} />
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                       <div className={"col-md-4"} style={{paddingLeft:"3.125rem"}}>
                           <h6 style={{fontSize:"18px", fontWeight:"500"}}>
                               Certificate awarded to:
                           </h6>
                           <div className={"container-fluid p-0 d-flex align-items-center"} style={{marginTop:"1rem"}}>
                               <div className={"avatar-holder"} style={{display:"inline-block", height:"3.875rem", width:"3.875rem", background:"#AB9AFF"}}></div>
                               <h6 style={{display:"inline-block", marginLeft:"0.688rem"}}>{fullname}</h6>
                           </div>
                           <h6 style={{marginTop:"1.313rem"}}>
                               For this course:
                           </h6>
                           <img src={poster} height="12%"/>
                           <h6 style={{marginTop:"1rem", fontSize:"18px", fontWeight:"500"}}>UX Research Skillshop</h6>
                           <div className={"row "} style={{marginTop:"0.563rem"}}>
                               <div className={"col-md-6 d-flex align-items-center "}>
                                   <div>
                                       <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>April 14 & 15</span>
                                   </div>

                               </div>
                               <div className={"col-md-6"}>
                                   <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>10AM-2PM (EST)</span>
                               </div>
                           </div>
                           <div className={"container-fluid p-0 workshop"} style={{marginTop:"2rem"}}>
                               <Dropdown>
                                   <DropdownToggle style={{background:"white", width:"100%", border:"none", textAlign:"left"}}>
                                       <div className={"btn"} style={{background:"#070707", color:"white", borderRadius:"200px"}} >Download</div>
                                   </DropdownToggle>

                                   <Dropdown.Menu>
                                       <Dropdown.Item>
                                           <button onClick={generatePDF} className={"btn"} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                               <img  src={pdf}/>
                                           </button>

                                       </Dropdown.Item>
                                       <Dropdown.Item >
                                           <button className={"btn"} onClick={handlePrintToImagejpg} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                               <img src={jpg}/>
                                           </button>
                                       </Dropdown.Item>
                                       <Dropdown.Item>
                                           <button className={"btn"} onClick={handlePrintToImage} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                               <img  src={png}/>
                                           </button>

                                       </Dropdown.Item>
                                   </Dropdown.Menu>
                               </Dropdown>
                           </div>

                       </div>
                   </div>
                   <div className={""}>
                       <div className={"row d-flex justify-content-lg-center" } id={"report"}  ref={componentref}  >
                           <div className={"certificate col-md-12 "} >
                               <div className={"certificate-info"}>
                                   <h3>
                                       Certificate awarded to
                                   </h3>
                                   <h2>
                                       {fullname}
                                   </h2>
                                   <hr />
                                   <h5>Reference No: {refnumber}</h5>
                                   <h5 style={{marginTop:"3.375rem"}}>
                                       For successfully completing
                                   </h5>
                                   <img src={Ux} style={{width:"40%"}}/>

                                   <div className={"row"}>
                                       <div className={"col-md-9"}>
                                           <img src={Ya} style={{marginTop:"4.063rem", width:"60%"}}/>
                                       </div>
                                       <div className={"col-md-3 d-flex justify-content-end"}>
                                           <QRCode value={`https://researchbookmark.io/certificate-verification/${refnumber}`}  renderAs="canvas" />
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>

               </div>
           </MediaQuery>
           <MediaQuery maxWidth={1200} minWidth={461}>
               <div className={"container"} style={{marginTop:"7rem", marginBottom:"2rem"}}>
                   <div className={"row"}>
                       <div className={"col-md-8"}>
                           <div className={"container"} style={{background:"#F5F3FF", padding:"1.813rem 2.375rem"}}>
                               <div className={"row d-flex justify-content-lg-center" }>

                               </div>
                           </div>
                       </div>
                       <div className={"col-md-4"} style={{paddingLeft:"3.125rem"}}>
                           <h6 style={{fontSize:"18px", fontWeight:"500"}}>
                               Certificate awarded to:
                           </h6>
                           <div className={"container-fluid p-0 d-flex align-items-center"} style={{marginTop:"1rem"}}>
                               <div className={"avatar-holder"} style={{display:"inline-block", height:"3.875rem", width:"3.875rem", background:"#AB9AFF"}}></div>
                               <h6 style={{display:"inline-block", marginLeft:"0.688rem"}}>{fullname}</h6>
                           </div>
                           <h6 style={{marginTop:"1.313rem"}}>
                               For this course:
                           </h6>
                           <img src={poster} height="12%"/>
                           <h6 style={{marginTop:"1rem", fontSize:"18px", fontWeight:"500"}}>UX Research Skillshop</h6>
                           <div className={"row "} style={{marginTop:"0.563rem"}}>
                               <div className={"col-md-6 d-flex align-items-center "}>
                                   <div>
                                       <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>April 14 & 15</span>
                                   </div>

                               </div>
                               <div className={"col-md-6"}>
                                   <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>10AM-2PM (EST)</span>
                               </div>
                           </div>
                           <div className={"container-fluid p-0 workshop"} style={{marginTop:"2rem"}}>
                               <Dropdown>
                                   <DropdownToggle style={{background:"white", width:"100%", border:"none", textAlign:"left"}}>
                                       <div className={"btn"} style={{background:"#070707", color:"white", borderRadius:"200px"}} >Download</div>
                                   </DropdownToggle>

                                   <Dropdown.Menu>
                                       <Dropdown.Item>
                                           <button onClick={generatePDF} className={"btn"} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                               <img  src={pdf}/>
                                           </button>

                                       </Dropdown.Item>
                                       <Dropdown.Item >
                                           <button className={"btn"} onClick={handlePrintToImagejpg} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                               <img src={jpg}/>
                                           </button>
                                       </Dropdown.Item>
                                       <Dropdown.Item>
                                           <button className={"btn"} onClick={handlePrintToImage} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                               <img  src={png}/>
                                           </button>

                                       </Dropdown.Item>
                                   </Dropdown.Menu>
                               </Dropdown>
                           </div>

                       </div>
                   </div>
                   <div className={""}>
                       <div className={"row d-flex justify-content-lg-center" } id={"report"}  ref={componentref}  >
                           <div className={"certificate col-md-12 "} >
                               <div className={"certificate-info"}>
                                   <h3>
                                       Certificate awarded to
                                   </h3>
                                   <h2>
                                       {fullname}
                                   </h2>
                                   <hr />
                                   <h5>Reference No: {refnumber}</h5>
                                   <h5 style={{marginTop:"3.375rem"}}>
                                       For successfully completing
                                   </h5>
                                   <img src={Ux} style={{width:"40%"}}/>

                                   <div className={"row"}>
                                       <div className={"col-md-9"}>
                                           <img src={Ya} style={{marginTop:"4.063rem", width:"60%"}}/>
                                       </div>
                                       <div className={"col-md-3 d-flex justify-content-end"}>
                                           <QRCode value={`https://researchbookmark.io/certificate-verification/${refnumber}`}  renderAs="canvas" />
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>

               </div>
           </MediaQuery>

            <Footer />
        </>
    )
}