import React from 'react'
import {Link} from "react-router-dom";
import Logo from "../assets/research-bookmark-logotype-one-color-rgb.svg";
import usericon from "../assets/Union.png";
import {HiHome, HiSave, HiSearch, HiUsers} from "react-icons/hi";
import {FaBook, FaIdBadge, FaPaintBrush, FaUnlockAlt} from "react-icons/fa";
import Dropdown from 'react-bootstrap/Dropdown';

export default function Header () {
    const onLogout = (res) => {
        localStorage.clear()
        window.location.href='/'
    }
  return (
      <>
          <header className="bg-white border-bottom" style={{marginBottom:"0", marginTop:"0"}}>
              <div className="container-fluid" style={{paddingLeft:"5rem", paddingRight:"6.563rem"}}>
                  <nav className="navbar navbar-expand-lg navbar-light bg-white">
                      <Link className="navbar-brand" style={{ textDecoration:"none"}} to="/admin-collections-search">
                          <img src={Logo}/>
                      </Link>
                    {/*  {
                          this.state.token ? <button className="navbar-toggler" type="button" onClick={this.handleOpen} aria-label="Toggle navigation" style={{marginRight:".6rem"}}>
                              <span className="navbar-toggler-icon"></span>
                          </button> :   <a onClick={()=>this.handleModalOpen()} className="nav-link logbtndesk"><button className="btn btn-login">Login</button> </a>
                      }*/}
                      <div className="collapse navbar-collapse d-flex justify-content-end align-items-center" id="navbarSupportedContent">
                          <ul className="navbar-nav mr-auto">
                             
                              <Dropdown style={{marginRight:"3rem"}}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Menu
                                </Dropdown.Toggle>

                                <Dropdown.Menu style={{marginTop:"20px"}}>
                                    <Dropdown.Item href="/uxr-communities/all">Communities</Dropdown.Item>
                                    <Dropdown.Item href="/conference/all">Conferences</Dropdown.Item>
                                    <Dropdown.Item href="/vocabulary/all">Vocabularies</Dropdown.Item>
                                    <Dropdown.Item href="/volunteer_opportunity/all">Volunteer Opportunities</Dropdown.Item>
                                    <Dropdown.Item href="/research_methods/all">Research Methods</Dropdown.Item>
                                    <Dropdown.Item href="/books/all">Books</Dropdown.Item>
                                    <Dropdown.Item href="/podcasts/all">Podcasts</Dropdown.Item>
                                    <Dropdown.Item href="/courses/all">Courses</Dropdown.Item>
                                    <Dropdown.Item href="/research_tools/all">Research Tools</Dropdown.Item>
                                    <Dropdown.Item href="/admin-collections-list">Collections</Dropdown.Item>
                                    <Dropdown.Item href="/admin-add-collections">Add Collections</Dropdown.Item>
                                    <Dropdown.Item href="/admin-organizations">Organizations</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                                
                              
                             

                                          <div className="userprof">
                                              <li className="nav-item d-flex align-items-center dropdown ">
                                                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                                     role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}>
                                                      <img src={usericon} className="user-icon"/>
                                                  </a>
                                                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                      <div className="container" style={{paddingTop:"2.75rem"}}>
                                                          <img src={usericon} className="user-icon" style={{marginLeft: "2.288rem"}}/>  <span style={{marginLeft: "1.688rem", fontSize:"14px", fontWeight:"400"}}>Admin</span>
                                                      </div>
                                                      <div className="container px-4 nav-menu-items">
                                                          <div className="row" style={{paddingBottom:"5.688rem"}}>
                                                              <div className="col-md-4">
                                                                  <Link to="/admin-dashboard" style={{textDecoration:"none"}}>
                                                                      <span className="d-flex justify-content-center" ><HiHome className="nav-item-icon" /></span>

                                                                      <h6>Home</h6>
                                                                  </Link>
                                                              </div>
                                                              <div className="col-md-4" onClick={onLogout}>
                                                                  <span className="d-flex justify-content-center" ><FaUnlockAlt className="nav-item-icon" /></span>
                                                                  <h6>Logout</h6>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </ul>
                                              </li>
                                          </div>
                          </ul>
                      </div>
                  </nav>
              </div>

          </header>
      </>
  )
}
