import React, {Component} from 'react'
import Header from "../../Components/Landing/Header";
import "./webinar.css"
import Footer from "../../Components/Landing/Footer";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import MediaQuery from "react-responsive";
import eventsimg from "../../../assets/Rectangle 816.png"
import LoginModal from "../Modals/LoginModal";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import Calendar from "../../../assets/Calendar.png";
import {MdAccessTime, MdKeyboardArrowRight, MdPersonOutline, MdVerifiedUser} from "react-icons/md";
import Speakers from "./Speakers";
import Recordings from "./Recordings";
import WebinarRegister from "./WebinarRegister";
import {FaShare} from "react-icons/fa";
import {Link} from "react-router-dom";
import useEventbrite from 'react-eventbrite-popup-checkout';
import WebinarEventModal from './WebinarEventModal';

class Webinar extends Component{
    state= {
        speakers:[],
        events:[],
        banners:[],
        EMAIL:"",
        FNAME:"",
        LNAME:"",
        TEXTB0F5E:"",
        MMERGE8:"",
        MMERGE10:"",
        show:false,
        activeview:1,
        success:false,
        eventId:0,

    }

    componentDidMount() {

      EventsHandler("Webinar page opened",  document.URL)

        axios.get(buildAPIUrl('v1/events')).then((res)=>{
            this.setState({
                events:res.data,
                eventId:res.data[0].link.split('/').pop().split('-').pop().split('?')[0]
            })
            const script = document.createElement('script');
            script.src = "https://www.eventbrite.com/static/widgets/eb_widgets.js";
            script.async = true;
            document.body.appendChild(script);
            script.onload = () => {
                window.EBWidgets.createWidget({
                    widgetType: 'checkout',
                    eventId: this.state.eventId,
                    modal: true,
                    modalTriggerElementId: "eventbrite-widget-modal-trigger-"+this.state.eventId,
                    iframeContainerHeight: 425,
                });
            };

            return () => {
                document.body.removeChild(script);
            };
        }).catch((error)=>{

        })
        axios.get(buildAPIUrl('v1/banners')).then((res)=>{
            this.setState({
                banners:res.data
            })

        }).catch((error)=>{

        })
        if(!localStorage.getItem('webinar')){
            setTimeout(()=>{
                localStorage.setItem('webinar', "1")
                window.location.reload()
            }, 100)
        }
        if(window.location.href.includes("videos")){
            this.setState({
                activeview:4
            })
        }



    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    componentWillUnmount() {
        localStorage.removeItem('webinar')
    }

    handleInput = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    copyLink = ()=>{
        navigator.clipboard.writeText(window.location.href).then(()=>{
            this.setState({
                success:true
            })
            setTimeout(()=>{
                this.setState({
                    success:false
                })
            }, 5000)
        })

    }
    setActiveView = (viewid, page, id)=>{
        EventsHandler("Webinar link clicked",  document.URL, "none", page)
        this.setState({
            activeview:viewid
        })
        if(id===3){
            window.location.reload()
        }
        window.location.assign(`/webinar${id}`)

    }

    
    render() {

        return (
            <div>
                <Header type={"webinar"}/>
                <LoginModal openLoginModal={this.handleModalOpen}
                            closeLoginModal={this.handleModalClose}
                            show={this.state.show} type={this.state.logintype}/>
                <MediaQuery minWidth={1200} >
                    <div className={"desktop"} style={{marginTop:"4rem", marginBottom:"2rem"}}>
                        <div className="container-fluid p-0 m-0 webinarbanner">
                            {
                                this.state.banners.map((banner)=>(
                                    <>
                                        <img src={banner.image} style={{width:"100%"}}/>
                                    </>
                                ))
                            }

                        </div>
                        <div className={"container-fluid px-5"} style={{background:"#F7F5FF"}}>
                            <div className={"row d-flex justify-content-center"}>
                                <div className={"col-md-8"}>
                                    <div className="webinar-content pt-3">

                                        {this.state.events.slice(0,1).map((event)=>(
                                            <>
                                                <h5 style={{marginTop:"1.375", fontWeight:"600", fontSize:"25px", color:"#333333", lineHeight:" 124.52%"}}>
                                                    {event.name}
                                                </h5>
                                                <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                    <div className={"col-md-12 d-flex align-items-center "}>
                                                        <div>
                                                            <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>{event.date} {event.time_zone}</span>
                                                        </div>
                                                    </div>
                                                    {/*<div className={"col-md-2"}>
                                                <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>5pm - 6pm</span>
                                            </div>*/}
                                                </div>
                                                {/*<h6 style={{color:"#00944D", fontSize:"16px", fontWeight:"400", marginTop:"0.75rem"}}>  <span><MdPersonOutline style={{color:"#200E32"}}/></span> 125 Attendees</h6>*/}
                                            </>
                                        ))}


                                    </div>
                                </div>
                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div style={{marginTop:"1.5rem"}}>

                                        <div id="my-app">
                                            <button  id={"eventbrite-widget-modal-trigger-"+this.state.eventId} className={"btn"} style={{color:"white", display:"block",background:"#070707", borderRadius:"200px", padding:"0.5rem 1.75rem"}}>Register now</button>
                                        </div>
                                        <button className={"btn"} onClick={this.copyLink} style={{marginTop:".5rem", border:"1px solid #767676", borderRadius:"200px", padding:"0.5rem 1.75rem", display:"block"}}>Share <FaShare /></button>
                                        <Link to={"webinar-recordings"} className={"btn"} style={{marginTop:".5rem", border:"1px solid #767676", borderRadius:"200px", padding:"0.5rem 1.75rem"}}>Webinar Recordings <MdKeyboardArrowRight /></Link>
                                        {
                                            this.state.success ? <>
                                                <p className={"text-success"}>Link copied</p>
                                            </> : <></>
                                        }
                                    </div>

                                </div>
                                <div className={"webinar-content"} style={{marginTop:"0"}}>
                                    <ul>
                                        <li className={this.state.activeview===1 ? "active": ""} onClick={()=>this.setActiveView(1, "Info", "#info")}>Info</li>
                                        <li className={this.state.activeview===2 ? "active": ""} onClick={()=>this.setActiveView(2, "Speakers", "#speakers")}>Speakers</li>
                                        {/* <li className={this.state.activeview===3 ? "active": ""} onClick={()=>this.setActiveView(3, "Register", "#register")}>Register</li> */}
                                        {/* <li className={this.state.activeview===4 ? "active": ""} onClick={()=>this.setActiveView(4, "Webinars", "#videos")}>Watch our past webinars</li>*/}

                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className={"webinar-content px-5"}>
                            <div className={this.state.activeview===1 ? "row" : "d-none"}>
                                <div className={"col-md-8 h-100"}>
                                    {this.state.events.slice(0,1).map((event)=>(
                                        <>
                                            <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                        </>
                                    ))}

                                </div>
                                <div className={"col-md-4"} style={{marginTop:"3.125rem"}}>
                                    {this.state.events.slice(1,2).map((event)=>(
                                        <div className={"container"} style={{background:" #F7F7F7", borderRadius:"10px", padding:"2.375rem 1.875rem"}}>
                                            <h1 style={{fontSize:"18px", fontWeight:"600", color:"#333333"}}>
                                                Mastering UX: Free Online Webinar
                                            </h1>
                                            <img src={event.thumbnail} style={{width:"100%", marginTop:"1.813rem"}}/>
                                            <div className={"col-md-12"}>
                                                <h6 className={"events-item-title"} style={{fontSize:"14px"}}>
                                                    {event.name}
                                                </h6>
                                                <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                    <div className={"col-md-12 d-flex align-items-center "}>
                                                        <div>
                                                            <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>{event.date}</span>
                                                        </div>

                                                    </div>
                                                    {/*<div className={"col-md-6"}>*/}
                                                    {/*    <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>5pm - 6pm</span>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <p className={"events-item-description"} style={{marginTop:"0.563rem", fontSize:"14px", lineHeight:"18.83px"}}>
                                                    <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                                </p>
                                                <a href={event.link}>
                                                    <h6 className={"events-register"} style={{marginTop:"0.563rem", color:"#522FFF", fontSize:"14px", textDecoration:"none"}}>
                                                        Register
                                                    </h6>
                                                </a>

                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                            <Speakers activeview={this.state.activeview} />
                            {/*<Recordings activeview={this.state.activeview} />*/}
                            {/* <WebinarRegister activeview={this.state.activeview} /> */}

                        </div>



                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"mobile"}>
                        <div className="container-fluid p-0 m-0 webinarbanner">
                            {
                                this.state.banners.map((banner)=>(
                                    <>
                                        <img src={banner.image} style={{width:"100%"}}/>
                                    </>
                                ))
                            }

                        </div>
                        <div className={"container-fluid p-0 m-0"} style={{background:"#F7F5FF"}}>
                            <div className={"row"}>
                                <div className={"col-md-8"}>
                                    <div className="webinar-content" style={{paddingTop:"2rem"}}>

                                        {this.state.events.slice(0,1).map((event)=>(
                                            <>
                                                <h5 style={{marginTop:"1.375", fontWeight:"600", fontSize:"25px", color:"#333333", lineHeight:" 124.52%"}}>
                                                    {event.name}
                                                </h5>
                                                <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                    <div className={"col-md-4 d-flex align-items-center "}>
                                                        <div>
                                                            <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>{event.date} {event.time_zone}</span>
                                                        </div>
                                                    </div>
                                                    {/*<div className={"col-md-2"}>
                                                <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>5pm - 6pm</span>
                                            </div>*/}
                                                </div>
                                                {/*<h6 style={{color:"#00944D", fontSize:"16px", fontWeight:"400", marginTop:"0.75rem"}}>  <span><MdPersonOutline style={{color:"#200E32"}}/></span> 125 Attendees</h6>*/}
                                            </>
                                        ))}

                                        <ul>
                                        <li className={this.state.activeview===1 ? "active": ""} onClick={()=>this.setActiveView(1, "Info", "#info")}>Info</li>
                                        <li className={this.state.activeview===2 ? "active": ""} onClick={()=>this.setActiveView(2, "Speakers", "#speakers")}>Speakers</li>
                                        <li className={this.state.activeview===3 ? "active": ""} onClick={()=>this.setActiveView(3, "Register", "#register")}>Register</li>
                                            {/* <li className={this.state.activeview===4 ? "active": ""} onClick={()=>this.setActiveView(4)}>Watch our past webinars</li> */}

                                        </ul>
                                    </div>
                                </div>
                                <div className={"col-md-4 d-flex align-items-center"}>
                                   {/* <div>
                                        <button onClick={()=>this.setActiveView(3)} className={"btn"} style={{color:"white", display:"block",background:"#070707", borderRadius:"200px", padding:"0.75rem 1.75rem"}}>Register now</button>
                                        <button className={"btn"} onClick={this.copyLink} style={{marginTop:".5rem", border:"1px solid #767676", borderRadius:"200px", padding:"0.75rem 1.75rem", display:"block"}}>Share <FaShare /></button>
                                        <Link to={"webinar-recordings"} className={"btn"} style={{marginTop:".5rem", border:"1px solid #767676", borderRadius:"200px", padding:"0.75rem 1.75rem"}}>Webinar Recordings <MdKeyboardArrowRight /></Link>
                                        {
                                            this.state.success ? <>
                                                <p className={"text-success"}>Link copied</p>
                                            </> : <></>
                                        }
                                    </div>*/}

                                </div>
                            </div>

                        </div>
                        <div className={"webinar-content"}>
                            <div className={this.state.activeview===1 ? "row" : "d-none"}>
                                <div className={"col-md-8 h-100"}>
                                    {this.state.events.slice(0,1).map((event)=>(
                                        <>
                                            <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                        </>
                                    ))}

                                </div>
                                <div className={"col-md-4"} style={{marginTop:"3.125rem"}}>
                                    {this.state.events.slice(1,2).map((event)=>(
                                        <div className={"container"} style={{background:" #F7F7F7", borderRadius:"10px", padding:"2.375rem 1.875rem"}}>
                                            <h1 style={{fontSize:"18px", fontWeight:"600", color:"#333333"}}>
                                                Mastering UX: Free Online Webinar
                                            </h1>
                                            <img src={event.thumbnail} style={{width:"100%", marginTop:"1.813rem"}}/>
                                            <div className={"col-md-12"}>
                                                <h6 className={"events-item-title"} style={{fontSize:"14px"}}>
                                                    {event.name}
                                                </h6>
                                                <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                    <div className={"col-md-12 d-flex align-items-center "}>
                                                        <div>
                                                            <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>{event.date}</span>
                                                        </div>

                                                    </div>
                                                    {/*<div className={"col-md-6"}>*/}
                                                    {/*    <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>5pm - 6pm</span>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <p className={"events-item-description"} style={{marginTop:"0.563rem", fontSize:"14px", lineHeight:"18.83px"}}>
                                                    <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                                </p>
                                                <a href={event.link}>
                                                    <h6 className={"events-register"} style={{marginTop:"0.563rem", color:"#522FFF", fontSize:"14px", textDecoration:"none"}}>
                                                        Register
                                                    </h6>
                                                </a>

                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                            <Speakers activeview={this.state.activeview} />
                            {/* <Recordings activeview={this.state.activeview} /> */}
                            <WebinarRegister activeview={this.state.activeview} />
                            
                        </div>

                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className={"tablet"}>
                        <div className="container-fluid p-0 m-0 webinarbanner">
                            {
                                this.state.banners.map((banner)=>(
                                    <>
                                        <img src={banner.image} style={{width:"100%"}}/>
                                    </>
                                ))
                            }

                        </div>
                        <div className={"container-fluid p-0 m-0"} style={{background:"#F7F5FF"}}>
                            <div className={"row"}>
                                <div className={"col-md-8"}>
                                    <div className="webinar-content" style={{paddingTop:"2rem"}}>
                                        {this.state.events.slice(0,1).map((event)=>(
                                            <>
                                                <h5 style={{marginTop:"1.375", fontWeight:"600", fontSize:"25px", color:"#333333", lineHeight:" 124.52%"}}>
                                                    {event.name}
                                                </h5>
                                                <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                    <div className={"col-md-12 d-flex align-items-center "}>
                                                        <div>
                                                            <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>{event.date} {event.time_zone}</span>
                                                        </div>
                                                    </div>
                                                    {/*<div className={"col-md-2"}>
                                                <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>5pm - 6pm</span>
                                            </div>*/}
                                                </div>
                                                {/*<h6 style={{color:"#00944D", fontSize:"16px", fontWeight:"400", marginTop:"0.75rem"}}>  <span><MdPersonOutline style={{color:"#200E32"}}/></span> 125 Attendees</h6>*/}
                                            </>
                                        ))}


                                    </div>
                                </div>
                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div>
                                        <WebinarEventModal/>
                                        <button className={"btn"} onClick={this.copyLink} style={{marginTop:".5rem", border:"1px solid #767676", borderRadius:"200px", padding:"0.75rem 1.75rem", display:"block"}}>Share <FaShare /></button>
                                        <Link to={"webinar-recordings"} className={"btn"} style={{marginTop:".5rem", border:"1px solid #767676", borderRadius:"200px", padding:"0.75rem 1.75rem"}}>Webinar Recordings <MdKeyboardArrowRight /></Link>
                                        {
                                            this.state.success ? <>
                                                <p className={"text-success"}>Link copied</p>
                                            </> : <></>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className={"webinar-content"}>
                                <ul>
                                <li className={this.state.activeview===1 ? "active": ""} onClick={()=>this.setActiveView(1, "Info", "#info")}>Info</li>
                                        <li className={this.state.activeview===2 ? "active": ""} onClick={()=>this.setActiveView(2, "Speakers", "#speakers")}>Speakers</li>
                                        <li className={this.state.activeview===3 ? "active": ""} onClick={()=>this.setActiveView(3, "Register", "#register")}>Register</li>
                                    {/* <li className={this.state.activeview===4 ? "active": ""} onClick={()=>this.setActiveView(4)}>Watch our past webinars</li> */}

                                </ul>
                            </div>


                        </div>
                        <div className={"webinar-content"}>
                            <div className={this.state.activeview===1 ? "row" : "d-none"}>
                                <div className={"col-md-8 h-100"}>
                                    {this.state.events.slice(0,1).map((event)=>(
                                        <>
                                            <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                        </>
                                    ))}

                                </div>
                                <div className={"col-md-4"} style={{marginTop:"3.125rem"}}>
                                    {this.state.events.slice(1,2).map((event)=>(
                                        <div className={"container"} style={{background:" #F7F7F7", borderRadius:"10px", padding:"2.375rem 1.875rem"}}>
                                            <h1 style={{fontSize:"18px", fontWeight:"600", color:"#333333"}}>
                                                Mastering UX: Free Online Webinar
                                            </h1>
                                            <img src={event.thumbnail} style={{width:"100%", marginTop:"1.813rem"}}/>
                                            <div className={"col-md-12"}>
                                                <h6 className={"events-item-title"} style={{fontSize:"14px"}}>
                                                    {event.name}
                                                </h6>
                                                <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                    <div className={"col-md-12 d-flex align-items-center "}>
                                                        <div>
                                                            <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>{event.date}</span>
                                                        </div>

                                                    </div>
                                                    {/*<div className={"col-md-6"}>*/}
                                                    {/*    <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>5pm - 6pm</span>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <p className={"events-item-description"} style={{marginTop:"0.563rem", fontSize:"14px", lineHeight:"18.83px"}}>
                                                    <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                                </p>
                                                <a href={event.link}>
                                                    <h6 className={"events-register"} style={{marginTop:"0.563rem", color:"#522FFF", fontSize:"14px", textDecoration:"none"}}>
                                                        Register
                                                    </h6>
                                                </a>

                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                            <Speakers activeview={this.state.activeview} />
                            {/* <Recordings activeview={this.state.activeview} /> */}
                            <WebinarRegister activeview={this.state.activeview} />
                        </div>
                    </div>
                </MediaQuery>
                <Footer />
            </div>
        );
    }
}
export default Webinar