import React from "react";
import SkeletonElement from "./SkeletonElement";

const SkeletonImage= () => {
    return (
        <div className="skeleton-wrapper">
            <div className="skeleton-results">
                <SkeletonElement type="image" />
                <SkeletonElement type="text" />
                <SkeletonElement type="text" />
            </div>
        </div>
    )
}
export default SkeletonImage