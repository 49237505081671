import Header from "../Header";
import {
    CardCvcElement, CardElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import PaymentForm from "../../Payment/PaymentForm";

import React, {useEffect, useMemo, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import Select from "react-select";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import countryList from "react-select-country-list";
import Footer from "../Footer";

export default function NewUserPayment(props) {
    const stripe = useStripe()
    const elements = useElements()
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);
    const [success, setSuccess]=useState(false)
    const [countries, setCountries]=useState([])
    const [country, setCountry] = useState("")
    const [defaultCountry, setDefaultCountry]=useState("")
    const[loading, setLoading]=useState(false)
    const[filled, setFilled]=useState(false)
    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(true);
    async function stripeTokenHandler(token) {
        const paymentData = {token: token.id};
       if(localStorage.getItem('freetrial')){
           const response  =  await axios.post(buildAPIUrl('v1/subscriptions/'), {
               card_token:token.token.id,
               plan_id:localStorage.getItem("pplan"),
               pre_subscription:true
           }, {
               headers: {
                   'Authorization': `Bearer ${localStorage.getItem('token')}`
               },
           }).then((res)=>{
               const startfree = axios.get(buildAPIUrl('v1/users/trial_period/?is_community_group=0'), {
                   headers: {
                       Authorization: `Bearer ${localStorage.getItem('token')}`
                   }
               })
               setSuccess(true)
               setTimeout(()=>{
                   localStorage.removeItem('freetrial')
                   window.location.assign('/trial-success-page')
               }, 3000)

           }).catch((error)=>{

           })
       } else {
           const response  =  await axios.post(buildAPIUrl('v1/subscriptions/'), {
               card_token:token.token.id,
               plan_id:localStorage.getItem("pplan"),
           }, {
               headers: {
                   'Authorization': `Bearer ${localStorage.getItem('token')}`
               },
           }).then((res)=>{
               window.location.assign('/payment-success-page')
               setLoading(false)
           }).catch((error)=>{

           })
       }
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
        if (paymentMethod) {
            const cardElement = elements.getElement(CardElement);
            let token  = await stripe.createToken(cardElement);
            await stripeTokenHandler(token)
        }
    };
    const handleChange = async (event) => {
        // 4️⃣ Listen for changes in the CardElement and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };
    useEffect(()=>{

    }, [])
    return (
        <>
            <Header />
            <div className={"row new-user-header d-flex justify-content-center"} style={{marginBottom:"18.75rem"}}>
                <div className={"col-md-7"}>
                    <h6 className={"text-start"}>Payment Method</h6>
                    <div className={"checkout-form mt-5"}>
                        <form onSubmit={handleSubmit}>
                            <CardElement
                                id="card-element"
                                options={props.cardStyle}
                                onChange={handleChange}
                            />
                            <div className={"container d-flex justify-content-center mt-3"}>
                                <button disabled={loading || disabled} id="submit" className="stripe_submit_button">
                                    <span id="button-text" style={{color:"white"}}>
                                      {loading ? "Processing..." : window.location.hash==="#trial" ? "Start your 14 days free trial" : `Confirm Payment $${localStorage.getItem('useramount')}`}
                                    </span>
                                </button>
                            </div>

                            {error && (
                                <div className="card-error alert alert-danger" role="alert">{error}</div>
                            )}
                            <div className={"container"}>
                                {
                                    window.location.hash==="#trial" ? <>
                                        <p className={"text-center"} style={{fontSize:"15px", lineHeight:"18px", fontWeight:"400", marginTop:"27px"}}>Please provide your card information to get started.
                                        </p>
                                        <p className={"text-center"} style={{fontSize:"15px", lineHeight:"18px", fontWeight:"400", marginTop:"18px"}}>
                                            Don't worry—you won't be charged until your 7-days trial period ends. <br/> You can cancel your subscription at any time in the settings.  </p>
                                    </> : <></>
                                }

                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <Footer />
        </>
    )
}