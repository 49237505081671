import {MdAdd, MdKeyboardBackspace} from "react-icons/md";
import React, {useEffect, useState} from "react";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import {FaTrash} from "react-icons/fa";
import Header from "../../Landing/Header";

export default function SpecialCollectionsDashboard(){
    const[users, setUsers]=useState([])
    const getUsers = async ()=>{
        const userlist = await axios.get(buildAPIUrl(`v1/collections/organisations?organisation_id=${localStorage.getItem('orgid')}`), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        setUsers(userlist.data)
        console.log(userlist.data)
    }
    const handleBack = ()=>{
        window.location.assign("/special-collections")
    }
    const removeMember = async(id, type, status)=>{
        //type 1 is for changing the user type from admin to normal user and vice versa
        //Type 2 is for deleting a user from an organization.
        if(type===1){
            await axios.patch(buildAPIUrl(`v1/collections/organisations/${id}/`), {
                is_admin:!status
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        } else {
            await axios.delete(buildAPIUrl(`v1/collections/organisations/${id}/`), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }

            })

        }

        getUsers()
    }
    useEffect(()=>{
        getUsers()
    }, [])
    return(
       <>
           <Header />
           <div className={"container-fluid bg-image   "} style={{ height:"14.625rem"}}>
               <div className={"container-fluid d-flex justify-content-center align-items-center"} style={{height:"80%"}}>
                   <h6 style={{fontSize:"30px", color:"#FFFFFF", fontWeight:"600"}}>Members List</h6>
               </div>
               <div className={"container d-flex justify-content-end align-items-end"}>


               </div>
           </div>
           <div className={"container"} style={{marginTop:"2rem"}}>
               <div className={"row d-flex justify-content-center"}>
                   <div className={"col-md-8"}>
                       <h6 onClick={handleBack} style={{color:"#522FFF", cursor:"pointer"}}><MdKeyboardBackspace /> Back to collections</h6>
                   </div>
               </div>
               <table className={"w-100 table table-bordered table-striped"}>
                   <thead>
                        <tr >

                            <th >Email</th>
                            <th >User Type</th>
                            <th>Remove</th>
                        </tr>
                   </thead>
                   <tbody>
                   {
                       users.length > 0 ? <>
                           {
                               users.filter((user)=>{return user.is_active}).map((user)=>(
                                   <tr key={user.id}>
                                       <td>{user.member}</td>
                                       {
                                           localStorage.getItem('email') === user.member ? <>
                                               <td style={{cursor:"pointer"}} className={"text-success"}>{user.is_admin ? "Admin" : "Normal User"}</td>
                                           </> : <>
                                               <td style={{cursor:"pointer"}} className={"text-success"} onClick={()=>removeMember(user.id, 1, user.is_admin)}>{user.is_admin ? "Admin" : "Normal User"}</td>
                                           </>
                                       }

                                       <td>
                                           {
                                               localStorage.getItem('email')===user.member ? <> Me </> : <>
                                                   {
                                                       user.is_active ? <>
                                                           <p className={"text-danger"} style={{cursor:"pointer"}} onClick={()=>removeMember(user.id, 2, true)}> <FaTrash /> Remove</p>
                                                       </> : <>
                                                           <p className={"text-danger"} > Removed</p></>
                                                   }
                                               </>
                                           }
                                       </td>
                                   </tr>
                               ))
                           }
                       </> : <></>
                   }


                   </tbody>
               </table>
           </div>
       </>
    )
}