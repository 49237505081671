import Header from "../Header";
import React, {useEffect, useState} from "react";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import {Accordion, Form} from "react-bootstrap";
import {MdKeyboardArrowDown} from "react-icons/md";

export default function AddCollectionsManually() {
    const [collectionDetails, setCollectionDetails] = useState({
        title:"",
        url:"",
        snippet:""
    })
    const[groupID, setGroupID] = useState(null)
    const[subGroupID, setSubGroupID] = useState(null)
    const [groups, setGroups]=useState([])
    const [subGroups, setSubGroups]=useState([])
    const [collectionid, setCollectionid]=useState(null)
    const[filteritems, setFilteritems]=useState([])
    const getGroups = async ()=>{
        const groupNames = await axios.get(buildAPIUrl('v1/collections/group/'))
        setGroups(groupNames.data)
    }
    const handleGetSubGroups = async (e) => {
        const subgroup = await axios.get(buildAPIUrl(`v1/collections/sub_group/?group=${e.target.value}`))
        setGroupID(e.target.value)
        setSubGroups(subgroup.data)
        console.log(subgroup.data)

    }
    const [success, setSuccess]=useState(false)
    const handleSave = async (e) => {
        const saveCollection = await axios.post(buildAPIUrl('v1/collections/main/'), {
            title: collectionDetails.title,
            link: collectionDetails.url,
            group: groupID,
            sub_group: filteritems,
            snippet:collectionDetails.snippet,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        setSuccess(true)
        setCollectionDetails({
            title:"",
            url:"",
            snippet:""
        })
        setFilteritems([])
        setTimeout(()=>{
            setSuccess(false)
            window.location.reload()
        }, 3000)

    }
    const handleSetSubGroups = async (e)=>{
       await setSubGroupID([e.target.value])
        console.log(e.target.value)
    }
    async function onChangeHandler (event){
        await setCollectionDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value};
        });
    }
//Save collection when user clicks on the checkbox
   const  handleCheckCollection = async (sub_category,group) => {
        setCollectionid(group)
    }
  const  handleCheck = async (e) => {
        const isChecked = e.target.checked
        if (isChecked) {
            await setFilteritems([...filteritems, e.target.value])
        } else {
            const index = filteritems.indexOf(e.target.value);
            filteritems.splice(index, 1);
            await setFilteritems(filteritems)
        }
    }
    useEffect(()=>{
        getGroups()
    }, [])
    return (
        <>
            <Header />
            <div className={"container"}>
                <div className={"row mt-3"}>
                    <div className={"col-md-6"}>
                        <div className={"card border"}>
                            <div className={"card-header"}>
                                Add Collections
                            </div>
                            <div className={"card-body mt-3"}>
                                {success ? <>
                                <p className={"text-success"}>Article saved successfull</p>
                                </> : <> </>}
                              {/*  <Accordion>
                                    {
                                        groups.length > 0 ? <>
                                            <>
                                                {
                                                    groups.map((collection,key)=>(
                                                        <>
                                                            <Accordion.Item eventKey={key}>
                                                                <Accordion.Header >
                                                                    <div className={"row"}>
                                                                        <div className={"col-md-4"} onClick={()=>handleGetSubGroups(collection.id)}>
                                                                            <h6 style={{color:"#333333", fontSize:"16px"}}>{collection.name}<MdKeyboardArrowDown /></h6>
                                                                        </div>
                                                                        <div className={"col-md-8 d-flex justify-content-end"}>
                                                                            {
                                                                                success ? <>
                                                                                  <span className={collectionid === collection.id ? "" : "d-none"} style={{background:" #522FFF", color:"white", borderRadius:"200px", padding:"4px 11px"}}>
                                                                            Added
                                                                        </span>
                                                                                </> : <>

                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <ul>
                                                                        {
                                                                            subGroups.length > 0 ? <>
                                                                                {
                                                                                    subGroups.map((sub)=>(
                                                                                        <li style={{marginTop:"0.688rem"}}>
                                                                                            <div className="form-check">
                                                                                                <div className={"row"}>

                                                                                                    <div className={"col-md-10 "}>
                                                                                                        <label  className="form-check-label collec-check-label" htmlFor={sub.id} style={{fontSize:"16px"}}>
                                                                                                            {sub.name}
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className={"col-md-2 d-flex justify-content-end"}>
                                                                                                        <input  onChange={handleCheck} onClick={()=>handleCheckCollection(sub.id, collection.id)} className="form-check-input collec-check" type="checkbox" value={sub.id}
                                                                                                                id={sub.id}   />
                                                                                                    </div>
                                                                                                </div>


                                                                                            </div>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                            </> : <></>
                                                                        }


                                                                    </ul>


                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </>
                                                    ))
                                                }
                                            </></> : <></>
                                    }


                                </Accordion>*/}
                                <div className={"mb-3"}>
                                    <label>Group</label>
                                   <select name={"group"} className={"form-control"} onChange={handleGetSubGroups} >
                                       <option>----Select Group---</option>
                                       {
                                           groups.length > 0 ? <>
                                               {
                                                   groups.map((group)=>(
                                                       <option key={group.id} value={group.id}> {group.name}
                                                       </option>
                                                   ))
                                               }
                                           </> : <></>
                                       }
                                   </select>
                                </div>
                                <div className={"mb-3"}>

                                    <div className={"container-fluid p-0"}>
                                        <label>Sub groups</label>
                                        <div className={"row bg-light border mt-2 p-2"}>

                                        {
                                            subGroups.length > 0 ? <>
                                                {
                                                    subGroups.map((group)=>(
                                                        <div className={"col-md-12"}>
                                                            <Form.Check label={group.name} value={group.id} onChange={handleCheck} />
                                                        </div>
                                                    ))
                                                }
                                            </> : <>
                                                <p>This group does not have any sub-groups</p>
                                            </>
                                        }
                                        </div>
                                    </div>
                                </div>
                              {/*  <div className={"mb-3"}>
                                    <label>Sub groups</label>
                                    <div className={"container"}>
                                        {
                                            subGroups.length > 0 ? <>
                                                {
                                                    subGroups.map((group)=>(
                                                       <Form.Check label={group.name} value={group.id} />
                                                    ))
                                                }
                                            </> : <>
                                            <p>This group does not have any sub-groups</p>
                                            </>
                                        }
                                    </div>
                                    <select name={"group"} className={"form-control"} onChange={handleSetSubGroups}>
                                        <option>----Select Sub Group---</option>
                                        {
                                            subGroups.length > 0 ? <>
                                                {
                                                    subGroups.map((group)=>(
                                                        <option key={group.id} value={group.id}> {group.name}
                                                        </option>
                                                    ))
                                                }
                                            </> : <></>
                                        }
                                    </select>
                                </div>*/}
                                <div className={"mb-3"}>
                                    <label>Title</label>
                                    <input required={true} type={"text"} name={"title"} className={"form-control"} onChange={onChangeHandler}/>
                                </div>
                                <div className={"mb-3"}>
                                    <label>Url</label>
                                    <input required={true} type={"url"} name={"url"} className={"form-control"} onChange={onChangeHandler}/>
                                </div>
                                <div className={"mb-3"}>
                                    <label>Snippet</label>
                                    <textarea required={true} name={"snippet"} className={"form-control"} onChange={onChangeHandler}>

                                    </textarea>

                                </div>
                                <div className={"mb-3"}>
                                    <button className={"btn btn-primary"} onClick={handleSave} disabled={!filteritems.length > 0}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}