import React, {Component} from "react";
import Header from "../../Landing/Header";
import {Link} from "react-router-dom";
import Footer from "../../Landing/Footer";
import {MdArrowForward, MdArrowRight, MdClose, MdKeyboardArrowRight, MdOutlineRemoveRedEye} from "react-icons/md";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import MediaQuery from "react-responsive";
import PopularBanner from "../PopularBanner";
import conducting from "../../../../assets/Group 89899.png"
import planning from "../../../../assets/Group 898990.png"
import analyzing from "../../../../assets/Group 898991.png"
import presenting from "../../../../assets/Group 898992.png"
import padlock from "../../../../assets/Group 89910.png"
import logo from "../../../../assets/RB LOGO1.png"
import bluearrow from "../../../../assets/Group 89940.png";
import searchicon from "../../../../assets/Frame 39.png";
import {Modal} from "react-bootstrap";
import gem1 from "../../../../assets/Gemstone Pink.H031.png";

class SpecialCollections extends Component{
    state={
        q:"",
        notfound:false,
        links:[],
        communitycollections:[],
        filter:"",
        waitlistsuccess:false,
        waitlist:[],
        userorganizations:[],

     }
    handleGetCommunityCollections = ()=>{
        axios.get(buildAPIUrl('v1/collections/group/'))
            .then((res)=>{
                this.setState({
                    communitycollections:res.data
                })
            }).catch((error)=>{
        })
    }

    componentDidMount() {
        if(!localStorage.getItem('token')){
            window.location.assign("/")
        }
        axios.get(buildAPIUrl('v1/collections/'))
            .then((res)=>{
                this.setState({
                    collections:res.data
                })
                window.scrollTo(0,0)
            }).catch((error)=>{
        })
        axios.get(buildAPIUrl(`v1/featured_post/?category_id=2`)).then((res)=>{
            if(res.data ===undefined){
                this.setState({
                    notfound:true
                })
            } else {
                this.setState({
                    popular:res.data
                })
            }

        }).catch((error)=>{

        })
        //Get article phases
        axios.get(buildAPIUrl('v1/article_phases/')).then((res)=>{
            console.log(res.data)
            this.setState({
                links:res.data,
                starting:res.data[1],
                presenting:res.data[0],
                analyzing:res.data[2],
                conducting:res.data[3],
            })
        })
        axios.get(buildAPIUrl('v1/collections/group/'))
            .then((res)=>{
                this.setState({
                    communitycollections:res.data
                })
                window.scrollTo(0,0)
            }).catch((error)=>{
        })

        //Check user organization
        axios.get(buildAPIUrl('v1/collections/organisations/'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
           this.setState({
               userorganizations:res.data
           })
        })
        this.handleGetCommunityCollections()
        this.getWaitList()
    }
    handleInput = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    handleWaitlist = (id)=>{
        axios.post(buildAPIUrl('v1/collections/waitlist/'), {
            collection_group:id
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(()=>{
            this.handleGetCommunityCollections()
            this.setState({
                waitlistsuccess:true
            })
        }).catch((error)=>{

        })
    }
    hideWaitListModal = ()=>{
        this.setState({
            waitlistsuccess:false
        })
        this.getWaitList()
    }
    getWaitList = ()=>{
        axios.get(buildAPIUrl('v1/collections/waitlist/'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
            this.setState({
                waitlist:res.data
            })
        })
    }
    handleSubCollection = (org, img, name) =>{
        localStorage.setItem('orgname', name)
        localStorage.setItem('orgid', org)
        localStorage.setItem('orgimg', img)
    }
    render() {
        const filtereditems = this.state.communitycollections.filter((result)=> {
            if (window.location.hash) {
                //Get the users collections based on the assigned id
                //This function is used when the user clicks the link sent when they are invited to an organization
                //The #id is the organization id
                if (result.organisation===parseInt(window.location.hash.replace('#', ""))) {
                    return result
                }
            } else {
                if (this.state.filter === "") {
                    return result
                } else if (result.name.toLowerCase().includes(this.state.filter.toLowerCase())) {
                    return result
                }
            }

        })
        return (
            <>
                <Header />
                <Modal show={this.state.waitlistsuccess} size={"sm"} onHide={this.hideWaitListModal} >
                    <Modal.Body>
                        <div className={"container d-flex justify-content-end"}>
                            <MdClose onClick={this.hideWaitListModal} style={{fontSize:"18px"}} />
                        </div>
                        <div className={"container d-flex justify-content-center"}>
                            <img src={gem1} style={{height:"4.688rem", width:"4.688rem"}}/>

                        </div>
                        <div>
                            <h6 className={"text-center"}>You’ve been added to the waitlist!</h6>
                        </div>

                    </Modal.Body>
                </Modal>
                <MediaQuery minWidth={1200}>
                    <div className={"row"}>
                        <div className={"col-md-2"}></div>
                        <div className={"col-md-9"}>
                            <div className={"container resources-search-section"}>
                                <h4>
                                    Explore our Collections
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-2"}>

                        </div>
                        <div className={"col-md-9"}>
                            <div className={"container"} style={{marginTop:"1.25rem"}}>
                                <div className={"row"} style={{zIndex:"1000", position:"relative"}}>
                                    <div className={"col-md-10 col-10 p-0 m-0"}>
                                        <input className="search-field-text" type="text" name="q" onChange={this.handleInput} onClick={this.handleInput} value={this.state.filter} onBlur={this.handleLeaveInput}
                                               placeholder="Search for sources in our collection" style={{borderRadius:"100px 0px 0px 100px"}}/>
                                    </div>
                                    <div className={"col-md-2 col-2 p-0 m-0"}>
                                        <button onTouchStart={this.handleSearch} onClick={this.handleSearch} className="btn btn-search" style={{borderRadius:"0px 6px 6px 0px"}}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-2"}>
                            <div className={"card border static-nav"} style={{marginTop:"1.25rem"}}>
                                <h6>
                                    Navigation
                                </h6>
                                <ul>
                                    <li>
                                        <Link to={"/collections"}>Collections home</Link> </li>
                                    <li> <Link to={"/collections#everyday-collections"}> Everyday Collections</Link></li>
                                    <li> <Link to={"/collections#ux-foundations-collections"}>Ux Foundations Collections </Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className={"col-md-9"}>

                            <div className={"container"}>
                                <div className={"container-fluid"} style={{ padding:"3.5rem 2.875rem", marginTop:"1.25rem", background:"#FBFBFF"}}>
                                    <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                        Special Collections
                                    </h6>
                                    <p style={{fontSize:"14px", fontWeight:"400"}}>
                                        Industry and subject focused collections to give you the edge you need in your career
                                    </p>
                                    <div className="container row3 p-0">
                                        <div className=" border-0">
                                            <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                                {
                                                    filtereditems.length > 0 ? <>
                                                        {
                                                            filtereditems.slice(0,8).map((collection)=>(
                                                               /* <>
                                                                    {
                                                                        this.state.userorganizations.length > 0  ? <>
                                                                            {
                                                                                this.state.userorganizations.some((o)=>parseInt(o.organisation.id)===collection.organisation || o.type==="GENERAL") ? <>
                                                                                    <>
                                                                                        <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                            <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                                <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                    <div className={"row w-100"}>
                                                                                                        <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                            <img src={logo} />
                                                                                                        </div>

                                                                                                    </div>

                                                                                                </div>
                                                                                                <Link onClick={()=>this.handleSubCollection(collection.organisation, collection.image, collection.name)}  to={`/sub-collections/${collection.id}`} style={{textDecoration:"none"}}>
                                                                                                    <div className={"container-fluid d-flex justify-content-center bg-white"}>
                                                                                                        <div className="categories-content-main text-center">
                                                                                                            <div>
                                                                                                                <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                                <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                    {collection.name}
                                                                                                                </h6>
                                                                                                                <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                            </div>

                                                                                                            <div className={"container-fluid p-0 collection-footer text-center d-flex align-items-center justify-content-center"}>
                                                                                                                <h6>View Collection  </h6>

                                                                                                            </div>


                                                                                                        </div>

                                                                                                    </div>
                                                                                                </Link>
                                                                                            </div>

                                                                                        </div>
                                                                                    </>
                                                                                </> : <>
                                                                                    {
                                                                                        this.state.waitlist.some((o)=>o.collection_group===collection.id) ? <>
                                                                                            <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                                <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                                    <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                        <div className={"row w-100"}>
                                                                                                            <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                                <img src={logo} />
                                                                                                            </div>
                                                                                                            <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                                                <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                                                    <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>

                                                                                                    <div className={"container d-flex justify-content-center bg-white"}>
                                                                                                        <div className="categories-content-main text-center">
                                                                                                            <div>
                                                                                                                <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                                <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                    {collection.name}
                                                                                                                </h6>
                                                                                                                <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                            </div>

                                                                                                            <div className={"container-fluid  collection-footer p-0 text-center d-flex align-items-center justify-content-center"}>
                                                                                                                <h6 >Added to waitlist  </h6>

                                                                                                            </div>


                                                                                                        </div>

                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>
                                                                                        </> : <>
                                                                                            <div className="col-md-3 d-flex justify-content-center"  onClick={()=>this.handleWaitlist(collection.id)} style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                                <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                                    <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                        <div className={"row w-100"}>
                                                                                                            <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                                <img src={logo} />
                                                                                                            </div>
                                                                                                            <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                                                <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                                                    <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>

                                                                                                    <div className={"container d-flex justify-content-center bg-white"}>
                                                                                                        <div className="categories-content-main text-center">
                                                                                                            <div>
                                                                                                                <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                                <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                    {collection.name}
                                                                                                                </h6>
                                                                                                                <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                            </div>

                                                                                                            <div className={"container-fluid p-0 text-center d-flex  collection-footer align-items-center justify-content-center"}>
                                                                                                                <h6>Coming soon  </h6>

                                                                                                            </div>


                                                                                                        </div>

                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>
                                                                                        </>
                                                                                    }

                                                                                </>
                                                                            }
                                                                        </> : <>
                                                                            <>
                                                                            </>


                                                                        </>
                                                                    }

                                                                </>*/
                                                                <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                    <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                        <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                            <div className={"row w-100"}>
                                                                                <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                    <img src={logo} />
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                        <Link onClick={()=>this.handleSubCollection(collection.organisation, collection.image, collection.name)}  to={`/special-collections/${collection.id}/${collection.name.replaceAll(" ", "-")}`} style={{textDecoration:"none"}}>
                                                                            <div className={"container-fluid d-flex justify-content-center bg-white"}>
                                                                                <div className="categories-content-main text-center">
                                                                                    <div>
                                                                                        <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                        <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                            {collection.name}
                                                                                        </h6>
                                                                                        <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                    </div>

                                                                                    <div className={"container-fluid p-0 collection-footer text-center d-flex align-items-center justify-content-center"}>
                                                                                        <h6>View Collection  </h6>

                                                                                    </div>


                                                                                </div>

                                                                            </div>
                                                                        </Link>
                                                                    </div>

                                                                </div>
                                                            ))
                                                        }

                                                    </> : <>

                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>



                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"mobile"}>
                        <div className={"container resources-search-section"}>
                            <h4>
                                Explore our Collections
                            </h4>
                        </div>
                        <div className={"container"} style={{marginTop:"2rem"}}>
                            <div className={"row px-3"} style={{zIndex:"1000", position:"relative"}}>
                                <div className={"col-md-10 bg-white searchb col-12 border p-0 m-0 input-group"}>
                                    <input className="form-control search-field-text " type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                           placeholder="Search for sources in our collection  " style={{borderRadius:"300px"}}/>
                                    <span className="input-group-text" id="basic-addon1">
                                                <img onClick={this.handleSearch} alt={"search icon"} src={searchicon} width={"42"} height={"42"} style={{height:"auto"}}/>
                                            </span>
                                </div>
                            </div>
                        </div>
                        <div className={"container"}>

                            <div className={"container-fluid"} style={{ padding:"3.5rem 2.875rem", marginTop:"1.25rem", background:"#FBFBFF"}}>
                                <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                    Special Collections
                                </h6>
                                <p style={{fontSize:"14px", fontWeight:"400"}}>
                                    Industry and subject focused collections to give you the edge you need in your career
                                </p>
                                <div className="container row3 p-0">
                                    <div className=" border-0">
                                        <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                            {
                                                filtereditems.length > 0 ? <>
                                                    {
                                                        filtereditems.slice(0,8).map((collection)=>(
                                                            <>
                                                                {
                                                                    this.state.userorganizations.length > 0  ? <>
                                                                        {
                                                                            this.state.userorganizations.some((o)=>parseInt(o.organisation.id)===collection.organisation || o.type==="GENERAL") ? <>
                                                                                <>
                                                                                    <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                        <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                            <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                <div className={"row w-100"}>
                                                                                                    <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                        <img src={logo} />
                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>
                                                                                            <Link onClick={()=>this.handleSubCollection(collection.organisation, collection.image, collection.name)}  to={`/sub-collections/${collection.id}`} style={{textDecoration:"none"}}>
                                                                                                <div className={"container-fluid d-flex justify-content-center bg-white"}>
                                                                                                    <div className="categories-content-main text-center">
                                                                                                        <div>
                                                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                {collection.name}
                                                                                                            </h6>
                                                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                        </div>

                                                                                                        <div className={"container-fluid p-0 collection-footer text-center d-flex align-items-center justify-content-center"}>
                                                                                                            <h6>View Collection  </h6>

                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>
                                                                                            </Link>
                                                                                        </div>

                                                                                    </div>
                                                                                </>
                                                                            </> : <>
                                                                                {
                                                                                    this.state.waitlist.some((o)=>o.collection_group===collection.id) ? <>
                                                                                        <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                            <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                                <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                    <div className={"row w-100"}>
                                                                                                        <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                            <img src={logo} />
                                                                                                        </div>
                                                                                                        <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                                            <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                                                <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div className={"container d-flex justify-content-center bg-white"}>
                                                                                                    <div className="categories-content-main text-center">
                                                                                                        <div>
                                                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                {collection.name}
                                                                                                            </h6>
                                                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                        </div>

                                                                                                        <div className={"container-fluid  collection-footer p-0 text-center d-flex align-items-center justify-content-center"}>
                                                                                                            <h6 >Added to waitlist  </h6>

                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                    </> : <>
                                                                                        <div className="col-md-3 d-flex justify-content-center"  onClick={()=>this.handleWaitlist(collection.id)} style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                            <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                                <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                    <div className={"row w-100"}>
                                                                                                        <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                            <img src={logo} />
                                                                                                        </div>
                                                                                                        <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                                            <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                                                <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div className={"container d-flex justify-content-center bg-white"}>
                                                                                                    <div className="categories-content-main text-center">
                                                                                                        <div>
                                                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                {collection.name}
                                                                                                            </h6>
                                                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                        </div>

                                                                                                        <div className={"container-fluid p-0 text-center d-flex  collection-footer align-items-center justify-content-center"}>
                                                                                                            <h6>Coming soon  </h6>

                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                    </>
                                                                                }

                                                                            </>
                                                                        }
                                                                    </> : <>
                                                                        <>
                                                                        </>


                                                                    </>
                                                                }

                                                            </>
                                                        ))
                                                    }

                                                </> : <>

                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>


                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className={"tablet"}>
                        <div className={"container resources-search-section"}>
                            <h4>
                                Explore our Collections
                            </h4>
                        </div>
                        <div className={"container"} style={{marginTop:"2rem"}}>
                            <div className={"row"} style={{zIndex:"1000", position:"relative"}}>
                                <div className={"col-md-10 col-10 p-0 m-0"}>
                                    <input className="search-field-text" type="text" name="q" onChange={this.handleInput} onClick={this.handleInput} value={this.state.filter} onBlur={this.handleLeaveInput}
                                           placeholder="Search for sources in our collection" style={{borderRadius:"100px 0px 0px 100px"}}/>
                                </div>
                                <div className={"col-md-2 col-2 p-0 m-0"}>
                                    <button onTouchStart={this.handleSearch} onClick={this.handleSearch} className="btn btn-search" style={{borderRadius:"0px 6px 6px 0px"}}>Search</button>
                                </div>
                            </div>
                        </div>
                        <div className={"container"}>

                            <div className={"container-fluid"} style={{ padding:"3.5rem 2.875rem", marginTop:"1.25rem", background:"#FBFBFF"}}>
                                <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                    Special Collections
                                </h6>
                                <p style={{fontSize:"14px", fontWeight:"400"}}>
                                    Industry and subject focused collections to give you the edge you need in your career
                                </p>
                                <div className="container row3 p-0">
                                    <div className=" border-0">
                                        <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                            {
                                                filtereditems.length > 0 ? <>
                                                    {
                                                        filtereditems.slice(0,8).map((collection)=>(
                                                            <>
                                                                {
                                                                    this.state.userorganizations.length > 0  ? <>
                                                                        {
                                                                            this.state.userorganizations.some((o)=>parseInt(o.organisation.id)===collection.organisation || o.type==="GENERAL") ? <>
                                                                                <>
                                                                                    <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                        <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                            <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                <div className={"row w-100"}>
                                                                                                    <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                        <img src={logo} />
                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>
                                                                                            <Link onClick={()=>this.handleSubCollection(collection.organisation, collection.image, collection.name)}  to={`/sub-collections/${collection.id}`} style={{textDecoration:"none"}}>
                                                                                                <div className={"container-fluid d-flex justify-content-center bg-white"}>
                                                                                                    <div className="categories-content-main text-center">
                                                                                                        <div>
                                                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                {collection.name}
                                                                                                            </h6>
                                                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                        </div>

                                                                                                        <div className={"container-fluid p-0 collection-footer text-center d-flex align-items-center justify-content-center"}>
                                                                                                            <h6>View Collection  </h6>

                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>
                                                                                            </Link>
                                                                                        </div>

                                                                                    </div>
                                                                                </>
                                                                            </> : <>
                                                                                {
                                                                                    this.state.waitlist.some((o)=>o.collection_group===collection.id) ? <>
                                                                                        <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                            <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                                <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                    <div className={"row w-100"}>
                                                                                                        <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                            <img src={logo} />
                                                                                                        </div>
                                                                                                        <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                                            <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                                                <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div className={"container d-flex justify-content-center bg-white"}>
                                                                                                    <div className="categories-content-main text-center">
                                                                                                        <div>
                                                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                {collection.name}
                                                                                                            </h6>
                                                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                        </div>

                                                                                                        <div className={"container-fluid  collection-footer p-0 text-center d-flex align-items-center justify-content-center"}>
                                                                                                            <h6 >Added to waitlist  </h6>

                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                    </> : <>
                                                                                        <div className="col-md-3 d-flex justify-content-center"  onClick={()=>this.handleWaitlist(collection.id)} style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                            <div className={"container community-container bg-white"} style={{borderRadius:"8px"}}>
                                                                                                <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                    <div className={"row w-100"}>
                                                                                                        <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                            <img src={logo} />
                                                                                                        </div>
                                                                                                        <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                                            <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                                                <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>

                                                                                                <div className={"container d-flex justify-content-center bg-white"}>
                                                                                                    <div className="categories-content-main text-center">
                                                                                                        <div>
                                                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                {collection.name}
                                                                                                            </h6>
                                                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                        </div>

                                                                                                        <div className={"container-fluid p-0 text-center d-flex  collection-footer align-items-center justify-content-center"}>
                                                                                                            <h6>Coming soon  </h6>

                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                    </>
                                                                                }

                                                                            </>
                                                                        }
                                                                    </> : <>
                                                                        <>
                                                                        </>


                                                                    </>
                                                                }

                                                            </>
                                                        ))
                                                    }

                                                </> : <>

                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>
                </MediaQuery>

                <Footer />
            </>
        );
    }
}
export default SpecialCollections