import buildAPIUrl from "../../Services/UrlBuilder";

export const fetchAllVocabularies = async () => {
    try {
      const response = await fetch(buildAPIUrl('v1/vocabularies/'));
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      return response;
    } catch (error) {
        throw new Error(error);
    }
    
}


export const createVocabulary = async (new_vocabulary, userToken) => {
    try {
      
      const response = await fetch(buildAPIUrl('v1/vocabularies/'), {
          method: 'POST',
          body: JSON.stringify(new_vocabulary),
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`
          }
      });
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      return response;
    } catch (error) {
        throw new Error(error);
    }
    
  }

  export const getVocabulary = async (vocabulary_id, userToken) => {
    try {
      const response = await fetch(buildAPIUrl(`v1/vocabularies/${vocabulary_id}/`), {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`
          }
      });
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      return response;
    } catch (error) {
        throw new Error(error);
    }
    
  }
  

  export const editVocabulary = async (vocabulary_id, vocabularyData, userToken) => {
    try {
      
      const response = await fetch(buildAPIUrl(`v1/vocabularies/${vocabulary_id}/`), {
          method: 'PATCH',
          body: JSON.stringify(vocabularyData),
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`
          }
      });
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      return response;
    } catch (error) {
        throw new Error(error);
    }
    
  }
  

  export const deleteVocabulary = async (vocabulary_id, userToken) => {
    try {
      const response = await fetch(buildAPIUrl(`v1/vocabularies/${vocabulary_id}/`), {
        method: 'DELETE',
  
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      });
    
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
  
    } catch (error) {
      throw new Error(error);
    }
  }