import React, {Component} from "react";
import Header from "../Landing/Header";
import Breadcrumbs from "./Breadcrumbs";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import LoginModal from "../Modals/LoginModal";
import MediaQuery from "react-responsive";
import UpgradeModal from "../Modals/UpgradeModal";

class OriginalsDetails extends Component{
    state={
        itemdetails:"",
        loading:true,
        token:localStorage.getItem('token'),
        show:false
    }
    componentDidMount() {
        if(!localStorage.getItem('token')) {
            window.location.assign("/")
        }
       
        const itemid = this.props.match.params.id
        if(this.state.token) {
            const response = axios.get(buildAPIUrl(`v1/rb_originals/${itemid}`), {
                headers: {
                    Authorization: `Bearer ${this.state.token}`
                }
            }).then((res)=>{
                window.scrollTo(0,0)
                this.setState({
                    itemdetails:res.data,
                    loading:false
                })

            }).catch((error)=>{
               /* window.location.assign('/')*/
            })
        } else {
            const response = axios.get(buildAPIUrl(`v1/rb_originals/${itemid}`)).then((res)=>{
                window.scrollTo(0,0)
                this.setState({
                    itemdetails:res.data,
                    loading:false
                })
                setTimeout(()=>{
                    window.addEventListener('scroll', this.handleScroll);
                }, 1000)
            }).catch((error)=>{
               /* window.location.assign('/')*/
            })
        }

    }
    handleModalOpen = () =>{
        this.setState({
            show:true
        })
    }
    handleModalClose = () =>{
        this.setState({
            show:false
        })
        window.location.assign('/rb-originals')
    }
    handleScroll = () => {
        if(!localStorage.getItem('token')) {
            this.handleModalOpen()
        }

    }

    render() {
        return (
            <>
                <Header />
                <UpgradeModal />
                <LoginModal openLoginModal={this.handleModalOpen}
                            closeLoginModal={this.handleModalClose}
                            show={this.state.show} type={this.state.logintype}/>
                <MediaQuery minWidth={1200}>
                    {
                        this.state.loading ? <>
                            <div className="container">
                                {
                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                        key={n}/>)
                                }
                            </div>


                        </>  :  <div className="originals-details" onScroll={this.handleScroll} style={{marginTop:"4rem"}}>
                            <div className="container-fluid">
                                <div className={"row"}>
                                    <div className={"col-md-12 col-12 origina-header"}>
                                        <h6 className="text-center" style={{color:"white"}}>{this.state.itemdetails.title}</h6>
                                        <div className={"col-md-12 col-12 d-flex justify-content-center"}>
                                            {this.state.itemdetails.link.includes("researchbookmark.io") ? 
                                                <a href={this.state.itemdetails.link} >
                                                    <button className="btn btn-download-template" style={{marginTop:"1.563rem"}}>Upgrade To Download Template</button>
                                                </a> :  
                                                 <a href={this.state.itemdetails.link} target="_blank" download={true} >
                                                    <button className="btn btn-download-template" onClick={this.handleModalOpen} style={{marginTop:"1.563rem"}}>Download Template</button>
                                                </a>
                                            }
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="container d-flex justify-content-center" style={{marginTop:"5rem"}}>
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-md-10 origin-content"}>
                                        <div dangerouslySetInnerHTML={ {__html: this.state.itemdetails.content} } />
                                    </div>
                                </div>

                            </div>
                            <div className="container mb-5">
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-md-10"}>
                                        {
                                            this.state.token ? <a href={this.state.itemdetails.link} target="_blank" download={true} >
                                                <button className="btn btn-download-template">Download Template</button>
                                            </a> :  <button className="btn btn-download-template" onClick={this.handleModalOpen}>Download Template</button>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    {
                        this.state.loading ? <>
                            <div className="container">
                                {
                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                        key={n}/>)
                                }
                            </div>


                        </>  :  <div className="originals-details" onScroll={this.handleScroll} style={{marginTop:"0rem"}}>
                            <div className="container-fluid">
                                <div className={"row"}>
                                    <div className={"col-md-12 col-12 origina-header"}>
                                        <h6 className="text-center" style={{color:"white"}}>{this.state.itemdetails.title}</h6>
                                        <div className={"col-md-12 col-12 d-flex justify-content-center"}>
                                            {
                                                this.state.token ? <a href={this.state.itemdetails.link} target="_blank" download={true} >
                                                    <button className="btn btn-download-template" style={{marginTop:"1.563rem"}}>Download Template</button>
                                                </a> :  <button className="btn btn-download-template" onClick={this.handleModalOpen} style={{marginTop:"1.563rem"}}>Download Template</button>
                                            }
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="container" style={{marginTop:"2rem"}}>
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-md-10 origin-content"}>
                                        <div dangerouslySetInnerHTML={ {__html: this.state.itemdetails.content} } />
                                    </div>
                                </div>

                            </div>
                            <div className="container mb-5">
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-md-10"}>
                                        {
                                            this.state.token ? <a href={this.state.itemdetails.link} target="_blank" download={true} >
                                                <button className="btn btn-download-template">Download Template</button>
                                            </a> :  <button className="btn btn-download-template" onClick={this.handleModalOpen}>Download Template</button>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    {
                        this.state.loading ? <>
                            <div className="container">
                                {
                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                        key={n}/>)
                                }
                            </div>


                        </>  :  <div className="originals-details" onScroll={this.handleScroll} style={{marginTop:"0rem"}}>
                            <div className="container-fluid">
                                <div className={"row"}>
                                    <div className={"col-md-12 col-12 origina-header"}>
                                        <h6 className="text-center" style={{color:"white"}}>{this.state.itemdetails.title}</h6>
                                        <div className={"col-md-12 col-12 d-flex justify-content-center"}>
                                            {
                                                this.state.token ? <a href={this.state.itemdetails.link} target="_blank" download={true} >
                                                    <button className="btn btn-download-template" style={{marginTop:"1.563rem"}}>Download Template</button>
                                                </a> :  <button className="btn btn-download-template" onClick={this.handleModalOpen} style={{marginTop:"1.563rem"}}>Download Template</button>
                                            }
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="container d-flex justify-content-center" style={{marginTop:"2rem"}}>
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-md-10 origin-content"}>
                                        <div dangerouslySetInnerHTML={ {__html: this.state.itemdetails.content} } />
                                    </div>
                                </div>

                            </div>
                            <div className="container mb-5">
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-md-10"}>
                                        {
                                            this.state.token ? <a href={this.state.itemdetails.link} target="_blank" download={true} >
                                                <button className="btn btn-download-template">Download Template</button>
                                            </a> :  <button className="btn btn-download-template" onClick={this.handleModalOpen}>Download Template</button>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </MediaQuery>

<Footer />

            </>

        );
    }
}
export default OriginalsDetails