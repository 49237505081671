import React, {useEffect, useState} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Header from "./Header";
import MediaQuery from "react-responsive";
import Footer from "./Footer";

export default function Terms() {
    //Create States
    const [terms, setTerms] = useState("")

    const getTerms = async () =>{
        const response = await axios(buildAPIUrl('v1/terms_of_use/'))
        setTerms(response.data[0])

    }

    useEffect(()=>{
        getTerms()
    }, [])
    return (
        <>
            <Header />
            <MediaQuery minWidth={1200} >
                <div className={"container"} style={{paddingLeft:"17.563rem", paddingRight:"12.5rem"}}>
                    <h4 style={{fontSize:"40px", fontWeight:"500", lineHeight:"50px"}}> Terms and Conditions</h4>
                    <div className={"container-fluid p-0"} style={{marginTop:"3.125rem"}}>
                        <div dangerouslySetInnerHTML={ {__html: terms.description} } />
                    </div>
                </div>
                <Footer />
            </MediaQuery>
            <MediaQuery maxWidth={1200} minWidth={461} >
                <div className={"container"} style={{paddingLeft:"8.563rem", paddingRight:"6.5rem"}}>
                    <h4 style={{fontSize:"40px", fontWeight:"500", lineHeight:"50px"}}> Terms and Conditions</h4>
                    <div className={"container-fluid p-0"} style={{marginTop:"3.125rem"}}>
                        <div dangerouslySetInnerHTML={ {__html: terms.description} } />
                    </div>
                </div>
                <Footer />
            </MediaQuery>
            <MediaQuery maxWidth={460} >
                <div className={"container"} style={{paddingLeft:"1.563rem", paddingRight:"1.5rem"}}>
                    <h4 style={{fontSize:"30px", fontWeight:"500", lineHeight:"50px"}}> Terms and Conditions</h4>
                    <div className={"container-fluid p-0"} style={{marginTop:"3.125rem"}}>
                        <div dangerouslySetInnerHTML={ {__html: terms.description} } />
                    </div>
                </div>
                <Footer />
            </MediaQuery>
        </>
    )
}