import {Modal} from "react-bootstrap";
import Logo from "../../../assets/rb-beta logo.svg";
import React, {useEffect, useState} from "react";
import "./modals.css"
import {Link} from "react-router-dom";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";

export default function UpgradeModal() {
    const[showModal, setShowModal]=useState(false)
    const[success, setSuccess]=useState(false)
    const[showButton, setShowButton]=useState(true)
    const checkuserpackage = async ()=>{
        const userpackage = await axios.get(buildAPIUrl('v1/users/me'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        
    
        //check if the user's trial period has started or ended
        if (userpackage.data.is_trial_period_over || userpackage.data.is_trial_period_over===null) {
            if(userpackage.data.is_staff || userpackage.data.is_superuser || userpackage.data.is_student){
                return
            }else{
               const userSubscription = await axios.get(buildAPIUrl('v1/subscriptions'), {
                   headers: {
                       Authorization: `Bearer ${localStorage.getItem('token')}`
                   }
               });
             
               
               if (userSubscription.data.length > 0) {
                const lastSub = 0
                   console.log("userSubscription.data[lastSub]?.db_is_active")
                   console.log(userSubscription.data[lastSub]?.db_is_active)
                   if (userSubscription.data[lastSub]?.db_is_active){
                       //setShowModal(true)
                       return
                   }else{
                        setShowModal(true)
                        setShowButton(false)
                   }
               } else if(userpackage.data.is_trial_period_over){
                    setShowModal(true)
                    setShowButton(false)
                   //setShowModal(true)
                   //window.location.assign('/payment-plans#pay');
               }else if(userpackage.data.is_trial_period_over===null){
                handleStartFreeTrial()
                //  window.location.assign('/payment-plans#trial');
               }
           }


        }
        // if(userpackage.data.is_trial_period_over){
        //     // setShowButton(false)
        //     //window.location.assign('/payment-plans');
        //     //window.location.assign('/payment-plans#pay');
            
        // }
    }


    //Start free trial
    const handleStartFreeTrial = async () => {
  
        // window.location.assign("/payment-plans")
        const startfree = await axios.get(buildAPIUrl('v1/users/trial_period/?is_community_group=0'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
      
    }
     useEffect(()=>{
   
         checkuserpackage()
     }, [])
    return (
        <>
           <Modal show={showModal} centered={true} size={"lg"} className={"upgrade-modal"}>
                <Modal.Body>
                    <img alt={"logo"} src={Logo} style={{width:"6.875rem"}} />
                    <div className={"container"}>

                        
                        {
                            showButton ? <>
                                <h6>
                                    Welcome to Research Bookmark Subscription
                                </h6>

                                <p>
                                    Subscribe to access learning resources on user experience. Starting today, our platform will require a subscription fee to continue using our services.
                                </p>
                                <p>
                                You can try our 7-day trial . Click below to get started!
                                </p>
                        
                        
                                <div className={"container d-flex justify-content-center"} style={{marginTop:"1.25rem", marginBottom:"2rem"}}>
                                    <Link onClick={handleStartFreeTrial} className={"btn btn-upgrade-modal"}>Start your 7 Days Trial</Link>
                                </div>

                                 <p className={"text-center"} style={{fontWeight:"600", color:"#522FFF", marginTop:"11px"}}>Don't worry—you won't be charged until your 7-day trial period ends. </p>

                            </> :     
                                <>
                                    <h6>
                                        Your 7-days trial period has ended
                                    </h6>

                                    <p>
                                        To contine accessing our premium research materials, please <br/>
                                        subscribe to our service
                                    </p>
                                    <p>
                                        We look forward to helping you with your research needs
                                    </p>
                                    
                                    <br/>
                                    <center>
                                        <Link to={"/payment-plans#pay"} className={"btn btn-upgrade-modal"}>Upgrade Now</Link>
                                    </center>
                                    <br/>

                                    
                                </>
                               
                       
                        }
                       




                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}