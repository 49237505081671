import React, {Component} from "react";
import axios from "axios";
import {secondLevelFilter} from "./CategoriesFunctions";

class PapersFilters extends Component{
    state = {
        website: "researchgate.net ",
        url:"1",
        searchterm: localStorage.getItem('searchquery'),
        configID:`${process.env.REACT_APP_BING_CONFIG_ID}`,
        subscrKey:`${process.env.REACT_APP_BING_SUBSCRIPTION_ID}`,
        site:null
    }
    handleSocialMedia = (company, urlid, response) => {
        secondLevelFilter(company, urlid, (successcallback)=>{
            this.setState({
                results: successcallback.webPages.value,
                total: successcallback.webPages.totalEstimatedMatches,
                pagination:successcallback.rankingResponse.mainline.items,
                url: urlid,
                website:company,
            })
            this.props.handleSocial(this.state.results, this.state.total, this.state.pagination, this.state.url, this.state.website)
        }, (error)=>{
            console.log(error)
        })
    }
    render() {
        return (

                <div className=" col-12 p-0 col-md-11">
                    <div className={this.props.device==="desktop"  ? "container desktop": this.props.device==="mobile"  ? "container mobile": "container tablet" }>
                        <ul  style={{listStyleType:"none"}} className="filter-level2 p-0">
                            <li onClick={() => this.handleSocialMedia("researchgate.net ", "1")} className={this.state.url==="1"? "active" : ""}><a   style={{paddingLeft:"0"}}>Research Gate</a></li>
                            <li onClick={() => this.handleSocialMedia("google scholar", "2")}  className={this.state.url==="2"? "active" : ""}><a >Google Scholar</a></li>
                            <li onClick={() => this.handleSocialMedia("academia.edu ", "3")} className={this.state.url==="3"? "active" : ""}><a  >Academia</a></li>
                        </ul>
                    </div>
                </div>

        );
    }
}

export default PapersFilters