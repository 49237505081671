import React, {Component} from "react";
import axios from "axios";
import Filters from "../Landing/Filters";
import SearchBox from "../Landing/SearchBox";
import LoginModal from "../Modals/LoginModal";
import Header from "../Landing/Header";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Sidebar from "../Landing/Sidebar";
import 'react-loading-skeleton/dist/skeleton.css'
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import notfound from "../../../assets/notfound.png"
import ResultComponent from "./ResultComponent";
import AlertComponent from "./AlertComponent";
import MediaQuery from "react-responsive";
import PapersFilters from "./PapersFilters";
import Pagination from "./Pagination";

class News extends Component{
    state = {
        results: [],
        results1: [],
        pagination:[],
        filter:"",
        siteid:"",
        social: false,
        total:"",
        search:"",
        web:true,
        imgs:false,
        vids:false,
        news:false,
        images:[],
        title:"",
        link:"",
        success:"",
        token: localStorage.getItem('token'),
        website: "researchgate.net ",
        searchterm: localStorage.getItem('searchquery').replace("+", " "),
        url:"1",
        copy: false,
        saved:false,
        perPage: 10,
        page: 0,
        pages: 0,
        count:10,
        configID:`${process.env.REACT_APP_BING_CONFIG_ID}`,
        subscrKey:`${process.env.REACT_APP_BING_SUBSCRIPTION_ID}`,
        logintype:"",
        loading:true,
        exists:"",
        offset:0,
        offsetvalue:"0"
    }

    componentWillMount() {
        if(!localStorage.getItem('token')){
            window.location.assign("/")
        }
        if(window.location.search) {
            localStorage.setItem('searchquery', window.location.search.replace('?q=', " ").replaceAll('%20', " "))
        }

    }
    componentDidMount() {

        this.setState({
            offset:0
        })
        axios.get(`https://api.bing.microsoft.com/v7.0/custom/news/search?q=${this.state.searchterm} ux research&customconfig=${this.state.configID}&count=10`, {
            headers:{
                "Ocp-Apim-Subscription-Key":this.state.subscrKey
            }
        }).then((res)=>{
            console.log(res.data)
            this.setState({
                results: res.data.value,
                total: res.data.totalEstimatedMatches,
                loading:false
            })
        })
        this.setState({
            pages: Math.floor(this.state.results.length / this.state.perPage)
        });

    }
    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({page})
    }
    handleModalClose = ()=>{
        this.setState({
            show: false,
            logintype:""
        })
    }
    handleModalOpen = (result, logtype)=>{

        this.setState({
            show: true,
            siteid:result,
            logintype:logtype
        })
    }
    handleOpen = ()=>{
        this.setState({
            active: false
        })
    }
    handleClose = () => {
        this.setState({
            active: true
        })
    }
    handleImages = () => {
        console.log("images")
        this.setState({
            search: "/images",
            social:false,
            web:false,
            imgs:true,
            vids:false,
            news:false,
            results:"",
        })
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    handleSaveSearch = async ( result) => {
        await axios.post(buildAPIUrl(`v1/bookmarks/`), {
                title: result.name,
                link: result.url,
                snippet:result.snippet,
                tag:"Papers"
            },
            {
                headers: {
                    Authorization: `Bearer ${this.state.token}`
                }
            } ).then((res) => {
            this.setState({
                saved:true,
                show: false,
            })
            setInterval(() => {
                this.setState({
                    saved: false
                })
            }, 20000)
        }).catch((error)=>{
            this.setState({
                exists: error.response.data.message
            })
            setInterval(() => {
                this.setState({
                    exists: ""
                })
            }, 10000)
        })
    }
    handleCopyLink = (result) =>{
        navigator.clipboard.writeText(result.url).then(()=>{
            this.setState({
                copy: true
            })
            setInterval(() => {
                this.setState({
                    copy: false
                })
            }, 3000)
        })
    }
    render() {
        const {page, perPage, pages} = this.state;
        let items = this.state.results.slice(page * perPage, (page + 1) * perPage);
        return (
            <>
                <Header />
                <MediaQuery minWidth={1200} className="desktop" >
                    <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem", marginTop:"7rem"}}>
                        <div className="col-md-1"></div>
                        <div
                            className="col-md-8 col-12">
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-12 col-md-11"><SearchBox web={true}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                {
                                    this.state.notfound ? " " :
                                        <Filters news={true}/>
                                }

                            </div>
                            <div className="row">
                                <div className=" col-12 p-0 col-md-10" style={{overflow: "hidden"}}>
                                    <div className="container mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            {
                                                this.state.notfound ? <h6>0 results found</h6> :
                                                    <h6>About {
                                                        this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                    } results </h6>
                                            }
                                            <AlertComponent status={this.state.copy}/>
                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img src={notfound}/>
                                                            </div>

                                                            <div className="search-message">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in tools list, try our search
                                                                    engine instead</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div>
                                                                {
                                                                    items.map((result) => (
                                                                        <>
                                                                            <ResultComponent  handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"News"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }

                                                            </div>
                                                    }
                                                </>
                                        }

                                        <div>
                                        </div>
                                        <div className="container p-0 mt-4">
                                            {this.state.results.length > 10 ?
                                                <Pagination paginateoff={this.state.offset} resulttype={"news"}  increment={15} type={"configID"} count={this.state.count}
                                                            handleNext={(response, totalcount, paginationcount,loadingstate,offset)=>
                                                                this.setState({
                                                                    results:response,
                                                                    total:totalcount,
                                                                    pagination:paginationcount,
                                                                    loading:loadingstate,
                                                                    offset:offset
                                                                })}

                                                />
                                                : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                    existstatus={this.state.exists}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 side-filters" style={{marginTop: "6.8rem"}}>

                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} className="mobile">
                    <div className="row mobile" >
                        <div className={this.state.active ? "col-md-2 col-2  " : "col-md-1 col-2 hidden-mob"}>
                            <Sidebar closeSideBar={this.handleClose} openSideBar={this.handleOpen}
                                     status={this.state.active}/>
                        </div>

                        <div
                            className={this.state.active ? "col-md-8 col-12  narrowcontainer" : "col-md-8 col-12  widecontainer"}>
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid mt-3">
                                <div className="row">
                                    <div className=" col-2 col-md-1 hidden-mob"></div>
                                    <div className=" col-12 col-md-11"><SearchBox web={true} device={"mobile"}/></div>
                                </div>

                            </div>
                            <div className="row p-0">
                                {
                                    this.state.notfound ? " " :
                                        <>
                                            <div className={"row m-0 d-flex align-items-center"}>
                                                <div className={"col-12 p-0"}>
                                                    <Filters device={"mobile"} papers={true}/>
                                                </div>
                                            </div>
                                        </>

                                }

                            </div>
                            <div className="row">

                                <div className=" col-12 p-0 col-md-10" style={{overflow: "hidden"}}>
                                    <div className="container mt-2 p-0">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                            existstatus={this.state.exists} type={"General"}/>
                                            <div className="row" style={{paddingLeft: "1.5rem", paddingRight: "1.5rem"}}>
                                                <div className="col-8">
                                                    {
                                                        this.state.notfound ? <h6>0 results found</h6> :
                                                            <h6>About {
                                                                this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                            } results </h6>
                                                    }

                                                </div>

                                            </div>

                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img src={notfound}/>
                                                            </div>

                                                            <div className="search-message">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in tools list, try our search
                                                                    engine instead</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div style={{background:"#F8F8F8"}} className={"p-0"}>
                                                                {
                                                                    items.slice(0,1).map((result) => (
                                                                        <>
                                                                            <ResultComponent position={"first"} type={"News"} handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})}  result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                                {
                                                                    items.slice(1,10).map((result) => (
                                                                        <>
                                                                            <ResultComponent type={"News"} handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})}  result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                    }
                                                </>
                                        }

                                        <div>
                                        </div>
                                        <div className="container p-0 mt-4">
                                            <Pagination type={"configID"} resulttype={"news"} paginateoff={this.state.offset} website={this.state.website} increment={10} count={this.state.count}
                                                        handleNext={(response, totalcount, paginationcount,loadingstate, offset)=>
                                                            this.setState({
                                                                results:response,
                                                                total:totalcount,
                                                                pagination:paginationcount,
                                                                loading:loadingstate,
                                                                offset:offset
                                                            })}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <AlertComponent status={this.state.copy}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} className="tablet" minWidth={461}>
                    <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-12 col-md-12"><SearchBox web={true} device={"tablet"}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                {
                                    this.state.notfound ? " " :
                                        <Filters device={"tablet"} papers={true}/>
                                }


                            </div>

                            <div className="row">
                                <div className=" col-12 p-0 col-md-12" style={{overflow: "hidden"}}>
                                    <div className="container mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                            existstatus={this.state.exists} type={"Paper"}/>
                                            <div className="row">
                                                <div className="col-12">
                                                    {
                                                        this.state.notfound ? <h6>0 results found</h6> :
                                                            <h6>About {
                                                                this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                            } results </h6>
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img src={notfound}/>
                                                            </div>

                                                            <div className="search-message">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in tools list, try our search
                                                                    engine instead</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div>
                                                                {
                                                                    items.map((result) => (
                                                                        <>
                                                                            <ResultComponent handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"News"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                    }
                                                </>
                                        }

                                        <div>
                                        </div>
                                        <div className="container p-0 mt-4">
                                            <Pagination type={"configID"} resulttype={"news"}  paginateoff={this.state.offset} increment={10} count={this.state.count} website={this.state.website}
                                                         handleNext={(response, totalcount, paginationcount,loadingstate, offset)=>
                                                             this.setState({
                                                                 results:response,
                                                                 total:totalcount,
                                                                 pagination:paginationcount,
                                                                 loading:loadingstate,
                                                                 offset:offset
                                                             })}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <AlertComponent status={this.state.copy}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <Footer/>
            </>
        )
    }
}
export default News