import React, {Component} from "react";
import Header from "../Header";
import Bgimage from "../../assets/pexels-kindel-media-7007191 1.png"
import { MdKeyboardBackspace} from "react-icons/md";
import {FaTrashAlt} from "react-icons/fa";
import "./collections.css"
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import {Link} from "react-router-dom";

class subCollections extends Component {
    state={
        collections:[],
        loading:true
    }
    handleBack = ()=>{
        window.location.assign("/admin-collections-list")
    }
    componentDidMount() {
        axios.get(buildAPIUrl(`v1/collections/group/${localStorage.getItem('collectionid')}`))
            .then((res)=>{
                this.setState({
                    collections:res.data.sub_group,
                    loading:false
                })
                console.log(res.data)
                window.scrollTo(0,0)
            }).catch((error)=>{
        })
    }
    handelViewDetails = (id, name) =>{
        localStorage.setItem('subcat', id)
        localStorage.setItem('subname', name)
        window.location.assign("/admin-subcollection-details")
    }
    render() {
        return (
            <div className={"pb-5"}  style={{backgroundColor:"#F9F8FF"}}>
                <Header />
                <div className={"container-fluid bg-image d-flex justify-content-center align-items-center "} style={{ height:"14.625rem"}}>
                    <h6 style={{fontSize:"30px", color:"#FFFFFF", fontWeight:"600"}}>{localStorage.getItem('collectionname')}</h6>
                </div>
                <div className={"container"} style={{marginTop:"6.313rem"}}>
                    <div className={"row d-flex justify-content-center"}>
                        <div className={"col-md-8"}>
                            <h6 onClick={this.handleBack} style={{color:"#522FFF", cursor:"pointer"}}><MdKeyboardBackspace /> Back to collections</h6>
                        </div>
                    </div>

                </div>
                <div className={"container"} style={{marginTop:"2rem"}}>
                   <div className={"row d-flex justify-content-center"}>
                       <div className={"col-md-8"}>
                           {
                               this.state.loading ?  <>
                               <p>Loading....</p>
                               </> : <>

                                   {
                                       this.state.collections.length > 0 ? <>
                                               {
                                                   this.state.collections.map((item)=>(
                                                       <>
                                                           <div className={"row bg-white mt-3"} style={{boxShadow:"0px 3px 4px rgba(0, 0, 0, 0.05)", borderRadius:"8px", padding:"1.313rem 1.313rem 1.313rem 2.5rem"}} >
                                                               <div className={"col-md-10"}>
                                                                   <h6 onClick={()=>this.handelViewDetails(item.id, item.name)} style={{color:"#000000", fontWeight:"500", fontSize:"18px", cursor:"pointer"}}>{item.name}</h6>
                                                               </div>
                                                               <div className={"col-md-2 d-flex justify-content-end"}>
                                                                   <FaTrashAlt />
                                                               </div>
                                                           </div>
                                                       </>
                                                   ))
                                               }
                                           </> :
                                           <>
                                               This category does not have any sub categories
                                           </>
                                   }
                                   </>
                            }


                       </div>
                   </div>
                </div>
            </div>
        );
    }
}
export  default subCollections