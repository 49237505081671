import React, { useState, useCallback, useEffect } from 'react';
import buildAPIUrl from "../../Services/UrlBuilder";
import { Link } from "react-router-dom";
import Header from "../Header";

function ListOfResearchCommunity() {
  const [listOfCommunities, setlistOfCommunities] = useState([]);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState("");



  if (!userToken) {
    window.location.assign('/');
  }

  const fetchCommunitiesHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(buildAPIUrl('v1/research_communities/'));
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }

      const data = await response.json();

      setlistOfCommunities(data[0]);
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchCommunitiesHandler();
  }, [fetchCommunitiesHandler]);

  async function deleteCommunityHandler(community) {

    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(buildAPIUrl(`v1/research_communities/${community.id}/`),{
        method: 'DELETE',

        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`
        }
      });
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }

      fetchCommunitiesHandler();
      

    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);


  }

  async function confirmDeleteCommunityHandler(community) {
    
    await deleteCommunityHandler(community);
    // let confirmDelete = confirm(`Are you sure you want to DELETE ${community.name}?`);
    // if (confirmDelete != null) {
    //   await deleteCommunityHandler(community);
    // }

  }


  let content = <p></p>;

  if (listOfCommunities.length > 0) {
    content = listOfCommunities.map((community) => (

      <tr key={community.id}>
        <td>{community.name}</td>
        <td>{community.url}</td>

        <td>{community.platform}</td>

        <td>{community.audience}</td>
          <td>
              <Link className={"btn btn-outline-primary w-100"} to={`/uxr-communities/edit/${community.id}`}>
                  Edit
              </Link>
              <button className={"btn btn-danger w-100 mt-2"} onClick={() => confirmDeleteCommunityHandler(community)}>Delete</button>
          </td>
      </tr>

    ));
  }
  else {
    content = <p>Found no research communities.</p>;
  }

  return (
    <>
        <Header />
        <div className={"container-fluid mt-3"}>

                <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                    <div className={"card"}>
                        <div className={"card-header border"}>
                            <div className={"row"}>
                                <div className={"col-md-10"}>
                                    <h4 style={{fontWeight:"500"}}>List of Communities</h4>
                                </div>
                                <div className={"col-md-2"}>
                                    <Link to="/uxr-communities/create">Create Community </Link>
                                </div>
                            </div>

                        </div>
                        <div className={"card-body border"}>

                            {isLoading && <p>Loading.....</p>}
                            {isError && <p>{error}</p>}
                            <table  className={"table table-striped"}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>URL</th>
                                        <th>Platform</th>
                                        <th>Audience</th>
                                        <th>Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {content}
                                </tbody>
                            </table>

                        </div>
                    </div>


                </div>

        </div>



    </>
  )
}
export default ListOfResearchCommunity;