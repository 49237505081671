import React, { useState } from 'react';

import {createConference} from './ConferenceApi';
import Header from "../Header";


function CreateConference() {
    const [newConferenceDetails, setnewConferenceDetails] = useState({
        name: "",
        url: "",
        start_date: "",
        end_date: "",
        location: "",
        cost: "",
    });

    const [userToken, setUserToken] = useState(localStorage.getItem('token'))
    const [isLoading, setisLoading] = useState(false);
    const [isError, setisError] = useState(false);
    const [error, seterror] = useState("");


    if (!userToken) {
        window.location.assign('/');
    }


    function onChangeHandler(event) {
        setnewConferenceDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }


    async function submitHandler(event) {
        event.preventDefault();
        const new_conference = {
            name: newConferenceDetails.name,
            url: newConferenceDetails.url,
            start_date: newConferenceDetails.start_date,
            end_date: newConferenceDetails.end_date,
            location: newConferenceDetails.location,
            cost: newConferenceDetails.cost
        }
        setisLoading(true);
        let response = await createConference(new_conference, userToken);
        setisLoading(false);
        if (response.status === 201) {
            setnewConferenceDetails({
                name: "",
                url: "",
                start_date: "",
                end_date: "",
                location: "",
                cost: "",
            })
            window.location.assign('/conference/all');

        } else {
            setisError(true);
            seterror(response.statusText);
        }

    }


    return (
        <>
           <Header />
            <div className={"container-fluid mt-5"}>
                <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            {isLoading && <p>Loading.....</p>}
                            {isError && <p>{error}</p>}
                            <div className={"card border"}>
                                <div className={"card-header"}>
                                    <h4>Create Conference</h4>
                                </div>
                                <div className={"card-body"}>
                                    <form onSubmit={submitHandler}>
                                        <div className={"mb-3"}>
                                            <label htmlFor='name'>Name</label>
                                            <input
                                                type='text'
                                                id='name'
                                                name='name'
                                                value={newConferenceDetails.name}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />
                                        </div>
                                        <div className={"mb-3"}>

                                            <label htmlFor="location">Location:</label>
                                            <select className={"form-control"} name="location" id="location" value={newConferenceDetails.location} onChange={event => onChangeHandler(event)}>
                                                <option value="">----</option>
                                                <option value="onsite">Onsite</option>
                                                <option value="virtual">Virtual</option>
                                                <option value="onsite and virtual">Onsite And Virtual</option>
                                            </select>

                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='url'>Url</label>
                                            <input
                                                className={"form-control"}
                                                type='url'
                                                id='url'
                                                name='url'
                                                value={newConferenceDetails.url}
                                                onChange={onChangeHandler}
                                                required
                                            />
                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='start_date'>Start Date</label>
                                            <input
                                                className={"form-control"}
                                                type='date'
                                                id='start_date'
                                                name='start_date'
                                                value={newConferenceDetails.start_date}
                                                onChange={onChangeHandler}
                                                required
                                            />

                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='end_date'>End Date</label>
                                            <input
                                                type='date'
                                                id='end_date'
                                                name='end_date'
                                                value={newConferenceDetails.end_date}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />


                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor="cost">Cost:</label>
                                            <select className={"form-control"} name="cost" id="cost" value={newConferenceDetails.cost} onChange={event => onChangeHandler(event)}>
                                                <option value="">----</option>
                                                <option value="free">Free</option>
                                                <option value="paid">Paid</option>
                                                <option value="paid and free">Paid and Free</option>
                                            </select>

                                        </div>
                                        <button className={"btn btn-success"}>Add New Conference</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
export default CreateConference;