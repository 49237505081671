import React, {Component} from "react";
import {secondLevelFilter} from "./CategoriesFunctions";

class CoursesFilters extends Component{
    state = {
        website: "linkedin.com/learning/",
        url:"1",
        searchterm: localStorage.getItem('searchquery'),
        configID:`${process.env.REACT_APP_BING_CONFIG_ID}`,
        subscrKey:`${process.env.REACT_APP_BING_SUBSCRIPTION_ID}`,
    }
    handleSocialMedia = (company, urlid, response) => {
        secondLevelFilter(company, urlid, (successcallback)=>{
            this.setState({
                results: successcallback.webPages.value,
                total: successcallback.webPages.totalEstimatedMatches,
                pagination:successcallback.rankingResponse.mainline.items,
                url: urlid,
                website:company
            })
            this.props.handleSocial(this.state.results, this.state.total, this.state.pagination, this.state.url, this.state.website)
        }, (error)=>{
            console.log(error)
        })
    }
    render() {
        return (
            <div className=" col-12 p-0 col-md-11">
                <div className="container">
                    <ul style={{listStyleType: "none"}} className="filter-level2 p-0">
                        <li className={this.state.url === "1" ? "active" : ""} onClick={() => this.handleSocialMedia("linkedin.com/learning/ ", "1")}>
                            <a> LinkedIn
                                Learning</a></li>
                        <li  className={this.state.url === "2" ? "active" : ""} onClick={() => this.handleSocialMedia("udemy.com ", "2")}><a

                            style={{paddingLeft: "0"}}>Udemy</a></li>
                        <li className={this.state.url === "3" ? "active" : ""} onClick={() => this.handleSocialMedia("coursera.org ", "3")}><a
                            >Coursera</a>
                        </li>
                        <li  className={this.state.url === "4" ? "active" : ""} onClick={() => this.handleSocialMedia("edx.org ", "4")}><a
                           >edX</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}
export default CoursesFilters