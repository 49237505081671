import React, {Component} from "react";
import {Link} from "react-router-dom";

class Breadcrumbs extends Component{
    render() {
        return (
            <>
               <div className="container originals-breadcrumbs">
                   <h6><Link to="/" style={{textDecoration:"none"}}><span style={{color:"black"}}>Home > </span></Link> <Link to="/rb-originals" style={{textDecoration:"none"}}><span style={{color: "#00944D"}}> RB Library</span></Link><span style={{color: "#00944D"}}>{this.props.title ? " >" + " " + this.props.title : ""}</span></h6>
               </div>
            </>
        );
    }
}
export default Breadcrumbs