import React, { useState, useEffect } from 'react';
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import "./supportrb.css";
import "./supportrb_mobile.css";
import AboutImg from "../../../assets/Group 90015.png";
import BannerImg from "../../../assets/Group 89929.jpg";
import BannerImgmob from "../../../assets/Group 89969.png";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import PaymentForm from "../Payment/PaymentForm";
import axios from "axios";
import MediaQuery from "react-responsive";
import buildAPIUrl from "../../../Services/UrlBuilder";


// Specicy Stripe Publishable API key here
//pk_test_51HYOVNCXQALUTHo7udzb6egZOhvQHzwH81SVkqJTv23cYseHxzop6gGlQ4Tf1xweVa19gGaFqDgRjT8x2sHuARfg00PiA0j2BD
//pk_live_51HYOVNCXQALUTHo7q788NQxaAmqi8HyTXvQxzfO477Zud5f2qbVC95r5YIZOvtWGbGvB3K2JUMIuwV348LBjuxrP0048gh6iu5
const stripe = process.env.REACT_APP_STRIPE_SECRET
const promise = loadStripe(`${stripe}`);

function SupportRB() {
  const[loading, setLoading]=useState(true)
  const[plans, setPlans]=useState([])
  const [pickedPrice, setPickedPrice]=useState(null)
  const [OneTimePayment, setisOneTimePayment]=useState({
    isOneTimePayment: true,
    name: "one_time_payment"
  });
  const [RecurringPayment, setisRecurringPayment]=useState({
    isRecurringPayment: false,
    name: "recurring_payment"
  });
  const [amount, setAmount]=useState(null);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  

  function changePaymentOptionHandler(event){
    if(event.target.name === RecurringPayment.name){
      setisRecurringPayment((prevState) => {
        return { ...prevState, isRecurringPayment: true, };
      });
      setisOneTimePayment((prevState) => {
        return { ...prevState, isOneTimePayment: false, };
      });
    }else if(event.target.name === OneTimePayment.name){
     
      setisOneTimePayment((prevState) => {
        return { ...prevState, isOneTimePayment: true, };
      });
      setisRecurringPayment((prevState) => {
        return { ...prevState, isRecurringPayment: false, };
      });
    }

  }

  function selectAmountHandler(event){
    setAmount(event.target.value);
  }
  useEffect(()=>{
    const url = 'v1/subscriptions/?support_rb_plan=1';
    const plans = axios.get(buildAPIUrl(url)).then((res)=>{
      setPlans(res.data)
      setLoading(false)
    }).catch((error)=>{

    })
  }, [])
  const handleChangePrice = async (price) => {
    await setPickedPrice(price)
    await parseInt(localStorage.setItem('priceamount', price))
  }
  const cancelPayment = async() =>{
    await setPickedPrice(null)
  }
  return (
    <>
      <Header />
      <MediaQuery minWidth={1200}>
        <div className={"container-fluid p-0"}>
          <img src={BannerImg} alt="support rb" style={{width:"100%"}}/>
        </div>
        <div className={"container-fluid"} style={{padding:"4.625rem 14.938rem 3.063rem 15.625rem "}}>
          <div className={"row"}>
            <div className={"col-md-8 supportrb__about_content"}>
              <h4>
                The RB team is overjoyed to watch the growth of this UXR
                community. We have enjoyed seeing you at workshops, hearing your
                UXR stories, and seeing you create content with our resources!
              </h4>
              <h4>
                We are committed to supporting your UX journey by sourcing the
                most relevant material to your searches. The RB team is excited to
                work on projects dedicated to this mission, including search engine
                updates! Please consider supporting our efforts through a one-time
                or monthly donation. We can't wait to share what we've been
                working on!
              </h4>
            </div>
            <div className={"col-md-4"}>
              <img
                  src={AboutImg}
                  alt={"two-young-intercultural-colleagues-discussing"}
              />
            </div>
          </div>
        </div>
        <div className="supportrb_main">
          <div className={OneTimePayment.isOneTimePayment ? 'supportrb__supportcontainer supportrb__supportsmallcontainer': 'supportrb__supportcontainer supportrb__supportlargecontainer'}>
            <h4>Support</h4>
            <div className='supportrb__box'>
              <h4>How much are you donating?</h4>

              <div className='supportrb__payment_type_options'>
                <button
                    className={OneTimePayment.isOneTimePayment ? "supportrb__payment_type active" : "supportrb__payment_type"}
                    name={OneTimePayment.name}
                    onClick={changePaymentOptionHandler}>ONE TIME</button>
                <button
                    className={RecurringPayment.isRecurringPayment? "supportrb__payment_type active" : "supportrb__payment_type"}
                    name={RecurringPayment.name}
                    onClick={changePaymentOptionHandler}>RECURRING</button>
              </div>
              {OneTimePayment.isOneTimePayment &&
                  <>
                    <div className='supportrb__amount_suggestion'>
                      <button className={amount==="5" ? "amount-active" : "amount"} value="5" onClick={selectAmountHandler}>$5</button>
                      <button className={amount==="10" ? "amount-active" : "amount"} value="10" onClick={selectAmountHandler}>$10</button>
                      <button className={amount==="20" ? "amount-active" : "amount"} value="20" onClick={selectAmountHandler}>$20</button>
                      <button className={amount==="30" ? "amount-active" : "amount"} value="30" onClick={selectAmountHandler}>$30</button>
                      <button className={amount==="50" ? "amount-active" : "amount"} value="50" onClick={selectAmountHandler}>$50</button>

                    </div>
                    <input
                        type="number"
                        className='donation_input'
                        placeholder='Enter price manually'
                        defaultValue={amount}
                        value={amount}
                        onChange={selectAmountHandler}/>
                    <div className={"container"} style={{marginTop:"1rem", padding: "0 12rem 2rem 12rem"}}>
                      {amount && <Elements stripe={promise}>
                        <CheckoutForm cardStyle={cardStyle} submitButtonText={"Donate" + " " + "$"+`${amount}`} amount={`${amount}00`}/>
                      </Elements>}
                    </div>

                  </>}


            {RecurringPayment.isRecurringPayment &&
                <>
                <div className={"supportrb__amount_suggestion"}>
                  {
                    !loading ?  <div className={"row d-flex justify-content-around"}>
                      {
                        plans.sort(function(a, b){return a.stripe_plan_details.amount - b.stripe_plan_details.amount}).map((plandet)=>(
                            <div className={"col-md-2"}>
                              <button style={{display:"inline-block"}} className={pickedPrice===plandet.stripe_plan_details.amount/100 ? "amount-active" : "amount"} onClick={()=>handleChangePrice(Math.trunc(plandet.stripe_plan_details.amount/100))}>
                                ${Math.trunc(plandet.stripe_plan_details.amount/100)}
                              </button>

                            </div>
                        ))
                      }
                    </div> :  <>
                      <p> Loading...</p>
                    </>
                  }
                </div>
                  <div className={"container mt-5 mb-5"} style={{paddingLeft:"10rem", paddingRight:"10rem"}}>
                    {pickedPrice && <Elements stripe={promise}>
                      <CheckoutForm cardStyle={cardStyle} submitButtonText={"Donate" + " " + "$"+ `${pickedPrice}`} amount={`${pickedPrice}00`}/>
                    </Elements>}
                  </div>

                </>
            }
            </div>

          </div>
          <div className='supportrb__donation'>
            <h4 className='header'>Where your donation goes</h4>
            <div className='supportrb__donation_content'>
              <div className='supportrb__donation_project'>
                <h3>Projects</h3>
                <h4>
                  This entails updating current features as well as
                  introducing brand-new ones. Our objective is
                  to democratize access to UX research data for
                  professionals and students worldwide. Your
                  financial support enables us to grow this
                  ambition.
                </h4>
              </div>
              <div className='supportrb__donation_people'>
                <h3>People</h3>
                <h4>
                  Our platform is built and maintained by a team
                  of competent developers from all around the world.
                </h4>
              </div>
              <div className='supportrb__donation_technology'>
                <h3>Technology</h3>
                <h4>
                  Servers, bandwidth, maintenance, and development.
                </h4>
              </div>

            </div>
          </div>
          <div className='supportrb__volunteer'>
            <h4>
              Volunteering at Research Bookmark in a variety of roles is a way to give
              back to the ux community. Machine learning, design, and development
              are only a few examples.
            </h4>
            <a href={"https://forms.gle/seLwFfweozvLAcX68"} target={"_blank"} className="supportrb__volunteer_button">Volunteer at RB</a>

          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1200} className="tablet" minWidth={461}>
        <div className={"container-fluid p-0"}>
          <img src={BannerImg} alt="support rb" style={{width:"100%"}}/>
        </div>
        <div className={"container-fluid"} style={{padding:"0 "}}>
          <div className={"row"}>
            <div className={"col-md-8 supportrb__about_content"}>
              <h4>
                The RB team is overjoyed to watch the growth of this UXR
                community. We have enjoyed seeing you at workshops, hearing your
                UXR stories, and seeing you create content with our resources!
              </h4>
              <h4>
                We are committed to supporting your UX journey by sourcing the
                most relevant material to your searches. The RB team is excited to
                work on projects dedicated to this mission, including search engine
                updates! Please consider supporting our efforts through a one-time
                or monthly donation. We can't wait to share what we've been
                working on!
              </h4>
            </div>
            <div className={"col-md-4"}>
              <img
                  src={AboutImg}
                  alt={"two-young-intercultural-colleagues-discussing"}
              />
            </div>
          </div>
        </div>
        <div className="supportrb_main">
          <div className={OneTimePayment.isOneTimePayment ? 'supportrb__supportcontainer supportrb__supportsmallcontainer': 'supportrb__supportcontainer supportrb__supportlargecontainer'}>
            <h4>Support</h4>
            <div className='supportrb__box' style={{width:"80%", left:"10%", right:"10%"}}>
              <h4>How much are you donating?</h4>

              <div className='supportrb__payment_type_options'>
                <button
                    className={OneTimePayment.isOneTimePayment ? "supportrb__payment_type active" : "supportrb__payment_type"}
                    name={OneTimePayment.name}
                    onClick={changePaymentOptionHandler}>ONE TIME</button>
                <button
                    className={RecurringPayment.isRecurringPayment? "supportrb__payment_type active" : "supportrb__payment_type"}
                    name={RecurringPayment.name}
                    onClick={changePaymentOptionHandler}>RECURRING</button>
              </div>
              {OneTimePayment.isOneTimePayment &&
                  <>
                    <div className='supportrb__amount_suggestion'>
                      <button className={amount==="5" ? "amount-active" : "amount"} value="5" onClick={selectAmountHandler}>$5</button>
                      <button className={amount==="10" ? "amount-active" : "amount"} value="10" onClick={selectAmountHandler}>$10</button>
                      <button className={amount==="20" ? "amount-active" : "amount"} value="20" onClick={selectAmountHandler}>$20</button>
                      <button className={amount==="30" ? "amount-active" : "amount"} value="30" onClick={selectAmountHandler}>$30</button>
                      <button className={amount==="50" ? "amount-active" : "amount"} value="50" onClick={selectAmountHandler}>$50</button>

                    </div>

                    <div className={"container"} style={{marginTop:"1rem", padding: "0 4rem 2rem 4rem"}}>
                      <input
                          type="number"
                          className='donation_input'
                          placeholder='Enter price manually'
                          defaultValue={amount}
                          value={amount}
                          onChange={selectAmountHandler} style={{width:"100%", marginBottom:"1rem"}}/>
                      {amount && <Elements stripe={promise}>
                        <CheckoutForm cardStyle={cardStyle} submitButtonText={"Donate" + " " + "$"+`${amount}`} amount={`${amount}00`}/>
                      </Elements>}
                    </div>

                  </>}


              {RecurringPayment.isRecurringPayment &&
                  <>
                    <div className={"supportrb__amount_suggestion"}>
                      {
                        !loading ?  <div className={"row d-flex justify-content-around"}>
                          {
                            plans.sort(function(a, b){return a.stripe_plan_details.amount - b.stripe_plan_details.amount}).map((plandet)=>(
                                <div className={"col-md-2"}>
                                  <button style={{display:"inline-block"}} className={pickedPrice===plandet.stripe_plan_details.amount/100 ? "amount-active" : "amount"} onClick={()=>handleChangePrice(Math.trunc(plandet.stripe_plan_details.amount/100))}>
                                    ${Math.trunc(plandet.stripe_plan_details.amount/100)}
                                  </button>

                                </div>
                            ))
                          }
                        </div> :  <>
                          <p> Loading...</p>
                        </>
                      }
                    </div>
                    <div className={"container mt-5 mb-5"} style={{paddingLeft:"4rem", paddingRight:"4rem"}}>
                      {pickedPrice && <Elements stripe={promise}>
                        <CheckoutForm cardStyle={cardStyle} submitButtonText={"Donate" + " " + "$"+ `${pickedPrice}`} amount={`${pickedPrice}00`}/>
                      </Elements>}
                    </div>

                  </>
              }


            </div>

          </div>
          <div className='supportrb__donation'>
            <h4 className='header'>Where your donation goes</h4>
            <div className='supportrb__donation_content'>
              <div className='supportrb__donation_project'>
                <h3>Projects</h3>
                <h4>
                  This entails updating current features as well as
                  introducing brand-new ones. Our objective is
                  to democratize access to UX research data for
                  professionals and students worldwide. Your
                  financial support enables us to grow this
                  ambition.
                </h4>
              </div>
              <div className='supportrb__donation_people'>
                <h3>People</h3>
                <h4>
                  Our platform is built and maintained by a team
                  of competent developers from all around the world.
                </h4>
              </div>
              <div className='supportrb__donation_technology'>
                <h3>Technology</h3>
                <h4>
                  Servers, bandwidth, maintenance, and development.
                </h4>
              </div>

            </div>
          </div>
          <div className='supportrb__volunteer'>
            <h4>
              Volunteering at Research Bookmark in a variety of roles is a way to give
              back to the ux community. Machine learning, design, and development
              are only a few examples.
            </h4>
            <a href={"https://forms.gle/seLwFfweozvLAcX68"} target={"_blank"} className="supportrb__volunteer_button">Volunteer at RB</a>

          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={460}>
        <div className={"mobile"}>
          <div className={"container-fluid p-0"}>
            <img src={BannerImgmob} alt="support rb" style={{width:"100%"}}/>
          </div>
          <div className={"container-fluid"} style={{padding:"1rem"}}>
            <div className={"row"}>
              <div className={"col-md-4"}>
                <img
                    src={AboutImg}
                    alt={"two-young-intercultural-colleagues-discussing"} style={{height:"167px"}}
                />
              </div>
              <div className={"col-md-8 supportrb__about_content"} style={{marginTop:"1.813rem"}}>
                <h4>
                  The RB team is overjoyed to watch the growth of this UXR
                  community. We have enjoyed seeing you at workshops, hearing your
                  UXR stories, and seeing you create content with our resources!
                </h4>
                <h4>
                  We are committed to supporting your UX journey by sourcing the
                  most relevant material to your searches. The RB team is excited to
                  work on projects dedicated to this mission, including search engine
                  updates! Please consider supporting our efforts through a one-time
                  or monthly donation. We can't wait to share what we've been
                  working on!
                </h4>
              </div>

            </div>
          </div>
          <div className="supportrb_main">
            <div className={OneTimePayment.isOneTimePayment ? 'supportrb__supportcontainer supportrb__supportsmallcontainer': 'supportrb__supportcontainer supportrb__supportlargecontainer'} style={{height:"unset", marginBottom:"0"}}>
              <h4>Support</h4>
              <div className='supportrb__box'>
                <h4>How much are you donating?</h4>

                <div className='supportrb__payment_type_options'>
                  <button
                      className={OneTimePayment.isOneTimePayment ? "supportrb__payment_type active" : "supportrb__payment_type"}
                      name={OneTimePayment.name}
                      onClick={changePaymentOptionHandler}>ONE TIME</button>
                  <button
                      className={RecurringPayment.isRecurringPayment? "supportrb__payment_type active" : "supportrb__payment_type"}
                      name={RecurringPayment.name}
                      onClick={changePaymentOptionHandler}>RECURRING</button>
                </div>
                {OneTimePayment.isOneTimePayment &&
                    <>
                     <div className={"container"}>
                       <div className='p-0 row d-flex justify-content-center' style={{marginTop:"2.313rem"}}>
                         <div className={"col-4 mt-2"}>
                           <button className={amount==="5" ? "amount-active" : "amount"} value="5" onClick={selectAmountHandler}>$5</button>
                         </div>
                         <div className={"col-4 mt-2"}>
                           <button className={amount==="10" ? "amount-active" : "amount"} value="10" onClick={selectAmountHandler}>$10</button>
                         </div>
                         <div className={"col-4 mt-2"}>
                           <button className={amount==="20" ? "amount-active" : "amount"} value="20" onClick={selectAmountHandler}>$20</button>
                         </div>
                         <div className={"col-4 mt-2"}>
                           <button className={amount==="30" ? "amount-active" : "amount"} value="30" onClick={selectAmountHandler}>$30</button>
                         </div>
                         <div className={"col-4 mt-2"}>
                           <button className={amount==="50" ? "amount-active" : "amount"} value="50" onClick={selectAmountHandler}>$50</button>
                         </div>
                       </div>

                     </div>
                      <div className={"container"} style={{marginTop:"1rem", padding: "0 2rem 2rem 2rem"}}>
                        <input
                            type="number"
                            className='donation_input'
                            placeholder='Enter price manually'
                            defaultValue={amount}
                            value={amount}
                            onChange={selectAmountHandler}
                            style={{marginBottom:"1rem"}}
                        />
                        {amount && <Elements stripe={promise}>
                          <CheckoutForm cardStyle={cardStyle} submitButtonText={"Donate" + " " + "$"+`${amount}`} amount={`${amount}00`}/>
                        </Elements>}
                      </div>

                    </>}


                {RecurringPayment.isRecurringPayment &&
                    <>
                      <div className={"supportrb__amount_suggestion"}>
                        {
                          !loading ?  <div className={"row d-flex justify-content-center"}>
                            {
                              plans.sort(function(a, b){return a.stripe_plan_details.amount - b.stripe_plan_details.amount}).map((plandet)=>(
                                  <div className={"col-4 mt-2"}>
                                    <button style={{display:"inline-block"}} className={pickedPrice===plandet.stripe_plan_details.amount/100 ? "amount-active" : "amount"} onClick={()=>handleChangePrice(Math.trunc(plandet.stripe_plan_details.amount/100))}>
                                      ${Math.trunc(plandet.stripe_plan_details.amount/100)}
                                    </button>

                                  </div>
                              ))
                            }
                          </div> :  <>
                            <p> Loading...</p>
                          </>
                        }
                      </div>
                      <div className={"container mt-5 mb-5"} style={{paddingLeft:"1rem", paddingRight:"1rem"}}>
                        {pickedPrice && <Elements stripe={promise}>
                          <CheckoutForm cardStyle={cardStyle} submitButtonText={"Donate" + " " + "$"+ `${pickedPrice}`} amount={`${pickedPrice}00`}/>
                        </Elements>}
                      </div>

                    </>
                }


              </div>

            </div>
            <div className='supportrb__donation'>
              <h4 className='header'>Where your donation goes</h4>
              <div className={"row"} style={{padding:"0 1rem"}}>
                <div className={"col-md-12"} style={{marginTop:"2.813rem"}}>
                  <div className='supportrb'>
                    <h3>Projects</h3>
                    <h4>
                      This entails updating current features as well as
                      introducing brand-new ones. Our objective is
                      to democratize access to UX research data for
                      professionals and students worldwide. Your
                      financial support enables us to grow this
                      ambition.
                    </h4>
                  </div>
                </div>
                <div className={"col-md-12"} style={{marginTop:"2.813rem"}}>
                  <div className='supportrb'>
                    <h3>People</h3>
                    <h4>
                      Our platform is built and maintained by a team
                      of competent developers from all around the world.
                    </h4>
                  </div>
                </div>
                <div className={"col-md-12"} style={{marginTop:"2.813rem"}}>
                  <div className='supportrb'>
                    <h3>Technology</h3>
                    <h4>
                      Servers, bandwidth, maintenance, and development.
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='supportrb__volunteer'>
              <h4>
                Volunteering at Research Bookmark in a variety of roles is a way to give
                back to the ux community. Machine learning, design, and development
                are only a few examples.
              </h4>
              <a href={"https://forms.gle/seLwFfweozvLAcX68"} target={"_blank"} className="supportrb__volunteer_button">Volunteer at RB</a>

            </div>
          </div>
        </div>

      </MediaQuery>

      <Footer />
     
    </>
  );
}

export default SupportRB;
