import React, {Component} from 'react'
import axios from "axios";
import SearchBox from "../Landing/SearchBox";
import Header from "../Landing/Header";
import {HiSearch, HiX} from "react-icons/hi";
import {Link} from "react-router-dom";
import cards from "../../../assets/Group 23.png";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Sidebar from "../Landing/Sidebar";
import {MdOutlineFilterAlt} from "react-icons/md";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import MediaQuery from "react-responsive";
import searchicon from "../../../assets/Frame 39.png";


class Trends extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    state = {
        clientID: "425793011868-1s6v97ajh0m70dhcvb7oejp3qup4qb5e.apps.googleusercontent.com",
        trending: [],
        userHistory: [],
        token: localStorage.getItem('token'),
        filterhistory: localStorage.getItem('filter'),
        show: true,
        filter:"",
        tags:"0",
        tagsmobile:"0",
        startDate: new Date(),
        customCalendar: false,
        showfilter:false,
        loading:true,
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleClickOutside = (event) => {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({
                showfilter:false,
                customcalendar:true
            })
        }

    }
    handleOpen = () => {
        this.setState({
            active: false
        })
    }
    handleClose = () => {
        this.setState({
            active: true
        })
    }
    handleToday = async (e) => {
        localStorage.setItem('filter', '1')
        e.preventDefault()
        await this.setState({
            filterhistory: "1"
        })
        console.log(this.state.filterhistory)
    }
    handleYesterday = async (e) => {
        localStorage.setItem('filter', '2')
        await this.setState({
            filterhistory: "2"
        })
        console.log(this.state.filterhistory)
    }
    handleWeek = async (e) => {
        localStorage.setItem('filter', '3')
        await this.setState({
            filterhistory: "3"
        })
        console.log(this.state.filterhistory)
    }
    handleMonth = async (e) => {
        localStorage.setItem('filter', '4')
        await this.setState({
            filterhistory: "4"
        })
        console.log(this.state.filterhistory)
    }
    handleReset = async (e) => {
        localStorage.setItem('filter', '0')
        await this.setState({
            filterhistory: "0"
        })
        console.log(this.state.filterhistory)
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    };

    componentDidMount() {
        localStorage.setItem('filter', '0')
        const response = axios.get(buildAPIUrl('v1/save_search_queries/trending')).then((response) => {
            this.setState({
                trending: [response.data],
                loading: false
            })
            const sorted = this.state.trending.sort((a, b) => a[0].localeCompare(b[0]))
            this.setState({
                trending: sorted
            })
        })
       axios.get(buildAPIUrl('v1/save_search_queries/'), {headers: {Authorization: `Bearer ${this.state.token}`}}).then((res) => {
           const today = new Date()
          today.setDate(today.getDate()-1)
            this.setState({
                userHistory: res.data,
                loading: false
            })

        })
    }
    handleLinkSearch = async(userhist) =>{
        localStorage.setItem('searchquery',userhist)
        window.location.assign('/all-results')
    }
    handleSearch = async (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (this.state.token) {
                const res = await axios.post(buildAPIUrl('v1/save_search_queries/'), {
                    query_search: this.state.q,
                }, {headers: {Authorization: `Bearer ${this.state.token}`}}).then((res) => {
                    localStorage.setItem('query', this.state.q)
                    window.location.assign(`/results?q=${this.state.q}`)
                });
            } else {
                const res = await axios.post(buildAPIUrl('v1/save_search_queries/'), {
                    query_search: this.state.q,
                }).then((res) => {
                    localStorage.setItem('query', this.state.q)
                    window.location.assign(`/results?q=${this.state.q}`)
                });
            }


        }
    }
    handleSearchFilter = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    handleSearchVoc = (e) => {
        if(this.state.filter===""){
            localStorage.setItem('searchquery', "Ux Research")
            window.location.assign('/all-results')
        } else {
            localStorage.setItem('searchquery', this.state.filter)
            window.location.assign('/all-results')
        }
    }
    onClearFilters = async (event) =>{
        this.setState({
            tags:""
        })
        window.location.reload()
    }
    onChangeValue = async (event) => {
        document.addEventListener("mousedown", this.handleClickOutside);
        if(this.state.token) {
            console.log(event.target.value);
            await this.setState({
                tags:event.target.value,
                tagsmobile:event.target.value,
            })
        } else {
            await this.handleModalOpen()
        }

    }
    onMobileChangeValue = async (event) => {
        document.addEventListener("mousedown", this.handleClickOutside);
        if(this.state.token) {
            console.log(event.target.value);
            await this.setState({
                tagsmobile:event.target.value,
            })
        } else {
            await this.handleModalOpen()
        }

    }
    handleCustom = (event) =>{
        let isChecked = event.target.value
        if(isChecked) {
            this.setState({
                tags: "5",
                customCalendar: true
            })
        } else {
            this.setState({
                customCalendar: false
            })
        }
    }
    handleFilterShow = () => {
        if(this.state.showfilter) {
            this.setState({
                showfilter:false
            })
        } else {
            this.setState({
                showfilter:true
            })
        }
    }
    handleOnBlur = event => {
        const date = new Date(event.target.value);
        console.log("blur" , date);
    };
    render() {

        const  today = new Date()
        today.setDate(today.getDate())

        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate()-1)

        const thismonth = new Date()
        thismonth.setDate(thismonth.getDate()-30)
        const thisweek = new Date()
        thisweek.setDate(thisweek.getDate()-7)
        const onSuccess = async (res) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            localStorage.setItem('token', res.accessToken)
            localStorage.setItem('username', res.profileObj.name)
            localStorage.setItem('image', res.profileObj.imageUrl)
            localStorage.setItem('email', res.profileObj.email)
            const resp = await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: localStorage.getItem('token'),
                client_id: "GJSAikgLpihdkgZlvCYAQIyRPVudOegDIjTELtJW",
                client_secret: "U05TLQZPU8DsBisDcMKNfxrtKDDeV1CEyAqFxwTIn9h0UbYiwQaYvfPqlPS6Qi2S1kZsUCTcekl0lYO7Gmp8ZpQs9wvaa30Ld642CsLX5xob7d0eH0mZiYGDceeCD0pR",
                grant_type:"convert_token",
                backend: "google-oauth2"
            }, requestOptions).then((response)=>{
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                window.location.assign("/trends")
            }).catch((err)=>{
              swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.assign('/trends')
                    localStorage.clear()
                });
                console.log(err)
            })
        }
        const onFailure = (res) => {
            console.log(res)
            console.log("Login Failed")
        }
        const facebookLogin = async (res) => {
            console.log(res)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            localStorage.setItem('token', res.accessToken)
            localStorage.setItem('username', res.name)
            localStorage.setItem('image', res.picture.data.url)
            localStorage.setItem('email', res.email)
            const resp = await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: localStorage.getItem('token'),
                client_id: "GJSAikgLpihdkgZlvCYAQIyRPVudOegDIjTELtJW",
                client_secret: "U05TLQZPU8DsBisDcMKNfxrtKDDeV1CEyAqFxwTIn9h0UbYiwQaYvfPqlPS6Qi2S1kZsUCTcekl0lYO7Gmp8ZpQs9wvaa30Ld642CsLX5xob7d0eH0mZiYGDceeCD0pR",
                grant_type:"convert_token",
                backend: "facebook"
            }, requestOptions).then((response)=>{
                console.log("new token",response)
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                window.location.assign("/search-iq")
            }).catch((err)=>{
                swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.assign('/search-iq')
                    localStorage.clear()
                });
                console.log(err)
            })
        }

            const filteritms = this.state.userHistory.filter((filtered) =>{
                let created = new Date(filtered.created_on).toLocaleDateString()
               if (this.state.tags === ""){
                   return filtered
               } else
                if(this.state.tags === "1") {
                    const filteritms1 = this.state.userHistory.filter(a => new Date(a.created_on).getDate() === today.getDate())
                    return filteritms1
                    console.log(filteritms1)
                } else if(this.state.tags === "2") {
                    var filtereditemsnew= this.state.userHistory.filter(a => {
                        var date = new Date(a.created_on).toLocaleDateString();
                        return (date === yesterday.toLocaleDateString());
                    });
                    console.log(filtereditemsnew)
                } else if(this.state.tags === "3") {
                    var filtereditemsnew= this.state.userHistory.filter(a => {
                        var date = new Date(a.created_on).toLocaleDateString();
                        return (date >= thisweek.toLocaleDateString());
                    });
                    console.log(filtereditemsnew)
                } else if(this.state.tags === "4") {
                    if(created >= thismonth.toLocaleDateString()){
                        return filtered
                    }
                }
            })
        console.log(filteritms)
        const filtereditems = this.state.userHistory.filter((result)=> {
            if (this.state.filter === "") {
                return result
            } else if (result.query_search.toLowerCase().includes(this.state.filter.toLowerCase())) {
                return result
            }
        })
        console.log("tag", typeof this.state.tags)
        return (

            <>
                <Header />
                <MediaQuery minWidth={1200} >
                    <div className="row" style={{marginTop:"7rem"}}>

                        <div className="col-md-2 side-filters">
                            <div className="container-fluid border ">
                                <h5>Filters <span onClick={this.onClearFilters} style={{color:"#00944D", fontSize:"10px", marginLeft:"1.3rem",  right:"0", cursor:"pointer"}}>Clear filters</span></h5>
                                <h6>by Date</h6>
                                <div className="" onChange={this.onChangeValue}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cost1"
                                               id="flexRadioDefault1"  value="1" checked={this.state.tags==="1"}  />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Today
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cost1"
                                               id="flexRadioDefault1" value="2" checked={this.state.tags==="2"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Yesterday
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cost1"
                                               id="flexRadioDefault1" value="3" checked={this.state.tags==="3"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Last 7 days
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cost1"
                                               id="flexRadioDefault1" value="4" checked={this.state.tags==="4"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Last 30 days
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cost1"
                                               id="flexRadioDefault1" onClick={()=>this.setState({customCalendar: true})} onChange={this.handleCustom} value="5" checked={this.state.tags==="5"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Custom
                                        </label>
                                    </div>
                                    <div className="form-input">
                                        <div>

                                        </div>
                                        <DatePicker  selected={this.state.startDate} className={this.state.tags==="5" ? "form-control" : "d-none" } open={this.state.tags==="5" && this.state.customCalendar} onSelect={()=>this.setState({customCalendar: false})} onChange={(date) =>this.setState({startDate: date, customcalendar:false})} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={this.state.active ? "col-md-8 col-12  narrowcontainer" : "col-md-8 col-12  widecontainer"}>
                            <div className="container-fluid">
                                <div className="row">

                                    <div className=" col-12 col-md-11" style={{marginBottom:"4rem"}}>
                                        <div className="search-section col-12 col-md-12 ">
                                            <div className="row d-flex align-items-center ">
                                                <div className=" col-12 col-md-12 d-flex justify-content-center">
                                                    <form onKeyUp={this.handleSearchFilter} style={{width:"100%"}} autoComplete="off">
                                                        <div className="form-input input-group border" style={{borderRadius:"6px"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                            <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                                   style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search from your history"/>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="container-fluid">
                                <div className="row" >

                                    <div className=" col-12 p-0 col-md-11">
                                        {
                                            this.state.token ?
                                                <div className="container mt-4">
                                                    <div className="row">
                                                        <div className="col-md-8 col-8 d-flex align-items-center">
                                                            <div className="history-title">
                                                                <h6 className={filtereditems.length>0 ? "" : "d-none"}>Your Search History</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.loading ? <>
                                                            {
                                                                [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                    key={n}/>)
                                                            }

                                                        </> :
                                                        <div className="history-details">
                                                            <table className="history-table mt-3"
                                                                   style={{tableLayout: "fixed"}}>
                                                                <thead
                                                                    className={filtereditems.length > 0 ? "" : "d-none"}>
                                                                <tr>
                                                                    <th>Search Term</th>
                                                                    <th>Date</th>
                                                                    <th>Time</th>

                                                                </tr>
                                                                </thead>
                                                                <tbody className="hidden-mob">


                                                                <>
                                                                    {this.state.tags === "0" ? <>
                                                                            {
                                                                                filtereditems.length > 0 ? filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).map((userhist) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="title"><a
                                                                                                style={{
                                                                                                    textDecoration: "none",
                                                                                                    color: "#00944D"
                                                                                                }}
                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                            </td>
                                                                                            <td>
                                                                                               Date {new Date(userhist.created_on).toLocaleDateString('en-US')}
                                                                                            </td>
                                                                                            <td>
                                                                                               Time {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )) : <>
                                                                                    <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                        </> :
                                                                        this.state.tags === "1" ? <>
                                                                            {

                                                                                filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).getDate() === today.getDate()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    Date {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tags === "2" ? <>

                                                                            {

                                                                                filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tags === "3" ? <>

                                                                                {

                                                                                    filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).map((userhist) => (
                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title"><a
                                                                                                        style={{
                                                                                                            textDecoration: "none",
                                                                                                            color: "#00944D"
                                                                                                        }}
                                                                                                        onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                        target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </>

                                                                            : this.state.tags === "4" ? <>
                                                                                {

                                                                                    filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).map((userhist) => (

                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title">
                                                                                                        <a
                                                                                                            style={{
                                                                                                                textDecoration: "none",
                                                                                                                color: "#00944D"
                                                                                                            }}
                                                                                                            onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                            target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message">
                                                                                            <br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any
                                                                                                searched sources</h6>
                                                                                            <p>Saved sources allows you
                                                                                                to keep sources you find
                                                                                                interesting for
                                                                                                later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </> : this.state.tags === "5" ? <>
                                                                                {

                                                                                    filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).map((userhist) => (

                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title">
                                                                                                        <a
                                                                                                            style={{
                                                                                                                textDecoration: "none",
                                                                                                                color: "#00944D"
                                                                                                            }}
                                                                                                            onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                            target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message">
                                                                                            <br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any
                                                                                                searched sources</h6>
                                                                                            <p>Saved sources allows you
                                                                                                to keep sources you find
                                                                                                interesting for
                                                                                                later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </> : this.state.tags === "4" ?
                                                                                <>
                                                                                    {

                                                                                        filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).map((userhist) => (
                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched
                                                                                                    sources</h6>
                                                                                                <p>Saved sources allows
                                                                                                    you to keep sources
                                                                                                    you find interesting
                                                                                                    for later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </> : <></>

                                                                    }

                                                                </>


                                                                </tbody>
                                                                <tbody className="mobmodal">

                                                                <>
                                                                    {this.state.tagsmobile === "0" ? <>
                                                                            {
                                                                                filtereditems.length > 0 ? filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).map((userhist) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="title"><a
                                                                                                style={{
                                                                                                    textDecoration: "none",
                                                                                                    color: "#00944D"
                                                                                                }}
                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                            </td>
                                                                                            <td>
                                                                                                {new Date(userhist.created_on).toLocaleDateString()}
                                                                                            </td>
                                                                                            <td>
                                                                                                {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )) : <>
                                                                                    <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                        </> :
                                                                        this.state.tagsmobile === "1" ? <>
                                                                            {

                                                                                filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message">

                                                                                        <br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tagsmobile === "2" ? <>

                                                                            {

                                                                                filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tagsmobile === "3" ? <>

                                                                                {

                                                                                    filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).map((userhist) => (
                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title"><a
                                                                                                        style={{
                                                                                                            textDecoration: "none",
                                                                                                            color: "#00944D"
                                                                                                        }}
                                                                                                        onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                        target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </>
                                                                            : this.state.tagsmobile === "4" ? <>
                                                                                    {

                                                                                        filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).map((userhist) => (

                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched sources</h6>
                                                                                                <p>Saved sources allows you
                                                                                                    to keep sources you find
                                                                                                    interesting for
                                                                                                    later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </>

                                                                                : this.state.tagsmobile === "5" ? <>
                                                                                    {

                                                                                        filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).map((userhist) => (

                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched
                                                                                                    sources</h6>
                                                                                                <p>Saved sources allows
                                                                                                    you to keep sources
                                                                                                    you find interesting
                                                                                                    for later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </> : this.state.tagsmobile === "4" ? <>
                                                                                    {

                                                                                        filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).map((userhist) => (
                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched
                                                                                                    sources</h6>
                                                                                                <p>Saved sources allows
                                                                                                    you to keep sources
                                                                                                    you find interesting
                                                                                                    for later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </> : <></>

                                                                    }

                                                                </>

                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    }
                                                </div> : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className="row mobile" style={{minHeight: "100vh"}}>
                        <div className={this.state.active ? "col-md-8 col-12  narrowcontainer" : "col-md-8 col-12  widecontainer"}>
                            <div className="container-fluid">
                                <div className="row">

                                    <div className=" col-12 col-md-11">
                                        <div className="search-section col-12 col-md-12 ">
                                            <div className="row d-flex align-items-center ">
                                                <div className=" col-12 col-md-12 d-flex justify-content-center">
                                                    <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                        <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                            <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                            <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                                   style={{width: "100%", border:"none"}} placeholder="Search in your search history"/>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="container-fluid">
                                <div className="row" >

                                    <div className=" col-12 p-0 col-md-11">
                                        {
                                            this.state.token ?
                                                <div className="container mt-4">
                                                    <div className="row">
                                                        <div className="col-md-8 col-8 d-flex align-items-center">
                                                            <div className="history-title">
                                                                <h6 className={filtereditems.length>0 ? "" : "d-none"}>Your Search History</h6>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-4 d-flex align-items-center justify-content-end">
                                                            <a className="mobfilter1" style={{color:"#757D8A", textDecoration:"none", cursor:"pointer"}} onClick={this.handleFilterShow}><h6 className="h6-filter">Filter <MdOutlineFilterAlt/> </h6></a>
                                                            <div ref={this.ref} className={this.state.showfilter? "row mobfilter mobfilter1 d-flex justify-content-center position-absolute" : " d-none mobfilter1" } style={{right:"5%", top:"17rem", width:"50%"}}>
                                                                <div className="container-fluid bg-white border ">
                                                                    <h5>Filters <span onClick={this.onClearFilters} style={{color:"#00944D", fontSize:"10px", marginLeft:"1.3rem",  right:"0", cursor:"pointer"}}>Clear filters</span></h5>

                                                                    <h6>by Date</h6>
                                                                    <div className="" onChange={this.onMobileChangeValue}>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" name="cost"
                                                                                   id="flexRadioDefault1"  value="1" checked={this.state.tagsmobile==="1"}  />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                Today
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" name="cost"
                                                                                   id="flexRadioDefault1" value="2" checked={this.state.tagsmobile==="2"} />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                Yesterday
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" name="cost"
                                                                                   id="flexRadioDefault1" value="3" checked={this.state.tagsmobile==="3"} />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                Last 7 days
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" name="cost"
                                                                                   id="flexRadioDefault1" value="4" checked={this.state.tagsmobile==="4"} />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                Last 30 days
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" name="cost"
                                                                                   id="flexRadioDefault1" onChange={this.handleCustom} value="5" checked={this.state.tagsmobile==="5"} />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                Custom
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-input">
                                                                            <DatePicker selected={this.state.startDate} className="form-control" open={this.state.tagsmobile==="5"? true : false}  onChange={(date) =>this.setState({startDate: date})} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.loading ? <>
                                                            {
                                                                [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                    key={n}/>)
                                                            }

                                                        </> :
                                                        <div className="history-details">
                                                            <table className="history-table mt-3"
                                                                   style={{tableLayout: "fixed"}}>
                                                                <thead
                                                                    className={filtereditems.length > 0 ? "" : "d-none"}>
                                                                <tr>
                                                                    <th>Search Term</th>
                                                                    <th>Date</th>
                                                                    <th>Time</th>

                                                                </tr>
                                                                </thead>
                                                                <tbody className="hidden-mob">


                                                                <>
                                                                    {this.state.tags === "0" ? <>
                                                                            {
                                                                                filtereditems.length > 0 ? filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).map((userhist) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="title"><a
                                                                                                style={{
                                                                                                    textDecoration: "none",
                                                                                                    color: "#00944D"
                                                                                                }}
                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                            </td>
                                                                                            <td>
                                                                                                {userhist.created_on.substring(0, 10)}
                                                                                            </td>
                                                                                            <td>
                                                                                                {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )) : <>
                                                                                    <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                        </> :
                                                                        this.state.tags === "1" ? <>
                                                                            {

                                                                                filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tags === "2" ? <>

                                                                            {

                                                                                filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tags === "3" ? <>

                                                                                {

                                                                                    filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).map((userhist) => (
                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title"><a
                                                                                                        style={{
                                                                                                            textDecoration: "none",
                                                                                                            color: "#00944D"
                                                                                                        }}
                                                                                                        onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                        target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </>

                                                                            : this.state.tags === "4" ? <>
                                                                                {

                                                                                    filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).map((userhist) => (

                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title">
                                                                                                        <a
                                                                                                            style={{
                                                                                                                textDecoration: "none",
                                                                                                                color: "#00944D"
                                                                                                            }}
                                                                                                            onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                            target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message">
                                                                                            <br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any
                                                                                                searched sources</h6>
                                                                                            <p>Saved sources allows you
                                                                                                to keep sources you find
                                                                                                interesting for
                                                                                                later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </> : this.state.tags === "5" ? <>
                                                                                {

                                                                                    filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).map((userhist) => (

                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title">
                                                                                                        <a
                                                                                                            style={{
                                                                                                                textDecoration: "none",
                                                                                                                color: "#00944D"
                                                                                                            }}
                                                                                                            onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                            target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message">
                                                                                            <br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any
                                                                                                searched sources</h6>
                                                                                            <p>Saved sources allows you
                                                                                                to keep sources you find
                                                                                                interesting for
                                                                                                later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </> : this.state.tags === "4" ?
                                                                                <>
                                                                                    {

                                                                                        filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).map((userhist) => (
                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched
                                                                                                    sources</h6>
                                                                                                <p>Saved sources allows
                                                                                                    you to keep sources
                                                                                                    you find interesting
                                                                                                    for later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </> : <></>

                                                                    }

                                                                </>


                                                                </tbody>
                                                                <tbody className="mobmodal">

                                                                <>
                                                                    {this.state.tagsmobile === "0" ? <>
                                                                            {
                                                                                filtereditems.length > 0 ? filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).map((userhist) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="title"><a
                                                                                                style={{
                                                                                                    textDecoration: "none",
                                                                                                    color: "#00944D"
                                                                                                }}
                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                            </td>
                                                                                            <td>
                                                                                                {userhist.created_on.substring(0, 10)}
                                                                                            </td>
                                                                                            <td>
                                                                                                {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )) : <>
                                                                                    <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                        </> :
                                                                        this.state.tagsmobile === "1" ? <>
                                                                            {

                                                                                filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tagsmobile === "2" ? <>

                                                                            {

                                                                                filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tagsmobile === "3" ? <>

                                                                                {

                                                                                    filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).map((userhist) => (
                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title"><a
                                                                                                        style={{
                                                                                                            textDecoration: "none",
                                                                                                            color: "#00944D"
                                                                                                        }}
                                                                                                        onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                        target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </>
                                                                            : this.state.tagsmobile === "4" ? <>
                                                                                    {

                                                                                        filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).map((userhist) => (

                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched sources</h6>
                                                                                                <p>Saved sources allows you
                                                                                                    to keep sources you find
                                                                                                    interesting for
                                                                                                    later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </>

                                                                                : this.state.tagsmobile === "5" ? <>
                                                                                    {

                                                                                        filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).map((userhist) => (

                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched
                                                                                                    sources</h6>
                                                                                                <p>Saved sources allows
                                                                                                    you to keep sources
                                                                                                    you find interesting
                                                                                                    for later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </> : this.state.tagsmobile === "4" ? <>
                                                                                    {

                                                                                        filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).map((userhist) => (
                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched
                                                                                                    sources</h6>
                                                                                                <p>Saved sources allows
                                                                                                    you to keep sources
                                                                                                    you find interesting
                                                                                                    for later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </> : <></>

                                                                    }

                                                                </>

                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    }
                                                </div> : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461} >
                    <div className="row tablet" style={{minHeight: "100vh"}}>

                        <div className="col-md-3 side-filters">
                            <div className="container-fluid border" style={{marginLeft:"1rem"}}>
                                <h5>Filters <span onClick={this.onClearFilters} style={{color:"#00944D", fontSize:"10px", marginLeft:"1.3rem",  right:"0", cursor:"pointer"}}>Clear filters</span></h5>
                                <h6>by Date</h6>
                                <div className="" onChange={this.onChangeValue}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cost1"
                                               id="flexRadioDefault1"  value="1" checked={this.state.tags==="1"}  />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Today
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cost1"
                                               id="flexRadioDefault1" value="2" checked={this.state.tags==="2"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Yesterday
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cost1"
                                               id="flexRadioDefault1" value="3" checked={this.state.tags==="3"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Last 7 days
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cost1"
                                               id="flexRadioDefault1" value="4" checked={this.state.tags==="4"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Last 30 days
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="cost1"
                                               id="flexRadioDefault1" onClick={()=>this.setState({customCalendar: true})} onChange={this.handleCustom} value="5" checked={this.state.tags==="5"} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Custom
                                        </label>
                                    </div>
                                    <div className="form-input">
                                        <div>

                                        </div>
                                        <DatePicker  selected={this.state.startDate} className={this.state.tags==="5" ? "form-control" : "d-none" } open={this.state.tags==="5" && this.state.customCalendar} onSelect={()=>this.setState({customCalendar: false})} onChange={(date) =>this.setState({startDate: date, customcalendar:false})} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={this.state.active ? "col-md-8 col-12  narrowcontainer" : "col-md-8 col-12  widecontainer"}>
                            <div className="container-fluid">
                                <div className="row">

                                    <div className=" col-12 col-md-11" style={{marginBottom:"4rem"}}>
                                        <div className="search-section col-12 col-md-12 ">
                                            <div className="row d-flex align-items-center ">
                                                <div className=" col-12 col-md-12 d-flex justify-content-center">
                                                    <form onKeyUp={this.handleSearchFilter} style={{width:"100%"}} autoComplete="off">
                                                        <div className="form-input">
                                                             <span className="input-group-addon d-flex align-items-center"> <HiSearch
                                                                 className="search-icon"/></span>
                                                            <input className="search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                                   style={{width: "100%", paddingLeft:"8%"}} placeholder="Search for history"/>

                                                        </div>
                                                    </form>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="container-fluid">
                                <div className="row" >

                                    <div className=" col-12 p-0 col-md-11">
                                        {
                                            this.state.token ?
                                                <div className="container mt-4">
                                                    <div className="row">
                                                        <div className="col-md-8 col-8 d-flex align-items-center">
                                                            <div className="history-title">
                                                                <h6 className={filtereditems.length>0 ? "" : "d-none"}>Your Search History</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.loading ? <>
                                                            {
                                                                [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                    key={n}/>)
                                                            }

                                                        </> :
                                                        <div className="history-details">
                                                            <table className="history-table mt-3"
                                                                   style={{tableLayout: "fixed"}}>
                                                                <thead
                                                                    className={filtereditems.length > 0 ? "" : "d-none"}>
                                                                <tr>
                                                                    <th>Search Term</th>
                                                                    <th>Date</th>
                                                                    <th>Time</th>

                                                                </tr>
                                                                </thead>
                                                                <tbody className="hidden-mob">


                                                                <>
                                                                    {this.state.tags === "0" ? <>
                                                                            {
                                                                                filtereditems.length > 0 ? filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).map((userhist) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="title"><a
                                                                                                style={{
                                                                                                    textDecoration: "none",
                                                                                                    color: "#00944D"
                                                                                                }}
                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                            </td>
                                                                                            <td>
                                                                                                {userhist.created_on.substring(0, 10)}
                                                                                            </td>
                                                                                            <td>
                                                                                                {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )) : <>
                                                                                    <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                        </> :
                                                                        this.state.tags === "1" ? <>
                                                                            {

                                                                                filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tags === "2" ? <>

                                                                            {

                                                                                filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tags === "3" ? <>

                                                                                {

                                                                                    filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).map((userhist) => (
                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title"><a
                                                                                                        style={{
                                                                                                            textDecoration: "none",
                                                                                                            color: "#00944D"
                                                                                                        }}
                                                                                                        onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                        target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </>

                                                                            : this.state.tags === "4" ? <>
                                                                                {

                                                                                    filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).map((userhist) => (

                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title">
                                                                                                        <a
                                                                                                            style={{
                                                                                                                textDecoration: "none",
                                                                                                                color: "#00944D"
                                                                                                            }}
                                                                                                            onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                            target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message">
                                                                                            <br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any
                                                                                                searched sources</h6>
                                                                                            <p>Saved sources allows you
                                                                                                to keep sources you find
                                                                                                interesting for
                                                                                                later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </> : this.state.tags === "5" ? <>
                                                                                {

                                                                                    filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).map((userhist) => (

                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title">
                                                                                                        <a
                                                                                                            style={{
                                                                                                                textDecoration: "none",
                                                                                                                color: "#00944D"
                                                                                                            }}
                                                                                                            onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                            target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message">
                                                                                            <br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any
                                                                                                searched sources</h6>
                                                                                            <p>Saved sources allows you
                                                                                                to keep sources you find
                                                                                                interesting for
                                                                                                later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </> : this.state.tags === "4" ?
                                                                                <>
                                                                                    {

                                                                                        filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).map((userhist) => (
                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched
                                                                                                    sources</h6>
                                                                                                <p>Saved sources allows
                                                                                                    you to keep sources
                                                                                                    you find interesting
                                                                                                    for later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </> : <></>

                                                                    }

                                                                </>


                                                                </tbody>
                                                                <tbody className="mobmodal">

                                                                <>
                                                                    {this.state.tagsmobile === "0" ? <>
                                                                            {
                                                                                filtereditems.length > 0 ? filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).map((userhist) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="title"><a
                                                                                                style={{
                                                                                                    textDecoration: "none",
                                                                                                    color: "#00944D"
                                                                                                }}
                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                            </td>
                                                                                            <td>
                                                                                                {userhist.created_on.substring(0, 10)}
                                                                                            </td>
                                                                                            <td>
                                                                                                {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>
                                                                                )) : <>
                                                                                    <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                        </> :
                                                                        this.state.tagsmobile === "1" ? <>
                                                                            {

                                                                                filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === today.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tagsmobile === "2" ? <>

                                                                            {

                                                                                filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === yesterday.toLocaleDateString()).map((userhist) => (
                                                                                    <>
                                                                                        {
                                                                                            <tr>
                                                                                                <td className="title"><a
                                                                                                    style={{
                                                                                                        textDecoration: "none",
                                                                                                        color: "#00944D"
                                                                                                    }}
                                                                                                    onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                    target="_blank">{userhist.query_search}</a>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {userhist.created_on.substring(0, 10)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                </td>
                                                                                            </tr>
                                                                                        }

                                                                                    </>
                                                                                )) : <tr>
                                                                                    <td colSpan="3"
                                                                                        className="search-message"><br/>
                                                                                        <img src={cards}/>
                                                                                        <h6 style={{
                                                                                            fontSize: "20px",
                                                                                            fontWeight: "500"
                                                                                        }}>You don’t have any searched
                                                                                            sources</h6>
                                                                                        <p>Saved sources allows you to
                                                                                            keep sources you find
                                                                                            interesting for later</p>
                                                                                        <button
                                                                                            onClick={this.handleSearchVoc}
                                                                                            className="btn btn-fav">Try
                                                                                            search
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </> : this.state.tagsmobile === "3" ? <>

                                                                                {

                                                                                    filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thisweek.toLocaleDateString()).map((userhist) => (
                                                                                        <>
                                                                                            {
                                                                                                <tr>
                                                                                                    <td className="title"><a
                                                                                                        style={{
                                                                                                            textDecoration: "none",
                                                                                                            color: "#00944D"
                                                                                                        }}
                                                                                                        onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                        target="_blank">{userhist.query_search}</a>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {userhist.created_on.substring(0, 10)}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }

                                                                                        </>
                                                                                    )) : <tr>
                                                                                        <td colSpan="3"
                                                                                            className="search-message"><br/>
                                                                                            <img src={cards}/>
                                                                                            <h6 style={{
                                                                                                fontSize: "20px",
                                                                                                fontWeight: "500"
                                                                                            }}>You don’t have any searched
                                                                                                sources</h6>
                                                                                            <p>Saved sources allows you to
                                                                                                keep sources you find
                                                                                                interesting for later</p>
                                                                                            <button
                                                                                                onClick={this.handleSearchVoc}
                                                                                                className="btn btn-fav">Try
                                                                                                search
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </>
                                                                            : this.state.tagsmobile === "4" ? <>
                                                                                    {

                                                                                        filtereditems.sort((a, b) => new Date(b.created_on) - new Date(a.created_on)).filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() > thismonth.toLocaleDateString()).map((userhist) => (

                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched sources</h6>
                                                                                                <p>Saved sources allows you
                                                                                                    to keep sources you find
                                                                                                    interesting for
                                                                                                    later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </>

                                                                                : this.state.tagsmobile === "5" ? <>
                                                                                    {

                                                                                        filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() === this.state.startDate.toLocaleDateString()).map((userhist) => (

                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched
                                                                                                    sources</h6>
                                                                                                <p>Saved sources allows
                                                                                                    you to keep sources
                                                                                                    you find interesting
                                                                                                    for later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </> : this.state.tagsmobile === "4" ? <>
                                                                                    {

                                                                                        filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).length > 0 ? filtereditems.filter(a => new Date(a.created_on).toLocaleDateString() < thismonth.toLocaleDateString()).map((userhist) => (
                                                                                            <>
                                                                                                {
                                                                                                    <tr>
                                                                                                        <td className="title">
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    textDecoration: "none",
                                                                                                                    color: "#00944D"
                                                                                                                }}
                                                                                                                onClick={() => this.handleLinkSearch(userhist.query_search)}
                                                                                                                target="_blank">{userhist.query_search}</a>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {userhist.created_on.substring(0, 10)}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {new Date(userhist.created_on).toLocaleTimeString()}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }

                                                                                            </>
                                                                                        )) : <tr>
                                                                                            <td colSpan="3"
                                                                                                className="search-message">
                                                                                                <br/>
                                                                                                <img src={cards}/>
                                                                                                <h6 style={{
                                                                                                    fontSize: "20px",
                                                                                                    fontWeight: "500"
                                                                                                }}>You don’t have any
                                                                                                    searched
                                                                                                    sources</h6>
                                                                                                <p>Saved sources allows
                                                                                                    you to keep sources
                                                                                                    you find interesting
                                                                                                    for later</p>
                                                                                                <button
                                                                                                    onClick={this.handleSearchVoc}
                                                                                                    className="btn btn-fav">Try
                                                                                                    search
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                </> : <></>

                                                                    }

                                                                </>

                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    }
                                                </div> : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </MediaQuery>
                          <Footer/>
            </>
        )
    }
}

export default Trends