import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import "./pricing.css"
import group1 from "../../../assets/Group 895701.png"
import group2 from "../../../assets/Group 89571.png"
import {Link} from "react-router-dom";

function Pricing() {
  
 

  

  return (
    <>
      <Header type={"loggedin"} />
      <div style={{marginTop:"8%", width:"100%"}}>
        <center>
          <h1 style={{fontSize:"40px", fontFamily: "Kumbh Sans"}}>Explore our All-In-One Resource <br/>
            Hub for UX Professionals</h1>

        {/* <div style={{marginTop: "10%", marginLeft: "5%", }}>
          <Link className="nav-link" to="/">
            <img src={AboutImage} style={{width:"80%"}}/>
          </Link>
        </div> */}

        {/* <div style={{marginBottom: "10%" }}>
          {/* <Link className="nav-link" to="/">
            <img src={PricingImage} style={{width:"40%"}}/>
          </Link> 
        </div> */}

          <div className={"container productDescriptions"}>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                  <Link  to="/join-rb" >
                                    <img src={group1} style={{width:"100%"}}/>
                                  </Link>
                                </div>
                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div>
                                        <p>
                                        Our intimate workshops, exclusively curated for discerning individuals, offer invaluable insights, practical techniques and hands-on exercises led by industry-leading experts.
                                        </p>
                                        <Link  to="/join-rb" className={"btn btn-plans"}>
                                          Get Priority Invites to weekly mini-skillshops
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className={"row row2"}>

                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div>
                                        <p>
                                        With our collection of curated sample case studies, explore real-world scenarios, uncover innovative methodologies, and witness the power of data-driven insights.
                                        </p>
                                        <Link  to="/join-rb" className={"btn btn-plans"} >
                                          Gain Exclusive Access to Sample UX Case Studies
                                        </Link>
                                    </div>
                                </div>
                                <div className={"col-md-1"}></div>
                                <div className={"col-md-6"}>
                                  <Link  to="/join-rb" >
                                    <img src={group2} style={{width:"100%"}} className={"group2-img"}/>
                                  </Link>
                                </div>
                            </div>
        </div>

        <div style={{marginBottom: "10%" }}>
                            <div className={"row new-user-plans d-flex justify-content-center"}>
                                <div className={"col-md-4"}>
                                    <div className={"container-fluid light-card"} style={{marginBottom: "30px"}}>
                                        <h6>$12 <span>/month</span></h6>
                                        <Link  to="/join-rb">
                                          <button className={"btn"} >Start Free Trial</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"col-md-4"}>
                                    <div className={"container dark-card"}>
                                        <div className={"container-fluid p-0 d-flex justify-content-end"}>
                                            <div>
                                                <p>MOST POPULAR</p>
                                                {/* <center>
                                                <button className={"btn popular-plan"} style={{backgroundColor: "#E3DDFF", fontSize: "12px", position:"relative",}}>48% Discount (5 months free)</button>
                                                </center> */}
                                               
                                            </div>
                                        </div>
                                        <button className={"btn popular-plan"} style={{backgroundColor: "#E3DDFF", fontSize: "12px", width: "80%"}}>50% Discount (6 months free)</button>
                                        <h6>$70 /Year</h6>
                                        <h6 style={{fontSize: "14px"}}>($6 per month)</h6>
                                        <Link  to="/join-rb">
                                          <button className={"btn"} >Start Free Trial</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            
                            <h6 style={{color: "#333333"}}>7-day Free Trial. No credit card information required!</h6>
                        </div>
                        

        </center>

       

      </div>

      <Footer/>
     
      
      
    </>
  )
}
export default Pricing;