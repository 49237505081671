import React, { useState, useEffect } from 'react';
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";
import { Link } from "react-router-dom";
import Header from "../Header";

function ListAllOpportunities() {
  const [listOfOpportunities, setlistOfOpportunities] = useState([]);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const { isLoading, error, isError, sendRequest: fetchOpportunities } = useHttpFetch();


  if (!userToken) {
    window.location.assign('/');
  }

  useEffect(() => {
    const setOpportunities = (data) => {
      setlistOfOpportunities(data.reverse());
    }

    fetchOpportunities(
      {
        url: buildAPIUrl('v1/volunteering_opportunities/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setOpportunities
    );
  }, [fetchOpportunities, userToken]);

  const setRefreshedOpportunities = (data) => {
    setlistOfOpportunities(data.reverse());
  }

  async function refreshOpportunities (data) {
    fetchOpportunities(
      {
        url: buildAPIUrl('v1/volunteering_opportunities/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setRefreshedOpportunities
    );
  }
  async function deleteOpportunityHandler(opportunity) {
    fetchOpportunities(
      {
        url: buildAPIUrl(`v1/volunteering_opportunities/${opportunity.id}/`),
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${userToken}`
        }
      },
      refreshOpportunities
    );

  }
  async function confirmDeleteOpportunityHandler(opportunity) {

    await deleteOpportunityHandler(opportunity);
    // let confirmDelete = confirm(`Are you sure you want to DELETE ${community.name}?`);
    // if (confirmDelete != null) {
    //   await deleteCommunityHandler(community);
    // }

  }

  let content = <p></p>;

  if (listOfOpportunities.length > 0) {
    content = listOfOpportunities.map((opportunity) => (

      <tr key={opportunity.id}>
        <td>{opportunity.volunteer_institution}</td>
        <td>{opportunity.url}</td>

        <td>
          <Link className={"btn btn-primary w-100"} to={`/volunteer_opportunity/edit/${opportunity.id}`}>
            Edit
          </Link>

          <button className={"btn btn-danger w-100 mt-2"} onClick={() => confirmDeleteOpportunityHandler(opportunity)}>Delete</button>
        </td>
      </tr>

    ));
  }
  else {
    content = <p>Found no Volunteer Opportunities.</p>;
  }

  return (
    <>
      <Header />
      <div className={"container-fluid mt-5"}>
        <div className={"container-fluid"} style={{ padding: "0 5rem" }}>
          <div className={"card border"}>
            <div className={"card-header"}>
              <div className={"row"}>
                <div className={"col-md-10"}>
                  <h4>List Of All Volunteer Opportunities</h4>
                </div>
                <div className={"col-md-2"}>
                  <Link to="/volunteer_opportunity/create">Create Volunteer Opportunity </Link>
                </div>
              </div>

            </div>
            <div className={"card-body"}>
              {isLoading && <p>Loading.....</p>}
              {isError && <p>{error}</p>}
              <table className={"table table-striped table-hover"}>
                <thead>
                  <tr>
                    <th>Volunteer Institution</th>
                    <th>Url</th>
                  </tr>
                </thead>
                <tbody>
                  {content}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export default ListAllOpportunities;