import Header from "../../Landing/Header";
import poster from "../../../../assets/Frame 90060.png";
import Calendar from "../../../../assets/Calendar.png";
import {MdAccessTime} from "react-icons/md";
import {Dropdown} from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import pdf from "../../../../assets/Frame 90035.png";
import jpg from "../../../../assets/Frame 900351.png";
import png from "../../../../assets/Frame 90036.png";
import "./januarybadge.css"
import React from "react";

export default function JanuaryBadge(){
   const text = document.getElementById('arc-text')
    return (
        <>
            <Header />
            <div className={"container"} style={{marginTop:"7rem", marginBottom:"2rem"}}>
                <div className={"row"}>
                    <div className={"col-md-8"}>
                        <div className={"container "} >
                            <div className={"badge-container"}>

                               <h6 id={'arc-text'}>GERALD IHIGA MAINA</h6>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-4"} style={{paddingLeft:"3.125rem"}}>
                        <h6 style={{fontSize:"18px", fontWeight:"500"}}>
                            Certificate awarded to:
                        </h6>
                        <div className={"container-fluid p-0 d-flex align-items-center"} style={{marginTop:"1rem"}}>
                            <div className={"avatar-holder"} style={{display:"inline-block", height:"3.875rem", width:"3.875rem", background:"#AB9AFF"}}></div>
                            <h6 style={{display:"inline-block", marginLeft:"0.688rem"}}></h6>
                        </div>
                        <h6 style={{marginTop:"1.313rem"}}>
                            For this course:
                        </h6>
                        <img src={poster}/>
                        <h6 style={{marginTop:"1rem", fontSize:"18px", fontWeight:"500"}}>UX Research Skillshop</h6>
                        <div className={"row "} style={{marginTop:"0.563rem"}}>
                            <div className={"col-md-6 d-flex align-items-center "}>
                                <div>
                                    <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>December 9 & 10</span>
                                </div>

                            </div>
                            <div className={"col-md-6"}>
                                <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>10AM-2PM (EST)</span>
                            </div>
                        </div>
                        <div className={"container-fluid p-0 workshop"} style={{marginTop:"2rem"}}>
                            <Dropdown>
                                <DropdownToggle style={{background:"white", width:"100%", border:"none", textAlign:"left"}}>
                                    <div className={"btn"} style={{background:"#070707", color:"white", borderRadius:"200px"}} >Download</div>
                                </DropdownToggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <button  className={"btn"} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                            <img  src={pdf}/>
                                        </button>

                                    </Dropdown.Item>
                                    <Dropdown.Item >
                                        <button className={"btn"} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                            <img src={jpg}/>
                                        </button>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <button className={"btn"}  style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                            <img  src={png}/>
                                        </button>

                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}