import React, { useState,useCallback,  useEffect} from 'react';
import {useParams} from "react-router-dom";
import Header from "../Header";
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";


function EditMethod() {
    const params = useParams();

    const [newMethodDetails, setnewMethodDetails] = useState({
        research_method: "",
        definition: "",
        source: ""
    });

    const [userToken, setUserToken] = useState(localStorage.getItem('token'))
    const { isLoading, error, isError, sendRequest: methodRequestHandler } = useHttpFetch();
    


    if (!userToken) {
      
        window.location.assign('/');
    }
    useEffect(() => {
        const setOpportunities = (data) => {
          setnewMethodDetails(data);
        }
    
        methodRequestHandler(
          {
            url: buildAPIUrl(`v1/ux_research_methods/${params.method_id}/`),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`
            }
          },
          setOpportunities
        );
      }, [methodRequestHandler, userToken, params.method_id]);

    function onChangeHandler(event) {
        setnewMethodDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }

    function updateMethod(data){
        setnewMethodDetails({
            research_method: "",
            definition: "",
            source: ""
        })
        window.location.assign('/research_methods/all');
    }
    async function submitHandler(event) {
        event.preventDefault();
        const new_opportunity = {
            research_method: newMethodDetails.research_method,
            definition: newMethodDetails.definition,
            source: newMethodDetails.source,
        }
        methodRequestHandler({
            method: 'PATCH',
            url: buildAPIUrl(`v1/ux_research_methods/${params.method_id}/`),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`,
                
            },
            body: new_opportunity,
        }, updateMethod);

    }


    return (
        <>
           <Header />
            <div className={"container-fluid mt-5"}>
                <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            {isLoading && <p>Loading.....</p>}
                            {isError && <p>{error}</p>}
                            <div className={"card border"}>
                                <div className={"card-header"}>
                                    <h4>Edit Research Method</h4>
                                </div>
                                <div className={"card-body"}>
                                    <form onSubmit={submitHandler}>
                                        <div className={"mb-3"}>
                                            <label htmlFor='research_method'>Method</label>
                                            <input
                                                type='text'
                                                id='research_method'
                                                name='research_method'
                                                value={newMethodDetails.research_method}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />
                                        </div>
                                        
                                        <div className={"mb-3"}>
                                            <label htmlFor='definition'>Definition</label>
                                            <textarea
                                                className={"form-control"}
                                                rows="4"
                                                cols="50"
                                                id='definition'
                                                name='definition'
                                                value={newMethodDetails.definition}
                                                onChange={onChangeHandler}
                                                required
                                            />

                                        </div>

                                        <div className={"mb-3"}>
                                            <label htmlFor='source'>Source</label>
                                            <input
                                                className={"form-control"}
                                                type='url'
                                                id='source'
                                                name='source'
                                                value={newMethodDetails.source}
                                                onChange={onChangeHandler}
                                                required
                                            />
                                        </div>
                                        
                                        <button className={"btn btn-success"}>Update Research Method</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default EditMethod;