import React, { useState, useEffect } from 'react';
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";
import { Link } from "react-router-dom";
import Header from "../Header";

function ListAllResearchMethods() {
  const [listOfMethods, setlistOfMethods] = useState([]);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const { isLoading, error, isError, sendRequest: fetchOpportunitiesMethods } = useHttpFetch();


  if (!userToken) {
    window.location.assign('/');
  }

  useEffect(() => {
    const setOpportunities = (data) => {
      setlistOfMethods(data);
    }

    fetchOpportunitiesMethods(
      {
        url: buildAPIUrl('v1/ux_research_methods/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setOpportunities
    );
  }, [fetchOpportunitiesMethods, userToken]);

  const setRefreshedMethods = (data) => {
    setlistOfMethods(data);
  }

  async function refreshOpportunities (data) {
    fetchOpportunitiesMethods(
      {
        url: buildAPIUrl('v1/ux_research_methods/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setRefreshedMethods
    );
  }
  async function deleteOpportunityHandler(method) {
    fetchOpportunitiesMethods(
      {
        url: buildAPIUrl(`v1/ux_research_methods/${method.id}/`),
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${userToken}`
        }
      },
      refreshOpportunities
    );

  }
  async function confirmDeleteOpportunityHandler(method) {

    await deleteOpportunityHandler(method);
    // let confirmDelete = confirm(`Are you sure you want to DELETE ${community.name}?`);
    // if (confirmDelete != null) {
    //   await deleteCommunityHandler(community);
    // }

  }

  let content = <p></p>;

  if (listOfMethods.length > 0) {
    content = listOfMethods.map((method) => (

      <tr key={method.id}>

        <td>{method.research_method}</td>
        <td>{method.definition}</td>
        <td>{method.source}</td>

        <td>
          <Link className={"btn btn-primary w-100"} to={`/research_methods/edit/${method.id}`}>
            Edit
          </Link>

          <button className={"btn btn-danger w-100 mt-2"} onClick={() => confirmDeleteOpportunityHandler(method)}>Delete</button>
        </td>
      </tr>

    ));
  }
  else {
    content = <p>Found no Research Methods.</p>;
  }

  return (
    <>
      <Header />
      <div className={"container-fluid mt-5"}>
        <div className={"container-fluid"} style={{ padding: "0 5rem" }}>
          <div className={"card border"}>
            <div className={"card-header"}>
              <div className={"row"}>
                <div className={"col-md-10"}>
                  <h4>List Of All Research Methods</h4>
                </div>
                <div className={"col-md-2"}>
                  <Link to="/research_methods/create">Create Research Method</Link>
                </div>
              </div>

            </div>
            <div className={"card-body"}>
              {isLoading && <p>Loading.....</p>}
              {isError && <p>{error}</p>}
              <table className={"table table-striped table-hover"}>
                <thead>
                  <tr>
                    <th>Methods</th>
                    <th>Definition</th>
                    <th>Source</th>
                  </tr>
                </thead>
                <tbody>
                  {content}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export default ListAllResearchMethods;