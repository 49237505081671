// AIzaSyB2tLFUZBbZsRszoFcC_REW5amHasvr0gY
//fbc8dfefa95ec942d
import axios from "axios";
import React, {useEffect, useState} from "react";
import Header from "../Header";
import {Accordion, Modal} from "react-bootstrap";
import {MdBookmark, MdClose, MdKeyboardArrowDown} from "react-icons/md";
import LoginModal from "../../User/Components/Modals/LoginModal";
import searchicon from "../../assets/Frame 39.png";
import AlertComponent from "../../User/Components/ResultsCategories/AlertComponent";
import notfound from "../../assets/notfound.png";
import SkeletonSearchResult from "../../User/Components/Landing/Skeletons/SkeletonSearchResult";
import ContentEditable from "react-contenteditable";
import Skeleton from "react-loading-skeleton";
import {FaBookmark, FaRegCopy} from "react-icons/fa";
import Pagination from "react-bootstrap/Pagination";
import {copyLink} from "../../User/Components/ResultsCategories/CategoriesFunctions";
import buildAPIUrl from "../../Services/UrlBuilder";
import {Link} from "react-router-dom";

export default function Google() {
    const [regshow, setRegshow] = useState(false)
    const [searchTerm, setSearchTerm]=useState(localStorage.getItem('searchquery'))
    const [notfound, setNotFound]=useState(false)
    const[loading, setLoading]=useState(true)
    const [resultUrl, setResultUrl]=useState("")
    const [resultName, setResultName]=useState("")
    const [resultSnippet, setResultSnippet]=useState("")
    const [searchResults, setSearchResults]=useState([])
    const [copyStatus, setCopyStatus]=useState(false)
    const [saveSuccess, setSaveSuccess]=useState(false)
    const [filterItems, setFilterItems]=useState([])
    const [subgroups, setSubgroups]=useState([])
    const [errorMessage, setErrorMessage]=useState(false)
    const [collections, setCollections]=useState([])
    const [start, setStart]=useState(0)
    const[added, setAdded]=useState(false)
    const [collectionid, setCollectionid]=useState(null)
    const reghandleClose = () => {
      setRegshow(false)
    }
   const getSearchResultsG = async() =>{
       const items = await axios.get(buildAPIUrl('v1/collections/fetch_article/'), {
           headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${localStorage.getItem('token')}`
           }
       })
       console.log(items)
       setSearchResults(items.data)
       setLoading(false)
    }
    const searchGoogleform = async (e)=>{
        e.preventDefault()
        await axios.post(buildAPIUrl('v1/collections/fetch_article/'), {
            title:searchTerm
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        getSearchResultsG()
    }

    const searchGoogle = async ()=>{
         await axios.post(buildAPIUrl('v1/collections/fetch_article/'), {
            title:searchTerm
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(()=>{
             getSearchResultsG()
         })

    }

    const handleInput = (e) =>{
        localStorage.setItem('searchquery', e.target.value)
        setSearchTerm(e.target.value)
    }
    const handleChange = () =>{

    }
    const handleCheck = async (e) => {
        const isChecked = e.target.checked
        if (isChecked) {
            await setFilterItems([...filterItems, e.target.value])
        } else {
            const index = filterItems.indexOf(e.target.value);
            filterItems.splice(index, 1);
            await setFilterItems(filterItems)
        }
    }
   const  reghandleOpen = (name, url, snippet) => {
        setRegshow(true)
       setResultName(name)
       setResultUrl(url)
       setResultSnippet(snippet)
    }
    const handleCopyLink = (result)=>{
        copyLink(result, (copystatus)=>{
          setCopyStatus(copystatus)
            setTimeout(()=>{
               setCopyStatus(copystatus)
            }, 3000)
        })

    }
    //Save collection when user clicks on the checkbox
   const handleSaveCollection = async (sub_category,group) => {
        axios.post(buildAPIUrl('v1/collections/main/'), {
            title: resultName,
            link: resultUrl,
            group: localStorage.getItem('groupid'),
            sub_group: filterItems,
            snippet:resultSnippet,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
           setSaveSuccess(true)
        }).catch((error)=>{
            setErrorMessage(true)
            setTimeout(()=>{
                setErrorMessage(false)
            }, 5000)
        })
    }
    const  handleGetSubGroups = (id) => {

        axios.get(buildAPIUrl(`v1/collections/sub_group/?group=${id}`)).then((res)=>{
            setSubgroups(res.data)
        }).catch((error)=>{

        })
    }
    const handleCheckCollection = async (sub_category,group) => {
        setCollectionid(group)
        localStorage.setItem('groupid',group )
    }
    const handlePagination = async (id) => {
     await setStart(id)
       await searchGoogle()
       await window.scrollTo(0,0)
    }
    const getCollections = () =>{
        //Get collections
        axios.get(buildAPIUrl('v1/collections/group/')).then((res)=>{
          setCollections(res.data)
        }).catch((error)=>{

        })

    }
    const addToCollection = async (id)=>{
        await axios.patch(buildAPIUrl(`v1/collections/fetch_article/${id}/`), {
            is_approved:true
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })

        setAdded(true)
        setTimeout(()=>{
            setAdded(false)
        }, 3000)
        searchGoogle()
    }

    useEffect(()=>{
        getCollections()
        searchGoogle()

    }, [])
    return (
        <>
            <Header />
            <Modal size="md" centered show={regshow}  onHide={reghandleClose} style={{borderRadius:"10px", boxShadow:"0px 4px 37px rgba(0, 0, 0, 0.15)"}}>
                <Modal.Body>
                    <div className="row border-bottom" style={{padding:"1.25rem 2rem ", color:"#767676"}}>
                        <div className={"col-md-10"}>
                            <MdBookmark />  Save to collection

                        </div>
                        <div className={"col-md-2 d-flex justify-content-end px-3"}>
                            <MdClose onClick={reghandleClose} style={{fontSize:"18px", cursor:"pointer"}} />
                        </div>
                    </div>
                    {
                       errorMessage ? <>
                            <div className={"container alert alert-danger text-center p-0 mt-2"}>
                                <h6 style={{fontSize:"14px", fontWeight:"400"}}>
                                    Article already exists
                                </h6>
                            </div>
                        </> : <>

                        </>
                    }
                    <div className={"container-fluid "} style={{padding:"1.25rem 2rem ", color:"#767676"}}>
                        <Accordion>
                            {
                                collections.length > 0 ? <>
                                        {
                                            collections.map((collection,key)=>(
                                                <>
                                                    <Accordion.Item eventKey={key}>
                                                        <Accordion.Header >
                                                            <div className={"row"}>
                                                                <div className={"col-md-6"} onClick={()=>handleGetSubGroups(collection.id)}>
                                                                    <h6 style={{color:"#333333"}}>{collection.name}<MdKeyboardArrowDown /></h6>
                                                                </div>
                                                                <div className={"col-md-6 d-flex justify-content-end"}>
                                                                    {
                                                                        saveSuccess ? <>
                                                                                  <span className={collectionid === collection.id ? "" : "d-none"} style={{background:" #522FFF", color:"white", borderRadius:"200px", padding:"4px 11px"}}>
                                                                            Added
                                                                        </span>
                                                                        </> : <>

                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <ul>
                                                                {
                                                                    subgroups.length > 0 ? <>
                                                                        {
                                                                            subgroups.map((sub)=>(
                                                                                <li style={{marginTop:"0.688rem"}}>
                                                                                    <div className="form-check">
                                                                                        <div className={"row"}>

                                                                                            <div className={"col-md-10 "}>
                                                                                                <label  className="form-check-label collec-check-label" htmlFor={sub.id}>
                                                                                                    {sub.name}
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className={"col-md-2 d-flex justify-content-end"}>
                                                                                                <input  onChange={handleCheck} onClick={()=>handleCheckCollection(sub.id, collection.id)} className="form-check-input collec-check" type="checkbox" value={sub.id}
                                                                                                        id={sub.id}   />
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </> : <></>
                                                                }


                                                            </ul>


                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </>
                                            ))
                                        }
                                    </> :
                                    <></>
                            }


                        </Accordion>
                    </div>
                    <div className={"container d-flex justify-content-end"}>
                        <button onClick={()=>handleSaveCollection()} className={filterItems.length > 0 ? "btn btn-primary" : "d-none"}>
                            Save
                        </button>
                    </div>
                </Modal.Body>

            </Modal>
            <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop:"1rem", background:"#F9F8FF"}}>
                <div className="col-md-1">
                    <div
                        className={added ? "alert alert-success position-fixed " : "d-none"}>
                        <FaBookmark/> Article added successfully
                    </div>
                </div>
                <div className="col-md-10 col-12">
                    <div className="container-fluid">
                        <div className="row">
                            <div className=" col-12 col-md-11">
                                <div className="search-section col-12 col-md-9">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center position-relative p-0">
                                            <form onSubmit={searchGoogleform} style={{width:"100%"}} autoComplete="off">

                                                <div className="form-input border bg-white" style={{ borderRadius:"100px", paddingLeft:"40px", paddingRight:"1.5rem", boxShadow:"rgba(0, 0, 0, 0.07) 0px 3px 4px"}}>
                                                    <span className="input-group-addon d-flex align-items-center">  <img src={searchicon}/></span>
                                                    <input className={"search-field-text1"} type="text" name="q" onChange={handleInput} value={searchTerm}
                                                           style={ {borderRadius:"6px", width:"100%"}} placeholder="Search for anything UX research eg use cases, medium articles, papers, videos"/>

                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="row mt-5">
                        <div className=" col-12 p-0 col-md-9" style={{overflow: "hidden"}}>
                          <div className={"container"}>
                              <div className={"row"}>
                                  <div className={"col-md-6"}>
                                      <p>Google</p>
                                  </div>
                                  <div className={"col-md-6 d-flex justify-content-end"}>
                                      <Link to={"/admin-collections"} className={"btn btn-primary"}>Switch to Bing</Link>
                                  </div>
                              </div>
                          </div>
                            <div className="container mt-2">
                                <div className={"col-md-3"}>
                                    <AlertComponent copystatus={copyStatus}  type={"General"}/>
                                </div>
                                {
                                    notfound ? <>


                                            <div className="container d-flex justify-content-center">
                                                <div>
                                                    <div className="container d-flex justify-content-center">
                                                        <img src={notfound}/>
                                                    </div>

                                                    <div className="search-message text-center">
                                                        <h6>No results found for this keyword</h6>
                                                        <p>We couldn’t find results for this keyword, try another term</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </> :
                                        <>

                                            {
                                                loading ? <>
                                                        {
                                                            [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                key={n}/>)
                                                        }

                                                    </> :
                                                    <div>

                                                        {
                                                            searchResults.filter((result)=>{return result.is_approved ===false}).sort((a, b)=>b.id - a.id).map((result) => (
                                                                <>

                                                                    <div className={"bg-white"} style={{boxShadow:"0px 1px 3px rgba(0, 0, 0, 0.25)", padding:"1.563rem", marginTop:"0.813rem"}}>
                                                                        <div className={"row bg-white"}>
                                                                            <div className={"col-md-9 bg-white"}>
                                                                                <a href={result.link} target="_blank"><h6
                                                                                    className="result-url mt-2 mb-2">{result.link.substr(0, 100)}</h6>
                                                                                </a>
                                                                            </div>

                                                                        </div>

                                                                        <a onClick={()=>this.saveEvent()} href={result.url} target="_blank"><h6
                                                                            className="result-name mt-2 mb-2">
                                                                            <ContentEditable
                                                                                innerRef={result.title}
                                                                                html={result.title.substr(0, 150).toLowerCase().replace(searchTerm.toLowerCase(), "<b style='font-weight: 900'>" + searchTerm.toLowerCase() + "</b>")} // innerHTML of the editable div
                                                                                disabled={true}       // use true to disable editing
                                                                                onChange={handleChange} // handle innerHTML change
                                                                                tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                            />
                                                                        </h6>
                                                                        </a>
                                                                        <p className="result-snippet">
                                                                            {result.html_content ?  result.html_content.substr(0, 100) : result.html_content}
                                                                        </p>
                                                                        <div className={"container-fluid p-0"} style={{marginTop:"0.813rem"}}>

                                                                            <span onClick={()=>addToCollection(result.id)} style={{cursor:"pointer",backgroundColor:"#EBEBEB", borderRadius:"200px", color:"#333333", padding:"0.375rem 0.75rem", fontSize:"13px", fontWeight:"400"}}>Add to collection +</span>
                                                                            <span className="" style={{color:"#767676", fontSize:"13px", marginLeft:"0.75rem", display:"inline-block", cursor:"pointer"}} onMouseDown={() => {
                                                                                handleCopyLink(result.link)
                                                                            }}> Copy link <FaRegCopy className="save-icon"/></span>


                                                                        </div>

                                                                    </div>
                                                                </>
                                                            ))
                                                        }


                                                    </div>
                                            }
                                        </>
                                }
                                <Pagination>
                                    <Pagination.Item onClick={()=>handlePagination(0)}>{1}</Pagination.Item>
                                    <Pagination.Item onClick={()=>handlePagination(11)}>{2}</Pagination.Item>
                                    <Pagination.Item onClick={()=>handlePagination(21)}>{3}</Pagination.Item>
                                    <Pagination.Item onClick={()=>handlePagination(31)}>{4}</Pagination.Item>
                                    <Pagination.Item onClick={()=>handlePagination(41)}>{5}</Pagination.Item>
                                </Pagination>


                            </div>

                        </div>
                        <div className={"col-md-1"}></div>
                        <div className={"col-md-4 "} style={{marginTop:"3.125rem"}}>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}