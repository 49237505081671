import React, {Component} from "react";
import {Modal, Nav} from "react-bootstrap";
import {FaTimes, FaBars, FaSignOutAlt, FaUnlockAlt} from "react-icons/fa"
import {BiHomeAlt, BiCog, BiGridAlt} from "react-icons/bi"
import Footer from "./Footer";
import {GoogleLogout} from "react-google-login";
import {Link} from "react-router-dom";
import axios from "axios";
import {
    MdBrush,
    MdCalendarToday,
    MdDateRange,
    MdGroup,
    MdHome, MdLocalLibrary,
    MdOutlineLock,
    MdOutlineSystemUpdate, MdSearch
} from "react-icons/md";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Logo from "../../../assets/research-bookmark-logotype-one-color-rgb.svg";
import logoutimg from "../../../assets/logout.png";
import feedback from "../../../assets/feedback.png";
import logout from "../../../assets/Frame 44.png";
import usericon from "../../../assets/Group 89747.png";
class Sidebar extends Component {
    state = {
        active: false,
        image: localStorage.getItem('image'),
        clientID: "425793011868-1s6v97ajh0m70dhcvb7oejp3qup4qb5e.apps.googleusercontent.com",
        username: localStorage.getItem('username'),
       token: localStorage.getItem('token'),
        profile_picture: "",
        googleprof: localStorage.getItem('image'),
        activepage:localStorage.getItem('activepage'),
        show: false,
        feedback:false,
        currentpage:window.location.pathname,
        menus: []
    }


    closeSidebar = ()=>{
        this.setState({
            active:true
        })
    }
    openSidebar = () => {
        this.setState({
            active:false
        })
    }
    handleOpen = ()=>{
       /* if (this.props.status) {
            this.setState({
                active: false
            })
        } else {
            this.setState({
                active: true
            })
        }*/
        this.setState({
            active: true
        })

    }
    handleClose = () => {
        this.setState({
            active: false
        })
    }
    handleActive = async (url) => {
        if(url==="0"){
            await localStorage.setItem('activepage', url)
            window.location.assign('/')
        } else if (url==="1"){
            await localStorage.setItem('activepage', url)
            window.location.assign('/my-library')
        }
        else if (url==="2"){
            await localStorage.setItem('activepage', url)
            window.location.assign('/search-iq')
        }
        else if (url==="3"){
            await localStorage.setItem('activepage', url)
            window.location.assign('/research-tools')
        }
        else if (url==="4"){
            await localStorage.setItem('activepage', url)
            window.location.assign('/uxr-communities')
        }
        else if (url==="5"){
            await localStorage.setItem('activepage', url)
            window.location.assign('/uxr-conferences')
        }
        else if (url==="6"){
            await localStorage.setItem('activepage', url)
            window.location.assign('/research-vocabulary')
        }
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleFeedback = async (e) =>{
        e.preventDefault()
        const response  = await axios.post(buildAPIUrl('v1/feed_backs/'), {
            feed_back: this.state.feedbackfield,
        }, {headers: {Authorization: `Bearer ${this.state.token}`}}).then((res) => {
            this.setState({
                feedbacksuccess: true
            })
            setTimeout(()=>{
                this.onLogout()
            }, 3000)
        })
    }
    handleModalOpen = (result)=>{
        this.setState({
            show: true,
            siteid:result
        })
    }
    handleFeedbackModal=()=>{
        this.setState({
            feedback:true,
            show:false
        })
    }
    onLogout = (res) => {
        localStorage.removeItem('email')
        localStorage.removeItem('image')
        localStorage.removeItem('latitude')
        localStorage.removeItem('longitude')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        window.location.href='/'
    }
    handleModalClose = ()=>{
        this.setState({
            show: false
        })
    }
    componentDidMount() {
        //Get the menus for the dropdown navigation menu
        axios.get(buildAPIUrl('v1/menus/')).then((res) => {
            this.setState({
                menus: res.data
            })
        }).catch((error) => {

        })
    }

    render() {
        return (
            <>
                <Modal centered show={this.state.show}  onHide={this.handleModalClose}>
                    <Modal.Body >
                        <div className="row logout-modal" style={{borderRadius:"8px"}}>
                            <img src={Logo} style={{width:"100px", height:"50px"}}/>
                            <div className="container text-center d-flex justify-content-center align-items-center">
                                <div>
                                    <img className="logout-icon" style={{height:"80px", width:"80px"}} src={logoutimg}/>
                                    <h6>
                                        On no! You’re leaving.....
                                    </h6>
                                    <h6>
                                        Are you sure?
                                    </h6>
                                    <button className="btn kidding" onClick={this.handleModalClose}>Naah, just kidding</button>
                                    <button className="btn yeslog" onClick={this.handleFeedbackModal}>Yes, Log me out</button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                <Modal centered show={this.state.feedback}  onHide={this.handleFeedbackClose}>
                    <Modal.Body >
                        <div className="row logout-modal mobile-modal" style={{borderRadius:"8px"}}>
                            <div className="container d-flex justify-content-center">
                                <div className={this.state.feedbacksuccess? "alert alert-success":  "d-none"} >
                                    Feedback sent successfully. Logging you out...
                                </div>
                            </div>

                            <img src={Logo} style={{width:"100px", height:"50px"}}/>
                            <div className="container text-center d-flex justify-content-center align-items-center">
                                <div className="container-fluid">
                                    <img className="logout-icon" style={{height:"80px", width:"80px"}} src={feedback}/>
                                    <h6>
                                        We would love your feedback
                                    </h6>
                                    <form  >
                                        <div className="mb-2 d-flex justify-content-center">
                                               <textarea className="form-control feedback-textarea" name="feedbackfield" required onChange={this.handleInput} value={this.state.feedbackfield}>

                                               </textarea>
                                        </div>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-6 col-6">  <button className="btn cancel-feedback" onClick={this.onLogout}>Cancel</button></div>
                                                <div className="col-md-6 col-6"><button className="btn send-feedback" onClick={this.handleFeedback}>Send Feedback</button></div>
                                            </div>


                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                <div className={this.props.status ? "sidebar col-md-5 col-8" : "inactiveside pt-5"} style={{zIndex:"100000"}}>
                    {
                        this.props.status ?  <div className="close-icon" onClick={this.props.closeSideBar}>
                            <FaTimes />
                        </div> :  <div className="close-icon" onClick={this.props.closeSideBar}>
                            <FaBars />
                        </div>
                    }

                    <div className="menu-items">

                            <div className="container-fluid border-bottom d-flex justify-content-center" style={{paddingTop:"0.75rem", paddingBottom:"0.75rem"}}>
                                <div>
                                    <img src={localStorage.getItem('image')} className="user-icon" />  <span style={{marginLeft: "1rem", fontSize:"14px", fontWeight:"400"}}>{localStorage.getItem('username')}</span>
                                </div>
                            </div>
                        <div className="row" style={{paddingBottom:"1.25rem", marginTop:"1rem"}}>
                            {
                                this.state.menus.length > 0 ? <>
                                    {
                                        this.state.menus.map((menu)=>(
                                            <div className={"col-md-12"}>
                                                <div className={menu.url===window.location.pathname ?  "desk-menu-item-active d-flex align-items-center" : "desk-menu-item  d-flex align-items-center"}>
                                                    <Link to={menu.url} style={{textDecoration:"none"}}>
                                                                        <span className="d-flex align-items-center" >
                                                                           {
                                                                               menu.url===window.location.pathname ?  <img src={menu.active_icon}/> :  <img src={menu.inactive_icon}/>
                                                                           }
                                                                            <h6>{menu.name}</h6>
                                                                           </span>


                                                    </Link>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </> : <>

                                </>
                            }
                            <div className="col-md-12" >
                                <div className={" desk-menu-item"}>
                                    <div onClick={this.handleModalOpen} style={{cursor:"pointer"}}>
                                                                                    <span className="d-flex align-items-center" ><img src={logout}/>
                                                                                    <h6>Logout</h6>
                                                                                    </span>

                                    </div>
                                </div>


                            </div>
                        </div>

                      {/*  <ul>

                                <li onClick={()=>this.handleActive("0")} className={this.state.activepage===null || this.state.activepage==="0" ? "active" : ""} style={{cursor:"pointer"}}>
                                    <a >
                                    <MdHome /> <span>Home</span></a>
                                </li>

                            <li onClick={()=>this.handleActive("1")}  className={this.state.activepage==="1"? "active" : ""} style={{cursor:"pointer"}}> <a title="Saved sources">  <MdOutlineSystemUpdate /> <span>Saved sources</span> </a></li>
                            <li onClick={()=>this.handleActive("2")} className={this.state.activepage==="2"? "active" : ""} style={{cursor:"pointer"}}>  <a title="Search history">  <MdSearch /> <span>Search history</span> </a></li>
                            <li onClick={()=>this.handleActive("3")} className={this.state.activepage==="3"? "active" : ""} style={{cursor:"pointer"}}> <a title="Tools">  <MdBrush /> <span>Tools</span> </a></li>
                            <li onClick={()=>this.handleActive("4")} className={this.state.activepage==="4"? "active" : ""} style={{cursor:"pointer"}}> <a title="Communities">  <MdGroup /> <span>Communities</span> </a></li>
                            <li onClick={()=>this.handleActive("5")} className={this.state.activepage==="5"? "active" : ""} style={{cursor:"pointer"}}> <a title="Conferences">  <MdDateRange/> <span>Conferences</span> </a></li>
                            <li onClick={()=>this.handleActive("6")} className={this.state.activepage==="6"? "active" : ""} style={{cursor:"pointer"}}> <a title="Vocabulary">  <MdLocalLibrary /> <span>Vocabulary</span> </a></li>
                            <li> <a onClick={this.handleModalOpen} style={{cursor:"pointer"}}>  <MdOutlineLock /> <span>Logout</span> </a></li>
                        </ul>*/}
                    </div>

                </div>

            </>
        )
    }
}
export default Sidebar