import Header from "../Header";
import "./Main.css"
import Footer from "../Footer";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { EventsHandler } from "./MainFunctions";
import ReactPlayer from "react-player";

export default function NewUser() {
    const [showButton, setShowButton] = useState(false)
    const [success, setSuccess] = useState(false)
    const [monthlyTrial, setMonthlyTrial] = useState("")
    const [yearlyTrial, setYearlyTrial] = useState("")
    const [monthly, setMonthly] = useState("")
    const [yearly, setYearly] = useState("")
    const lightItems = ["Access to basic UX research resources", "Sourced articles","UX vocabulary", "Weekly UX newsletter", "Search engine for UX-related content" ]
    const darkItems = ["All access to UX research resources", "Full sourced articles", "Paid Mini-courses ","Quarterly webinars","Advanced search engine features", "Connecting UX professionals to real-life UX projects", "Certificate of Completion"  ]
    const handlePayment = (amount, type, pplan) => {
        ReactGA.event({
            category: type,
            action: `Click ${type}`,
            label: "RB Subscription Plan",
            value: amount,
        });
        EventsHandler(
            "RB Subscription Plan",
            window.location.pathname,
            "",
            type
        );

        const path = `${pplan}&client_reference_id=${localStorage.getItem('user_id')}`
       
        window.location.assign(path)
    }

    const checkuserpackage = async () => {
        const userpackage = await axios.get(buildAPIUrl('v1/users/me'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        localStorage.setItem('user_id', userpackage.data.id)
        // if (userpackage.data.is_trial_period_over===null) {
        //     setShowButton(true)
        // }

    }
    const getRBPlans = async () => {
        const plans = await axios.get(buildAPIUrl("v1/subscriptions/?is_rb_pro_plan=1"))
        for (let plan of plans.data) {
    
            if(plan?.plan_db_details?.is_active === true){
                if (plan?.plan_db_details?.plan_type === "Monthly" && plan?.plan_db_details?.is_trial === false) {
                
                    setMonthly(plan.plan_db_details.payment_link)
                }
                else if (plan?.plan_db_details?.plan_type === "Yearly" && plan?.plan_db_details?.is_trial === false) {
                    
                    setYearly(plan.plan_db_details.payment_link)
                }
                else if (plan?.plan_db_details?.plan_type === "Yearly" && plan?.plan_db_details?.is_trial === true) {
                   
                    setYearlyTrial(plan.plan_db_details.payment_link)
                }
                else if (plan?.plan_db_details?.plan_type === "Monthly" && plan?.plan_db_details?.is_trial === true) {
                   
                    setMonthlyTrial(plan.plan_db_details.payment_link)
                }
            }
            
        }
   
       
    }

    console.log('yearly', { yearly, monthly})
    useEffect(() => {
        getRBPlans();
        checkuserpackage();

        ReactGA.pageview(window.location.pathname);
        EventsHandler(
            "RB Subscription Plan PageView",
            window.location.pathname,
            "",
            "PageView"
        )
    }, [])

    return (
        <>
            <Header />
            <div className={"container-fluid"} style={{minHeight:"100vh", background:"#F6F6F6"}}>
                <div className={"container"}>
                    <div className={"row d-flex justify-content-center "}>
                        <div className={"new-user-header"}>

                            <center>
                                <br />
                                <h3>Fast-track Your <br />
                                    User Experience Career</h3>
                                <p>
                                    Don't worry—you won't be charged until your 7-day trial period ends.
                                </p>
                            </center>
                            <>
                                <div className={"row new-user-plans d-flex justify-content-center"}>
                                    <div className={"col-md-4"} onClick={() => handlePayment(8, "monthlyPaid", `${monthly}?prefilled_email=${localStorage.getItem('email')}`)} style={{ marginBottom: "20px" }}>
                                        <div className={"container-fluid light-card"}>
                                            <div className={"row"}>
                                                <div className={"col-md-7"}>
                                                    <h5>
                                                        RB Explore
                                                    </h5>

                                                </div>
                                                <div className={"col-md-5"}>
                                                    <div className={"plantype"}>
                                                        Starter Plan
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                The RB Explore plan provides users with free access to essential UX resources and insights, fostering a learning community and encouraging initial engagement with your platform.
                                            </p>
                                            <h6>$12 <span>/month</span></h6>
                                            <hr />
                                            <p>Includes:</p>
                                            <ul>
                                                {
                                                    lightItems.map((item, key)=>(
                                                        <>
                                                            <li key={key}><img src={"../assets/light-circle.svg"}/>{item}</li>
                                                        </>
                                                    ))
                                                }
                                            </ul>
                                            <hr />
                                           {/* <button onClick={() => handlePayment(8, "monthlyPaid", `${monthly}?prefilled_email=${localStorage.getItem('email')}`)} className={"btn"} >Choose Plan</button>*/}
                                        </div>
                                    </div>
                                    <div className={"col-md-4 "} onClick={() => handlePayment(
                                        70,
                                        "yearlyPaid",
                                        `${yearly}?prefilled_email=${localStorage.getItem('email')}`
                                    )}>
                                        <div className={"container dark-card"}>
                                            <div className={"row"}>
                                                <div className={"col-md-7"}>
                                                    <h5>
                                                        RB Connect
                                                    </h5>

                                                </div>
                                                <div className={"col-md-5"}>
                                                    <div className={"plantype-black"}>
                                                        Pro Plan
                                                    </div>
                                                </div>
                                            </div>
                                            <p>
                                                RB Connect is designed for dedicated UX professionals seeking deep, continuous engagement with advanced resources, education, and real-world application of UX skills.
                                            </p>
                                            <h6>$70<span> /Year</span></h6>
                                            <hr />
                                            <p>Includes: </p>
                                            <ul>
                                                {
                                                    darkItems.map((item, key)=>(
                                                        <>
                                                            <li key={key}>{item}</li>
                                                        </>
                                                    ))
                                                }
                                            </ul>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </>




                        </div>



                        {/* <center>
                        <div className={"new-user-header"} style={{marginTop:"8%", marginBottom:"5%"}}>
                            <h6>Catch a Glimpse of what you can do on Research Bookmark</h6>
                        </div>

                    </center> */}

                        {/* <div className={"container d-flex justify-content-center video-container"}  >

                        <ReactPlayer
                        playing={false}
                        loop={true}
                        style={{height:"unset"}}
                        controls={true}
                        url={"https://res.cloudinary.com/analogteams/video/upload/v1680077586/Loom___Free_Screen_Video_Recording_Software___Loom_-_27_March_2023_tmsdax.mp4"} />


                    </div> */}
























                        {/* <div className={"col-md-7 new-user-header"}>
                        <center>
                            The Benefits of Research Bookmark
                        </center>
                        <br/>
                       <h6>
                       How will Research Bookmark <br/>help you get a job?
                       </h6>

                   </div> */}
                    </div>
                    {/* <div className={"row new-user-content"}>
                    <div className={"col-md-4"}>
                        <h6>Live Trainings</h6>
                        <p>
                        Work on real projects, receive valuable resources, and enhance your skills through interactive training.
                        <br/><br/>
                            <center>
                            <span style={{textAlign: "center", color: "#EB6AB6"}}>Get certified in UX</span>
                            </center>

                        </p>
                    </div>
                    <div className={"col-md-4"}></div>
                    <div className={"col-md-4"}>
                        <h6>Learn new tools and methods</h6>
                        <p>
                        Discover, explore, and apply new skills by browsing our curated resources.

                        <br/><br/>
                            <center>
                            <span style={{textAlign: "center", color: "#EB6AB6"}}>Over 1,000,000 resources</span>
                            </center>

                        </p>
                    </div>
                    <div className={"col-md-4"}>
                        <h6>Grow your network</h6>
                        <p>Expand your network with UX conferences, communities, and events.
                        <br/><br/>
                            <center>
                            <span style={{textAlign: "center", color: "#EB6AB6"}}>
                                Led by UX Leaders from Google, Apple, Pinterest, Netflix and more!
                                </span>
                            </center>
                        </p>
                    </div>
                    <div className={"col-md-4"}></div>
                    <div className={"col-md-4"}>
                        <h6>
                            Search for jobs
                        </h6>
                        <p>
                        Discover your next career move with ease, search and find top UX jobs anywhere in the world!
                        <br/><br/>
                            <center>
                            <span style={{textAlign: "center", color: "#EB6AB6"}}>
                            Over 4000 new jobs every week
                                </span>
                            </center>

                        </p>
                    </div>
                </div> */}

                    <br /><br />
                </div>
            </div>

            <Footer />
        </>
    )
}