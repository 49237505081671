import buildAPIUrl from "../../Services/UrlBuilder";

export const fetchAllConferences = async () => {
    try {
      const response = await fetch(buildAPIUrl('v1/conferences/'));
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
      return response;
    } catch (error) {
        throw new Error(error);
    }
    
}


export const createConference = async (new_conference, userToken) => {
  try {
    
    const response = await fetch(buildAPIUrl('v1/conferences/'), {
        method: 'POST',
        body: JSON.stringify(new_conference),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`
        }
    });
    if (!response.ok) {
      throw new Error('Something went wrong!');
    }
    return response;
  } catch (error) {
      throw new Error(error);
  }
  
}


export const getConference = async (conference_id, userToken) => {
  try {
    const response = await fetch(buildAPIUrl(`v1/conferences/${conference_id}/`), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`
        }
    });
    if (!response.ok) {
      throw new Error('Something went wrong!');
    }
    return response;
  } catch (error) {
      throw new Error(error);
  }
  
}


export const editConference = async (conference_id, conferenceData, userToken) => {
  try {
    
    const response = await fetch(buildAPIUrl(`v1/conferences/${conference_id}/`), {
        method: 'PATCH',
        body: JSON.stringify(conferenceData),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`
        }
    });
    if (!response.ok) {
      throw new Error('Something went wrong!');
    }
    return response;
  } catch (error) {
      throw new Error(error);
  }
  
}


export const deleteConference = async (conference_id, userToken) => {
  try {
    const response = await fetch(buildAPIUrl(`v1/conferences/${conference_id}/`), {
      method: 'DELETE',

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`
      }
    });
  
    if (!response.ok) {
      throw new Error('Something went wrong!');
    }

  } catch (error) {
    throw new Error(error);
  }
}