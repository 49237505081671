import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import PopularBanner from "../PopularBanner";
import {MdArrowForward, MdOutlineRemoveRedEye} from "react-icons/md";
import logo from "../../../../assets/RB LOGO1.png";
import {Link} from "react-router-dom";
import padlock from "../../../../assets/Group 89910.png";
import bluearrow from "../../../../assets/Group 89940.png";
import conducting from "../../../../assets/Group 89899.png";
import analyzing from "../../../../assets/Group 898991.png";
import presenting from "../../../../assets/Group 898992.png";

class EverydayCollections extends Component{
    state={
        collections:[]
    }
    componentDidMount() {
        axios.get(buildAPIUrl('v1/collections/'))
            .then((res)=>{
                this.setState({
                    collections:res.data
                })
                window.scrollTo(0,0)
            }).catch((error)=>{
        })
    }

    render() {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-2"}>

                    </div>
                    <div className={"col-md-9"}>
                        <div className={"container resources-search-section"}>
                            <h4>
                                Explore our Collections
                            </h4>
                        </div>
                        <div className={"container"}>
                            <PopularBanner name={"Quick links"} />
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-2 position-relative"}>
                        <div className={"card border static-nav"}>
                            <h6>
                                Navigation
                            </h6>
                            <ul>
                                <li>
                                    <Link to={"/collections"}>Collections home</Link> </li>
                                <li> <Link to={"/collections#ux-foundations-collections"}>Ux Foundations Collections </Link></li>
                                <li> <Link to={"/collections#special-collections"}>Special Collections </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className={"col-md-9"}>
                        <div className={"container"}>

                            <div className={"container-fluid"} style={{background:"#FBFBFF", padding:"3.5rem 2.875rem", marginTop:"3.625rem"}} id={"everyday-collections"}>
                                <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}} >
                                    Everyday collections
                                </h6>
                                <p style={{fontSize:"14px", fontWeight:"400"}}>
                                    Everything you need to  stay current in your career
                                </p>
                                <div className="container-fluid row3 p-0">
                                    <div className="categories border-0">
                                        <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                            {
                                                this.state.collections.map((collection)=>(
                                                    <>
                                                        <div className="col-md-3" style={{marginTop:"1.625rem"}}>
                                                            <a href={collection.url} style={{textDecoration:"none"}}>
                                                                <div className={"container h-100 d-flex justify-content-center pb-3 bg-white"}>
                                                                    <div className="categories-content-main h-100 text-center">
                                                                        <div >
                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem"}}/>
                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                {collection.header}
                                                                            </h6>
                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", lineHeight:"150%" }}>{collection.description}</p>
                                                                        </div>

                                                                        <span style={{backgroundColor:"#F0EEFF", padding:"0.125rem .5rem", borderRadius:"1.438rem", color:"#000000", fontSize:"10px", height:"1.375rem"}}><span style={{fontSize:"18px"}}><MdOutlineRemoveRedEye /> </span>{collection.number_of_views} views</span>
                                                                        <div className={"container-fluid"} style={{marginTop:"1.25rem", marginBottom:".875rem"}}>
                                                                            <a style={{ color:"#00944D"}} href={collection.url}>Explore <MdArrowForward /></a>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </a>
                                                        </div>
                                                    </>

                                                ))
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EverydayCollections