import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";


/*****Constants*********/
const subscriptionkey = "a269ccab324846ea85b7c696d5f48e39";
const configID="d434f7b5-c57e-4e98-b6f0-27ddd337c829";
const searchterm = localStorage.getItem('searchquery')
const token = localStorage.getItem('token')
export function copyLink(url, copystatus){
    navigator.clipboard.writeText(url) //copy link
    copystatus(true)
    EventsHandler(`Link copied: ${url}`, document.URL)
    setTimeout(()=>{
        copystatus(false)
    }, 3000)
}

//Handle save bookmark
export function saveBookmark(result, bookmarktype, savestatus, errorcallback){
    if(bookmarktype==="Videos") {
         axios.post(buildAPIUrl(`v1/bookmarks/`), {
                title: result.name,
                link: result.contentUrl,
                snippet:result.description,
                tag:bookmarktype
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            } ).then((res)=>{
             EventsHandler(`Bookmarked a result ${result.url}`, document.URL)
            savestatus(true)
            setTimeout(()=>{
                savestatus(false)
            }, 10000)

        }).catch((error)=>{
            errorcallback(error)
            setTimeout(()=>{
                errorcallback("")
            }, 3000)
        })
    } else {
       axios.post(buildAPIUrl(`v1/bookmarks/`), {
                title: result.name,
                link: result.url,
                snippet:result.snippet,
                tag:bookmarktype
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            } ).then((res)=>{
           EventsHandler(`Bookmarked a result ${result.url}`, document.URL)
            savestatus(true)
            setTimeout(()=>{
                savestatus(false)
            }, 3000)

        }).catch((error)=>{
            errorcallback(error)
            setTimeout(()=>{
                errorcallback("")
            }, 3000)
        })
    }
}
//Save Notion Article
export function saveNotionArticle(result, bookmarktype, savestatus, errorcallback){
   axios.post(buildAPIUrl(`v1/bookmarks/`), {
            title: result.title,
            link: result.url,
            snippet:result.content,
            tag:bookmarktype
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        } ).then((res)=>{
        savestatus(true)
       EventsHandler(`Bookmarked a result ${result.url}`, document.URL)
        setTimeout(()=>{
            savestatus(false)
        }, 3000)

    }).catch((error)=>{
        errorcallback(error)
        setTimeout(()=>{
            errorcallback("")
        }, 3000)
    })
}
//Save Notion Article
export function saveCollection(result, bookmarktype, savestatus, errorcallback){
    axios.post(buildAPIUrl(`v1/bookmarks/`), {
            title: result.title,
            link: result.link,
            snippet:result.snippet,
            tag:bookmarktype
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        } ).then((res)=>{
        savestatus(true)
        EventsHandler(`Bookmarked a result ${result.url}`, document.URL)
        setTimeout(()=>{
            savestatus(false)
        }, 3000)

    }).catch((error)=>{
        errorcallback(error)
        setTimeout(()=>{
            errorcallback("")
        }, 3000)
    })
}
// Second level filter
export function secondLevelFilter(company,urlid, successcallback, errorcallback){
  axios.get(`https://api.bing.microsoft.com/v7.0/custom/search?q=${"site:" + company + localStorage.getItem('searchquery')}&customconfig=${configID}&count=50`, {
        headers:{
            "Ocp-Apim-Subscription-Key":subscriptionkey
        }
    }).then((res)=>{
      EventsHandler(`View search results for ${company}`, document.URL)
            successcallback(res.data)
    }).catch((error)=>{
        errorcallback(error)
    })
}

//Pagination on all results pages. Move to the next page
export function nextPage(paginationType,resultType, offSetValue, offSet,itemsCount,website, successCallBack, errorCallback){
    //There are two types of api requests on Bing. One with a configuration ID and one without.
    //The configuration ID applies for Images, Videos, News and All Results
    if(paginationType==="configID"){
        if (resultType==="videos"){
            axios.get(`https://api.bing.microsoft.com/v7.0/custom/videos/search?q=${searchterm}&customconfig=${configID}&count=${itemsCount}&offset=${offSetValue}`, {
                headers: {
                    "Ocp-Apim-Subscription-Key": subscriptionkey,
                    "token": token
                }
            }).then((res) => {
                successCallBack(res.data)
                window.scrollTo(0, 0)

            }).catch((error)=>{
                errorCallback(error)
            })
        }
        else if(resultType==="news"){
            axios.get(`https://api.bing.microsoft.com/v7.0/news/search?q=${searchterm}&customconfig=${configID}&count=${itemsCount}&offset=${offSetValue}`, {
                headers: {
                    "Ocp-Apim-Subscription-Key": subscriptionkey,
                    "token": token
                }
            }).then((res) => {
                window.scrollTo(0, 0)
                successCallBack(res.data)


            }).catch((error)=>{
                errorCallback(error)
            })
        }
        else {
             axios.get(`https://api.bing.microsoft.com/v7.0/custom/search?q=${searchterm}&customconfig=${configID}&count=${itemsCount}&offset=${offSetValue}`, {
                headers: {
                    "Ocp-Apim-Subscription-Key": subscriptionkey,
                    "token": token
                }
            }).then((res) => {
                window.scrollTo(0, 0)
                successCallBack(res.data)

            }).catch((error)=>{
                errorCallback(error)
            })
        }

    } else {
       axios.get(`https://api.bing.microsoft.com/v7.0/custom/search?q=${"site: " + website + searchterm}&count=${itemsCount}&offset=${offSetValue}`, {
            headers: {
                "Ocp-Apim-Subscription-Key": subscriptionkey,
            }
        }).then((res) => {
            successCallBack(res.data)
            window.scrollTo(0, 0)
        }).catch((error)=>{
            errorCallback(error)
        })
    }
}

//Display bookmark exists alert
