import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import MediaQuery from "react-responsive";
import searchicon from "../../../assets/Frame 39.png";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import cards from "../../../assets/Group 23.png";
import ResourcesNav from "./ResourcesNav";
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import nikki from "../../../assets/Nikki Anderson.jfif"
import {FaLinkedin} from "react-icons/fa";
import MetaTags from "react-meta-tags";
import LoginModal from "../Modals/LoginModal";
import NotFound from "./NotFound";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import SeoMetaData from "./SeoMetaData";
import {MdStars} from "react-icons/md";

class Volunteering extends Component{
    state = {
        items: [],
        token: localStorage.getItem('token'),
        costitems: [],
        filter:"",
        showfilter:false,
        loading:true,
        show:false
    }
     componentDidMount () {
         EventsHandler("Volunteering Section Opened",  document.URL)
        window.scrollTo(0,0)
         //Get a list of volunteering opportunities
    axios.get(buildAPIUrl(`v1/volunteering_opportunities/`)).then((res)=>{
            this.setState({
                items: res.data,
                loading: false
            })
        setTimeout(()=>{
            window.addEventListener('scroll', this.handleScroll);
        }, 1000)
        }).catch((err)=>{

        })
         //Increase views by 1
         axios.get(buildAPIUrl('v1/collections/7/')).then(()=>{

         }).catch((error)=>{

         })
    }

    //Open and close modal
    handleModalOpen = () =>{
        this.setState({
            show:true
        })
    }
    handleModalClose = () =>{
        this.setState({
            show:false
        })
        window.location.assign('/collections')
    }
//Display login modal when the user tries to scroll
    handleScroll = () => {
        if(!localStorage.getItem('token')) {
            this.handleModalOpen()
        }

    }
    handleSearch = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    saveEvent = (id)=> {
            EventsHandler("Volunteering Opportunities Link Clicked", document.URL, "none", id)
        //Increase views by 1
        axios.get(buildAPIUrl(`v1/volunteering_opportunities/${id}/`)).then(()=>{

        }).catch((error)=>{

        })
    }
    render() {

        return (
            <>
                <SeoMetaData title={"Volunteering"} />
                <Header pageType={"Free"} />
                <LoginModal openLoginModal={this.handleModalOpen}
                            closeLoginModal={this.handleModalClose}
                            show={this.state.show} type={this.state.logintype}/>
                <MediaQuery minWidth={1200} >
                    <div className="row" style={{paddingLeft: "1rem", paddingRight:"1rem", marginTop:"7rem"}}>
                        <div className="col-md-2 side-filters">

                        </div>
                        <div className="col-md-8 col-12 px-4">
                            <div className="search-section col-12 col-md-12 mt-4 ">
                                <div className="row d-flex align-items-center ">
                                    <div className=" col-12 col-md-12 d-flex justify-content-center">
                                        <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                            <div className="form-input input-group border"  style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                       style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search for conferences"/>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="saved-sources">
                                <div className="row">
                                   <ResourcesNav />
                                    <div className="col-md-12 col-6 border p-0 rounded-3" style={{marginTop:"1rem"}}>
                                        <div className={"container-fluid p-0 mt-3 border-bottom"}>
                                           <div className={"container py-3"}>
                                               <h1 style={{fontSize:"18px", marginLeft:"0"}}>Gain hands-on ux experience through Volunteer Opportunities</h1>
                                           </div>
                                        </div>

                                        <div className={"container-fluid mt-2 p-0"}>
                                            <div className={"row px-3"} style={{borderRadius:"8px 8px 0px 0px", paddingTop:"24px", paddingBottom:"24px"}}>
                                                <div className={"col-md-1 d-flex justify-content-center align-items-center"}>
                                                    <img src={nikki} style={{borderRadius:"50%", width:"2.688rem", height:"2.688rem"}}/>
                                                </div>
                                                <div className={"col-md-7 "}>
                                                    <a href={"https://www.linkedin.com/in/nikkianderson-ux/"} style={{fontSize:"16px", fontWeight:"500", color:"#070707", textDecoration:"none"}}>Nikki Anderson</a><br/>
                                                    <a href={"https://www.linkedin.com/in/nikkianderson-ux/"}  style={{fontSize:"12px", fontWeight:"400", textDecoration:"none", color:"#070707"}}>Founder, User Research Academy  </a>
                                                    <br/>
                                                </div>
                                                <div className={"col-md-4  d-flex justify-content-end"}>
                                                    <div style={{border:"1px solid #C4C4C4", fontSize:"12px", color:"#545454", padding:"2% 4%", borderRadius:"8px", height:"40px", display:"flex", alignItems:"center", justifyContent:"space-around"}}>
                                                       <MdStars className={"me-1"} /> Contributor
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.loading ? <>
                                                    {
                                                        [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                    }

                                                </> :
                                                <table className="history-table table-responsive" style={{overflow: "hidden"}}>
                                                    <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Link</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="mobmodal">
                                                    {
                                                        this.state.items.length > 0 ?
                                                            this.state.items.map((item) => (
                                                                <tr >
                                                                    <td style={{overflow: "hidden"}}>
                                                                        {item.volunteer_institution}
                                                                    </td>
                                                                    <td style={{overflow: "hidden", color:"#522FFF", textDecoration:"underline"}}>
                                                                        <a onClick={()=>this.saveEvent(item.volunteer_institution)} href={item.url} target={"_blank"} style={{color:"#522FFF"}} >{item.url}</a>
                                                                    </td>

                                                                </tr>
                                                            )) :
                                                            <tr>
                                                                <td colSpan="5" className="search-message"><img src={cards}/><br/>
                                                                    <h6>No results found for this keyword</h6>
                                                                    <p>This word is not available in conferences, try our search
                                                                        engine instead</p>
                                                                    <NotFound term={this.state.filter} />
                                                                </td>
                                                            </tr>
                                                    }
                                                    </tbody>
                                                </table>
                                        }
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className="row mobile">
                        <div className="col-md-8 col-12">
                            <div className="search-section col-12 col-md-12 mt-4 mb-2 ">
                                <div className="row d-flex align-items-center ">
                                    <div className=" col-12 col-md-12 d-flex justify-content-center">
                                        <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                            <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                       style={{width: "100%", border:"none"}} placeholder="Search for volunteer opportunities"/>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="saved-sources">

                                    <ResourcesNav />
                                    <div className="col-md-8 col-12 d-flex align-items-center">
                                        <h1 style={{fontWeight:"600"}}>Gain hands-on ux experience through Volunteer Opportunities</h1>
                                    </div>
                                {
                                    this.state.loading ? <>
                                            {
                                                [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                            }

                                        </> :
                                        <>
                                            <div className={"container-fluid mt-2 p-0"}>
                                                <div className={"row px-3"} style={{backgroundColor:"rgba(237, 30, 152, 0.03)", borderRadius:"8px 8px 0px 0px", paddingTop:"24px", paddingBottom:"24px"}}>
                                                    <div className={"col-md-1 col-3 d-flex align-items-center"}>
                                                        <img src={nikki} style={{borderRadius:"50%", width:"70px", height:"70px"}}/>
                                                    </div>
                                                    <div className={"col-md-7 col-9 d-flex align-items-center"}>
                                                        <div>
                                                            <a href={"https://www.linkedin.com/in/nikkianderson-ux/"} style={{fontSize:"16px", fontWeight:"500", color:"#070707", textDecoration:"none"}}>Nikki Anderson</a><br/>
                                                            <a href={"https://www.linkedin.com/in/nikkianderson-ux/"}  style={{fontSize:"12px", fontWeight:"400", textDecoration:"none", color:"#070707"}}>Founder, User Research Academy   <a href={"https://www.linkedin.com/in/nikkianderson-ux/"}  style={{fontSize:"12px", fontWeight:"400", textDecoration:"none", color:"#070707"}}>
                                                                <FaLinkedin style={{fontSize:"16px"}} />
                                                            </a> </a>
                                                        </div>
                                                    </div>
                                                    <div className={"col-3"}>

                                                    </div>
                                                    <div className={"col-md-4 col-9 d-flex"}>
                                                        <div style={{backgroundColor:"#070707", color:"white", padding:".8rem 1rem", borderRadius:"20px", height:"19px", display:"flex", alignItems:"center"}}>
                                                            Contributor
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="history-table mt-3 table-responsive" style={{overflow: "hidden"}}>
                                                <tbody className="mobmodal">
                                                {
                                                    this.state.items.length > 0 ?
                                                        this.state.items.map((item) => (
                                                            <tr >
                                                                <td style={{overflow: "hidden"}}>
                                                                    {item.volunteer_institution}
                                                                </td>
                                                                <td style={{overflow: "hidden", color:"#00944D", textDecoration:"underline", paddingRight:"1rem"}}>
                                                                    <a onClick={()=>this.saveEvent(item.volunteer_institution)} href={item.url} target={"_blank"} style={{color:"#00944D"}} >{item.url}</a>
                                                                </td>

                                                            </tr>
                                                        )) :
                                                        <tr>
                                                            <td colSpan="5" className="search-message"><img src={cards}/><br/>
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in conferences, try our search
                                                                    engine instead</p>
                                                                <NotFound term={this.state.filter} />
                                                            </td>
                                                        </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </>

                                }
                            </div>

                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className="row mobile" style={{paddingLeft: "1rem", paddingRight:"1rem"}}>
                        <div className="col-md-8 col-12 px-4">
                            <div className="search-section col-12 col-md-12  mb-2 ">
                                <div className="row d-flex align-items-center ">
                                    <div className=" col-12 col-md-12 d-flex justify-content-center">
                                        <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                            <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                       style={{width: "100%", border:"none"}} placeholder="Search for volunteer opportunities"/>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="saved-sources">
                                <div className="row">
                                    <ResourcesNav />
                                    <div className="col-md-8 col-12 d-flex align-items-center" style={{marginTop:"26px"}}>
                                        <h1>Gain hands-on ux experience through Volunteer Opportunities</h1>
                                    </div>
                                </div>
                                {
                                    this.state.loading ? <>
                                            {
                                                [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                            }

                                        </> :
                                        <table className="history-table mt-3 table-responsive" style={{overflow: "hidden"}}>
                                            <thead>
                                            <tr>
                                                <th  style={{overflow: "hidden", zIndex:"1"}}>Title</th>
                                                <th style={{overflow: "hidden"}}>Source</th>


                                            </tr>
                                            </thead>
                                            <tbody className="mobmodal">
                                            {
                                                this.state.items.length > 0 ?
                                                    this.state.items.map((item) => (
                                                        <tr >
                                                            <td style={{overflow: "hidden"}}>
                                                                {item.volunteer_institution}
                                                            </td>
                                                            <td style={{overflow: "hidden", color:"#00944D", textDecoration:"underline"}}>
                                                                <a onClick={()=>this.saveEvent(item.volunteer_institution)} href={item.url} target={"_blank"} style={{color:"#00944D"}} >{item.url}</a>
                                                            </td>

                                                        </tr>
                                                    )) :
                                                    <tr>
                                                        <td colSpan="5" className="search-message"><img src={cards}/><br/>
                                                            <h6>No results found for this keyword</h6>
                                                            <p>This word is not available in conferences, try our search
                                                                engine instead</p>
                                                            <NotFound term={this.state.filter} />
                                                        </td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </table>
                                }
                            </div>

                        </div>
                    </div>
                </MediaQuery>
                <Footer />
            </>
        );
    }
}
export default Volunteering