import React, {Component} from "react";
import MediaQuery from "react-responsive";
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import {MdCheckCircle} from "react-icons/md";
import {InlineWidget} from 'react-calendly'

class Participate extends Component{
    state = {
        name:"",
        email:"",
        title:"",
        timezone:"",
        success:false
    }
    handleInput =  (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSaveParticipant = (e) => {
        e.preventDefault()
        document.getElementById('submit-btn').innerHTML = 'Submitting...'
        const postparticipant = axios.post(buildAPIUrl('v1/research_participate/'), {
            email:this.state.email,
            full_name:this.state.name,
            job_title:this.state.title,
            time_zone:this.state.timezone
        }).then((res)=>{
            this.setState({
                success:true,
                name:"",
                email:"",
                title:"",
                timezone:"",
            })
            setTimeout(()=>{
                this.setState({
                    success:false
                })
            }, 5000)
            document.getElementById('submit-btn').innerHTML = 'Register'
        }).catch((error)=>{

        })
    }
    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={1200} >
                    <Header />
                    <div className={"container participate"}>
                        <div className={"col-md-10"}>

                            <h4 style={{fontSize:"40px", fontWeight:"500", lineHeight:"50px"}}>Help make Research Bookmark better</h4>
                            <h5 style={{marginTop:"51px", fontSize:"30px", fontWeight:"400", lineHeight:"37px"}}>Your feedback is important to us</h5>
                            <hr style={{marginTop:"27px"}}/>
                            <p style={{fontSize:"16px", fontWeight:"400", lineHeight:"26px", marginTop:"31px",}}>
                                As a user/visitor of our product, we’d love to hear your feedback. Sign up to participate in our research studies and influence how our teams improve our product for our worlwide users who use it everyday.
                            </p>
                            <p style={{fontSize:"16px", fontWeight:"400", lineHeight:"26px"}}>
                                If your profile is a fit for any of our upcoming research studies, you’ll be the first to try new offerings or features being built for our product.
                            </p>
                            <p style={{fontSize:"16px", fontWeight:"400", lineHeight:"26px"}}>
                                Everyone who participates in a volunteer study will stand a chance to be rewarded for their valubale feedback

                            </p>


                            <h5 style={{marginTop:"70px", fontSize:"30px", fontWeight:"400", lineHeight:"37px"}}>Register to participate</h5>
                            <hr style={{marginTop:"27px"}}/>
                            <form onSubmit={this.handleSaveParticipant}>
                                {
                                    this.state.success ?
                                        <div className={"container d-flex justify-content-center"}>
                                            <h6 className={"alert alert-success w-100 text-center"}><MdCheckCircle /> Details sent successfully</h6>
                                        </div>
                                        : ""
                                }
                                <div className={"col-md-8"}>
                                    <div className={"mb-3"}>
                                        <label>Name:</label>
                                        <input type={"text"} name={"name"} className={"form-control"} onChange={this.handleInput} value={this.state.name}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <label>Email Address:</label>
                                        <input required={true} type={"text"} name={"email"} className={"form-control"} onChange={this.handleInput} value={this.state.email}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <label>Job Title:</label>
                                        <input required={true} type={"text"} name={"title"} className={"form-control"} onChange={this.handleInput} value={this.state.title}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <label>Timezone</label>
                                        <input required={true} type={"text"} name={"timezone"} className={"form-control"} onChange={this.handleInput} value={this.state.timezone}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <button id={"submit-btn"} className={"btn btn-participate text-white"} style={{width:"fit-content", borderRadius:"6px"}}>Register</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className={"container-fluid"}>
                            <div className={"col-md-10"}>
                                <h5 style={{marginTop:"51px", fontSize:"30px", fontWeight:"400", lineHeight:"37px"}}>Your feedback is important to us</h5>
                                <hr style={{marginTop:"27px"}}/>
                            </div>

                            <InlineWidget url="https://calendly.com/nourshams/30min" />

                        </div>


                    </div>

                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <Header />
                    <div className={"container participate"}>
                        <div className={"col-md-10"}>

                            <h4 style={{fontSize:"40px", fontWeight:"500", lineHeight:"50px"}}>Help make Research Bookmark better</h4>
                            <h5 style={{marginTop:"51px", fontSize:"30px", fontWeight:"400", lineHeight:"37px"}}>Your feedback is important to us</h5>
                            <hr style={{marginTop:"27px"}}/>
                            <p style={{fontSize:"16px", fontWeight:"400", lineHeight:"26px", marginTop:"31px",}}>
                                As a user/visitor of our product, we’d love to hear your feedback. Sign up to participate in our research studies and influence how our teams improve our product for our worlwide users who use it everyday.
                            </p>
                            <p style={{fontSize:"16px", fontWeight:"400", lineHeight:"26px"}}>
                                If your profile is a fit for any of our upcoming research studies, you’ll be the first to try new offerings or features being built for our product.
                            </p>
                            <p style={{fontSize:"16px", fontWeight:"400", lineHeight:"26px"}}>
                                Everyone who participates in a volunteer study will stand a chance to be rewarded for their valubale feedback

                            </p>


                            <h5 style={{marginTop:"70px", fontSize:"30px", fontWeight:"400", lineHeight:"37px"}}>Register to participate</h5>
                            <hr style={{marginTop:"27px"}}/>
                            <form onSubmit={this.handleSaveParticipant}>
                                {
                                    this.state.success ?
                                        <div className={"container d-flex justify-content-center"}>
                                            <h6 className={"alert alert-success w-100 text-center"}><MdCheckCircle /> Details sent successfully</h6>
                                        </div>
                                        : ""
                                }
                                <div className={"col-md-8"}>
                                    <div className={"mb-3"}>
                                        <label>Name:</label>
                                        <input type={"text"} name={"name"} className={"form-control"} onChange={this.handleInput} value={this.state.name}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <label>Email Address:</label>
                                        <input required={true} type={"text"} name={"email"} className={"form-control"} onChange={this.handleInput} value={this.state.email}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <label>Job Title:</label>
                                        <input required={true} type={"text"} name={"title"} className={"form-control"} onChange={this.handleInput} value={this.state.title}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <label>Timezone</label>
                                        <input required={true} type={"text"} name={"timezone"} className={"form-control"} onChange={this.handleInput} value={this.state.timezone}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <button id={"submit-btn"} className={"btn btn-participate text-white"} style={{width:"fit-content", borderRadius:"6px"}}>Register</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className={"container-fluid"}>
                            <div className={"col-md-10"}>
                                <h5 style={{marginTop:"51px", fontSize:"30px", fontWeight:"400", lineHeight:"37px"}}>Your feedback is important to us</h5>
                                <hr style={{marginTop:"27px"}}/>
                            </div>

                            <InlineWidget url="https://calendly.com/cavendish-mwangi/30min" />

                        </div>


                    </div>

                </MediaQuery>
                <MediaQuery maxWidth={1200}  minWidth={461} >
                    <Header />
                    <div className={"container participate"}>
                        <div className={"col-md-10"}>

                            <h4 style={{fontSize:"40px", fontWeight:"500", lineHeight:"50px"}}>Help make Research Bookmark better</h4>
                            <h5 style={{marginTop:"51px", fontSize:"30px", fontWeight:"400", lineHeight:"37px"}}>Your feedback is important to us</h5>
                            <hr style={{marginTop:"27px"}}/>
                            <p style={{fontSize:"16px", fontWeight:"400", lineHeight:"26px", marginTop:"31px",}}>
                                As a user/visitor of our product, we’d love to hear your feedback. Sign up to participate in our research studies and influence how our teams improve our product for our worlwide users who use it everyday.
                            </p>
                            <p style={{fontSize:"16px", fontWeight:"400", lineHeight:"26px"}}>
                                If your profile is a fit for any of our upcoming research studies, you’ll be the first to try new offerings or features being built for our product.
                            </p>
                            <p style={{fontSize:"16px", fontWeight:"400", lineHeight:"26px"}}>
                                Everyone who participates in a volunteer study will stand a chance to be rewarded for their valubale feedback

                            </p>


                            <h5 style={{marginTop:"70px", fontSize:"30px", fontWeight:"400", lineHeight:"37px"}}>Register to participate</h5>
                            <hr style={{marginTop:"27px"}}/>
                            <form onSubmit={this.handleSaveParticipant}>
                                {
                                    this.state.success ?
                                        <div className={"container d-flex justify-content-center"}>
                                            <h6 className={"alert alert-success w-100 text-center"}><MdCheckCircle /> Details sent successfully</h6>
                                        </div>
                                        : ""
                                }
                                <div className={"col-md-8"}>
                                    <div className={"mb-3"}>
                                        <label>Name:</label>
                                        <input type={"text"} name={"name"} className={"form-control"} onChange={this.handleInput} value={this.state.name}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <label>Email Address:</label>
                                        <input required={true} type={"text"} name={"email"} className={"form-control"} onChange={this.handleInput} value={this.state.email}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <label>Job Title:</label>
                                        <input required={true} type={"text"} name={"title"} className={"form-control"} onChange={this.handleInput} value={this.state.title}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <label>Timezone</label>
                                        <input required={true} type={"text"} name={"timezone"} className={"form-control"} onChange={this.handleInput} value={this.state.timezone}/>
                                    </div>
                                    <div className={"mb-3"}>
                                        <button id={"submit-btn"} className={"btn btn-participate text-white"} style={{width:"fit-content", borderRadius:"6px"}}>Register</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className={"container-fluid"}>
                            <div className={"col-md-10"}>
                                <h5 style={{marginTop:"51px", fontSize:"30px", fontWeight:"400", lineHeight:"37px"}}>Your feedback is important to us</h5>
                                <hr style={{marginTop:"27px"}}/>
                            </div>

                            <InlineWidget url="https://calendly.com/cavendish-mwangi/30min" />

                        </div>


                    </div>
                </MediaQuery>
                <Footer />
            </>
        );
    }
}
export default Participate