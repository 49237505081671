import React, {Component} from "react";
import Header from "./Header";
import MediaQuery from "react-responsive";
import NotLoggedInMainDesktop from "./MainFile/NotLoggedInMainDesktop";
import NotLoggedInMainMobile from "./MainFile/NotLoggedInMainMobile";
import NotLoggedInMainTablet from "./MainFile/NotLoggedInMainTablet";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import SeoMetaData from "../RBResourceTables/SeoMetaData";


class Main extends Component {

    
    render() {
        return (
            
            <>
                <SeoMetaData title={"Home"} />

                            <MediaQuery minWidth={1200} >
                                    <NotLoggedInMainDesktop/>
                            </MediaQuery>
                            <MediaQuery maxWidth={460} className="mobile">

                            <NotLoggedInMainMobile />
                            </MediaQuery>
                            <MediaQuery maxWidth={1200} className="tablet" minWidth={461}>

                                <NotLoggedInMainTablet />
                            </MediaQuery>
        </>

        )
    }
}

export default Main