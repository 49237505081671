import React, { useState, useCallback, useEffect} from 'react';
import {fetchAllVocabularies, deleteVocabulary} from './VocabulariesApi';
import {Link} from "react-router-dom";
import Header from "../Header";

function ListOfVocabularies() {
    const [listOfVocabularies, setlistOfVocabularies] = useState([]);
    const [userToken, setUserToken]=useState(localStorage.getItem('token'));
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");



    if(!userToken){
        window.location.assign('/');
    }

    const fetchVocabulariesHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
          let response = await fetchAllVocabularies();
          const data = await response.json();
          setlistOfVocabularies(data.reverse());
        } catch (error) {
          setError(error.message);
          setIsError(true);
        }
        setIsLoading(false);
      }, []);

      useEffect(() => {
        fetchVocabulariesHandler();
      }, [fetchVocabulariesHandler]);

      async function deleteVocabularyHandler(vocabulary) {

        setIsLoading(true);
        setError(null);
        try {
          await deleteVocabulary(vocabulary.id, userToken);
          fetchVocabulariesHandler();
    
        } catch (error) {
          setError(error.message);
        }
        setIsLoading(false);
    
    
      }
      async function confirmDeleteVocabularyHandler(vocabulary) {
    
        await deleteVocabularyHandler(vocabulary);
        // let confirmDelete = confirm(`Are you sure you want to DELETE ${community.name}?`);
        // if (confirmDelete != null) {
        //   await deleteCommunityHandler(community);
        // }
    
      }



    let content = <p></p>;

    if (listOfVocabularies.length > 0) {
        content = listOfVocabularies.map((vocabularies)=>(
            
          <tr key={vocabularies.id}>

                <td>{vocabularies.name}</td>
                <td>{vocabularies.definition}</td>
                <td>{vocabularies.source}</td>
    
              <td>
                  <Link className={"btn btn-primary w-100"} to={`/vocabulary/edit/${vocabularies.id}`}>
                     Edit
                  </Link>

                  <button className={"btn btn-danger w-100 mt-2"} onClick={() => confirmDeleteVocabularyHandler(vocabularies)}>Delete</button>
              </td>
        </tr>

        ));
    }
    else{
        content = <p>Found no Vocabularies.</p>;
    }

  return(
      <>
            <Header />
          <div className={"container-fluid mt-5"}>
              <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                  <div className={"card border"}>
                      <div className={"card-header"}>
                          <div className={"row"}>
                              <div className={"col-md-10"}>
                                  <h4>List of Vocabularies</h4>
                              </div>
                              <div className={"col-md-2"}>
                                  <Link to="/vocabulary/create">Create Vocabularies </Link>
                              </div>
                          </div>

                      </div>
                      <div className={"card-body"}>
                          {isLoading && <p>Loading.....</p>}
                          {isError && <p>{error}</p>}
                          <table className={"table table-striped table-hover"}>
                              <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Definition</th>
                                        <th>Source</th>
                                    </tr>
                              </thead>
                              <tbody>
                              {content}
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>


      </>
  )
}
export default ListOfVocabularies;