import React, {Component} from "react";
import {HiSearch} from "react-icons/hi";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import searchicon from "../../../assets/Frame 39.png";
import MediaQuery from "react-responsive";

class SearchBox extends Component{
    state = {
        q:localStorage.getItem('searchquery'),
        suggestion:[],
        userHistory: []
    }
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    handleClickOutside(event) {
        if (!this.wrapperRef.current.contains(event.target)) {
            this.setState({
                activeinput:false,
                suggestion:[],
            })

        }
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.setState({
            q:localStorage.getItem('searchquery').replaceAll("+", " ")
        })
        //Get users history and display it when they start typing in the searchbox
        if (localStorage.getItem('token')){
            axios.get(buildAPIUrl('v1/save_search_queries/'), {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}}).then((res) => {
                this.setState({
                    userHistory: res.data,
                })

            })
        }
    }

    handleInput = (e) => {
        axios.get(`https://api.bing.microsoft.com/v7.0/suggestions?q=${e.target.value} ux`, {
            headers:{
                "Ocp-Apim-Subscription-Key":"823ca4d0626b4634818ab9e09081ffff",
                "token":this.state.token
            }}).then((res)=>{
            console.log("Suggestions", res.data.suggestionGroups[0].searchSuggestions)
            this.setState({
                suggestion:res.data.suggestionGroups[0].searchSuggestions
            })
        }).catch((error)=>{
            console.log(error)
        })
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSuggestion = async (item)=>{
        localStorage.setItem('searchquery', item)
        this.setState({
            q:item,
            suggestion:[]
        })
        if(this.props.web) {
            window.location.assign('/all-results')
        } else if(this.props.images){
            window.location.assign('/images')
        } else if(this.props.videos){
            window.location.assign('/videos')
        } else if(this.props.news) {
            window.location.assign('/news')
        }
        else if(this.props.social) {
            window.location.assign('/social-media')
        }
    }
    handleSearch = () => {
        localStorage.setItem('searchquery', this.state.q)
        let searterm = localStorage.getItem('searchquery')
        this.handleSuggestion(this.state.q)
        if(this.props.web) {
            window.location.assign('/all-results')
        } else if(this.props.images){
            window.location.assign('/images')
        } else if(this.props.videos){
            window.location.assign('/videos')
        } else if(this.props.news) {
            window.location.assign('/news')
        }
        else if(this.props.social) {
            window.location.assign('/social-media')
        }
    }

    onChangehandler = () =>{

    }
    render() {

        return(
            <>
                <MediaQuery minWidth={1200} >
                    <div className="search-section col-12 col-md-9">
                        <div className="row d-flex align-items-center ">
                            <div className=" col-12 col-md-12 d-flex justify-content-center position-relative p-0 "  ref={this.wrapperRef} >
                                <form onSubmit={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                    <div className="form-input border" style={this.props.device==="mobile" ? {border: " 1px solid rgba(13, 0, 76, 0.08)", borderRadius:"200px", paddingLeft:"1rem", paddingRight:"1.5rem", boxShadow:"0px 3px 4px rgba(0, 0, 0, 0.07)"} : { borderRadius:"100px", paddingLeft:"40px", paddingRight:"1.5rem", boxShadow:"rgba(0, 0, 0, 0.07) 0px 3px 4px"}}>
                                        <span className="input-group-addon d-flex align-items-center">  <img src={searchicon}/></span>
                                        <input className={this.props.device==="mobile" ? "search-field-text1mob" : this.props.device==="tablet" ? "search-field-text1tablet" :  "search-field-text1"} type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                               style={this.state.suggestion.length > 0 ? {borderRadius:"35px 35px 10px 10px", width:"100%"} : {borderRadius:"6px", width:"100%"}} placeholder="Search for anything UX research eg use cases, medium articles, papers, videos"/>

                                    </div>
                                    <div className="form-input1 position-absolute" style={{zIndex:"1000", width:"100%", marginTop:"-10px"}}>
                                        {
                                            this.state.suggestion && this.state.suggestion.map((suggest, i)=>(
                                                <div className="suggest-option" style={{cursor:"pointer"}} key={i} onClick={ ()=>this.handleSuggestion(suggest.displayText)}> {suggest.displayText}</div>
                                            ))
                                        }
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className="search-section col-12 col-md-7">
                        <div className="row d-flex align-items-center ">
                            <div className=" col-12 col-md-12 d-flex justify-content-center position-relative p-0">
                                <form onSubmit={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                    <div className="form-input border" style={this.props.device==="mobile" ? {border: " 1px solid rgba(13, 0, 76, 0.08)", borderRadius:"200px", paddingLeft:"1rem", paddingRight:"1.5rem", boxShadow:"0px 3px 4px rgba(0, 0, 0, 0.07)"} : {border: "1px solid silver", borderRadius:"100px", paddingLeft:"40px", paddingRight:"1.5rem"}}>
                                        <span className="input-group-addon d-flex align-items-center">  <img src={searchicon}/></span>
                                        <input className={this.props.device==="mobile" ? "search-field-text1mob" : this.props.device==="tablet" ? "search-field-text1tablet" :  "search-field-text1"} type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                               style={this.state.suggestion.length > 0 ? {borderRadius:"35px 35px 10px 10px", width:"100%"} : {borderRadius:"6px", width:"100%"}} placeholder="Search for anything UX research eg use cases, medium articles, papers, videos"/>

                                    </div>
                                    <div className="form-input1 position-absolute" style={{zIndex:"1000", width:"100%", marginTop:"-10px"}}>
                                        {
                                            this.state.suggestion && this.state.suggestion.map((suggest, i)=>(
                                                <div className="suggest-option" style={{cursor:"pointer"}} key={i} onClick={ ()=>this.handleSuggestion(suggest.displayText)}> {suggest.displayText}</div>
                                            ))
                                        }
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461} >
                    <div className="search-section col-12 col-md-12">
                        <div className="row d-flex align-items-center ">
                            <div className=" col-12 col-md-12 d-flex justify-content-center position-relative p-0">
                                <form onSubmit={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                    <div className="form-input border" style={this.props.device==="mobile" ? {border: " 1px solid rgba(13, 0, 76, 0.08)", borderRadius:"200px", paddingLeft:"1rem", paddingRight:"1.5rem", boxShadow:"0px 3px 4px rgba(0, 0, 0, 0.07)"} : {border: "1px solid silver", borderRadius:"100px", paddingLeft:"40px", paddingRight:"1.5rem"}}>
                                        <span className="input-group-addon d-flex align-items-center">  <img src={searchicon}/></span>
                                        <input className={this.props.device==="mobile" ? "search-field-text1mob" : this.props.device==="tablet" ? "search-field-text1tablet" :  "search-field-text1"} type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                               style={this.state.suggestion.length > 0 ? {borderRadius:"35px 35px 10px 10px", width:"100%"} : {borderRadius:"6px", width:"100%"}} placeholder="Search for anything UX research eg use cases, medium articles, papers, videos"/>

                                    </div>
                                    <div className="form-input1 position-absolute" style={{zIndex:"1000", width:"100%", marginTop:"-10px"}}>
                                        {
                                            this.state.suggestion && this.state.suggestion.map((suggest, i)=>(
                                                <div className="suggest-option" style={{cursor:"pointer"}} key={i} onClick={ ()=>this.handleSuggestion(suggest.displayText)}> {suggest.displayText}</div>
                                            ))
                                        }
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </MediaQuery>

            </>
        )
    }
}
export default SearchBox