import React, {useEffect, useState} from "react";
import Header from "../../Landing/Header";
import SkeletonSearchResult from "../../Landing/Skeletons/SkeletonSearchResult";
import {FaBookmark, FaRegBookmark, FaRegCopy} from "react-icons/fa";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import {copyLink, saveCollection, saveNotionArticle} from "../../ResultsCategories/CategoriesFunctions";
import {useHistory, useParams} from "react-router-dom";
import {Modal, ModalFooter} from "react-bootstrap";
import {MdClear, MdOpenInNew, MdSettings} from "react-icons/md";
export default function CommunitySubCollectionDetails(props){
    const [collections, setCollections]=useState([])
    const[copystatus, setCopyStatus]=useState(false)
    const [savestatus, setSaveStatus]=useState(false)
    const [existstatus, setExistStatus]=useState("")
    const[preview, setPreview]=useState(false)
    const[previewName, setPreviewName]=useState("")
    const[previewUrl, setPreviewUrl]=useState("")
    const[previewcontent, setPreviewContent]=useState("")
    const[generatingPreview, setGeneratingPreview]=useState(true)
    const[chatGPT, setChatGPT]=useState(process.env.REACT_APP_CHATGPT_SECRET)
    const{id}=useParams()
    const[loading, setLoading]=useState(true)
    const handleBack = ()=>{
       props.history.goBack()
    }
    const getCollections = async () =>{
        //Get collections
        const collectionslist = await axios.get(buildAPIUrl(`v1/collections/main/?sub_group=${localStorage.getItem('colid')}`), {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        await setCollections(collectionslist.data)
        console.log(collectionslist.data)
        setLoading(false)

    }
    const handleCopyLink = (result)=>{
        copyLink(result, (copystatus)=>{
           setCopyStatus(copystatus)
            setTimeout(()=>{
                setCopyStatus(false)
            }, 5000)
        })

    }
    const  handleSaveSearch = (result, bookmarktype)=>{
        saveCollection(result, bookmarktype, (savestatus)=>{
           setSaveStatus(savestatus)
        }, (error)=>{
           setExistStatus(error)
            setInterval(()=>{
                setExistStatus("")
            }, 3000)
        })
    }
    //Show content preview
  const  handlePreview = async (result) => {
        setPreview(true)
      setPreviewName(result.title)
      setPreviewUrl(result.link)
        //Get response from chat gpt
        await axios.post('https://api.openai.com/v1/engines/text-davinci-002/completions',
            {
                prompt: `Please summarize the content of this URL in a list of 4 paragraphs without numbering: ${result.link}`,
                max_tokens: 500
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${chatGPT}`
                }
            }).then((res)=>{
            setTimeout(()=>{
                setPreviewContent(res.data.choices[0].text.slice(0,res.data.choices[0].text.length + 1))
                setGeneratingPreview(false)
            }, 100)


        });
    }
    //Hide content preview
   const reghandleClose = ()=>{
        setPreview(false)
       setPreviewContent("")
       setGeneratingPreview(true)
    }

    useEffect(()=>{
        if(!localStorage.getItem('token')){
            window.location.assign("/")
        }
       getCollections()
    }, [])
    return (
        <>
            <Header />
            <Modal size="lg" style={{borderRadius:"2px"}} centered show={preview}  onHide={reghandleClose} >

                <Modal.Body style={{overflowX:"hidden", overflowY:"hidden", padding:"2rem"}}>
                    <div className={"row w-100"}>
                        <div className={"col-md-12 col-2 d-flex justify-content-end"}>
                            <span onClick={reghandleClose} className={"d-flex justify-content-center align-items-center"} style={{color:"#000000", borderRadius:"8px", height:"34px", width:"34px",cursor:"pointer", fontSize:"34px"}}><MdClear /></span>
                        </div>
                        <div className={"col-md-12 d-flex justify-content-center"}>
                            <h6 className={"summary-title text-center"} style={{fontWeight:"400", fontSize:"28px", lineHeight:"110%", color:"#00944D", marginTop:"1rem"}}>{previewName.substr(0, 100)}</h6>
                        </div>

                    </div>
                    <div className="row content-preview" style={{borderRadius:"2px", padding:"1rem"}}>
                        {
                            generatingPreview ? <>
                                <div className={"container d-flex justify-content-center text-center"}>
                                    <div>
                                        <MdSettings style={{fontSize:"45px"}} />
                                        <p className={"generating"} style={{marginTop:"1rem"}}>Generating a summary...</p>
                                    </div>
                                </div>

                            </> : <>
                                <div style={{color:"black", lineHeight:"150%", }}>
                                    <p className={"summary"} style={{whiteSpace:"pre-line", fontSize:"16px", lineHeight:"22px", marginTop:"-2.5rem"}}>{previewcontent}</p>
                                </div>
                                <div className={"container-fluid mt-3 d-flex justify-content-end"}>
                                    <a href={previewUrl} target={"_blank"} className={"btn"} style={{background:"#522FFF", color:"white"}}>
                                        Go to site <MdOpenInNew />
                                    </a>
                                </div>
                            </>
                        }
                    </div>
                </Modal.Body>
                <ModalFooter style={{border:"none"}}>

                </ModalFooter>

            </Modal>
            <div className={"container-fluid bg-image d-flex justify-content-center align-items-center "} style={{ height:"14.625rem"}}>
                <h6 style={{fontSize:"30px", color:"#FFFFFF", fontWeight:"600"}}>{localStorage.getItem('colname')}</h6>

            </div>
            <div className={"container"} style={{marginTop:"1.313rem"}}>
                <div className={"row d-flex justify-content-center"}>
                    <div className={"col-md-2"}>
                        <div
                            className={copystatus ? "alert alert-success position-fixed" : "d-none"}>
                            <FaBookmark/> Link copied successfully
                        </div>
                        <div
                            className={savestatus ? "alert alert-success position-fixed " : "d-none"}>
                            <FaBookmark/> Article saved successfully
                        </div>
                        <div
                            className={existstatus ? "alert alert-warning position-fixed" : "d-none"}>
                            <FaBookmark/> Bookmark already exists
                        </div>
                    </div>
                    <div className={"col-md-8"}>
                        <div className={"row"}>

                            <div className={"col-md-6"}>
                                <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                    {localStorage.getItem('subname')}
                                </h6>
                            </div>
                            <div className={"col-md-2 d-flex justify-content-end"}>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={"container"} style={{marginTop:"2rem"}}>
                <div className={"row d-flex justify-content-center"}>
                    <div className={"col-md-9"}>
                        {
                            loading ? <>
                                    {
                                        [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                            key={n}/>)
                                    }

                                </> :
                                <div>
                                    {
                                        collections.length > 0 ? <>
                                            {
                                                collections.sort(function (a,b){return b.id-a.id}).map((result) => (
                                                    <>

                                                        <div className={"bg-white"} style={{boxShadow:"0px 1px 3px rgba(0, 0, 0, 0.25)", padding:"1.563rem", marginTop:"1.313rem"}}>
                                                            <div className={"row bg-white"}>
                                                                <div className={"col-md-7 bg-white"}>
                                                                    <a  onClick={()=>handlePreview(result)} target="_blank"><h6
                                                                        className="result-url mt-2 mb-2">{result.link.substr(0, 100)}</h6>
                                                                    </a>
                                                                </div>

                                                            </div>

                                                            <a onClick={()=>handlePreview(result)} target="_blank"><h6
                                                                className="result-name mt-2 mb-2">
                                                                {result.title}
                                                            </h6>
                                                            </a>
                                                            <p className="result-snippet">
                                                                {result.snippet ?  result.snippet.substr(0, 100) : result.snippet}
                                                            </p>
                                                            <div className={"container-fluid p-0"} style={{marginTop:"0.813rem"}}>
                                                        <span className="" style={{color:"#767676", fontSize:"13px", marginLeft:"0.75rem", display:"inline-block", cursor:"pointer"}} onMouseDown={() => {
                                                            handleCopyLink(result.link)
                                                        }}> Copy link <FaRegCopy className="save-icon"/></span>
                                                                <span className="save save-icon-onboard"
                                                                      onClick={() => handleSaveSearch(result, "Collection")}> Save <FaRegBookmark
                                                                    className="save-icon"/></span>
                                                            </div>

                                                        </div>
                                                    </>
                                                ))
                                            }

                                        </> : <>
                                        <p>
                                            No items found
                                        </p>
                                        </>
                                    }


                                </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )
}