import React, {useState, useEffect} from "react";
import {Line, Pie} from 'react-chartjs-2'
import {Chart as ChartJS} from 'chart.js/auto'
import {
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
    MdOutlineFilterAlt
} from "react-icons/md";
import axios from "axios";
import buildAPIUrl from "../Services/UrlBuilder";
import moment from "moment";
const date = new Date();
    function getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
}
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
const currentYear = date.getFullYear();
const currentMonth = date.getMonth() + 1;
const currentMonthText = monthNames[date.getMonth()]
const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);
const now = new Date();
const firstMonthDay = moment().startOf('month').format('YYYY-MM-DD');
const lastMonthDay =  moment().endOf('month').format('YYYY-MM-DD');
const firstWeekDay =  moment().startOf('week').format('YYYY-MM-DD');
const lastWeekDay =  moment().endOf('week').format('YYYY-MM-DD');
const firstYearDay =  moment().startOf('year').format('YYYY-MM-DD');
const lastYearDay =  moment().endOf('year').format('YYYY-MM-DD');
var monthdaysarray = []
for(var i=1; i<=daysInCurrentMonth; i++) {
    monthdaysarray.push(i.toString());
}



export default function Impressions() {
    const [dateType, setDateType] = useState("1")
    const [chartType, setChartType] = useState("1")
    const [trending, setTrending] = useState(false)
    const [signOptions, setSignOptions] = useState(false)
    const [allSearches, setAllSearches] = useState([])
    const [allSearchesData, setAllSearchesData]=useState([])
    const [allUsersData, setAllUsersData]=useState([])
    const [loading, setLoading]=useState(false)
    const [startDate, setStartDate]=useState(firstMonthDay)
    const [endDate, setEndDate] = useState(lastMonthDay)
    const [userToken, setUserToken]=useState(localStorage.getItem('token'))
    const[periodLength, setPeriodLength] = useState("month")
    const[facebook, setFacebook]=useState("")
    const [google, setGoogle]=useState("")
    useEffect(()=>{
        const response1 = axios.get(buildAPIUrl('v1/save_search_queries/trending'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log(res1.data)
            setAllSearches([res1.data])
            setLoading(false)
        })

            const response2 = axios.get(buildAPIUrl(`v1/users/me/?socials=google-oauth2`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                console.log("Dataset", res1.data)
                setGoogle(res1.data.total_number_of_all_users)
            })
        const response3 = axios.get(buildAPIUrl(`v1/users/me/?socials=facebook`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log("Dataset", res1.data)
            setFacebook(res1.data.total_number_of_all_users)
            const response4 = axios.get(buildAPIUrl(`v1/save_search_queries/?start_date=${firstMonthDay}&end_date=${lastMonthDay}&saved_searches=weeks`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                console.log("Dataset Searches", res1.data)
                setAllSearchesData(res1.data.graph_data)
            })
        })
    }, [])
    const  handleDateFilter = async (type) => {
        console.log("Date Type", type)
       await setDateType(type)
        if(type==="1"){
            await setStartDate(firstMonthDay)
            await setEndDate(lastMonthDay)
            await setPeriodLength("weeks")
            if (chartType==="1"){
                const response2 = axios.get(buildAPIUrl(`v1/save_search_queries/?start_date=${firstMonthDay}&end_date=${lastMonthDay}&saved_searches=weeks`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                    console.log("Dataset Searches", res1.data)
                    setAllSearchesData(res1.data.graph_data)
                })
            } else if (chartType==="2"){
                const response2 = axios.get(buildAPIUrl(`v1/users/me/?start_date=${firstMonthDay}&end_date=${lastMonthDay}&users=weeks`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                    console.log("Dataset users", res1.data)
                    setAllSearchesData(res1.data.graph_data)
                })
            } if (chartType==="3"){
                const response2 = axios.get(buildAPIUrl(`v1/bookmarks/?start_date=${firstMonthDay}&end_date=${lastMonthDay}&query_all_bookmarks=weeks`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                    console.log("Dataset saved sources", res1.data)
                    setAllSearchesData(res1.data[0].graph_data)
                })
            }
        } else if(type==="2"){
            await   setStartDate(firstWeekDay)
            await  setEndDate(lastWeekDay)
            await setPeriodLength("weeks")
            if (chartType==="1"){
                const response2 = axios.get(buildAPIUrl(`v1/save_search_queries/?start_date=${firstWeekDay}&end_date=${lastWeekDay}&saved_searches=weeks`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                    console.log("Dataset Searches", res1.data)
                    setAllSearchesData(res1.data.graph_data)
                })
            } else if (chartType==="2"){
                const response2 = axios.get(buildAPIUrl(`v1/users/me/?start_date=${firstWeekDay}&end_date=${lastWeekDay}&users=weeks`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                    console.log("Dataset users", res1.data)
                    setAllSearchesData(res1.data.graph_data)
                })
            } if (chartType==="3"){
                const response2 = axios.get(buildAPIUrl(`v1/bookmarks/?start_date=${firstWeekDay}&end_date=${lastWeekDay}&query_all_bookmarks=weeks`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                    console.log("Dataset saved sources multi", res1.data)
                    setAllSearchesData(res1.data[0].graph_data)
                })
            }
        } else if (type==="3"){
            await   setStartDate(firstYearDay)
            await  setEndDate(lastYearDay)
            await setPeriodLength("months")
            if (chartType==="1"){
                const response2 = axios.get(buildAPIUrl(`v1/save_search_queries/?start_date=${firstYearDay}&end_date=${lastYearDay}&saved_searches=months`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                    console.log("Dataset Searches", res1.data)
                    setAllSearchesData(res1.data.graph_data)
                })
            } else if (chartType==="2"){
                const response2 = axios.get(buildAPIUrl(`v1/users/me/?start_date=${firstYearDay}&end_date=${lastYearDay}&users=months`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                    console.log("Dataset users year", res1.data)
                    setAllSearchesData(res1.data.graph_data)
                })
            } if (chartType==="3"){
                const response2 = axios.get(buildAPIUrl(`v1/bookmarks/?start_date=${firstYearDay}&end_date=${lastYearDay}&query_all_bookmarks=months`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                    console.log("Dataset saved sources sources multi", res1.data)
                    setAllSearchesData(res1.data.graph_data)
                })
            }
        }

    }
    const handleChartFilter = async (type) =>{
        await setChartType(type)
        if (type==="1"){
           const response2 = await axios.get(buildAPIUrl(`v1/save_search_queries/?start_date=${startDate}&end_date=${endDate}&saved_searches=${periodLength}`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                console.log("Dataset Searches", res1.data)
              setAllSearchesData(res1.data.graph_data)
            })
        } else if (type==="2"){
            const response2 = await axios.get(buildAPIUrl(`v1/users/me/?start_date=${startDate}&end_date=${endDate}&users=${periodLength}`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                console.log("Dataset users", res1.data)
                setAllSearchesData(res1.data.graph_data)
            })
        } if (type==="3"){
            const response2 = await axios.get(buildAPIUrl(`v1/bookmarks/?start_date=${startDate}&end_date=${endDate}&query_all_bookmarks=${periodLength}`),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
                console.log("Dataset saved sources", res1.data)
                setAllSearchesData(res1.data[0].graph_data)
            })
        }
    }
    if (chartType==="1"){
        var charttypename = "Total searches"
    } else if (chartType==="2"){
        var charttypename = "Total signed up users"
    } else if (chartType==="3"){
        var charttypename = "Total saved sources"
    }
    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
        datasets: [
            {
                label: charttypename ,
                data:  allSearchesData,
                fill: true,
                backgroundColor: "#f2efff",
                borderColor: "#043CCC",
                lineTension:.3
            },
        ]
    };

    const monthdata =  {
        labels: monthdaysarray,
        datasets: [
            {
                label:  charttypename + " " + "in" + " " + currentMonthText,
                data: allSearchesData,
                fill: true,
                backgroundColor: "#f2efff",
                borderColor: "#043CCC",
                lineTension:.3
            },
        ]
    };

    const weekdata =  {
        labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        datasets: [
            {
                label: charttypename ,
                data: allSearchesData,
                fill: true,
                backgroundColor: "#f2efff",
                borderColor: "#043CCC",
                lineTension:.3
            },
        ]
    };
    const signup =  {
        labels: ["Google", "Facebook"],
        datasets: [
            {
                label: "" ,
                data: [google, facebook],
                fill: true,
                backgroundColor: ["#522FFF", "#ED1E98"],
            },
        ]
    };
    const handleShowTrending = () =>{
        if(trending) {
            setTrending(false)
        } else {
            setTrending(true)
        }

    }
    const handleSignOptions = () =>{
        if(signOptions) {
            setSignOptions(false)
        } else {
            setSignOptions(true)
        }

    }
    console.log("Start Date", startDate, "type", dateType)
    console.log("End Date", endDate)
    console.log(periodLength)
    return (
        <>
            <div className="impressions">
                <div className="row">
                    <div className="col-md-6">
                      <div className="container-fluid nav-item d-flex align-items-center dropdown ">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                               role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}>
                                <h6>Impressions <span className="impression-icon"><MdKeyboardArrowDown /></span> </h6>
                            </a>
                          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                              <li className={chartType==="1" ? "active" : ""} style={{cursor:"pointer"}}>
                                  <a className="dropdown-item"  onClick={()=>handleChartFilter("1")} >Total searches</a>
                              </li>
                              <li className={chartType==="2" ? "active" : ""} style={{cursor:"pointer"}}>
                                  <a className="dropdown-item" onClick={()=>handleChartFilter("2")} >Total signed up users</a>
                              </li>
                              <li className={chartType==="3" ? "active" : ""} style={{cursor:"pointer"}}>
                                  <a className="dropdown-item"  onClick={()=>handleChartFilter("3")} >Total saved sources</a>
                              </li>
                          </ul>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end impressions-filter">
                        <div className="container-fluid nav-item d-flex align-items-end justify-content-end dropdown ">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                               role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}>
                                <h6><span className="impression-icon"><MdOutlineFilterAlt /></span> </h6>
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li className={dateType==="2" ? "active" : ""} style={{cursor:"pointer"}}>
                                    <a className="dropdown-item" onClick={()=>handleDateFilter("2")}>Week</a>
                                </li>
                                <li className={dateType==="1" ? "active" : ""} style={{cursor:"pointer"}}>
                                    <a className="dropdown-item" onClick={()=>handleDateFilter("1")} >Month</a>
                                </li>
                                <li className={dateType==="3" ? "active" : ""} style={{cursor:"pointer"}}>
                                    <a className="dropdown-item" onClick={()=>handleDateFilter("3")} >Year</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <Line data={weekdata} className={dateType==="2" ? "" : "d-none"}   />
                    <Line data={data} className={dateType==="3" ? "" : "d-none"}   />
                    <Line data={monthdata} className={dateType==="1" ? "" : "d-none"}  />
                </div>
            </div>
            <div className="impressions1">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card bg-white">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6>Trending Searches</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <span onClick={handleShowTrending} className="impression-icon" style={{cursor:"pointer"}}>
                                        {trending ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> }
                                        </span>
                                </div>
                            </div>
                            <div className={trending ? "table-responsive fullheight" : "d-none" }>
                                <table className= "table table-borderless">
                                    <thead>
                                    <tr>
                                        <th>Keyword</th>
                                        <th style={{textAlign:"right"}}>Number of Searches</th>
                                    </tr>
                                    </thead>
                                    <tbody style={{marginBottom:"2rem"}}>
                                    {allSearches.map((item) => {
                                        return (
                                            <>
                                                {Object.entries(item).slice(0,9).sort((a, b) => b[1] - (a[1])).map((field) => {
                                                    return (
                                                        <tr style={{borderBottom:"0.5px solid rgba(235, 235, 235, 0.9)"}}>
                                                            <td style={{paddingTop:"4%"}} >{field[0]}</td>
                                                            <td  style={{textAlign:"right", paddingTop:"4%"}}>{field[1]}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </>


                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card bg-white">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6>Sign Up Options</h6>
                                </div>
                                <div className="col-md-6 d-flex justify-content-end">
                                    <span onClick={handleSignOptions} className="impression-icon" style={{cursor:"pointer"}}>
                                        {signOptions ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> }
                                        </span>
                                </div>
                            </div>
                            <div className={signOptions ? "container-fluid d-flex justify-content-center fullheight" :"d-none"}>
                                <Pie height={400} data={signup}
                                     options={{legend: {
                                             labels: {
                                                 generateLabels: (chart) => {
                                                     const datasets = chart.data.datasets;
                                                     return datasets[0].data.map((data, i) => ({
                                                         text: `${chart.data.labels[i]} ${data}`,
                                                         fillStyle: datasets[0].backgroundColor[i],
                                                     }))
                                                 }
                                             }}, maintainAspectRatio:false}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}