import React, {Component} from "react";
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import {FaBookmark, FaRegBookmark, FaRegCopy, FaUser} from "react-icons/fa";
import {MdClear} from "react-icons/md";
import Filters from "../Landing/Filters";
import SearchBox from "../Landing/SearchBox";
import LoginModal from "../Modals/LoginModal";
import Header from "../Landing/Header";
import buildAPIUrl from "../../../Services/UrlBuilder";
import SkeletonImage from "../Landing/Skeletons/SkeletonImage";
import MediaQuery from "react-responsive";

class Images extends Component{
    state = {
        results: [],
        filter:"",
        siteid:"",
        social: false,
        total:"",
        search:"",
        web:true,
        imgs:false,
        vids:false,
        news:false,
        images:[],
        isOpen: false,
        image:"",
        show:false,
        token: localStorage.getItem('token'),
        configID:`${process.env.REACT_APP_BING_CONFIG_ID}`,
        subscrKey:`${process.env.REACT_APP_BING_SUBSCRIPTION_ID}`,
        loading: true,
        logintype:"",
        imageshow:false,
        exists:""

    }

    componentWillMount() {
        if(!localStorage.getItem('token')){
            window.location.assign("/")
        }
        if(window.location.search) {
            localStorage.setItem('searchquery', window.location.search.replace('?q=', " ").replaceAll('%20', " "))
        }

    }
    componentDidMount() {

        let searchterm = localStorage.getItem('searchquery').replace("+", " ")
        const response  = axios.get(`https://api.bing.microsoft.com/v7.0/custom/images/search?q=${searchterm} ux research&customconfig=${this.state.configID}&count=150`, {
            headers:{
                "Ocp-Apim-Subscription-Key":this.state.subscrKey
            }
        }).then((res)=>{
            console.log(res.data)
            this.setState({
                images: res.data.value,
                total:res.data.totalEstimatedMatches,
                loading: false
            })
        })
    }
    handleModalClose = ()=>{
        this.setState({
            show: false,
            logintype:""
        })
    }
    handleModalOpen = (result, logtype)=>{
        console.log("clicked")
        this.setState({
            show: true,
            image:result,
            logintype:logtype

        })
    }
    handleImageModalClose = ()=>{
        this.setState({
            imageshow: false,
        })
    }
    handleImageModalOpen = (result)=>{
        console.log("clicked")
        this.setState({
            imageshow: true,
            image:result,

        })
    }
    handleOpen = ()=>{
        this.setState({
            active: false
        })
    }
    handleClose = () => {
        this.setState({
            active: true
        })
    }
    handleImages = () => {
        console.log("images")
        this.setState({
            search: "/images",
            social:false,
            web:false,
            imgs:true,
            vids:false,
            news:false,
            results:"",
        })
    }
    handleShowDialog = (result) => {
        console.log(result)
        this.setState({ isOpen: !this.state.isOpen,
        image:result
        });
        console.log("cliked");
    };
    handleSaveSearch = async ( result) => {
        await axios.post(buildAPIUrl(`v1/bookmarks/`), {
                title: result.name,
                link: result.hostPageUrl,
                snippet:result.thumbnailUrl,
                tag:"Images"
            },
            {
                headers: {
                    Authorization: `Bearer ${this.state.token}`
                }
            } ).then((res) => {
            this.setState({
                saved:true,
                show: false,
            })
            setInterval(() => {
                this.setState({
                    saved: false
                })
            }, 20000)
        }).catch((error)=>{
            console.log(error.response.data.message)
            this.setState({
                exists: error.response.data.message
            })
            setInterval(() => {
                this.setState({
                    exists: ""
                })
            }, 10000)
        })
    }
    handleCopyLink = (result) =>{
        navigator.clipboard.writeText(result.thumbnailUrl).then(()=>{
            this.setState({
                copy: true,
                show:false
            })
            setInterval(() => {
                this.setState({
                    copy: false
                })
            }, 3000)
        })
    }
    render() {
        const breakpointColumnsObj = {
            default: 4,
            1100: 3,
            700: 2,
            500: 1
        };
        return (
            <>

                <Header />

                <MediaQuery minWidth={1200}>
                    <Modal  show={this.state.imageshow}  onHide={this.handleImageModalClose}>
                        <form onSubmit={this.handleSaveSearch}>
                            <Modal.Body style={{padding:"0"}} >
                                <div className="close" onClick={this.handleImageModalClose} style={{cursor:"pointer", margin:"1rem"}}>
                                   <h4><MdClear /></h4>
                                </div>
                                <img src={this.state.image.contentUrl} style={{width:"100%"}}/>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="container position-relative">
                                    <div
                                        className={this.state.copy ? "alert alert-success" : "d-none"} style={{top:"75%"}}>
                                        <FaBookmark/> Link copied successfully
                                    </div>
                                    <div
                                        className={this.state.saved ? "alert alert-success " : "d-none"} style={{top:"75%"}}>
                                        <FaBookmark/> Image saved successfully
                                    </div>
                                    <div
                                        className={this.state.exists ? "alert alert-warning " : "d-none"} style={{top:"75%"}}>
                                        <FaBookmark/> Image already exists
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 images">
                                            <a target="_blank" href={this.state.image.hostPageUrl} style={{color:"#767676"}}><h6>{this.state.image.name}</h6></a>
                                            <a  target="_blank" href={this.state.image.hostPageUrl} style={{color:"#767676"}}><h6>{this.state.image.hostPageUrl}</h6></a>
                                        </div>
                                        <div className="col-md-4">
                                            {
                                                this.state.token ?  <span className="save" onClick={()=>this.handleSaveSearch(this.state.image)}> Save <FaRegBookmark className="save-icon" /></span> :
                                                    <span className="save " onClick={()=>this.handleModalOpen(this.state.image,  "1")}> Save <FaRegBookmark /> </span>
                                            }
                                            <span className="save" onClick={() => {this.handleCopyLink(this.state.image)}}> Copy link <FaRegCopy className="save-icon" /></span>
                                        </div>
                                    </div>
                                </div>

                            </Modal.Footer>
                        </form>
                    </Modal>
                    <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem", marginTop:"7rem"}}>
                        <div
                            className={this.state.active ? "col-md-1 col-2  " : "col-md-1 col-2 "}>
                            <LoginModal openLoginModal={this.handleModalOpen}
                                        closeLoginModal={this.handleModalClose}
                                        show={this.state.show} type={this.state.logintype}/>
                            {/* <Sidebar closeSideBar={this.handleClose} openSideBar={this.handleOpen} status={this.state.active} />*/}
                        </div>
                        <div
                            className={this.state.active ? "col-md-9 col-12  narrowcontainer" : "col-md-9 col-12  widecontainer"}>
                            <div className="container-fluid">
                                <div className="row">

                                    <div className=" col-md-11 "><SearchBox images={true}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                <Filters images={true} device={"desktop"}/>
                            </div>
                            <div className="row ">
                                <div className=" col-2 col-md-1 hidden-mob"></div>
                                <div className=" col-12 col-md-11">
                                    <div className="container-fluid mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            <h6>About {
                                                this.state.total > 1000000 ? (this.state.total/1000).toFixed(0) : this.state.total
                                            } results </h6>
                                        </div>
                                        <div>
                                            {
                                                this.state.loading ? <>
                                                        <div className="row">

                                                            {
                                                                [1, 2, 3, 4, 5, 6, 7,9,10,11,12].map((n) => (
                                                                    <div className="col-md-3">
                                                                        <SkeletonImage key={n}/>
                                                                    </div>
                                                                ) )
                                                            }

                                                        </div>

                                                    </> :
                                                    <div className="row">
                                                        {
                                                            this.state.images.map((result, index) => (
                                                                <div className="col-md-3 col-6 images">
                                                                    <img src={result.contentUrl}
                                                                         className="img-thumbnail"
                                                                         onClick={() => this.handleImageModalOpen(result)}/>

                                                                    <a target="_blank" href={result.hostPageUrl}
                                                                       style={{color: "#767676"}}>
                                                                        <h6>{result.name}</h6></a>
                                                                    <a target="_blank" className="image-url"
                                                                       href={result.hostPageUrl}
                                                                       style={{color: "#767676"}}>
                                                                        <h6>{result.hostPageUrl}</h6></a>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-2 col-1 hidden-mob" style={{marginTop: "6.8rem"}}>
                            <div className={this.state.copy ? "alert alert-success position-fixed" : "d-none"}>
                                <FaBookmark/> Link copied successfully
                            </div>
                            <div className={this.state.saved ? "alert alert-success position-fixed" : "d-none"}>
                                <FaBookmark/> Image saved successfully
                            </div>
                            <div
                                className={this.state.exists ? "alert alert-warning position-fixed" : "d-none"}>
                                <FaBookmark/> Image already exists
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <Modal className={"mobile"}  show={this.state.imageshow}  onHide={this.handleImageModalClose}>
                        <form onSubmit={this.handleSaveSearch}>
                            <Modal.Body style={{padding:"0"}} >
                                <div className="close" onClick={this.handleImageModalClose} style={{cursor:"pointer"}}>
                                    <MdClear />
                                </div>
                                <img src={this.state.image.contentUrl} style={{width:"100%"}}/>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="container">
                                    <div
                                        className={this.state.copy ? "alert alert-success " : "d-none"}>
                                        <FaBookmark/> Link copied successfully
                                    </div>
                                    <div
                                        className={this.state.saved ? "alert alert-success  " : "d-none"}>
                                        <FaBookmark/> Image saved successfully
                                    </div>
                                    <div
                                        className={this.state.exists ? "alert alert-warning " : "d-none"}>
                                        <FaBookmark/> Image already exists
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 images">
                                            <a target="_blank" href={this.state.image.hostPageUrl} style={{color:"#00944D"}}><h6 style={{lineHeight:"17px"}}>{this.state.image.name}</h6></a>
                                            <a  target="_blank" className={"mt-4"} href={this.state.image.hostPageUrl} style={{color:"#767676"}}><h6>{this.state.image.hostPageUrl ? this.state.image.hostPageUrl.substr(0,50) : ""}...</h6></a>
                                        </div>
                                        <div className="col-md-4">
                                            {
                                                this.state.token ?  <span className="save" onClick={()=>this.handleSaveSearch(this.state.image)}> Save <FaRegBookmark className="save-icon" /></span> :
                                                    <span className="save " onClick={()=>this.handleModalOpen(this.state.image,  "1")}> Save <FaRegBookmark /> </span>
                                            }
                                            <span className="save" onClick={() => {this.handleCopyLink(this.state.image)}}> Copy link <FaRegCopy className="save-icon" /></span>
                                        </div>
                                        <div className={"col-12"}>
                                            <p style={{color:"#BBBBBB", fontSize:"10px", lineHeight:"12px", marginTop:"0.938rem"}}>Images may be subject to copyright</p>
                                        </div>
                                    </div>
                                </div>

                            </Modal.Footer>
                        </form>
                    </Modal>
                    <div className="row mobile" >
                        <div
                            className={this.state.active ? "col-md-2 col-2 hidden-mob " : "col-md-1 col-2 hidden-mob"}>
                            <LoginModal openLoginModal={this.handleModalOpen}
                                        closeLoginModal={this.handleModalClose}
                                        show={this.state.show} type={this.state.logintype}/>
                            {/* <Sidebar closeSideBar={this.handleClose} openSideBar={this.handleOpen} status={this.state.active} />*/}
                        </div>
                        <div
                            className={this.state.active ? "col-md-10 col-12  narrowcontainer" : "col-md-8 col-12  widecontainer"}>
                            <div className="container-fluid mt-3">
                                <div className="row">
                                    <div className=" col-md-11"><SearchBox images={true} device={"mobile"}/></div>
                                </div>

                            </div>
                            <div className="row p-0">
                                {
                                    this.state.notfound ? " " :
                                        <>
                                            <div className={"row m-0 d-flex align-items-center"}>
                                                <div className={"col-12 p-0"}>
                                                    <Filters device={"mobile"} images={true}/>
                                                </div>
                                            </div>
                                        </>

                                }


                            </div>
                            <div className="row ">
                                <div className=" col-12 col-md-11">
                                    <div className="container-fluid mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            <h6>About {
                                                this.state.total > 1000000 ? (this.state.total/1000).toFixed(0) : this.state.total
                                            } results </h6>
                                        </div>
                                        <div>
                                            {
                                                this.state.loading ? <>
                                                        <div className="row">

                                                            {
                                                                [1, 2, 3, 4, 5, 6, 7,9,10,11,12].map((n) => (
                                                                    <div className="col-md-3">
                                                                        <SkeletonImage key={n}/>
                                                                    </div>
                                                                ) )
                                                            }

                                                        </div>

                                                    </> :
                                                    <div className="row">
                                                        {
                                                            this.state.images.map((result, index) => (
                                                                <div className="col-md-3 col-6 images">
                                                                    <img src={result.contentUrl}
                                                                         className="img-thumbnail"
                                                                         onClick={() => this.handleImageModalOpen(result)}/>

                                                                    <a target="_blank" href={result.hostPageUrl}
                                                                       style={{color: "#333333"}}>
                                                                        <h6>{result.name.substr(0,50)}...</h6></a>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-2 col-1 hidden-mob" style={{marginTop: "6.8rem"}}>
                            <div className={this.state.copy ? "alert alert-success position-fixed" : "d-none"}>
                                <FaBookmark/> Link copied successfully
                            </div>
                            <div className={this.state.saved ? "alert alert-success position-fixed" : "d-none"}>
                                <FaBookmark/> Image saved successfully
                            </div>
                            <div
                                className={this.state.exists ? "alert alert-warning position-fixed" : "d-none"}>
                                <FaBookmark/> Image already exists
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <Modal  show={this.state.imageshow}  onHide={this.handleImageModalClose}>
                        <form onSubmit={this.handleSaveSearch}>
                            <Modal.Body style={{padding:"0"}} >
                                <div className="close" onClick={this.handleImageModalClose} style={{cursor:"pointer", margin:"1rem"}}>
                                    <MdClear />
                                </div>
                                <img src={this.state.image.contentUrl} style={{width:"100%"}}/>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="container">
                                    <div
                                        className={this.state.copy ? "alert alert-success" : "d-none"}>
                                        <FaBookmark/> Link copied successfully
                                    </div>
                                    <div
                                        className={this.state.saved ? "alert alert-success " : "d-none"}>
                                        <FaBookmark/> Image saved successfully
                                    </div>
                                    <div
                                        className={this.state.exists ? "alert alert-warning " : "d-none"}>
                                        <FaBookmark/> Image already exists
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 images">
                                            <a target="_blank" href={this.state.image.hostPageUrl} style={{color:"#767676"}}><h6>{this.state.image.name}</h6></a>
                                            <a  target="_blank" href={this.state.image.hostPageUrl} style={{color:"#767676"}}><h6>{this.state.image.hostPageUrl}</h6></a>
                                        </div>
                                        <div className="col-md-4">
                                            {
                                                this.state.token ?  <span className="save" onClick={()=>this.handleSaveSearch(this.state.image)}> Save <FaRegBookmark className="save-icon" /></span> :
                                                    <span className="save " onClick={()=>this.handleModalOpen(this.state.image,  "1")}> Save <FaRegBookmark /> </span>
                                            }
                                            <span className="save" onClick={() => {this.handleCopyLink(this.state.image)}}> Copy link <FaRegCopy className="save-icon" /></span>
                                        </div>
                                    </div>
                                </div>

                            </Modal.Footer>
                        </form>
                    </Modal>
                    <div className="row tablet" style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                        <div
                            className={this.state.active ? "col-md-1 col-2 " : "col-md-1 col-2"}>
                            <LoginModal openLoginModal={this.handleModalOpen}
                                        closeLoginModal={this.handleModalClose}
                                        show={this.state.show} type={this.state.logintype}/>
                            {/* <Sidebar closeSideBar={this.handleClose} openSideBar={this.handleOpen} status={this.state.active} />*/}
                        </div>
                        <div
                            className={this.state.active ? "col-md-10 col-12  narrowcontainer" : "col-md-10 col-12  widecontainer"}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-md-11"><SearchBox images={true}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                <Filters images={true} device={"tablet"}/>
                            </div>
                            <div className="row ">

                                <div className=" col-12 col-md-12">
                                    <div className="container-fluid mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            <h6>About {
                                                this.state.total > 1000000 ? (this.state.total/1000).toFixed(0) : this.state.total
                                            } results </h6>
                                        </div>
                                        <div>
                                            {
                                                this.state.loading ? <>
                                                        <div className="row">

                                                            {
                                                                [1, 2, 3, 4, 5, 6, 7,9,10,11,12].map((n) => (
                                                                    <div className="col-md-3">
                                                                        <SkeletonImage key={n}/>
                                                                    </div>
                                                                ) )
                                                            }

                                                        </div>

                                                    </> :
                                                    <div className="row" data-masonry='{"percentPosition": true }'>
                                                        {
                                                            this.state.images.map((result, index) => (
                                                                <div className="col-md-4 col-6 images">
                                                                    <img src={result.contentUrl}
                                                                         className="img-thumbnail"
                                                                         onClick={() => this.handleImageModalOpen(result)}/>

                                                                    <a target="_blank" href={result.hostPageUrl}
                                                                       style={{color: "#767676"}}>
                                                                        <h6>{result.name}</h6></a>
                                                                   {/* <a target="_blank" className="image-url"
                                                                       href={result.hostPageUrl}
                                                                       style={{color: "#767676"}}>
                                                                        <h6>{result.hostPageUrl}</h6></a>*/}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-2 col-1 hidden-mob" style={{marginTop: "6.8rem"}}>
                            <div className={this.state.copy ? "alert alert-success position-fixed" : "d-none"}>
                                <FaBookmark/> Link copied successfully
                            </div>
                            <div className={this.state.saved ? "alert alert-success position-fixed" : "d-none"}>
                                <FaBookmark/> Image saved successfully
                            </div>
                            <div
                                className={this.state.exists ? "alert alert-warning position-fixed" : "d-none"}>
                                <FaBookmark/> Image already exists
                            </div>
                        </div>
                    </div>
                </MediaQuery>


            </>
        )
    }
}
export default Images