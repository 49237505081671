import React, { useState} from 'react';
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";
import Header from "../Header";


function CreateOpportunity() {

    const [newOpportunityDetails, setnewOpportunityDetails] = useState({
        volunteer_institution: "",
        url: "",
    });
    const [userToken, setUserToken] = useState(localStorage.getItem('token'))
    const { isLoading, error, isError, sendRequest: createOpportunityHandler } = useHttpFetch();
    


    if (!userToken) {
      
        window.location.assign('/');
    }

    function onChangeHandler(event) {
        setnewOpportunityDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }

    function createOpportunity(data){
        setnewOpportunityDetails({
            volunteer_institution: "",
            url: "",
        })
        window.location.assign('/volunteer_opportunity/all');
    }
    async function submitHandler(event) {
        event.preventDefault();
        const new_opportunity = {
            volunteer_institution: newOpportunityDetails.volunteer_institution,
            url: newOpportunityDetails.url,
        }
        createOpportunityHandler({
            method: 'POST',
            url: buildAPIUrl('v1/volunteering_opportunities/'),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`
            },
            body: new_opportunity,
        }, createOpportunity);

    }


    return (
        <>
           <Header />
            <div className={"container-fluid mt-5"}>
                <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            {isLoading && <p>Loading.....</p>}
                            {isError && <p>{error}</p>}
                            <div className={"card border"}>
                                <div className={"card-header"}>
                                    <h4>Create Volunteer Opportunity</h4>
                                </div>
                                <div className={"card-body"}>
                                    <form onSubmit={submitHandler}>
                                        <div className={"mb-3"}>
                                            <label htmlFor='volunteer_institution'>Institution</label>
                                            <input
                                                type='text'
                                                id='volunteer_institution'
                                                name='volunteer_institution'
                                                value={newOpportunityDetails.volunteer_institution}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />
                                        </div>
                                        
                                        <div className={"mb-3"}>
                                            <label htmlFor='url'>Url</label>
                                            <input
                                                className={"form-control"}
                                                type='url'
                                                id='url'
                                                name='url'
                                                value={newOpportunityDetails.url}
                                                onChange={onChangeHandler}
                                                required
                                            />
                                        </div>
                                        
                                        <button className={"btn btn-success"}>Create Opportunity</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
export default CreateOpportunity;