import React, {useEffect, useState, useCallback, useRef} from "react";
import MediaQuery from "react-responsive";
import Footer from "../Landing/Footer";
import Header from "../Landing/Header";
import {Link} from "react-router-dom";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import bgimg from "../../../assets/Group 90039.png"
import certtemp from"../../../assets/cert_templates.png"
import {HiOutlineDownload, HiOutlineEye} from "react-icons/hi";
import {Dropdown, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import pdf from "../../../assets/Frame 90035.png"
import jpg from "../../../assets/Frame 900351.png"
import png from "../../../assets/Frame 90036.png"
import certImage from"../../../assets/cert-image.png"
import {toJpeg, toPng} from "html-to-image";
import {useReactToPrint} from "react-to-print";
import Ux from "../../../assets/Group 90052.png";
import Ya from "../../../assets/Group 89803.png";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import useHttpFetch from "../../../hooks/use-http-fetch";
import Logo from "../../../assets/rb-beta logo.svg"

export default function UserSettings(){
    const [certificate, setCertificate] = useState([]);
    const [certificate2023, setCertificate2023] = useState([]);
    const [plans, setPlans]=useState([]);
    const [price, setPrice]=useState(0);
    const[priceperiod, setPriceperiod]=useState("")
    const[priceplanid, setPriceplanid]=useState("")
    const[print, setPrint]=useState(false)
    const [fullname, setFullName]=useState("")
    const [refnumber, setRefnumber]=useState("")
    const [showModal, setShowModal]=useState(false)
    const[userDets, setUserDets]=useState([])
    const [succcess, setSuccess]=useState(false)
    const[selectedImage, setSelectedImage]=useState(null)
    const [planID, setPlanID]=useState(0)
    const [cancelSuccess, setCancelSuccess]=useState(false)
    const[showCancelButton, setShowCancelButton]=useState(false)
    const[showCancelModal, setShowCancelModal]=useState(false)
    const [showSuccess, setShowSuccess]=useState(false)
    const [personalDetails, setPersonalDetails] = useState({
        first_name:"",
        last_name:"",
        profile_picture:""
    });
    const { isLoading, error, isError, sendRequest: createBookHandler } = useHttpFetch();
    async function getCertificates () {
        const certificates = await axios.get(buildAPIUrl('v1/ux_certificates/'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        setCertificate(certificates.data);

        const certificates2023 = await axios.get(buildAPIUrl('v1/ux_certificates/?year=YEAR_2023'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        setCertificate2023(certificates2023.data);
        const prices = await axios.get(buildAPIUrl('v1/subscriptions/'))
        setPlans(prices.data)
        setPrice(Math.trunc(prices.data[0].stripe_plan_details.amount / 100))
        setPriceperiod(prices.data[0].stripe_plan_details.interval)
        setPriceplanid(prices.data[0].stripe_plan_details.id)
    }
    const componentref = useRef()
    const handlePrinttoPdf = useReactToPrint({
        content:()=>componentref.current
    })
    async function getCertificateDetails () {
        const getCertificate= await axios.get(buildAPIUrl('v1/ux_certificates/'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        setFullName(getCertificate.data[0].full_name)
        setRefnumber(getCertificate.data[0].ref_number)
    }
    async function getUserDetails(){
        const userDetails = await axios.get(buildAPIUrl(`v1/users/me/`), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
       setPersonalDetails(userDetails.data)
        localStorage.setItem('image', userDetails.data.profile_picture)
        if(userDetails.data.first_name !=null){
            localStorage.setItem('username', userDetails.data.first_name)
        }

    }
    function onChangeHandler(event){
        setPersonalDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }
    function updateProf(data){
        window.location.reload()
    }
    const updateUserDetails = async (e)=>{
        e.preventDefault()
        let formData = new FormData();
        formData.append("first_name", personalDetails.first_name)
        formData.append("last_name", personalDetails.last_name)
        if (selectedImage){
            formData.append('profile_picture', selectedImage);
        }
        createBookHandler({
            method: 'PATCH',
            url: buildAPIUrl('v1/users/me/'),
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: formData,
        }, updateProf, true);

    }
    function onChangeImageHandler(event){
        setPersonalDetails((prevState) => {
            return { ...prevState, profile_picture: event.target.files[0] };
        });
    }
    const generatePDF = async () => {
        await setPrint(true)
        await html2canvas(document.querySelector('#report'),
            {windowWidth: '1280px'}
        ).then(canvas => {
            const pdf = new jsPDF('l', 'mm', 'a4');
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 298, 211);
            pdf.save('report');
        }).then(()=>{
            setPrint(false)
        }).catch((error)=>{

        })
    }
    const getUserSubscription = async ()=>{
        const usersubscription = await axios.get(buildAPIUrl('v1/subscriptions'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        setPlanID(usersubscription.data[1].subscriptions.data[0].plan.id)
    }


    const handlePrintToImage = async () =>{
        await setPrint(true)
        await toPng(componentref.current)
            .then((dataUrl) => {
                console.log("dataUrl")
                console.log(dataUrl)
                const link = document.createElement('a')
                link.download = `${localStorage.getItem('username') + ".png"}`
                link.href = dataUrl
                link.click()
            }).then(()=>{
                setPrint(false)
            }).catch((error)=>{

            })

    }

    const handlePrintToImagejpg = async () =>{
        await setPrint(true)
        await toJpeg(componentref.current)
            .then((dataUrl) => {
                const link = document.createElement('a')
                link.download = `${localStorage.getItem('username') + ".jpg"}`
                link.href = dataUrl
                link.click()
            }).then(()=>{
                setPrint(false)
            }).catch((error)=>{

            })
    }
    const getBadges = async () =>{
        const badge = await axios.get(buildAPIUrl('v1/webinar_badges/member/'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
    }

    //Check if the user has active free trial
    const checkuserpackage = async ()=>{
        const userSubscription = await axios.get(buildAPIUrl('v1/subscriptions'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        if(userSubscription.data.length){
            const lastSub = 0
            if(!userSubscription.data[lastSub].db_is_active){
                setShowCancelButton(true)
            }
        } else {
            if(!userSubscription.data.db_is_active){
                setShowCancelButton(true)
            }
        }

    }
    const handleCancelSubscription = ()=>{
        const canclePlan = axios.delete(buildAPIUrl(`v1/subscriptions/cancel/${planID}/`), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        setShowCancelModal(false)
        setShowSuccess(true)
        setTimeout(()=>{

            checkuserpackage()
        }, 5000)
    }
    useEffect(()=>{
        getCertificateDetails()
        getCertificates()
        getUserDetails()
        getBadges()
        getUserSubscription()
        checkuserpackage()

    }, [])
    const  handlePayment = () =>{
        if(localStorage.getItem('token')){
            if(priceperiod==="year"){
                localStorage.setItem('pricechoice', "year")
                localStorage.setItem('priceid', priceplanid)
                localStorage.setItem('priceamount', price)

            } else {
                localStorage.setItem('pricechoice', "month")
                localStorage.setItem('priceid', priceplanid)
                localStorage.setItem('priceamount', price)
            }
            window.location.assign('/checkout')
        } else {
            this.handleLoginModalOpen("1")
        }
    }
    const closeprofilemodal = () => {
        setShowModal(false)
    }
    const showprofilemodal = ()=>{
        setShowModal(true)
    }
    //show cancel subscription modal
    const showCancelSubscription = ()=>{
        // setShowCancelModal(true)
    }
    const hideCancelSubscription = ()=>{
        setShowCancelModal(false)
    }
    const  handleCloseSuccess = ()=>{
        setShowSuccess(false)
        window.location.reload()
    }
    return (
        <>
            <Header />
            <Modal size="lg" className={"cancel-modal"} centered show={showCancelModal} onHide={hideCancelSubscription} >
                <Modal.Body  style={{overflow:"hidden", borderRadius:"20px", padding:"2rem"}}>
                    <div className={"card"}>
                        <div className={"container"}>
                            <img src={Logo} style={{width:"130px"}}/>
                            <h6 style={{fontWeight:"600", fontSize:"24px", textAlign:"center", marginTop:"2rem"}}>
                                Are you sure you want to cancel your subscription?
                            </h6>
                            <p style={{fontWeight:"400", fontSize:"19px", textAlign:"center", marginTop:"2.688rem", textDecoration:"none", }}>
                                If you proceed, your access to the platform will be terminated. If you have any issues or concerns, please contact our support team."
                            </p>
                            <div className={"row"} style={{marginTop:"5.063rem"}}>
                                <div className={"col-md-6"}>
                                    <button className={"btn btn-light"} onClick={hideCancelSubscription} style={{marginTop:"0.625rem", background:"#130071", color:"white", width:"18.438rem"}}>No. I’ve changed my mind</button>
                                </div>
                                <div className={"col-md-6 d-flex justify-content-end"}>
                                    <button onClick={handleCancelSubscription} className={"btn"} style={{marginTop:"0.625rem", background:"#130071", color:"white", width:"18.438rem"}}>Yes. I’m sure</button>
                                </div>
                            </div>

                        </div>
                    </div>


                </Modal.Body>
            </Modal>
            <Modal size="lg" className={"cancel-modal"} centered show={showSuccess} onHide={handleCloseSuccess} >
                <Modal.Body  style={{overflow:"hidden", borderRadius:"20px", padding:"2rem"}}>
                    <div className={"card d-flex align-items-center"}>
                        <div className={"container"}>
                            <img src={Logo} style={{width:"130px"}}/>

                            <p style={{fontWeight:"400", fontSize:"19px", textAlign:"center", marginTop:"2.688rem", textDecoration:"none", }}>
                                "Thank you for your request. Your subscription has been cancelled, and your access to the platform will end at the end of your trial period."
                            </p>
                            <div className={"row"} style={{marginTop:"5.063rem"}}>

                                <div className={"col-md-12 d-flex justify-content-end"}>
                                    <button onClick={handleCloseSuccess} className={"btn"} style={{marginTop:"0.625rem", background:"#130071", color:"white"}}>Close</button>
                                </div>
                            </div>

                        </div>
                    </div>


                </Modal.Body>
            </Modal>
            <MediaQuery minWidth={1200}>
                <Modal size="lg" className={"user-settings-modal"} centered show={showModal} onHide={closeprofilemodal} >
                    <form onSubmit={updateUserDetails}>
                    <Modal.Header>
                        Edit Profile

                    </Modal.Header>
                    <Modal.Body  style={{overflow:"hidden", borderRadius:"20px", paddingLeft:"2.5rem", paddingRight:"2.5rem"}}>

                           <div className={"card"}>
                               {
                                   succcess ? <>
                                       <div className={"alert alert-success"}>
                                           Profile updated successfully
                                       </div>
                                   </> : <></>
                               }
                               <div className={"row"} style={{marginTop:"1.875rem"}}>
                                   <div className={"col-md-3"}>
                                       <div className={"avatar-holder"}>
                                           <img src={localStorage.getItem('image')}/>
                                       </div>
                                   </div>
                                   <div className={"col-md-9"}>
                                       <p style={{textAlign:"left", textDecoration:"none"}}>
                                           You can upload images up to 256x256. Your avatar shows up in your public profile and your team notifications.
                                       </p>
                                       <input onChange={(event) => {
                                           setSelectedImage(event.target.files[0]);
                                       }} type="file"
                                              id='image'
                                              name='profile_picture' className={"btn avatar-upload"} />
                                   </div>
                               </div>
                               <div className={"row"} style={{marginTop:"1.875rem"}}>
                                   <div className={"col-md-6"}>
                                       <label style={{color:"#767676", fontSize:"16px", fontWeight:"500"}}>First Name</label>
                                       <input type={"text"} onChange={onChangeHandler}  name={"first_name"} value={personalDetails.first_name} className={"form-control"} style={{height:"55px"}}/>
                                   </div>
                                   <div className={"col-md-6"}>
                                       <label style={{color:"#767676", fontSize:"16px", fontWeight:"500"}}>Last Name</label>
                                       <input type={"text"} onChange={onChangeHandler}  name={"last_name"} value={personalDetails.last_name} className={"form-control"} style={{height:"55px"}}/>
                                   </div>
                                   {/*<div className={"col-md-12"} style={{marginTop:"0.813rem"}}>
                                    <label style={{color:"#767676", fontSize:"16px", fontWeight:"500"}}>Email</label>
                                    <input type={"text"} onChange={onChangeHandler}  name={"first_name"} value={userDets.last_name} className={"form-control"} style={{height:"55px"}}/>
                                </div>*/}
                               </div>
                           </div>


                    </Modal.Body>
                    <Modal.Footer style={{marginTop:"2.5rem"}}>
                        <div className={"container d-flex justify-content-end"}>
                            <button className={"btn"} style={{background:"#522FFF", color:"white", marginTop:"0.625rem", borderRadius:"200px"}}>Save</button>
                        </div>
                    </Modal.Footer>
                    </form>
                </Modal>

                <div className={"row d-flex justify-content-center"} style={{marginTop:"7rem",}}>
                    <div className={"col-md-8"}>
                        <div className={"container"} style={{ marginBottom:"2rem"}}>
                            <div className={"row d-flex align-items-center"}>
                                <div className={"col-md-8"}>
                                    <h1 style={{fontWeight:"600", fontSize:"40px"}}>User Profile</h1>
                                </div>
                                <div className={"col-md-4 d-flex justify-content-end"}>
                                    <a target={"_blank"} href={`https://billing.stripe.com/p/login/4gwbKq0Q98Ds0jCfYY?prefilled_email=${localStorage.getItem('email')}`} onClick={showCancelSubscription} className={"btn btn-secondary"} style={{height:"40px"}}>
                                        Manage Subscription
                                    </a>


                                </div>
                            </div>

                            <div className={"container p-0 mt-3"} style={{ border:"0.5px solid #BCAEFF", background:"#FBFBFF", borderRadius:"10px"}}>

                                <div className={"container-fluid p-0"} style={{borderRadius:"10px 10px 0px 0px", background:"#BAACFF", zIndex:"-1000"}}>
                                    <div className={"container-fluid p-0"}>
                                        <img src={bgimg} style={{zIndex:"-1000", width:"100%"}} />
                                    </div>
                                </div>
                                <div className={"container-fluid p-0 border-bottom"}>
                                    <div className={"container position-relative"} style={{zIndex:"1000", paddingLeft:"3.125rem"}}>
                                        <div className={"row"}>
                                            <div className={"col-md-6"}>
                                                <div className={"avatar-holder-container"}>
                                                    <div className={"avatar-holder"}>
                                                        <img src={localStorage.getItem('image')}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-md-6 d-flex justify-content-end"}>
                                                <button type={"submit"} onClick={showprofilemodal} className={"btn"} style={{background:"#522FFF", color:"white", marginTop:"1.75rem", borderRadius:"200px"}}>Edit Profile</button>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={"container"} style={{marginTop:"1.3rem", marginBottom:"2rem", paddingLeft:"3.125rem"}} >
                                        <h2>
                                            {localStorage.getItem('username')}
                                        </h2>
                                        <h6>
                                            {localStorage.getItem('email')}
                                        </h6>
                                    </div>
                                </div>
                                <div className={"container-fluid p-0 border-bottom"}>
                                    <div className={"container"} style={{marginTop:"1.3rem", paddingLeft:"3.125rem", marginBottom:"2rem"}} >
                                        <h6 style={{marginTop:"2.5rem", color:"#522FFF", fontSize:'18px', }}>
                                            Your certificates
                                        </h6>

                                               <div className={"row p-0"} style={{marginTop:"1.75rem"}}>
                                                   {
                                                       certificate.length > 0 ? <>
                                                   <div className={"col-md-4"}>

                                                       <div className={"cert-box"}>
                                                           <img src={certtemp} style={{width:"100%"}}/>
                                                           <h6>
                                                               UX Research Skillshop | December 2022 Edition
                                                           </h6>
                                                           <div className={"row"} style={{marginTop:"1.75rem"}}>
                                                               <div className={"col-md-5"}>
                                                                   <Link to={"/skillshop-2022"} style={{textDecoration:"none"}}>
                                                                       <h6 ><HiOutlineEye /> <span style={{fontWeight:"400", color:"#00944D"}}>Preview</span></h6>
                                                                   </Link>
                                                               </div>
                                                               <div className={"col-md-7 position-relative"}>
                                                                   {/* <Dropdown>
                                                                       <DropdownToggle style={{background:"white", width:"100%", border:"none"}}>
                                                                           <h6><HiOutlineDownload /> <span style={{fontWeight:"400", color:"#00944D"}}>Download</span></h6>
                                                                       </DropdownToggle>

                                                                       <Dropdown.Menu>
                                                                           <Dropdown.Item>
                                                                               <button onClick={generatePDF} className={"btn"} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                                   <img  src={pdf}/>
                                                                               </button>

                                                                           </Dropdown.Item>
                                                                           <Dropdown.Item >
                                                                               <button className={"btn"} onClick={handlePrintToImagejpg} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                                   <img src={jpg}/>
                                                                               </button>
                                                                           </Dropdown.Item>
                                                                           <Dropdown.Item>
                                                                               <button className={"btn"} onClick={handlePrintToImage} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                                   <img  src={png}/>
                                                                               </button>

                                                                           </Dropdown.Item>
                                                                       </Dropdown.Menu>
                                                                   </Dropdown> */}

                                                               </div>
                                                           </div>

                                                       </div>


                                                   </div>
                                                       </>
                                                           : <>
                                                               <p>No certificates found for December 2022 Edition</p>
                                                           </>
                                                   }

{
                                                       certificate2023.length > 0 ? <>
                                                   <div className={"col-md-4"}>

                                                       <div className={"cert-box"}>
                                                           <img src={certImage} style={{width:"100%", height:"180px"}}/>
                                                           <h6>
                                                               UX Research Skillshop | April 2023 Edition
                                                           </h6>
                                                           <div className={"row"} style={{marginTop:"1.75rem"}}>
                                                               <div className={"col-md-5"}>
                                                                   <Link to={"/skillshop-2023"} style={{textDecoration:"none"}}>
                                                                       <h6 ><HiOutlineEye /> <span style={{fontWeight:"400", color:"#00944D"}}>Preview</span></h6>
                                                                   </Link>
                                                               </div>
                                                               <div className={"col-md-7 position-relative"}>
                                                                   {/* <Dropdown>
                                                                       <DropdownToggle style={{background:"white", width:"100%", border:"none"}}>
                                                                           <h6><HiOutlineDownload /> <span style={{fontWeight:"400", color:"#00944D"}}>Download</span></h6>
                                                                       </DropdownToggle>

                                                                       <Dropdown.Menu>
                                                                           <Dropdown.Item>
                                                                               <button onClick={generatePDF} className={"btn"} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                                   <img  src={pdf}/>
                                                                               </button>

                                                                           </Dropdown.Item>
                                                                           <Dropdown.Item >
                                                                               <button className={"btn"} onClick={handlePrintToImagejpg} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                                   <img src={jpg}/>
                                                                               </button>
                                                                           </Dropdown.Item>
                                                                           <Dropdown.Item>
                                                                               <button className={"btn"} onClick={handlePrintToImage} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                                   <img  src={png}/>
                                                                               </button>

                                                                           </Dropdown.Item>
                                                                       </Dropdown.Menu>
                                                                   </Dropdown> */}

                                                               </div>
                                                           </div>

                                                       </div>


                                                   </div>
                                                       </>
                                                           : <>
                                                               <p>No certificates found for 2023 Edition</p>
                                                           </>
                                                   }

                                               </div>


                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>

            </MediaQuery>
            <MediaQuery maxWidth={460}>
                <div className={"mobile"}>
                    <div className={"container"}>
                        <Modal centered show={showModal} onHide={closeprofilemodal} >
                            <form onSubmit={updateUserDetails}>
                            <Modal.Header>
                                Edit Profile
                            </Modal.Header>
                            <Modal.Body  style={{overflow:"hidden", borderRadius:"20px", paddingLeft:"2.5rem", paddingRight:"2.5rem"}}>
                                <div className={"card"}>
                                    <div className={"row"} style={{marginTop:"1.875rem"}}>
                                        <div className={"col-md-3"}>
                                            <div className={"avatar-holder-mobile"}>
                                                <img src={localStorage.getItem('image')}/>
                                            </div>
                                        </div>
                                        <div className={"col-md-9"}>
                                            <p style={{textAlign:"left", textDecoration:"none"}}>
                                                You can upload images up to 256x256. Your avatar shows up in your public profile and your team notifications.
                                            </p>
                                            <input onChange={(event) => {
                                                setSelectedImage(event.target.files[0]);
                                            }} type="file"
                                                   id='image'
                                                   name='profile_picture' className={"btn avatar-upload"}  />
                                        </div>
                                    </div>
                                    <div className={"row"} style={{marginTop:"1.875rem"}}>
                                        <div className={"col-md-6"}>
                                            <label style={{color:"#767676", fontSize:"16px", fontWeight:"500"}}>First Name</label>
                                            <input onChange={onChangeHandler}  name={"first_name"} value={personalDetails.first_name} type={"text"} className={"form-control"} style={{height:"55px"}}/>
                                        </div>
                                        <div className={"col-md-6"} style={{marginTop:"0.813rem"}}>
                                            <label style={{color:"#767676", fontSize:"16px", fontWeight:"500"}}>Last Name</label>
                                            <input onChange={onChangeHandler}  name={"last_name"} value={personalDetails.last_name} type={"text"} className={"form-control"} style={{height:"55px"}}/>
                                        </div>
                                        <div className={"col-md-12"} style={{marginTop:"0.813rem"}}>
                                            <label style={{color:"#767676", fontSize:"16px", fontWeight:"500"}}>Email</label>
                                            <input type={"text"} className={"form-control"} style={{height:"55px"}}/>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer style={{marginTop:"2.5rem"}}>
                                <div className={"container d-flex justify-content-end"}>
                                    <button onClick={showprofilemodal} className={"btn"} style={{background:"#522FFF", color:"white", marginTop:"0.625rem", borderRadius:"200px"}}>Save</button>
                                </div>
                            </Modal.Footer>
                            </form>
                        </Modal>
                    </div>
                    <div className={"container "} style={{marginTop:"2rem", marginBottom:"2rem"}}>
                        <h1 style={{fontWeight:"600", fontSize:"40px", lineHeight:"50px"}}>User Profile</h1>
                        <a target={"_blank"} href={`https://billing.stripe.com/p/login/4gwbKq0Q98Ds0jCfYY?prefilled_email=${localStorage.getItem('email')}`} onClick={showCancelSubscription} className={"btn btn-secondary"} style={{height:"40px"}}>
                            Manage Subscription
                        </a>
                        <div className={"container p-0"} style={{ border:"0.5px solid #BCAEFF", background:"#FBFBFF", borderRadius:"10px"}}>

                            <div className={"container-fluid p-0"} style={{borderRadius:"10px 10px 0px 0px", background:"#BAACFF", zIndex:"-1000"}}>
                                <div className={"container-fluid p-0"}>
                                    <img src={bgimg} style={{zIndex:"-1000", width:"100%"}} />
                                </div>
                            </div>
                            <div className={"container-fluid p-0 border-bottom"}>
                                <div className={"container position-relative"} style={{ paddingLeft:"1rem", paddingRight:"1rem"}}>
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <div className={"avatar-holder-container"}>
                                                <div className={"avatar-holder"}>
                                                    <img src={localStorage.getItem('image')}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-md-6 d-flex justify-content-end"}>
                                            <button onClick={showprofilemodal} className={"btn"} style={{background:"#522FFF", color:"white", marginTop:"1.75rem", borderRadius:"200px"}}>Edit Profile</button>
                                        </div>
                                    </div>

                                </div>

                                <div className={"container"} style={{marginTop:"1.3rem", marginBottom:"2rem",  paddingLeft:"1rem", paddingRight:"1rem"}} >
                                    <h2>
                                        {localStorage.getItem('username')}
                                    </h2>
                                    <h6>
                                        {localStorage.getItem('email')}
                                    </h6>
                                </div>
                            </div>
                            <div className={"container-fluid p-0 border-bottom"}>
                                <div className={"container"} style={{marginTop:"1.3rem",  paddingLeft:"1rem", paddingRight:"1rem", marginBottom:"2rem"}} >
                                    <h6 style={{marginTop:"2.5rem", color:"#522FFF", fontSize:'18px', }}>
                                        Your certificates
                                    </h6>
                                    {
                                        certificate.length > 0 ? <>
                                            <div className={"row p-0"} style={{marginTop:"1.75rem"}}>
                                                <div className={"col-md-3"}>

                                                    <div className={"cert-box"}>
                                                        <img src={certtemp} style={{width:"100%"}}/>
                                                        <h6>
                                                            UX Research Skillshop | December 2022 Edition
                                                        </h6>
                                                        <div className={"row"} style={{marginTop:"1.75rem"}}>

                                                            <div className={"col-6 position-relative"}>
                                                            
                                                                   <Link to={"/skillshop-2022"} style={{textDecoration:"none"}}>
                                                                       <h6 ><HiOutlineEye /> <span style={{fontWeight:"400", color:"#00944D"}}>Preview</span></h6>
                                                                   </Link>
                                                         

                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                        </> : <>
                                            <p> No certificates found for December 2022 Edition </p>
                                        </>
                                    }

{
                                        certificate2023.length > 0 ? <>
                                            <div className={"row p-0"} style={{marginTop:"1.75rem"}}>
                                                <div className={"col-md-3"}>

                                                    <div className={"cert-box"}>
                                                        <img src={certtemp} style={{width:"100%"}}/>
                                                        <h6>
                                                            UX Research Skillshop | April 2023 Edition
                                                        </h6>
                                                        <div className={"row"} style={{marginTop:"1.75rem"}}>

                                                            <div className={"col-6 position-relative"}>
                                                                    <Link to={"/skillshop-2023"} style={{textDecoration:"none"}}>
                                                                       <h6 ><HiOutlineEye /> <span style={{fontWeight:"400", color:"#00944D"}}>Preview</span></h6>
                                                                   </Link>
                                                         

                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>
                                            </div>
                                        </> : <>
                                            <p> No certificates found for April 2023 Edition </p>
                                        </>
                                    }







                                </div>
                            </div>

                        </div>


                    </div>
                </div>

            </MediaQuery>
            <MediaQuery maxWidth={1200} minWidth={461}>
                <Modal size="lg" centered show={showModal} onHide={closeprofilemodal} >
                    <form onSubmit={updateUserDetails}>
                    <Modal.Header>
                        Edit Profile
                    </Modal.Header>
                    <Modal.Body  style={{overflow:"hidden", borderRadius:"20px", paddingLeft:"2.5rem", paddingRight:"2.5rem"}}>
                        <div className={"card"}>
                            <div className={"row"} style={{marginTop:"1.875rem"}}>
                                <div className={"col-md-4"}>
                                    <div className={"avatar-holder"}>
                                        <img src={localStorage.getItem('image')}/>
                                    </div>
                                </div>
                                <div className={"col-md-8"}>
                                    <p style={{textAlign:"left", textDecoration:"none"}}>
                                        You can upload images up to 256x256. Your avatar shows up in your public profile and your team notifications.
                                    </p>
                                    <input onChange={(event) => {
                                        setSelectedImage(event.target.files[0]);
                                    }} type="file"
                                                   id='image'
                                                   name='profile_picture' className={"btn avatar-upload"} />
                                </div>
                            </div>
                            <div className={"row"} style={{marginTop:"1.875rem"}}>
                                <div className={"col-md-6"}>
                                    <label style={{color:"#767676", fontSize:"16px", fontWeight:"500"}}>First Name</label>
                                    <input onChange={onChangeHandler}  name={"first_name"} value={personalDetails.first_name}type={"text"} className={"form-control"} style={{height:"55px"}}/>
                                </div>
                                <div className={"col-md-6"}>
                                    <label style={{color:"#767676", fontSize:"16px", fontWeight:"500"}}>Last Name</label>
                                    <input onChange={onChangeHandler}  name={"last_name"} value={personalDetails.last_name} type={"text"} className={"form-control"} style={{height:"55px"}}/>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{marginTop:"2.5rem"}}>
                        <div className={"container d-flex justify-content-end"}>
                            <button onClick={showprofilemodal} className={"btn"} style={{background:"#522FFF", color:"white", marginTop:"0.625rem", borderRadius:"200px"}}>Save</button>
                        </div>
                    </Modal.Footer>
                    </form>
                </Modal>
                <div className={"container"} style={{marginTop:"2rem", marginBottom:"2rem"}}>
                    <div className={"row d-flex align-items-center"}>
                        <div className={"col-md-8"}>
                            <h1 style={{fontWeight:"600", fontSize:"40px"}}>User Profile</h1>
                        </div>
                        <div className={"col-md-4 d-flex justify-content-end"}>
                            <a target={"_blank"} href={`https://billing.stripe.com/p/login/4gwbKq0Q98Ds0jCfYY?prefilled_email=${localStorage.getItem('email')}`} onClick={showCancelSubscription} className={"btn btn-secondary"} style={{height:"40px"}}>
                                Manage Subscription
                            </a>


                        </div>
                    </div>
                    <div className={"container p-0"} style={{ border:"0.5px solid #BCAEFF", background:"#FBFBFF", borderRadius:"10px", marginTop:"2.125rem"}}>

                        <div className={"container-fluid p-0"} style={{borderRadius:"10px 10px 0px 0px", background:"#BAACFF", zIndex:"-1000"}}>
                            <div className={"container-fluid p-0"}>
                                <img src={bgimg} style={{zIndex:"-1000", width:"100%"}} />
                            </div>
                        </div>
                        <div className={"container-fluid p-0 border-bottom"}>
                            <div className={"container position-relative"} style={{ paddingLeft:"3.125rem"}}>
                                <div className={"row"}>
                                    <div className={"col-md-6"}>
                                        <div className={"avatar-holder-container"}>
                                            <div className={"avatar-holder"}>
                                                <img src={localStorage.getItem('image')}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 d-flex justify-content-end"}>
                                        <button onClick={showprofilemodal} className={"btn"} style={{background:"#522FFF", color:"white", marginTop:"1.75rem", borderRadius:"200px"}}>Edit Profile</button>
                                    </div>
                                </div>

                            </div>

                            <div className={"container"} style={{marginTop:"1.3rem", marginBottom:"2rem", paddingLeft:"3.125rem"}} >
                                <h2>
                                    {localStorage.getItem('username')}
                                </h2>
                                <h6>
                                    {localStorage.getItem('email')}
                                </h6>
                            </div>
                        </div>
                        <div className={"container-fluid p-0 border-bottom"}>
                            <div className={"container"} style={{marginTop:"1.3rem", paddingLeft:"3.125rem", marginBottom:"2rem"}} >
                                <h6 style={{marginTop:"2.5rem", color:"#522FFF", fontSize:'18px', }}>
                                    Your certificates
                                </h6>
                                {
                                    certificate.length > 0 ? <>
                                        <div className={"row p-0"} style={{marginTop:"1.75rem"}}>
                                            <div className={"col-md-6"}>

                                                <div className={"cert-box"}>
                                                    <img src={certtemp} style={{width:"100%"}}/>
                                                    <h6>
                                                        UX Research Skillshop | December 2022 Edition
                                                    </h6>
                                                    <div className={"row"} style={{marginTop:"1.75rem"}}>
                                                        <div className={"col-md-7 position-relative"}>
                                                            <Dropdown>
                                                                <DropdownToggle style={{background:"white", width:"100%", border:"none", textAlign:"left"}}>
                                                                    <h6><HiOutlineDownload /> <span style={{fontWeight:"400", color:"#00944D"}}>Download</span></h6>
                                                                </DropdownToggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item>
                                                                        <button onClick={generatePDF} className={"btn"} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                            <img  src={pdf}/>
                                                                        </button>

                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item >
                                                                        <button className={"btn"} onClick={handlePrintToImagejpg} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                            <img src={jpg}/>
                                                                        </button>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item>
                                                                        <button className={"btn"} onClick={handlePrintToImage} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                            <img  src={png}/>
                                                                        </button>

                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </> : <>
                                        <p> No certificates found for December 2022 Edition</p>
                                    </>
                                }

{
                                    certificate2023.length > 0 ? <>
                                        <div className={"row p-0"} style={{marginTop:"1.75rem"}}>
                                            <div className={"col-md-6"}>

                                                <div className={"cert-box"}>
                                                    <img src={certtemp} style={{width:"100%"}}/>
                                                    <h6>
                                                        UX Research Skillshop | April 2023 Edition
                                                    </h6>
                                                    <div className={"row"} style={{marginTop:"1.75rem"}}>
                                                        <div className={"col-md-7 position-relative"}>
                                                            <Dropdown>
                                                                <DropdownToggle style={{background:"white", width:"100%", border:"none", textAlign:"left"}}>
                                                                    <h6><HiOutlineDownload /> <span style={{fontWeight:"400", color:"#00944D"}}>Download</span></h6>
                                                                </DropdownToggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item>
                                                                        <button onClick={generatePDF} className={"btn"} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                            <img  src={pdf}/>
                                                                        </button>

                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item >
                                                                        <button className={"btn"} onClick={handlePrintToImagejpg} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                            <img src={jpg}/>
                                                                        </button>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item>
                                                                        <button className={"btn"} onClick={handlePrintToImage} style={{width:"100%", border:"none", background:"white", textAlign:"left"}}>
                                                                            <img  src={png}/>
                                                                        </button>

                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>

                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </> : <>
                                        <p> No certificates found for April 2023 Edition</p>
                                    </>
                                }




                            </div>
                        </div>

                    </div>

                </div>
            </MediaQuery>
            <div className={print ? "" : "d-none"}>
                <div className={"row d-flex justify-content-lg-center" } id={"report"}  ref={componentref} style={{width:"1280px"}} >
                    <div className={"certificate col-md-12 "}>
                        <div className={"certificate-info"}>
                            <h3>
                                Certificate awarded to
                            </h3>
                            <h2>
                                {fullname}
                            </h2>
                            <hr />
                            <h5>Reference No: {refnumber}</h5>
                            <h5 style={{marginTop:"3.375rem"}}>
                                For successfully completing
                            </h5>
                            <img src={Ux} style={{width:"40%", marginTop:"1.313rem"}}/>

                            <div className={"row"}>
                                <div className={"col-md-9 col-9"}>
                                    <img src={Ya} style={{marginTop:"4.063rem", width:"60%"}}/>
                                </div>
                                <div className={"col-md-3 col-3 d-flex justify-content-end"}>
                                    <QRCode value={`https://researchbookmark.io/certificate-verification/${refnumber}`}  renderAs="canvas" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}