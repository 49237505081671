import React, { useState, useEffect} from 'react';
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";
import Header from "../Header";
import {useParams} from "react-router-dom";


function EditCourse() {

    const params = useParams();

    const [newCourseDetails, setnewCourseDetails] = useState({
        name: "",
        price: "",
        payment_plan: "",
        url: "",
        currency: "",
    });
    const [listOfCurrencies, setlistOfCurrencies] = useState([]);
    
    const [userToken, setUserToken] = useState(localStorage.getItem('token'))
    const { isLoading, error, isError, sendRequest: courseRequestHandler } = useHttpFetch();
    


    if (!userToken) {
      
        window.location.assign('/');
    }

    function onChangeHandler(event) {
        setnewCourseDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }

    useEffect(() => {
        const setCurrencies = (data) => {
            setlistOfCurrencies(data);
        }

        const setCourseDetails=(data)=>{
            setnewCourseDetails(data)
        }
    
        courseRequestHandler(
          {
            url: buildAPIUrl('v1/currencies/'),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`
            }
          },
          setCurrencies
        );

        courseRequestHandler(
            {
              url: buildAPIUrl(`v1/ux_research_courses/${params.course_id}`),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`
              }
            },
            setCourseDetails
          );

       
      }, [courseRequestHandler, userToken, params.course_id]);


      


    function editBook(data){
        setnewCourseDetails({
            name: "",
            price: "",
            payment_plan: "",
            url: "",
            currency: "",
        })
        window.location.assign('/courses/all');
    }
    async function submitHandler(event) {
        event.preventDefault();

        let formData = new FormData();
        console.log("newCourseDetails.currency");
        console.log(newCourseDetails.currency)
        formData.append("name", newCourseDetails.name);
        formData.append('price', newCourseDetails.price);
        formData.append('payment_plan', newCourseDetails.payment_plan);
        formData.append('url', newCourseDetails.url);
        
        if(newCourseDetails.currency !== ""){
            formData.append('currency', newCourseDetails.currency);
        }
        
        
        courseRequestHandler({
            method: 'PATCH',
            url: buildAPIUrl(`v1/ux_research_courses/${params.course_id}/`),
            headers: {
                Authorization: `Bearer ${userToken}`
            },
            body: formData,
        }, editBook, true);

    }


    let content = <option value="NONE">------</option>;
    console.log(listOfCurrencies)
    if (listOfCurrencies.length > 0) {
        
        content = listOfCurrencies.map((currency) => (

            <option key={currency.id} value={currency.id}>{currency.code}</option>
        ));
    }

    return (
        <>
           <Header />
            <div className={"container-fluid mt-5"}>
                <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            {isLoading && <p>Loading.....</p>}
                            {isError && <p>{error}</p>}
                            <div className={"card border"}>
                                <div className={"card-header"}>
                                    <h4>Edit Course</h4>
                                </div>
                                <div className={"card-body"}>
                                    <form onSubmit={submitHandler}>
                                        <div className={"mb-3"}>
                                            <label htmlFor='name'>Name</label>
                                            <input
                                                type='text'
                                                id='name'
                                                name='name'
                                                value={newCourseDetails.name}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />
                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='price'>Price</label>
                                            <input
                                                type='text'
                                                id='price'
                                                name='price'
                                                value={newCourseDetails.price}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />
                                        </div>
                                        
                                        <div className={"mb-3"}>
                                            <label htmlFor='url'>Url</label>
                                            <input
                                                className={"form-control"}
                                                type='url'
                                                id='url'
                                                name='url'
                                                value={newCourseDetails.url}
                                                onChange={onChangeHandler}
                                                required
                                            />
                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor="payment_plan">Payment Plan:</label>
                                            <select className={"form-control"} name="payment_plan" id="payment_plan" 
                                            value={newCourseDetails.payment_plan} onChange={event => onChangeHandler(event)}
                                            data-testid="MySelectField"
                                            >
                                                <option value="">----</option>
                                                <option value="NONE">NONE</option>
                                                <option value="MONTHLY">MONTHLY</option>
                                                <option value="WEEKLY">WEEKLY</option>
                                                <option value="YEARLY">YEARLY</option>
                                            </select>

                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor="currency">Currency:</label>
                                            <select className={"form-control"} name="currency" id="currency" value={newCourseDetails.currency} onChange={event => onChangeHandler(event)}>
                                                <option value="">----</option>
                                                {content}
                                            </select>

                                        </div>
                                        
                                        
                                        <button className={"btn btn-success"}>Edit Course</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
export default EditCourse;