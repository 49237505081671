/*
    Show onboarding introduction modal.
    The user is a first time visitor if firstvisit is equal to zero
*/
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import mixpanel from "mixpanel-browser";
import mixPanelApi from "../../../../Services/ApiService";

export function showOnBoardingModal(visit){
    if(!localStorage.getItem('firstvisit')){
        return true
    }
}

/*---Get suggestions when the user is typing in the searchbox-----*/
export function getSuggestions(e,token, successCallBack, errorCallBack){
    if(e.target.value.length > 0){
        axios.get(`https://api.bing.microsoft.com/v7.0/suggestions?q=${e.target.value}  ux`, {
            headers:{
                "Ocp-Apim-Subscription-Key":"823ca4d0626b4634818ab9e09081ffff",
                "token":token
            }}).then((res)=>{
            if(successCallBack != null){
                successCallBack(res);
            }
        }).catch((error)=>{
            if(errorCallBack != null){
                errorCallBack(error);
            }
        })
    } else {
        axios.get(`https://api.bing.microsoft.com/v7.0/suggestions?q=`, {
            headers:{
                "Ocp-Apim-Subscription-Key":"823ca4d0626b4634818ab9e09081ffff",
                "token":token
            }}).then((res)=>{
            if(successCallBack != null){
                successCallBack(res);
            }
        }).catch((error)=>{
            if(errorCallBack != null){
                errorCallBack(error);
            }
        })
    }


}
/*---Get suggestions when the user is typing in the searchbox-----*/
export function getSuggestionsAdmin(e,token, successCallBack, errorCallBack){
    if(e.target.value.length > 0){
        axios.get(`https://api.bing.microsoft.com/v7.0/suggestions?q=${e.target.value}`, {
            headers:{
                "Ocp-Apim-Subscription-Key":"823ca4d0626b4634818ab9e09081ffff",
                "token":token
            }}).then((res)=>{
            if(successCallBack != null){
                successCallBack(res);
            }
        }).catch((error)=>{
            if(errorCallBack != null){
                errorCallBack(error);
            }
        })
    } else {
        axios.get(`https://api.bing.microsoft.com/v7.0/suggestions?q=`, {
            headers:{
                "Ocp-Apim-Subscription-Key":"823ca4d0626b4634818ab9e09081ffff",
                "token":token
            }}).then((res)=>{
            if(successCallBack != null){
                successCallBack(res);
            }
        }).catch((error)=>{
            if(errorCallBack != null){
                errorCallBack(error);
            }
        })
    }


}
/*-----Search function. User enters a search term and presses enter. */
export function handleSearch ( query){
    axios.post(buildAPIUrl(`v1/save_search_queries/`), {
        query_search: query,
    }).then((res) => {
        localStorage.setItem('searchquery', query)
        window.location.assign(`/all-results?q=${query}`)
    });
}

export function EventsHandler(eventtype,url,timespent, itemname){
    if (timespent){
        if (localStorage.getItem('token')){
            mixpanel.init(mixPanelApi());
            mixpanel.track(eventtype, {
                "userid": localStorage.getItem('email'),
                "url": url,
                "timespent":timespent,
                "itemname":itemname
            });
        }
        else {
            mixpanel.init(mixPanelApi());
            mixpanel.track(eventtype, {
                "userid": "not logged in",
                "url": url,
                "timespent":timespent,
                "itemname":itemname
            });
        }
    } else {
        if (localStorage.getItem('token')){
            mixpanel.init(mixPanelApi());
            mixpanel.track(eventtype, {
                "userid": localStorage.getItem('email'),
                "url": url,
                "itemname":itemname
            });
        }
        else {
            mixpanel.init(mixPanelApi());
            mixpanel.track(eventtype, {
                "userid": "not logged in",
                "url": url,
                "itemname":itemname
            });
        }
    }

}
