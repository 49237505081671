import React, {useEffect, useState} from "react";
import Header from "../../Landing/Header";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import {useParams} from "react-router-dom";

export default function VerifyCertificate(){
    let { id } = useParams();
    const [certificateDetails, setCertificateDetails]=useState([])
    useEffect(async ()=>{
        await axios.get(buildAPIUrl(`v1/ux_certificates/?ref_number=${id}`)).then((res)=>{
            setCertificateDetails(res.data)
            console.log(certificateDetails.length)
        }).catch((error)=>{

        })
    }, [])
    return (
        <>
            <Header />
            <div className={"container"} style={{marginTop:"7rem"}}>
                <h5>Certificate verification</h5>
                {
                    certificateDetails ? <table className={"table mt-5"}>
                        <tr>
                            <th>Name</th>
                            <th>Ref Number</th>
                        </tr>
                        <tr>
                            <td>{certificateDetails.full_name}</td>
                            <td>{certificateDetails.ref_number}</td>
                        </tr>
                    </table> : <>
                    The certificate does not exist
                    </>
                }
            </div>
        </>
    )
}