import React from "react";
export default function NotFound(props){
    const handleSearchVoc = (e) => {
        localStorage.setItem('searchquery', props.term)
        window.location.assign('/all-results')
    }
    return(
        <>
            <button onClick={handleSearchVoc} className="btn btn-fav">Keep Searching</button>
            <a target={"_blank"} href={"https://forms.gle/4cucNZpA4Xk9x5JZA"} className="btn mx-2" style={{border:"1px solid #333333", borderRadius:"5px"}}>Contribute</a>
        </>
    )
}