import React, {Component} from "react";
import Header from "../Landing/Header";
import "./payment.css"
import {MdCheck, MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";
import Footer from "../Landing/Footer";
import paymentImage1 from "../../../assets/paymentsimage.jpg"
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import Logo from "../../../assets/research-bookmark-logotype-one-color-rgb.svg";
import {GoogleLogin} from "react-google-login";
import FacebookLogin from "react-facebook-login";
import modalimage from "../../../assets/bro.png";
import LoginModal from "../Modals/LoginModal";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import swal from "sweetalert";
import group1 from "../../../assets/Group 89570.png"
import group2 from "../../../assets/Group 89571.png"
import MediaQuery from "react-responsive";

class PaymentTable extends Component {
    state = {
        modalshow:false,
        show:false,
        clientID: "425793011868-1s6v97ajh0m70dhcvb7oejp3qup4qb5e.apps.googleusercontent.com",
        name:"",
        email:"",
        company:"",
        message:"",
        sent:false,
        pageto:1,
        showfeatures:0,
        price:0,
        pricingtype:false,
        plans:[],
        priceperiod:"",
        priceplanid:""
    }
    componentDidMount() {
        const plans = axios.get(buildAPIUrl('v1/subscriptions/')).then((res)=>{
            console.log("plans", res)
            this.setState({
                plans:res.data,
                price:Math.trunc(res.data[0].stripe_plan_details.amount / 100),
                priceperiod:res.data[0].stripe_plan_details.interval,
                priceplanid:res.data[0].stripe_plan_details.id
            })
        }).catch((error)=>{

        })
    }

    handleShowFeatures = (id) => {
        this.setState({
            showfeatures:id
        })
    }
    handleModalOpen = () => {
        this.setState({
            modalshow:true
        })
    }
    handleModalClose = () => {
        this.setState({
            modalshow:false
        })
    }
    handlePayment = () =>{
        if(localStorage.getItem('token')){
            if(this.state.priceperiod==="year"){
                localStorage.setItem('pricechoice', "year")
                localStorage.setItem('priceid', this.state.priceplanid)
                localStorage.setItem('priceamount', this.state.price)

            } else {
                localStorage.setItem('pricechoice', "month")
                localStorage.setItem('priceid', this.state.priceplanid)
                localStorage.setItem('priceamount', this.state.price)
            }
            window.location.assign('/checkout')
        } else {
            this.handleLoginModalOpen("1")
        }
    }
    handleLoginModalOpen =(type) =>{
        if(localStorage.getItem('token')){
            window.location.assign('/')
        } else {
            if(type==="1"){
                this.setState({
                    show:true,
                    pageto:1
                })
            }else {
                this.setState({
                    show:true,
                    pageto:2
                })
            }
        }


}
    reghandleClose = () => {
        this.setState({
            show: false
        })
    }
    handleSubmitEnterprise = (e) =>{
        e.preventDefault()
        const response = axios.post(buildAPIUrl('v1/enterprises/waitlist/'), {
            full_name:this.state.name,
            work_mail:this.state.email,
            company_name:this.state.company,
            note:this.state.message
        }).then((res)=>{
            this.setState({
                full_name:"",
                work_mail:"",
                company_name:"",
                note:"",
                sent:true,
                modalshow:false
            })
            setTimeout(()=>{
                this.setState({
                    sent:false,
                })
            }, 5000)
        }).catch((error)=>{

        })
    }
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changePricingPlan = (e) =>{
        if(this.state.priceperiod==="month"){
            const plans = axios.get(buildAPIUrl('v1/subscriptions/')).then((res)=>{
                console.log("plans", res)
                this.setState({
                    plans:res.data,
                    price:Math.trunc(res.data[1].stripe_plan_details.amount / 100),
                    priceperiod:res.data[1].stripe_plan_details.interval,
                    priceplanid:res.data[1].stripe_plan_details.id
                })
            }).catch((error)=>{

            })
        } else {
            const plans = axios.get(buildAPIUrl('v1/subscriptions/')).then((res)=>{
                console.log("plans", res)
                this.setState({
                    plans:res.data,
                    price:Math.trunc(res.data[0].stripe_plan_details.amount / 100),
                    priceperiod:res.data[0].stripe_plan_details.interval,
                    priceplanid:res.data[0].stripe_plan_details.id
                })
            }).catch((error)=>{

            })
        }
    }
    render() {
        const onSuccess = async (res) => {
            console.log(res)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            localStorage.setItem('calendar_token',res.accessToken)
            localStorage.setItem('token', res.accessToken)
            localStorage.setItem('username', res.profileObj.givenName)
            localStorage.setItem('image', res.profileObj.imageUrl)
            localStorage.setItem('email', res.profileObj.email)
            const resp = await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: localStorage.getItem('token'),
                client_id: "GJSAikgLpihdkgZlvCYAQIyRPVudOegDIjTELtJW",
                client_secret: "U05TLQZPU8DsBisDcMKNfxrtKDDeV1CEyAqFxwTIn9h0UbYiwQaYvfPqlPS6Qi2S1kZsUCTcekl0lYO7Gmp8ZpQs9wvaa30Ld642CsLX5xob7d0eH0mZiYGDceeCD0pR",
                grant_type:"convert_token",
                backend: "google-oauth2"
            }, requestOptions).then((response)=>{
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                if(this.state.pageto===1){
                    window.location.assign('/checkout')
                } else {
                    window.location.assign('/')
                }

            }).catch((err)=>{
                swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.assign("/")
                    localStorage.clear()
                });
                console.log(err)
            })
        }
        const onFailure = (res) => {
            console.log(res)
            console.log("Login Failed")
        }
        const facebookLogin = async (res) => {
            console.log(res)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            localStorage.setItem('token', res.accessToken)
            localStorage.setItem('username', res.name)
            localStorage.setItem('image', res.picture.data.url)
            localStorage.setItem('email', res.email)
            const resp = await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: localStorage.getItem('token'),
                client_id: "GJSAikgLpihdkgZlvCYAQIyRPVudOegDIjTELtJW",
                client_secret: "U05TLQZPU8DsBisDcMKNfxrtKDDeV1CEyAqFxwTIn9h0UbYiwQaYvfPqlPS6Qi2S1kZsUCTcekl0lYO7Gmp8ZpQs9wvaa30Ld642CsLX5xob7d0eH0mZiYGDceeCD0pR",
                grant_type:"convert_token",
                backend: "facebook"
            }, requestOptions).then((response)=>{
                console.log("new token",response)
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                window.location.reload()
            }).catch((err)=>{
                swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.reload()
                    localStorage.clear()
                });
                console.log(err)
            })
        }

        //Submit data form for enterprise

        return (
            <>
                <Header />
                <Modal size="lg" centered show={this.state.show} onHide={this.reghandleClose} >
                    <form onSubmit={this.handleUpload}>
                        <Modal.Body>
                            <div className="row">
                                <div className={this.props.device==="tablet" ? "col-md-12 modal-left" : "col-md-6 modal-left" }>
                                    <div className="card" style={{marginLeft:"3rem", marginRight:"2.25rem", marginTop:"2.563rem", marginBottom:"2.313rem"}}>
                                        <img src={Logo} style={{width:"100px", height:"50px"}}/>
                                        <h6 className="">Login to continue {this.props.type ==="1"? "saving" : this.props.type==="2" ? "filtering" : "browsing"} resources on Research Bookmark</h6>

                                        <div className="container-fluid p-0 flex-column d-flex justify-content-center">
                                            <div className=" mb-2 pt-2" >
                                                <GoogleLogin
                                                    clientId = {this.state.clientID}
                                                    render={renderProps => (
                                                        <button onClick={renderProps.onClick} className="google-login border"><img
                                                            className="google-icon"
                                                            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" style={{width:"15px"}}/> Sign in with Google </button>
                                                    )}
                                                    onSuccess = {onSuccess}
                                                    onFailure = {onFailure}
                                                    cookiePolicy = {'single_host_origin'}
                                                    isSignedIn={false}


                                                >
                                                </GoogleLogin>

                                            </div>
                                            <div className=" mb-3 d-flex justify-content-center facebook-login">
                                                <FacebookLogin
                                                    textButton="Sign in with Facebook"
                                                    appId="714881769793978"
                                                    autoLoad={false}
                                                    fields="name,email,picture"
                                                    callback={facebookLogin}
                                                    onSuccess={facebookLogin}
                                                    cssClass="my-facebook-button-class mt-3 border"
                                                    icon={<span className="social-media-facebook">
                                                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/1200px-Facebook_f_logo_%282021%29.svg.png" style={{width:"15px"}}/>
                                                        </span>}
                                                >
                                                </FacebookLogin>
                                            </div>
                                        </div>

                                        <div className="mt-3 mb-5 text-center" style={{marginTop:"1rem"}}>
                                            <h6 className="mobmodal donot">Do not have an account? <span onClick={this.reghandleOpen} style={{color:"#00944d", cursor:"pointer"}}>Signup</span></h6>
                                        </div>

                                    </div>
                                </div>
                                <div className={this.props.device==="desktop" ? "col-md-6 modal-right " : "col-md-6 modal-right "  }>
                                    <div className="container-fluid text-center">

                                        <img src={modalimage}/>

                                        <p>
                                            You will gain access to:
                                        </p>
                                        <h6>
                                            -Ad-free search results
                                        </h6>
                                        <h6>-Useful free UXR resources</h6>
                                        <h6>-Carefully curated UXR conferences</h6>
                                        <h6>-Free monthly webinars featuring industry thought leaders</h6>
                                    </div>
                                </div>

                            </div>

                        </Modal.Body>
                    </form>
                </Modal>
                <Modal size="lg" centered  show={this.state.modalshow}  onHide={this.handleModalClose}>
                    <form onSubmit={this.handleSubmitEnterprise}>
                        <Modal.Body >

                            <div className={"container contact-sales-modal"}>
                                <h5>Ready to use advanced Enterprise tools?</h5>
                                <div className={"mb-3"}>
                                    <label>Name</label>
                                    <input type={"text"} name={"name"} className={"form-control"} onChange={this.handleInput} value={this.state.name}/>
                                </div>
                                <div className={"mb-3"}>
                                    <label>Work email</label>
                                    <input type={"text"} name={"email"} className={"form-control"} onChange={this.handleInput} value={this.state.email}/>
                                </div>
                                <div className={"mb-3"}>
                                    <label>Company name</label>
                                    <input type={"text"} name={"company"} className={"form-control"} onChange={this.handleInput} value={this.state.company}/>
                                </div>
                                <div className={"mb-3"}>
                                    <label>What do you want us to help you with?</label>
                                    <textarea name={"message"} className={"form-control text-area"} onChange={this.handleInput} value={this.state.message}>
                                    </textarea>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-md-4"}>
                                        <div className={"card-body"}>
                                            <a onClick={this.handleModalClose} className={"btn btn-cancel-sales"}>Cancel</a>
                                        </div>
                                    </div>
                                    <div className={"col-md-8"}>
                                        <div className={"card-body"}>
                                            <button className={"btn btn-confirm-sales"}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </form>
                </Modal>
                <MediaQuery minWidth={1200}>
                    <div className={"desktop mt-5"}>
                        <div className={"container d-flex justify-content-center paymentheader"}>
                            <div>
                                <h4 className={"mt-5"}>
                                    Want top-rated UX Research Tools?
                                </h4>
                            </div>
                        </div>
                        <div className={"container productDescriptions"}>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <img src={group1} style={{width:"100%"}}/>
                                </div>
                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div>
                                        <p>
                                            With our plans, you can now track your daily meetings from our platform. Receive daily notifications on your windows
                                        </p>
                                        <a href={"#pricing-plans"} className={"btn btn-plans"}>
                                            Check our plans
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={"row row2"}>

                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div>
                                        <p>
                                            With our plans, you can now track your daily meetings from our platform. Receive daily notifications on your windows
                                        </p>
                                        <a href={"#pricing-plans"} className={"btn btn-plans"} >
                                            Check our plans
                                        </a>
                                    </div>
                                </div>
                                <div className={"col-md-1"}></div>
                                <div className={"col-md-6"}>
                                    <img src={group2} style={{width:"100%"}}/>
                                </div>
                            </div>
                        </div>
                        <div className={"container d-flex justify-content-center paymentheader"} id={"pricing-plans"}>
                            <div>
                                <p>
                                    Upgrade to start using more tools
                                </p>
                                <div className={"container d-flex justify-content-center"}>
                                    <div className="d-inline-block me-1">Monthly</div>
                                    <div className="form-check form-switch d-inline-block">
                                        <input type="checkbox" onClick={this.changePricingPlan} className="form-check-input" id="site_state" style={{cursor:"pointer"}} />
                                        <label className="form-check-label">Yearly  <span className={"yearDiscount"}>22% discount</span> </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={"container paymentDetails"}>
                            <div className={"container d-flex justify-content-center"}>
                                <div className={this.state.sent ? "enterprise-success col-md-6 mb-5" : "d-none"}>

                                 <span className={"enterprise-success-span"}>
                                    <MdCheck />
                                </span>
                                    <h6>
                                        Thanks for showing interest in our Enterprise plan.
                                        Check your email for further instructions
                                    </h6>
                                </div>
                            </div>

                            <div  className={"row d-flex justify-content-center"}>
                                <div className={"col-md-3"}>
                                    <div className={"card-body"}>
                                        <h4 className={"price"}>$0 <span className={"period"}>/month</span> </h4>
                                        <h4 className={"paymentClass"}>
                                            Freemium
                                        </h4>
                                        <div className={"planItems"}>
                                            <OverlayTrigger overlay={<Tooltip>Conduct online search on anything ux research</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Search
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Save interested resources right into your account</Tooltip>} placement={"top"}>
                                            <h6 >
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saved Searches
                                            </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>See only the data you want displayed</Tooltip>} placement={"top"}>
                                                <h6 >
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Filtering
                                            </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Access your past search terms to see your search activity</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search History
                                            </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Share interesting resources with your colleagues via shareable links</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Share
                                            </h6>
                                            </OverlayTrigger>
                                                <div className={"container"}>
                                                <button className={"btn btn-payment btn-block"} onClick={()=>this.handleLoginModalOpen("2")}>
                                                    Try for free
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-3"}>
                                    <div className={"card-body card-body-dark"}>
                                        <h4 className={"price"}> ${this.state.price}<span className={"period"}>/{this.state.priceperiod}</span> </h4>
                                        <h4 className={"paymentClass"}>
                                            Pro Plan
                                        </h4>
                                        <div className={"planItems"}>
                                            <p>
                                                Automation plus enterprise-grade features.
                                            </p>
                                            <OverlayTrigger overlay={<Tooltip>Conduct online search on anything ux research</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Search
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Save interested resources right into your account</Tooltip>} placement={"top"}>
                                                <h6 >
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Saved Searches
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>See only the data you want displayed</Tooltip>} placement={"top"}>
                                                <h6 >
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Filtering
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Access your past search terms to see your search activity</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Search History
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Share interesting resources with your colleagues via shareable links</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Share
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Add your work calendar right into your RB account</Tooltip>} placement={"top"}>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Google Calendar Integration
                                            </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Search whenever and wherever and still save resources in your RB account</Tooltip>} placement={"top"}>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saving using our extension
                                            </h6>
                                            </OverlayTrigger>
                                            <div className={"container"}>
                                                <button className={"btn btn-payment-dark"} style={{cursor:"pointer"}} onClick={this.handlePayment}>
                                                    Choose plan
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-3"}>
                                    <div className={"card-body"}>
                                        {/*<h4 className={"price"}>$89 <span className={"period"}>/month</span> </h4>*/}
                                        <h4 className={"paymentClass"}>
                                            Enterprise
                                        </h4>
                                        <div className={"planItems"} style={{marginTop:"0"}}>
                                            <p>
                                                Advanced tools to take your work to the next level.
                                            </p>
                                            <OverlayTrigger overlay={<Tooltip>Conduct online search on anything ux research</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Search
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Save interested resources right into your account</Tooltip>} placement={"top"}>
                                                <h6 >
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Saved Searches
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>See only the data you want displayed</Tooltip>} placement={"top"}>
                                                <h6 >
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Filtering
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Access your past search terms to see your search activity</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Search History
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Share interesting resources with your colleagues via shareable links</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Share
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Add your work calendar right into your RB account</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Google Calendar Integration
                                                </h6>
                                            </OverlayTrigger>
                                            <OverlayTrigger overlay={<Tooltip>Search whenever and wherever and still save resources in your RB account</Tooltip>} placement={"top"}>
                                                <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                    Saving using our extension
                                                </h6>
                                            </OverlayTrigger>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                User Management
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Co-sharing
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Customer Support
                                            </h6>
                                            <div className={"container"}>
                                                <button className={"btn btn-payment btn-block"} onClick={this.handleModalOpen}>
                                                    Contact Sales
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"mobile"}>
                        <div className={"container d-flex justify-content-center paymentheader"}>
                            <div>
                                <h4>
                                    Want top-rated UX Research Tools?
                                </h4>
                            </div>
                        </div>
                        <div className={"container productDescriptions"}>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <img src={group1} style={{width:"100%"}}/>
                                </div>
                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div>
                                        <p>
                                            With our plans, you can now track your daily meetings from our platform. Receive daily notifications on your windows
                                        </p>
                                        <a href={"#pricing-plans"} className={"btn btn-plans"} >
                                            Check our plans
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={"row row2"}>
                                <div className={"col-md-6"}>
                                    <img src={group2} style={{width:"100%"}}/>
                                </div>
                                <div className={"col-md-1"}></div>
                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div>
                                        <p>
                                            With our plans, you can now track your daily meetings from our platform. Receive daily notifications on your windows
                                        </p>
                                        <a href={"#pricing-plans"} className={"btn btn-plans"} >
                                            Check our plans
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"container d-flex justify-content-center paymentheader"} id={"pricing-plans"}>
                            <div>
                                <p>
                                    Upgrade to start using more tools
                                </p>
                                <div className={"container d-flex justify-content-center"}>
                                    <div className="d-inline-block me-1">Monthly</div>
                                    <div className="form-check form-switch d-inline-block">
                                        <input type="checkbox" onClick={this.changePricingPlan} className="form-check-input" id="site_state" style={{cursor:"pointer"}} />
                                        <label className="form-check-label">Yearly   </label>

                                    </div>

                                </div>
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-6 yearDiscount text-center"} style={{borderRadius:"2px"}}>
                                        30% discount when you pay yearly
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className={"container paymentDetails"}>
                            <div className={"container d-flex justify-content-center"}>
                                <div className={this.state.sent ? "enterprise-success col-md-6" : "d-none"}>

                                 <span className={"enterprise-success-span"}>
                                    <MdCheck />
                                </span>
                                    <h6>
                                        Thanks for showing interest in our Enterprise plan.
                                        Check your email for further instructions
                                    </h6>
                                </div>
                            </div>

                            <div className={"row d-flex justify-content-center"}>
                                <div className={"col-md-3 mt-5"}>
                                    <div className={"card-body"}>
                                        <h4 className={"price"}>$0 <span className={"period"}>/month</span> </h4>
                                        <h4 className={"paymentClass"}>
                                            Freemium
                                        </h4>

                                        <div className={this.state.showfeatures===1 ? "planItems" : "d-none"}>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saved Searches
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Filtering
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search History
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Share
                                            </h6>

                                        </div>
                                        <div className={"container"}>
                                            <button className={"btn btn-payment btn-block"} onClick={()=>this.handleLoginModalOpen("2")}>
                                                Try for free
                                            </button>
                                        </div>
                                        <div className={"container"} style={{marginTop:"12px"}}>
                                            {
                                                this.state.showfeatures===0 ?
                                                    <h6 onClick={()=>this.handleShowFeatures(1)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                    :
                                                    <>
                                                        {
                                                            this.state.showfeatures===1 ?  <h6 onClick={()=>this.handleShowFeatures(0)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Hide features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                                :  <h6 onClick={()=>this.handleShowFeatures(1)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                        }
                                                    </>

                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-3 mt-5"}>
                                    <div className={"card-body card-body-dark"}>
                                        <h4 className={"price"}>${this.state.price} <span className={"period"}>/month</span> </h4>
                                        <h4 className={"paymentClass"}>
                                            Pro Plan
                                        </h4>
                                        <div className={this.state.showfeatures===2 ? "planItems" : "d-none"}>
                                            <p>
                                                Automation plus enterprise-grade features.
                                            </p>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saved Searches
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Filtering
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search History
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Google Calendar Integration
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saving using our extension
                                            </h6>
                                        </div>
                                        <div className={"container"}>
                                            <button style={{background:"white", color:"black"}} className={"btn btn-payment btn-block"} onClick={()=>this.handlePayment()}>
                                               Choose plan
                                            </button>
                                        </div>
                                        <div className={"container"} style={{marginTop:"12px"}}>
                                            {
                                                this.state.showfeatures===0 ?
                                                    <h6 onClick={()=>this.handleShowFeatures(2)} className={"text-center"} style={{color:"#CBC0FF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                    :
                                                    <>
                                                        {
                                                            this.state.showfeatures===2 ?  <h6 onClick={()=>this.handleShowFeatures(0)} className={"text-center"} style={{color:"#CBC0FF", cursor:"pointer"}}>Hide features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                                :  <h6 onClick={()=>this.handleShowFeatures(2)} className={"text-center"} style={{color:"#CBC0FF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                        }
                                                    </>

                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-3 mt-5"}>
                                    <div className={"card-body"}>
                                       {/* <h4 className={"price"}>$89 <span className={"period"}>/month</span> </h4>*/}
                                        <h4 className={"paymentClass"}>
                                            Enterprise
                                        </h4>
                                        <div className={this.state.showfeatures===3 ? "planItems" : "d-none"} style={{marginTop:"0"}}>
                                            <p>
                                                Advanced tools to take your work to the next level.
                                            </p>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saved Searches
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Filtering
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search History
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Share
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Google Calendar Integration
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saving using our extension
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                User Management
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Co-sharing
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Customer Support
                                            </h6>
                                        </div>
                                        <div className={"container"}>
                                            <button  style={{background:"black", color:"white", height:"45px"}} className={"btn btn-payment btn-block"} onClick={()=>this.handleModalOpen()}>
                                                Contact Sales
                                            </button>
                                        </div>
                                        <div className={"container"} style={{marginTop:"12px"}}>
                                            {
                                                this.state.showfeatures===0 ?
                                                    <h6 onClick={()=>this.handleShowFeatures(3)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                    :
                                                    <>
                                                        {
                                                            this.state.showfeatures===3 ?  <h6 onClick={()=>this.handleShowFeatures(0)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Hide features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                                :  <h6 onClick={()=>this.handleShowFeatures(3)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                        }
                                                    </>

                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className={"tablet"}>
                        <div className={"container d-flex justify-content-center paymentheader"}>
                            <div>
                                <h4>
                                    Want top-rated UX Research Tools?
                                </h4>
                            </div>
                        </div>
                        <div className={"container productDescriptions"}>
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <img src={group1} style={{width:"100%"}}/>
                                </div>
                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div>
                                        <p>
                                            With our plans, you can now track your daily meetings from our platform. Receive daily notifications on your windows
                                        </p>
                                        <a href={"#pricing-plans"} className={"btn btn-plans"} >
                                            Check our plans
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={"row row2"}>


                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div>
                                        <p>
                                            With our plans, you can now track your daily meetings from our platform. Receive daily notifications on your windows
                                        </p>
                                        <a href={"#pricing-plans"} className={"btn btn-plans"} >
                                            Check our plans
                                        </a>
                                    </div>
                                </div>
                                <div className={"col-md-1"}></div>
                                <div className={"col-md-6"}>
                                    <img src={group2} style={{width:"100%"}}/>
                                </div>
                            </div>
                        </div>
                        <div className={"container d-flex justify-content-center paymentheader"} id={"pricing-plans"}>
                            <div>
                                <p>
                                    Upgrade to start using more tools
                                </p>
                                <div className={"container d-flex justify-content-center"}>
                                    <div className="d-inline-block me-1">Monthly</div>
                                    <div className="form-check form-switch d-inline-block">
                                        <input type="checkbox" onClick={this.changePricingPlan} className="form-check-input" id="site_state" style={{cursor:"pointer"}} />
                                        <label className="form-check-label">Yearly   </label>

                                    </div>

                                </div>
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-6 yearDiscount text-center"} style={{borderRadius:"2px"}}>
                                        30% discount when you pay yearly
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className={"container paymentDetails"} style={{marginTop:"2rem"}}>
                            <div className={"container d-flex justify-content-center"}>
                                <div className={this.state.sent ? "enterprise-success col-md-6" : "d-none"}>

                                 <span className={"enterprise-success-span"}>
                                    <MdCheck />
                                </span>
                                    <h6>
                                        Thanks for showing interest in our Enterprise plan.
                                        Check your email for further instructions
                                    </h6>
                                </div>
                            </div>

                            <div className={"row d-flex justify-content-center"}>
                                <div className={"col-md-4 mt-5"}>
                                    <div className={"card-body"}>
                                        <h4 className={"price"}>$0 <span className={"period"}>/month</span> </h4>
                                        <h4 className={"paymentClass"}>
                                            Freemium
                                        </h4>

                                        <div className={this.state.showfeatures===1 ? "planItems" : "d-none"}>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saved Searches
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Filtering
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search History
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Share
                                            </h6>

                                        </div>
                                        <div className={"container-fluid p-0"}>
                                            <button className={"btn btn-payment btn-block"} onClick={()=>this.handleLoginModalOpen("2")}>
                                                Try for free
                                            </button>
                                        </div>
                                        <div className={"container"} style={{marginTop:"12px"}}>
                                            {
                                                this.state.showfeatures===0 ?
                                                    <h6 onClick={()=>this.handleShowFeatures(1)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                    :
                                                    <>
                                                        {
                                                            this.state.showfeatures===1 ?  <h6 onClick={()=>this.handleShowFeatures(0)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Hide features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                                :  <h6 onClick={()=>this.handleShowFeatures(1)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                        }
                                                    </>

                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-4 mt-5"}>
                                    <div className={"card-body card-body-dark"}>
                                        <h4 className={"price"}>${this.state.price} <span className={"period"}>/month</span> </h4>
                                        <h4 className={"paymentClass"}>
                                            Pro Plan
                                        </h4>
                                        <div className={this.state.showfeatures===2 ? "planItems" : "d-none"}>
                                            <p>
                                                Automation plus enterprise-grade features.
                                            </p>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saved Searches
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Filtering
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search History
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Google Calendar Integration
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saving using our extension
                                            </h6>
                                        </div>
                                        <div className={"container-fluid p-0"}>
                                            <button style={{background:"white", color:"black"}} className={"btn btn-payment btn-block"} onClick={()=>this.handlePayment()}>
                                                Choose plan
                                            </button>
                                        </div>
                                        <div className={"container-fluid p-0"} style={{marginTop:"12px"}}>
                                            {
                                                this.state.showfeatures===0 ?
                                                    <h6 onClick={()=>this.handleShowFeatures(2)} className={"text-center"} style={{color:"#CBC0FF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                    :
                                                    <>
                                                        {
                                                            this.state.showfeatures===2 ?  <h6 onClick={()=>this.handleShowFeatures(0)} className={"text-center"} style={{color:"#CBC0FF", cursor:"pointer"}}>Hide features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                                :  <h6 onClick={()=>this.handleShowFeatures(2)} className={"text-center"} style={{color:"#CBC0FF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                        }
                                                    </>

                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-4 mt-5"}>
                                    <div className={"card-body"}>
                                        {/* <h4 className={"price"}>$89 <span className={"period"}>/month</span> </h4>*/}
                                        <h4 className={"paymentClass"}>
                                            Enterprise
                                        </h4>
                                        <div className={this.state.showfeatures===3 ? "planItems" : "d-none"} style={{marginTop:"0"}}>
                                            <p>
                                                Advanced tools to take your work to the next level.
                                            </p>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saved Searches
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Filtering
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Search History
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Share
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Google Calendar Integration
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Saving using our extension
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                User Management
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Co-sharing
                                            </h6>
                                            <h6>
                                        <span className={"planItemIcon"}>
                                            <MdCheck />
                                        </span>
                                                Customer Support
                                            </h6>
                                        </div>
                                        <div className={"container-fluid p-0"}>
                                            <button  style={{background:"black", color:"white", height:"45px"}} className={"btn btn-payment btn-block"} onClick={()=>this.handleModalOpen()}>
                                                Contact Sales
                                            </button>
                                        </div>
                                        <div className={"container"} style={{marginTop:"12px"}}>
                                            {
                                                this.state.showfeatures===0 ?
                                                    <h6 onClick={()=>this.handleShowFeatures(3)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                    :
                                                    <>
                                                        {
                                                            this.state.showfeatures===3 ?  <h6 onClick={()=>this.handleShowFeatures(0)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Hide features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                                :  <h6 onClick={()=>this.handleShowFeatures(3)} className={"text-center"} style={{color:"#522FFF", cursor:"pointer"}}>Show features <MdKeyboardArrowUp style={{fontSize:"20px"}} /></h6>
                                                        }
                                                    </>

                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>


                <Footer />
            </>
        );
    }
}
export default PaymentTable