import React, {Component} from "react";
import Header from "../Landing/Header";
import axios from "axios";
import {HiSearch} from "react-icons/hi";
import LoginModal from "../Modals/LoginModal";
import cards from "../../../assets/Group 23.png";
import {
    MdCancel, MdClose, MdFilterList,
    MdHighlightOff,
    MdKeyboardArrowDown,
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdOutlineFilterAlt
} from "react-icons/md";
import {Link} from "react-router-dom";
import buildAPIUrl from "../../../Services/UrlBuilder";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import MediaQuery from "react-responsive";
import searchicon from "../../../assets/Frame 39.png";
import ResourcesNav from "./ResourcesNav";
import MetaTags from "react-meta-tags";
import ReactPaginate from "react-paginate";
import NotFound from "./NotFound";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import SeoMetaData from "./SeoMetaData";

class Tools extends Component{
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    state = {
        items:[],
        token: localStorage.getItem('token'),
        tags:[],
        filter:"",
        filteritems:[],
        loading:true,
        showfilter:false,
        currrentitems:null,
        offset:0,
        itemscount:15,
        pages:0,
        page:0
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    };
    componentDidMount() {
        EventsHandler("Tools Section Opened",  document.URL)

        axios.get(buildAPIUrl('v1/research_tools/')).then((res)=>{
            this.setState({
                items: res.data,
                loading: false,
                pages:Math.floor(res.data.length / this.state.itemscount)
            })
            window.scrollTo(0,0)
        }).catch((err)=>{

        })
        axios.get(buildAPIUrl('v1/tags/')).then((res)=>{
            this.setState({
                tags: res.data
            })
        }).catch((err)=>{

        })
        //Increase views by 1
        axios.get(buildAPIUrl('v1/collections/1/')).then(()=>{

        }).catch((error)=>{

        })

        //Get SEO content
        axios.get(buildAPIUrl('v1/seo_contents/')).then((res)=>{
            console.log(res.data)
        }).catch((error)=>{

        })
    }
    handleSearch = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    handleFilter = async (e) => {
        document.addEventListener("mousedown", this.handleClickOutside);
        if(this.state.token) {
            const isChecked = e.target.checked
            if(isChecked){
                await this.setState({filteritems: [...this.state.filteritems, e.target.value]})
            } else {
                const index = this.state.filteritems.indexOf(e.target.value);
                this.state.filteritems.splice(index,1);
                await this.setState({filteritems: this.state.filteritems})
            }
            const filternew = this.state.items.filter(
                function (e) {
                    return this.includes(e)
                } , this.state.filteritems
            );
            this.setState({
                items:filternew
            })
           await axios.get(buildAPIUrl(`v1/research_tools/?tags=${this.state.filteritems}`)).then((res)=>{
                this.setState({
                    items: res.data,
                    pages:0,
                    page:0
                })
            }).catch((err)=>{

            })
        } else {
            await this.handleModalOpen()
        }

    }
    handleModalClose = ()=>{
        this.setState({
            show: false
        })
        window.location.reload()
    }
    handleModalOpen = async ()=>{
        await this.setState({
            show: true,
        })
        console.log(this.state.siteid)
    }


    clearFilters = () => {
        window.location.reload()
    }
    handleFilterShow = () => {
        if(this.state.showfilter) {
            this.setState({
                showfilter:false
            })
        } else {
            this.setState({
                showfilter:true
            })
        }
    }
    handleClickOutside = (event) => {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({
                showfilter:false
            })
        }

    }
    handleClickCount =(id)=>{
        const response = axios.get(buildAPIUrl(`v1/research_tools/${id}/`)).then(()=>{

        }).catch(()=>{

        })

    }
    handleRemoveFilterItem = async (item) =>{
        var listitems = this.state.filteritems
        const index = listitems.indexOf(item)
        var removed = listitems.filter(itm => itm !==item)
        await this.setState({
            filteritems: removed
        })
        const response2 = await axios.get(buildAPIUrl(`v1/research_tools/?tags=${this.state.filteritems}`)).then((res)=>{
            console.log(res)
            this.setState({
                items: res.data
            })
        }).catch((err)=>{

        })

    }
    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({page})
        window.scrollTo(0,0)
    }
    saveEvent = (id)=> {
            EventsHandler("Tools Link Clicked", document.URL, "none", id)
        //Increase views by 1
        axios.get(buildAPIUrl(`v1/research_tools/${id}/`)).then(()=>{

        }).catch((error)=>{

        })
    }
    handleClearAll = async ()=>{
        this.setState({
            filteritems:[]
        })
        await axios.get(buildAPIUrl(`v1/research_tools`)).then((res)=>{
            this.setState({
                items: res.data,
                pages:0,
                page:0
            })
        }).catch((err)=>{

        })
    }
    render() {
        const filtereditems = this.state.items.filter((result)=> {
            if (this.state.filter === "") {
                return result
            } else if (result.name.toLowerCase().includes(this.state.filter.toLowerCase())) {
                return result
            }
        })
        function compareStrings(a, b) {
            // Assuming you want case-insensitive comparison
            a = a.toLowerCase();
            b = b.toLowerCase();

            return (a < b) ? -1 : (a > b) ? 1 : 0;
        }
        const pages = Math.floor( filtereditems.length/ this.state.itemscount)

        return(
            <>
                <SeoMetaData title={"Tools"} />
                <LoginModal type={"2"} openLoginModal={this.handleModalOpen} closeLoginModal={this.handleModalClose} show = {this.state.show}/>
                <MediaQuery minWidth={1200} >
                    <Header pageType={"Free"} />
                    <div className="row" style={{paddingLeft: "1rem", paddingRight:"1rem", marginTop:"7rem"}}>
                        <div className="col-md-2 side-filters">


                        </div>
                        <div className="col-md-8 col-12 px-4">
                            <div className="search-section col-12 col-md-12 mt-4">
                                <div className="row d-flex align-items-center ">
                                    <div className=" col-12 col-md-12 d-flex justify-content-center">
                                        <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                            <div className="form-input input-group border"  style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                       style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search for tools"/>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                            <div className="saved-sources">
                                <div className="row">
                                    <ResourcesNav />
                                    <div className={this.state.filteritems.length > 0 ? "col-md-12 mt-3 d-flex justify-content-end" : "d-none"}>
                                        {
                                            this.state.filteritems.length > 0 ?
                                            <>
                                                <ul className={"p-0"} style={{listStyleType:"none"}}>
                                                    {
                                                        this.state.filteritems.map((filter)=>(
                                                            <li className={"d-flex justify-content-between align-items-center"} style={{color:"#767676", borderRadius:"8px", padding:"3px 10px", float: "left", marginRight:"10px", background:"#E8E8E8",height:"2rem"}}>
                                                                <h6 className={"p-0 m-0"} style={{float:"left", marginTop:"6px", fontSize:"14px", fontWeight:"500", color:"#202020"}}>{filter}</h6> <span onClick={()=>this.handleRemoveFilterItem(filter)} style={{float:"left", marginLeft:"10px"}}> <MdClose /></span>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                <button onClick={this.handleClearAll} className={"clear"} style={{height:"2rem", border:"none", backgroundColor:"#202020", color:"white", fontSize:"14px", borderRadius:"8px", padding:"3px 10px", }}>Clear All</button>

                                            </> :
                                                <></>
                                        }
                                    </div>
                                    <div className="col-md-12 col-6 border p-0 rounded-3" style={{marginTop:"1rem"}}>
                                       <div className={"container py-4 d-flex justify-content-between align-items-center"}>
                                           <h1 style={{fontSize:"18px", margin:"0"}}>The Ultimate Collection of UX Tools for your Every Need</h1>
                                           <a className="" target="_blank" style={{color:"black", fontSize:"14px",  textDecoration:"none"}}>
                                               <div className="dropdown-toggle originals-toggle" id="navbarDropdown"
                                                    role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}>
                                                   <h6 style={{margin:"0"}}>
                                                        <span>
                                                              <MdFilterList className={"ori-icon"} /> Used for
                                                        </span>

                                                   </h6>
                                               </div>
                                               <div className="dropdown-menu originals-category-filter px-3" aria-labelledby="navbarDropdown" style={{height:"100vh", overflowY:"scroll"}}>
                                                   <div className={"container-fluid p-0"}>
                                                       <div className="" >
                                                           {
                                                               this.state.tags.sort(function (a,b){
                                                                   return compareStrings(a.name, b.name)
                                                               }).map((tag)=>(
                                                                   <div className="form-check">
                                                                       <input className="form-check-input" type="checkbox" name={tag.name} value={tag.name}
                                                                              id={tag.id} onClick={this.handleFilter} checked={this.state.filteritems.includes(tag.name)}/>
                                                                       <label className="form-check-label" htmlFor={tag.id}>
                                                                           {tag.name}
                                                                       </label>
                                                                   </div>
                                                               ))
                                                           }
                                                       </div>
                                                   </div>

                                               </div>
                                           </a>

                                       </div>
                                        {
                                            this.state.loading ? <>
                                                    {
                                                        [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                    }

                                                </> :
                                                <table className="history-table" style={{overflow:"hidden"}}>
                                                    <thead>
                                                    <tr>
                                                        <th>Tool name</th>
                                                        <th>Used for</th>
                                                        <th>Source</th>
                                                    </tr>
                                                    </thead>
                                                    {
                                                        localStorage.getItem('token')  ? <>
                                                            <tbody>

                                                            {
                                                                filtereditems.length > 0 ?
                                                                    <>
                                                                        { this.state.filter.length > 0 ? <>
                                                                            {
                                                                                filtereditems.sort(function (a,b){
                                                                                    return compareStrings(a.name, b.name)
                                                                                }).map((item)=>(
                                                                                    <tr>
                                                                                        <td >{item.name}</td>
                                                                                        <td style={{whiteSpace:"initial", padding:"1rem 0"}}>

                                                                                            {item.tags.map((tag)=>(
                                                                                                <>
                                                                                                    <div  className={"history-tag"}>{tag.name}</div>
                                                                                                </>
                                                                                            ))}


                                                                                        </td>
                                                                                        <td style={{color:"#522FFF", textDecoration:"underline"}}><a onClick={()=>this.saveEvent(item.name)} style={{color:"#522FFF"}} target="_blank"  href={item.url}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a></td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </> : <>
                                                                            {
                                                                                filtereditems.sort(function (a,b){
                                                                                    return compareStrings(a.name, b.name)
                                                                                }).slice(this.state.page * this.state.itemscount, (this.state.page  +1) * this.state.itemscount).map((item)=>(
                                                                                    <tr>
                                                                                        <td >{item.name}</td>
                                                                                        <td style={{whiteSpace:"initial", padding:"1rem 0"}}>

                                                                                            {item.tags.map((tag)=>(
                                                                                                <>
                                                                                                    <div  className={"history-tag"}>{tag.name}</div>
                                                                                                </>
                                                                                            ))}


                                                                                        </td>
                                                                                        <td style={{color:"#522FFF", textDecoration:"underline"}}><a onClick={()=>this.saveEvent(item.name)} style={{color:"#522FFF"}} target="_blank"  href={item.url}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a></td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </>
                                                                        }
                                                                    </>
                                                                    :<>

                                                                        <tr>
                                                                            <td colSpan="3" className="search-message"> <img src={cards}/><br/>
                                                                                <h6>No results found for this keyword</h6>
                                                                                <p>This word is not available in tools list, try our search engine instead</p>
                                                                                <NotFound term={this.state.filter} />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                            }

                                                            </tbody>
                                                        </> : <>
                                                            <tbody>

                                                            {
                                                                filtereditems.length > 0 ?
                                                                    filtereditems.sort(function (a,b){
                                                                        return compareStrings(a.name, b.name)
                                                                    }).slice(0,7).map((item)=>(
                                                                        <tr>
                                                                            <td >{item.name}</td>
                                                                            <td style={{whiteSpace:"initial"}}>

                                                                                {item.tags.map((tag)=>(
                                                                                    <>
                                                                                        <div  style={{border:"1px solid rgba(118, 118, 118, .5)", padding:"1%", borderRadius:"6px", margin:"1%", display:"inline-block"}}>{tag.name}</div>
                                                                                    </>
                                                                                ))}


                                                                            </td>
                                                                            <td style={{color:"#00944D", textDecoration:"underline"}}><a onClick={()=>this.saveEvent(item.name)} style={{color:"rgb(0, 148, 77)"}} target="_blank"  href={item.url}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a></td>
                                                                        </tr>
                                                                    )):<>

                                                                        <tr>
                                                                            <td colSpan="3" className="search-message"> <img src={cards}/><br/>
                                                                                <h6>No results found for this keyword</h6>
                                                                                <p>This word is not available in tools list, try our search engine instead</p>
                                                                                <NotFound term={this.state.filter} />
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                            }
                                                            {
                                                                filtereditems.sort(function (a,b){
                                                                    return compareStrings(a.name, b.name)
                                                                }).slice(7,10).map((item)=>(
                                                                    <tr className="passed-create">
                                                                        <td >{item.name}</td>
                                                                        <td style={{whiteSpace:"initial"}}>

                                                                            {item.tags.map((tag)=>(
                                                                                <>
                                                                                    <div  style={{border:"1px solid rgba(118, 118, 118, .5)", padding:"1%", borderRadius:"6px", margin:"1%", display:"inline-block"}}>{tag.name}</div>
                                                                                </>
                                                                            ))}


                                                                        </td>
                                                                        <td style={{color:"#00944D", textDecoration:"underline"}}><a onClick={()=>this.saveEvent(item.name)} style={{color:"rgb(0, 148, 77)"}} target="_blank"  href={item.url}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a></td>
                                                                    </tr>
                                                                ))
                                                            }

                                                            </tbody>
                                                        </>
                                                    }

                                                </table>
                                        }
                                    </div>
                                </div>

                                {
                                    localStorage.getItem('token')  ? "" :
                                        <>
                                            {
                                                filtereditems.length > 0 ? <>
                                                    <div className="container-fluid">

                                                        <div className="lower-hidden d-flex justify-content-center ">
                                                            <div className="pt-5 text-center">
                                                                <h6>Create a free account to gain unlimited access</h6>
                                                                <p>
                                                                    Login to Research Bookmark to gain access to over 25,000 free UX resources
                                                                </p>
                                                                <button onClick={()=>this.handleModalOpen()} className="btn btn-create-account">Create an account</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : ""
                                            }
                                        </>

                                }
                            </div>
                            <div className={"container d-flex justify-content-center"}>
                                {
                                    localStorage.getItem('token') && pages > 1 ?
                                        <ReactPaginate
                                            pageCount={pages}
                                            previousLabel={'<<'}
                                            nextLabel={'>>'}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            activeClassName={'active'}
                                        />
                                        :
                                        ""

                                }

                            </div>



                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className={"container p-0 position-relative"}>
                        <div ref={this.ref} className={this.state.showfilter? "row p-0 bg-white mobfilter mobfilter1 d-flex justify-content-center position-absolute" : " d-none mobfilter1" } style={{zIndex:"1000", width:"100%", margin:"0"}}>
                            <div className="container-fluid bg-white p-0"  >
                                <div className={"row border-bottom"} style={{paddingLeft:"1.5rem", paddingRight:"3rem"}}>
                                    <div className={"col-4"}>
                                        <span style={{color:"#00944D", fontSize:"14px",  right:"0", cursor:"pointer"}} onClick={this.clearFilters}>Reset</span>
                                    </div>
                                    <div className={"col-4"}>
                                        <h5 style={{fontSize:"20px", color:"#070707"}}>Filters </h5>
                                    </div>
                                    <div className={"col-4 d-flex justify-content-end"}>
                                        <MdHighlightOff onClick={this.handleFilterShow} style={{fontSize:"20px"}} />
                                    </div>
                                </div>


                                <div className={"container"} style={{paddingLeft:"1.5rem"}}>
                                    <h6 className={"filter-title"}>by what it's used for</h6>
                                    {
                                        this.state.tags.map((tag)=>(
                                            <div className="form-check" style={{marginTop:"21px"}}>
                                                <input className="form-check-input" type="checkbox" name={tag.name} value={tag.name}
                                                       id={tag.id} onClick={this.handleFilter}/>
                                                <label className="form-check-label" htmlFor={tag.id}>
                                                    {tag.name}
                                                </label>
                                            </div>
                                        ))
                                    }

                                </div>


                            </div>
                            <button onClick={this.handleFilterShow} className={"btn btn-save-filter mt-5"}>
                                Save Filter
                            </button>

                        </div>
                    </div>
                    <Header pageType={"Free"} />
                    <div className="container-fluid p-0 mobile " style={{background:"white"}}>
                        <div className="col-md-8 col-12 p-0">
                            <div className="search-section col-12 col-md-12 mt-4 ">
                                <div className=" col-12 col-md-12 d-flex justify-content-center">
                                    <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                        <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                            <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                            <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                   style={{width: "100%", border:"none"}} placeholder="Search for tools"/>

                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="saved-sources">
                                    <ResourcesNav />
                                <div className={"row p-0 m-0"}>
                                    <div className="col-md-8 col-6 d-flex align-items-center p-0">
                                        <h1 style={{fontWeight:"600", fontSize:"15px", margin:"12px"}}>The Ultimate Collection of UX Tools for your Every Need</h1>
                                    </div>
                                    <div className="col-md-4 col-6 d-flex justify-content-end">
                                        <a className="" style={{color:"#757D8A",}} onClick={this.handleFilterShow}><h6 className="h6-filter">Filter </h6></a>
                                    </div>
                                </div>

                                {
                                    this.state.loading ? <>
                                            {
                                                [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                            }

                                        </> :
                                        <table className="history-table mt-3" style={{overflow:"hidden"}}>
                                            <thead >
                                            <tr>
                                                <th>Tool name</th>
                                                <th>Source</th>
                                            </tr>
                                            </thead>

                                            {
                                                localStorage.getItem('token')  ? <>
                                                    <tbody>

                                                    {
                                                        filtereditems.length > 0 ?
                                                            filtereditems.slice(this.state.page * this.state.itemscount, (this.state.page  +1) * this.state.itemscount).map((item)=>(
                                                                <tr>
                                                                    <td>{item.name}</td>
                                                                    <td style={{color:"#00944D", textDecoration:"underline"}}><a onClick={()=>this.saveEvent(item.name)} style={{color:"rgb(0, 148, 77)"}} target="_blank"  href={item.url}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a></td>
                                                                </tr>
                                                            )):<>

                                                                <tr>
                                                                    <td colSpan="2" className="search-message" style={{padding:"2rem"}}> <img src={cards}/><br/>
                                                                        <h6>No results found for this keyword</h6>
                                                                        <p>This word is not available in tools list, try our search engine instead</p>
                                                                        <NotFound term={this.state.filter} />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                    }

                                                    </tbody>
                                                </> : <>
                                                    <tbody>

                                                    {
                                                        filtereditems.length > 0 ?
                                                            filtereditems.sort(function (a,b){
                                                                return compareStrings(a.name, b.name)
                                                            }).slice(0,7).map((item)=>(
                                                                <tr>
                                                                    <td>{item.name}</td>
                                                                    <td style={{color:"#00944D", textDecoration:"underline"}}><a onClick={()=>this.saveEvent(item.name)} style={{color:"rgb(0, 148, 77)"}} target="_blank"  href={item.url}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a></td>
                                                                </tr>
                                                            )):<>

                                                                <tr>
                                                                    <td colSpan="2" className="search-message" style={{padding:"1rem"}}> <img src={cards}/><br/>
                                                                        <h6>No results found for this keyword</h6>
                                                                        <p>This word is not available in tools list, try our search engine instead</p>
                                                                        <NotFound term={this.state.filter} />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                    }
                                                    {
                                                        filtereditems.sort(function (a,b){
                                                            return compareStrings(a.name, b.name)
                                                        }).slice(7,10).map((item)=>(
                                                            <tr className="passed-create">
                                                                <td>{item.name}</td>
                                                                <td style={{color:"#00944D", textDecoration:"underline"}}><a onClick={()=>this.saveEvent(item.name)} style={{color:"rgb(0, 148, 77)"}} target="_blank"  href={item.url}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a></td>
                                                            </tr>
                                                        ))
                                                    }

                                                    </tbody>
                                                </>
                                            }
                                        </table>
                                }
                                {
                                    localStorage.getItem('token')  ? "" :
                                        <>
                                            {
                                                filtereditems.length > 0 ? <>
                                                    <div className="container-fluid">

                                                        <div className="lower-hidden d-flex justify-content-center ">
                                                            <div className="pt-5 text-center">
                                                                <h6>Create a free account to gain unlimited access</h6>
                                                                <p>
                                                                    Login to Research Bookmark to gain access to over 25,000 free UX resources
                                                                </p>
                                                                <button onClick={()=>this.handleModalOpen()} className="btn btn-create-account">Create an account</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : ""
                                            }
                                        </>

                                }
                            </div>
                            <div className={"container d-flex justify-content-center"}>
                                <ReactPaginate
                                    pageCount={pages}
                                    previousLabel={'<<'}
                                    nextLabel={'>>'}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461} >
                    <div className={"container-fluid position-relative"}>
                        <div ref={this.ref} className={this.state.showfilter? "row p-0 bg-white mobfilter mobfilter1 d-flex justify-content-center position-absolute" : " d-none mobfilter1" } style={{zIndex:"1000"}}>
                            <div className="container-fluid bg-white p-0"  >
                                <div className={"row border-bottom"} style={{paddingLeft:"1.5rem", paddingRight:"3rem"}}>
                                    <div className={"col-4"}>
                                        <span style={{color:"#00944D", fontSize:"14px",  right:"0", cursor:"pointer"}} onClick={this.clearFilters}>Reset</span>
                                    </div>
                                    <div className={"col-4"}>
                                        <h5 style={{fontSize:"20px", color:"#070707"}}>Filters </h5>
                                    </div>
                                    <div className={"col-4 d-flex justify-content-end"}>
                                        <MdHighlightOff onClick={this.handleFilterShow} style={{fontSize:"20px"}} />
                                    </div>
                                </div>


                                <div className={"container"} style={{paddingLeft:"1.5rem"}}>
                                    <h6 className={"filter-title"}>by what it's used for</h6>
                                    {
                                        this.state.tags.map((tag)=>(
                                            <div className="form-check" style={{marginTop:"21px"}}>
                                                <input className="form-check-input" type="checkbox" name={tag.name} value={tag.name}
                                                       id={tag.id} onClick={this.handleFilter}/>
                                                <label className="form-check-label" htmlFor={tag.id}>
                                                    {tag.name}
                                                </label>
                                            </div>
                                        ))
                                    }

                                </div>


                            </div>
                            <button onClick={this.handleFilterShow} className={"btn btn-save-filter mt-5"}>
                                Save Filter
                            </button>

                        </div>
                    </div>
                    <Header pageType={"Free"} />
                    <div className="row mobile " style={{paddingLeft: "1rem", paddingRight:"1rem", background:"white"}}>


                        <div className="col-md-8 col-12 p-0">
                            <div className="search-section col-11 col-md-12 mb-2 ">
                                <div className="row d-flex align-items-center">
                                    <div className=" col-12 col-md-12 d-flex justify-content-center" style={{paddingLeft:"2.5rem"}}>
                                        <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                            <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                       style={{width: "100%", border:"none"}} placeholder="Search for tools"/>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="saved-sources" style={{paddingLeft:"2.5rem"}}>
                                <div className="row mt-2">
                                    <ResourcesNav />
                                    <div className="col-md-8 col-6 d-flex align-items-center p-0">
                                        <h1 style={{marginTop:"2.125rem"}}>The Ultimate Collection of UX Tools for your Every Need</h1>
                                    </div>
                                    <div className="col-md-4 col-5 d-flex justify-content-end">
                                        <a className="" style={{color:"#757D8A", marginTop:"2.125rem"}} onClick={this.handleFilterShow}><h6 className="h6-filter">Filter </h6></a>
                                    </div>
                                </div>
                                {
                                    this.state.loading ? <>
                                            {
                                                [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                            }

                                        </> :
                                        <table className="history-table mt-3" style={{overflow:"hidden", marginLeft:"-1rem"}}>
                                            <thead>
                                            <tr>
                                                <th>Tool name</th>
                                                <th>Source</th>
                                            </tr>
                                            </thead>

                                            {
                                                localStorage.getItem('token')  ? <>
                                                    <tbody>

                                                    {
                                                        filtereditems.length > 0 ?
                                                            filtereditems.slice(this.state.page * this.state.itemscount, (this.state.page  +1) * this.state.itemscount).map((item)=>(
                                                                <tr>
                                                                    <td >{item.name}</td>
                                                                    <td style={{color:"#00944D", textDecoration:"underline"}}><a onClick={()=>this.saveEvent(item.name)} style={{color:"rgb(0, 148, 77)"}} target="_blank"  href={item.url}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a></td>
                                                                </tr>
                                                            )):<>

                                                                <tr>
                                                                    <td colSpan="2" className="search-message"> <img src={cards}/><br/>
                                                                        <h6>No results found for this keyword</h6>
                                                                        <p>This word is not available in tools list, try our search engine instead</p>
                                                                        <NotFound term={this.state.filter} />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                    }

                                                    </tbody>
                                                </> : <>
                                                    <tbody>

                                                    {
                                                        filtereditems.length > 0 ?
                                                            filtereditems.sort(function (a,b){
                                                                return compareStrings(a.name, b.name)
                                                            }).slice(0,7).map((item)=>(
                                                                <tr>
                                                                    <td >{item.name}</td>
                                                                    <td style={{color:"#00944D", textDecoration:"underline"}}><a onClick={()=>this.saveEvent(item.name)} style={{color:"rgb(0, 148, 77)"}}  target="_blank"  href={item.url}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a></td>
                                                                </tr>
                                                            )):<>

                                                                <tr>
                                                                    <td colSpan="2" className="search-message"> <img src={cards}/><br/>
                                                                        <h6>No results found for this keyword</h6>
                                                                        <p>This word is not available in tools list, try our search engine instead</p>
                                                                        <NotFound term={this.state.filter} />
                                                                    </td>
                                                                </tr>
                                                            </>
                                                    }
                                                    {
                                                        filtereditems.sort(function (a,b){
                                                            return compareStrings(a.name, b.name)
                                                        }).slice(7,10).map((item)=>(
                                                            <tr className="passed-create">
                                                                <td >{item.name}</td>
                                                                <td style={{color:"#00944D", textDecoration:"underline"}}><a onClick={()=>this.saveEvent(item.name)} style={{color:"rgb(0, 148, 77)"}} target="_blank"  href={item.url}>{item.url.replace("https://", "").replace("www.", "").replace("http://", "")}</a></td>
                                                            </tr>
                                                        ))
                                                    }

                                                    </tbody>
                                                </>
                                            }
                                        </table>
                                }
                                {
                                    localStorage.getItem('token')  ? "" :
                                        <>
                                            {
                                                filtereditems.length > 0 ? <>
                                                    <div className="container-fluid">

                                                        <div className="lower-hidden d-flex justify-content-center ">
                                                            <div className="pt-5 text-center">
                                                                <h6>Create a free account to gain unlimited access</h6>
                                                                <p>
                                                                    Login to Research Bookmark to gain access to over 25,000 free UX resources
                                                                </p>
                                                                <button onClick={()=>this.handleModalOpen()} className="btn btn-create-account">Create an account</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : ""
                                            }
                                        </>

                                }
                            </div>
                            <div className={"container d-flex justify-content-center"}>
                                <ReactPaginate
                                    pageCount={pages}
                                    previousLabel={'<<'}
                                    nextLabel={'>>'}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                </MediaQuery>

                <Footer/>
            </>
        )
    }
}
export default Tools