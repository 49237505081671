import React, {useEffect, useState} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import MediaQuery from "react-responsive";

export default function Speakers(props){
    const [speakers, setSpeakers]=useState([])
    async function getSpeakers() {
     const speakers = await axios.get(buildAPIUrl('v1/speakers'))
        setSpeakers(speakers.data)
        console.log(speakers, "speakers")
    }
      useEffect(()=>{
          getSpeakers()
      }, [])
    return (
        <>
            <MediaQuery minWidth={1200} >
                <div className={props.activeview===2 ? "row" : "d-none"}>
                    {
                        speakers.length > 0 ?
                            <>
                                {
                                    speakers.sort((function(a, b){return a.id-b.id})).map((speakername)=>(
                                        <div className="col-md-3 mt-4">
                                            <div className="speaker card">
                                                <img src={speakername.profile_picture}/>
                                                <h6>
                                                    {
                                                        speakername.full_name
                                                    }
                                                </h6>
                                                <a href={speakername.linkedIn} target="_blank">
                                                    <p>
                                                        {
                                                            speakername.description
                                                        }
                                                    </p>
                                                </a>
                                            </div>
                                        </div>
                                    ))

                                }
                            </>
                            : "No speakers found"
                    }

                </div>
            </MediaQuery>
            <MediaQuery maxWidth={460}>
                <div  className={props.activeview===2 ? "row" : "d-none"}>
                    {
                       speakers.sort((function(a, b){return a.id-b.id})).map((speakername)=>(
                            <div className="col-md-3 mt-4">
                                <div className="speaker card">
                                    <img src={speakername.profile_picture}/>
                                    <h6>
                                        {
                                            speakername.full_name
                                        }
                                    </h6>
                                    <a href={speakername.linkedIn} target="_blank">
                                        <p>
                                            {
                                                speakername.description
                                            }
                                        </p>
                                    </a>
                                </div>
                            </div>
                        ))

                    }

                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1200} minWidth={461}>
                <div  className={props.activeview===2 ? "row" : "d-none"}>
                    {
                       speakers.sort((function(a, b){return a.id-b.id})).map((speakername)=>(
                            <div className="col-md-3 mt-4">
                                <div className="speaker card">
                                    <img src={speakername.profile_picture}/>
                                    <h6>
                                        {
                                            speakername.full_name
                                        }
                                    </h6>
                                    <a href={speakername.linkedIn} target="_blank">
                                        <p>
                                            {
                                                speakername.description
                                            }
                                        </p>
                                    </a>
                                </div>
                            </div>
                        ))

                    }

                </div>
            </MediaQuery>

        </>
    )
}