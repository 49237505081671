export default function mixPanelApi(){
    let current = window.origin;
    if(current.includes("localhost:") || current.includes("127.0.0.1:") || current.includes("https://bing-search-api-test-staging.herokuapp.com")){
        return "da1972e9885d799ab769914eec32b286"
    }else if(current.includes("rbbeta") || current.includes("researchbookmark.io")){
        return "7726c3c95fe5b5b25cb8084d85b4de94";
    }else{
        return "da1972e9885d799ab769914eec32b286"
    }
}
