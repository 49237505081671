import React, {useState, useEffect} from "react";
import {MdFolder, MdOutlineFolder, MdOutlinePersonAdd, MdOutlineSearch} from "react-icons/md";
import CountUp from "react-countup";
import axios from "axios";
import buildAPIUrl from "../Services/UrlBuilder";
import {Link} from "react-router-dom";

export default function Statistics () {
    const [allUsers, setAllUsers] = useState("")
    const [allSearches, setAllSearches] = useState("")
    const [allSavedSources, setSavedSources] = useState("")
    const [userToken, setUserToken]=useState(localStorage.getItem('token'))
   useEffect(()=>{
        const response = axios.get(buildAPIUrl('v1/save_search_queries/?saved_searches=all'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res)=>{
            console.log(res.data.total_number_of_saved_searches)
            setAllSearches(res.data.total_number_of_saved_searches)
        })
        const response1 = axios.get(buildAPIUrl('v1/bookmarks/?query_all_bookmarks=all'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log(res1.data[0].total_number_of_bookmarks)
            setSavedSources(res1.data[0].total_number_of_bookmarks)
        })
        const response2 = axios.get(buildAPIUrl('v1/users/me/?users=all'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log("users", res1)
            setAllUsers(res1.data.total_number_of_all_users)
        })
    }, [])

    return (
        <>
            <div className="container-fluid p-0 m-0 stats">
                <div className="row">
                    <div className="col-md-4">
                        <Link to="/signed-up-users">
                            <div className="card-body">
                                <div className="admin-icon-holder1">
                                    <span><MdOutlinePersonAdd /></span>
                                </div>
                                <div className="stats-content">
                                    <h6>
                                        Total signed up users
                                    </h6>
                                    <h5>
                                        <CountUp
                                            start={0}
                                            end={allUsers}
                                            duration={2.75}
                                            separator=","
                                            decimals={0}
                                        />

                                    </h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to="/all-searches">
                        <div className="card-body">
                            <div className="admin-icon-holder2">
                                <span><MdOutlineSearch /></span>
                            </div>
                            <div className="stats-content">
                                <h6>
                                    Total search conducted
                                </h6>
                                <h5>
                                    <CountUp
                                        start={0}
                                        end={allSearches}
                                        duration={2.75}
                                        separator=","
                                        decimals={0}
                                    />
                                </h5>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to="/all-saved-sources">
                        <div className="card-body">
                            <div className="admin-icon-holder3">
                                <span><MdFolder/></span>
                            </div>
                            <div className="stats-content">
                                <h6>
                                    Total  saved sources
                                </h6>
                                <h5>
                                    <CountUp
                                        start={0}
                                        end={allSavedSources}
                                        duration={2.75}
                                        separator=","
                                        decimals={0}
                                    />
                                </h5>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}