import React, {useEffect, useMemo, useState} from "react";
import {
    CardCvcElement,
    CardElement,
    CardExpiryElement,
    CardNumberElement,
    PaymentElement, PaymentRequestButtonElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Select from "react-select";
import {ReactCountryDropdown} from "react-country-dropdown";
import 'react-country-dropdown/dist/index.css'
import countryList from 'react-select-country-list'
import Header from "../Landing/Header";
import GooglePayButton from "@google-pay/button-react";
import "../SupportRBPage/supportrb.css";
import MediaQuery from "react-responsive";
export default function PaymentForm(props) {
    const stripe = useStripe()
    const [countries, setCountries]=useState([])
    const [country, setCountry] = useState("")
    const [defaultCountry, setDefaultCountry]=useState("")
    const[plan, setPlan]=useState("")
    const[plans, setPlans]=useState([])
    const elements = useElements()
    const [paymentPlan, setPaymentPlan]=useState("")
    const[planId, setPlanId]=useState(localStorage.getItem('priceid'))
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [pickedPrice, setPickedPrice]=useState(null)
    const[loading, setLoading]=useState(true)
    const [success, setSuccess]=useState(false)
    useEffect(async ()=>{
        const response = axios.get('https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code').then((res)=>{
            setCountries(res.data.countries)
            setDefaultCountry(res.data.userSelectValue.label)
        }).catch((error)=>{

        })
        let url = 'v1/subscriptions/';
        if(props?.is_donation){
            url = 'v1/subscriptions/?support_rb_plan=1';
        }
        const plans = axios.get(buildAPIUrl(url)).then((res)=>{
            setPlans(res.data)
            setLoading(false)
        }).catch((error)=>{

        })
       setPlan(localStorage.getItem('priceid'))
        if(stripe){
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'Amount',
                    amount: pickedPrice,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
            pr.canMakePayment().then(result => {
                if (result) {
                    setPaymentRequest(pr);
                }
            });
        }
        //Create a payment intent
        /*const paymentIntent =  stripe.paymentIntents.create({
            amount: 1099,
            currency: 'usd',
            automatic_payment_methods: {
                enabled: true,
            },
        });*/
    }, [])
    const handleSubmit = async (event) => {
        event.preventDefault();
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
        });
        if (paymentMethod) {
            const cardElement = elements.getElement(CardNumberElement);
            let token  = await stripe.createToken(cardElement);
            await stripeTokenHandler(token)
        }
    };
    async function stripeTokenHandler(token) {
        const paymentData = {token: token.id};

        const response  =  await axios.post(buildAPIUrl('v1/subscriptions/'), {
            card_token:token.token.id,
            plan_id:plan
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
        }).then((res)=>{
            /*console.log("Payment successful")*/
           window.location.assign('/payment-success-page')
            setSuccess(true)
        }).catch((error)=>{

        })
    }


   /* const selectCountry =  (val) => {
        setCountry(val.label)
    }
*/const options = useMemo(() => countryList().getData(), [])
    const changeHandler = value => {
        setCountry(value)
    }


    //Get the id of each payment plan
  const  handlePaymentPlan = async (event) => {
  await setPlan(event.target.value)
     await setPlanId(event.target.value)

  }
   const handleChangePrice = async (price) => {
       await setPickedPrice(price)
       await parseInt(localStorage.setItem('priceamount', price))
    }
    const cancelPayment = async() =>{
    await setPickedPrice(null)
    }
    return(
        <>
            <MediaQuery minWidth={1200}>
                {
                    props.type === "support" ? "" : <Header/>
                }
                <div className={"container checkout-header"}>
                    <div className={"row d-flex justify-content-center"}>
                        <div className={"col-md-8"}>
                            <div className={"checkout-header"}>
                                {!props?.is_donation &&<h6>
                                    Payment Method
                                </h6>}
                            </div>
                            <div className={"pricing-options"}>
                                {
                                    props.type==="support" ?

                                        <div className={"row"} onChange={handlePaymentPlan}>
                                            <div className={"d-flex justify-content-around flex-row container-fluid p-0"}>
                                                {
                                                    !loading ?  <>
                                                        {
                                                            plans.sort(function(a, b){return a.stripe_plan_details.amount - b.stripe_plan_details.amount}).map((plandet)=>(
                                                                <div className={""}>
                                                                    <button className={pickedPrice===plandet.stripe_plan_details.amount/100 ? "amount-active" : "amount"} onClick={()=>handleChangePrice(Math.trunc(plandet.stripe_plan_details.amount/100))}>
                                                                        ${Math.trunc(plandet.stripe_plan_details.amount/100)}
                                                                    </button>

                                                                </div>
                                                            ))
                                                        }
                                                    </> :  <>
                                                        <p> Loading...</p>
                                                    </>
                                                }

                                            </div>
                                        </div> :
                                        <div className={"row"} onChange={handlePaymentPlan}>

                                            {
                                                !loading ?  <>
                                                    {
                                                        plans.sort(function(a, b){return a.stripe_plan_details.amount - b.stripe_plan_details.amount}).map((plandet)=>(

                                                            <>
                                                                <div className={"col-md-6 mt-3"}>
                                                                    <div className={"card"}>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                   id="flexRadioDefault1" onClick={()=>handleChangePrice(Math.trunc(plandet.stripe_plan_details.amount/100))} checked={planId===plandet.stripe_plan_details.id} value={plandet.stripe_plan_details.id} />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                {plandet.plan_db_details.plan_type}
                                                                                {
                                                                                    plandet.plan_db_details.plan_type ==="Monthly" ? "" : <span>Save 25%</span>
                                                                                }

                                                                            </label>
                                                                            <h6>${Math.trunc(plandet.stripe_plan_details.amount/100)}/{plandet.stripe_plan_details.interval}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                        ))
                                                    }
                                                </> :  <>
                                                    <p> Loading...</p>
                                                </>
                                            }


                                        </div>
                                }

                            </div>
                            {/*  <GooglePayButton
                            environment="TEST"
                            buttonType={"pay"}
                            buttonSizeMode={"fill"}
                            className={"col-md-12"}
                            paymentRequest={{
                                apiVersion: 2,
                                apiVersionMinor: 0,
                                allowedPaymentMethods: [
                                    {
                                        type: 'CARD',
                                        parameters: {
                                            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                            allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                        },
                                        tokenizationSpecification: {
                                            type: 'PAYMENT_GATEWAY',
                                            parameters: {
                                                gateway: 'example',
                                                gatewayMerchantId: 'exampleGatewayMerchantId',
                                            },
                                        },
                                    },
                                ],
                                merchantInfo: {
                                    merchantId: '12345678901234567890',
                                    merchantName: 'Demo Merchant',
                                },
                                transactionInfo: {
                                    totalPriceStatus: 'FINAL',
                                    totalPriceLabel: 'Total',
                                    totalPrice: '100.00',
                                    currencyCode: 'USD',
                                    countryCode: 'US',
                                },
                            }}
                            onLoadPaymentData={paymentRequest => {
                                console.log('load payment data', paymentRequest);
                            }}
                        />*/}
                            <div className={pickedPrice ? "checkout-form mt-5" : "d-none"}>
                                <form onSubmit={handleSubmit}>
                                    <div className={"mb-3 mt-3"}>
                                        <div className={"card"}>
                                            <label>Card Number</label>

                                            <CardNumberElement />
                                        </div>
                                    </div>
                                    <div className={"mb-3"}>
                                        <div className={"row"}>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>Expiry</label>
                                                    <CardExpiryElement />
                                                </div>
                                            </div>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>CVC</label>
                                                    <CardCvcElement />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mb-3"}>
                                        <div className={"row"}>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>Country</label>
                                                    <Select options={options} className={"country"} value={country} onChange={changeHandler} />
                                                </div>
                                            </div>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>Postal code</label>
                                                    <input type={"text"} className={"form-control postal-code"}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row checkout-buttons"}>
                                        <div className={"col-md-4 mt-3"}>
                                            <div className={"card"}>
                                                <button onClick={cancelPayment} className={"btn btn-cancel-sales"}>Cancel</button>
                                            </div>
                                        </div>
                                        <div className={"col-md-8 mt-3"}>
                                            <div className={"card"}>
                                                {/*   {
                                                plan ?    <button className={"btn btn-confirm-sales"}>Confirm Payment ${filtereditems[0].stripe_plan_details.amount}</button> :*/}
                                                <button className={"btn btn-confirm-sales mb-3"}>${pickedPrice} Confirm Payment</button>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1200} className="tablet" minWidth={461}>
                {
                    props.type === "support" ? "" : <Header/>
                }
                <div className={"container checkout-header"}>
                    <div className={"row d-flex justify-content-center"}>
                        <div className={"col-md-8"}>
                            <div className={"checkout-header"}>
                                {!props?.is_donation &&<h6>
                                    Payment Method
                                </h6>}
                            </div>
                            <div className={"pricing-options"}>
                                {
                                    props.type==="support" ?

                                        <div className={"row"} onChange={handlePaymentPlan}>
                                            <div className={"d-flex justify-content-around flex-row container-fluid p-0"}>
                                                {
                                                    !loading ?  <>
                                                        {
                                                            plans.sort(function(a, b){return a.stripe_plan_details.amount - b.stripe_plan_details.amount}).map((plandet)=>(
                                                                <div c>
                                                                    <button className={pickedPrice===plandet.stripe_plan_details.amount/100 ? "amount-active" : "amount"} onClick={()=>handleChangePrice(Math.trunc(plandet.stripe_plan_details.amount/100))}>
                                                                        ${Math.trunc(plandet.stripe_plan_details.amount/100)}
                                                                    </button>

                                                                </div>
                                                            ))
                                                        }
                                                    </> :  <>
                                                        <p> Loading...</p>
                                                    </>
                                                }

                                            </div>
                                        </div> :
                                        <div className={"row"} onChange={handlePaymentPlan}>

                                            {
                                                !loading ?  <>
                                                    {
                                                        plans.sort(function(a, b){return a.stripe_plan_details.amount - b.stripe_plan_details.amount}).map((plandet)=>(

                                                            <>
                                                                <div className={"col-md-6 mt-3"}>
                                                                    <div className={"card"}>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                   id="flexRadioDefault1" onClick={()=>handleChangePrice(Math.trunc(plandet.stripe_plan_details.amount/100))} checked={planId===plandet.stripe_plan_details.id} value={plandet.stripe_plan_details.id} />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                {plandet.plan_db_details.plan_type}
                                                                                {
                                                                                    plandet.plan_db_details.plan_type ==="Monthly" ? "" : <span>Save 25%</span>
                                                                                }

                                                                            </label>
                                                                            <h6>${Math.trunc(plandet.stripe_plan_details.amount/100)}/{plandet.stripe_plan_details.interval}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                        ))
                                                    }
                                                </> :  <>
                                                    <p> Loading...</p>
                                                </>
                                            }


                                        </div>
                                }

                            </div>
                            {/*  <GooglePayButton
                            environment="TEST"
                            buttonType={"pay"}
                            buttonSizeMode={"fill"}
                            className={"col-md-12"}
                            paymentRequest={{
                                apiVersion: 2,
                                apiVersionMinor: 0,
                                allowedPaymentMethods: [
                                    {
                                        type: 'CARD',
                                        parameters: {
                                            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                            allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                        },
                                        tokenizationSpecification: {
                                            type: 'PAYMENT_GATEWAY',
                                            parameters: {
                                                gateway: 'example',
                                                gatewayMerchantId: 'exampleGatewayMerchantId',
                                            },
                                        },
                                    },
                                ],
                                merchantInfo: {
                                    merchantId: '12345678901234567890',
                                    merchantName: 'Demo Merchant',
                                },
                                transactionInfo: {
                                    totalPriceStatus: 'FINAL',
                                    totalPriceLabel: 'Total',
                                    totalPrice: '100.00',
                                    currencyCode: 'USD',
                                    countryCode: 'US',
                                },
                            }}
                            onLoadPaymentData={paymentRequest => {
                                console.log('load payment data', paymentRequest);
                            }}
                        />*/}
                            <div className={pickedPrice ? "checkout-form mt-5" : "d-none"}>
                                <form onSubmit={handleSubmit}>
                                    <div className={"mb-3 mt-3"}>
                                        <div className={"card"}>
                                            <label>Card Number</label>

                                            <CardNumberElement />
                                        </div>
                                    </div>
                                    <div className={"mb-3"}>
                                        <div className={"row"}>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>Expiry</label>
                                                    <CardExpiryElement />
                                                </div>
                                            </div>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>CVC</label>
                                                    <CardCvcElement />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mb-3"}>
                                        <div className={"row"}>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>Country</label>
                                                    <Select options={options} className={"country"} value={country} onChange={changeHandler} />
                                                </div>
                                            </div>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>Postal code</label>
                                                    <input type={"text"} className={"form-control postal-code"}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row checkout-buttons"}>
                                        <div className={"col-md-4 mt-3"}>
                                            <div className={"card"}>
                                                <button onClick={cancelPayment} className={"btn btn-cancel-sales"}>Cancel</button>
                                            </div>
                                        </div>
                                        <div className={"col-md-8 mt-3"}>
                                            <div className={"card"}>
                                                {/*   {
                                                plan ?    <button className={"btn btn-confirm-sales"}>Confirm Payment ${filtereditems[0].stripe_plan_details.amount}</button> :*/}
                                                <button className={"btn btn-confirm-sales mb-3"}>${pickedPrice} Confirm Payment</button>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={460}>
                {
                    props.type === "support" ? "" : <Header/>
                }
                <div className={"container checkout-header"}>
                    <div className={"row d-flex justify-content-center"}>
                        <div className={"col-md-8"}>
                            <div className={"checkout-header"}>
                                {!props?.is_donation &&<h6>
                                    Payment Method
                                </h6>}
                            </div>
                            <div className={"pricing-options"}>
                                {
                                    props.type==="support" ?

                                        <div className={"row px-3"} onChange={handlePaymentPlan}>
                                            <div className={"row container d-flex justify-content-center"}>
                                                {
                                                    !loading ?  <>
                                                        {
                                                            plans.sort(function(a, b){return a.stripe_plan_details.amount - b.stripe_plan_details.amount}).map((plandet)=>(
                                                                <div className={"col-4 mt-2"}>
                                                                    <button className={pickedPrice===plandet.stripe_plan_details.amount/100 ? "amount-active" : "amount"} onClick={()=>handleChangePrice(Math.trunc(plandet.stripe_plan_details.amount/100))}>
                                                                        ${Math.trunc(plandet.stripe_plan_details.amount/100)}
                                                                    </button>

                                                                </div>
                                                            ))
                                                        }
                                                    </> :  <>
                                                        <p> Loading...</p>
                                                    </>
                                                }

                                            </div>
                                        </div> :
                                        <div className={"row"} onChange={handlePaymentPlan}>

                                            {
                                                !loading ?  <>
                                                    {
                                                        plans.sort(function(a, b){return a.stripe_plan_details.amount - b.stripe_plan_details.amount}).map((plandet)=>(

                                                            <>
                                                                <div className={"col-md-6 mt-3"}>
                                                                    <div className={"card"}>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                                   id="flexRadioDefault1" onClick={()=>handleChangePrice(Math.trunc(plandet.stripe_plan_details.amount/100))} checked={planId===plandet.stripe_plan_details.id} value={plandet.stripe_plan_details.id} />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                                {plandet.plan_db_details.plan_type}
                                                                                {
                                                                                    plandet.plan_db_details.plan_type ==="Monthly" ? "" : <span>Save 25%</span>
                                                                                }

                                                                            </label>
                                                                            <h6>${Math.trunc(plandet.stripe_plan_details.amount/100)}/{plandet.stripe_plan_details.interval}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>

                                                        ))
                                                    }
                                                </> :  <>
                                                    <p> Loading...</p>
                                                </>
                                            }


                                        </div>
                                }

                            </div>
                            {/*  <GooglePayButton
                            environment="TEST"
                            buttonType={"pay"}
                            buttonSizeMode={"fill"}
                            className={"col-md-12"}
                            paymentRequest={{
                                apiVersion: 2,
                                apiVersionMinor: 0,
                                allowedPaymentMethods: [
                                    {
                                        type: 'CARD',
                                        parameters: {
                                            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                            allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                        },
                                        tokenizationSpecification: {
                                            type: 'PAYMENT_GATEWAY',
                                            parameters: {
                                                gateway: 'example',
                                                gatewayMerchantId: 'exampleGatewayMerchantId',
                                            },
                                        },
                                    },
                                ],
                                merchantInfo: {
                                    merchantId: '12345678901234567890',
                                    merchantName: 'Demo Merchant',
                                },
                                transactionInfo: {
                                    totalPriceStatus: 'FINAL',
                                    totalPriceLabel: 'Total',
                                    totalPrice: '100.00',
                                    currencyCode: 'USD',
                                    countryCode: 'US',
                                },
                            }}
                            onLoadPaymentData={paymentRequest => {
                                console.log('load payment data', paymentRequest);
                            }}
                        />*/}
                            <div className={pickedPrice ? "checkout-form mt-5" : "d-none"}>
                                <form onSubmit={handleSubmit}>
                                    <div className={"mb-3 mt-3"}>
                                        <div className={"card"}>
                                            <label>Card Number</label>

                                            <CardNumberElement />
                                        </div>
                                    </div>
                                    <div className={"mb-3"}>
                                        <div className={"row"}>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>Expiry</label>
                                                    <CardExpiryElement />
                                                </div>
                                            </div>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>CVC</label>
                                                    <CardCvcElement />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"mb-3"}>
                                        <div className={"row"}>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>Country</label>
                                                    <Select options={options} className={"country"} value={country} onChange={changeHandler} />
                                                </div>
                                            </div>
                                            <div className={"col-md-6 mt-3"}>
                                                <div className={"card"}>
                                                    <label>Postal code</label>
                                                    <input type={"text"} className={"form-control postal-code"}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"row checkout-buttons"}>
                                        <div className={"col-md-4 mt-3"}>
                                            <div className={"card"}>
                                                <button onClick={cancelPayment} className={"btn btn-cancel-sales"}>Cancel</button>
                                            </div>
                                        </div>
                                        <div className={"col-md-8 mt-3"}>
                                            <div className={"card"}>
                                                {/*   {
                                                plan ?    <button className={"btn btn-confirm-sales"}>Confirm Payment ${filtereditems[0].stripe_plan_details.amount}</button> :*/}
                                                <button className={"btn btn-confirm-sales mb-3"}>${pickedPrice} Confirm Payment</button>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>


        </>
    )
}