import React, { useState, useEffect} from 'react';
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";
import Header from "../Header";
import {useParams} from "react-router-dom";


function EditTool() {
    const params = useParams();

    const [newToolDetails, setnewToolDetails] = useState({
        name: "",
        url: "",
        tag: "",
    });
    const [listOfTags, setlistOfTags] = useState([]);
    
    const [userToken, setUserToken] = useState(localStorage.getItem('token'))
    const { isLoading, error, isError, sendRequest: createToolHandler } = useHttpFetch();
    


    if (!userToken) {
        window.location.assign('/');
    }

    useEffect(() => {
        const setTools = (data) => {
          setnewToolDetails(data);
        }
       
        createToolHandler(
          {
            url: buildAPIUrl(`v1/research_tools/${params.tool_id}/`),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`
            }
          },
          setTools
        );
      }, [createToolHandler, userToken, params.tool_id]);

    function onChangeHandler(event) {
        setnewToolDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }

    useEffect(() => {
        const setTags = (data) => {
            setlistOfTags(data);
        }

        createToolHandler(
          {
            url: buildAPIUrl('v1/tags/'),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`
            }
          },
          setTags
        );
      }, [createToolHandler, userToken]);


    function resetValue(data){
        setnewToolDetails({
            name: "",
            url: "",
            tag: "",
        })
        window.location.assign('/research_tools/all');
    }
    async function submitHandler(event) {
        event.preventDefault();



        const new_tool = {
            name: newToolDetails.name,
            url: newToolDetails.url
        }

        if(newToolDetails.tag){
            new_tool['tags'] = [newToolDetails.tag,]
        }
        
        createToolHandler({
            method: 'PATCH',
            url: buildAPIUrl(`v1/research_tools/${params.tool_id}/`),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`
            },
            body: new_tool,
        }, resetValue, false);

    }
  

    let content = <option defaultValue="NONE">------</option>;

    if (listOfTags.length > 0) {
        
        content = listOfTags.map((tag) => (

            <option key={tag.id} value={tag.id}>{tag.name}</option>
        ));
    }

    return (
        <>
           <Header />
            <div className={"container-fluid mt-5"}>
                <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            {isLoading && <p>Loading.....</p>}
                            {isError && <p>{error}</p>}
                            <div className={"card border"}>
                                <div className={"card-header"}>
                                    <h4>Edit Tool</h4>
                                </div>
                                <div className={"card-body"}>
                                    <form onSubmit={submitHandler}>
                                        <div className={"mb-3"}>
                                            <label htmlFor='name'>Name</label>
                                            <input
                                                type='text'
                                                id='name'
                                                name='name'
                                                defaultValue={newToolDetails.name}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />
                                        </div>
                                        
                                        <div className={"mb-3"}>
                                            <label htmlFor='url'>Url</label>
                                            <input
                                                className={"form-control"}
                                                type='url'
                                                id='url'
                                                name='url'
                                                defaultValue={newToolDetails.url}
                                                onChange={onChangeHandler}
                                                required
                                            />
                                        </div>


                                        <div className={"mb-3"}>
                                            <label htmlFor="tag">Tags:</label>
                                            <select className={"form-control"} name="tag" id="tag" defaultValue={newToolDetails.tag} onChange={event => onChangeHandler(event)}>
                                                <option value="">----</option>
                                                {content}
                                            </select>

                                        </div>
                                        
                                        
                                        <button className={"btn btn-success"}>Edit Tool</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
export default EditTool;