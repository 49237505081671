import React, {Component} from "react";
import ContentEditable from "react-contenteditable";
import Skeleton from "react-loading-skeleton";
import {FaRegBookmark, FaRegCopy} from "react-icons/fa";
import {copyLink, saveBookmark} from "./CategoriesFunctions";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import {Modal, ModalFooter} from "react-bootstrap";
import {MdClear, MdFormatQuote, MdOpenInNew, MdOutlinePreview, MdSettings} from "react-icons/md";
import axios from "axios";
import OpenAI from "openai"


class ResultComponent extends Component{
    state = {
        searcht: localStorage.getItem('searchquery'),
        copy:false,
        saved:false,
        token:localStorage.getItem('token'),
        exists:"",
        isTourOpen: true,
        result:[],
        chatgptsecret:process.env.REACT_APP_CHATGPT_SECRET,
        chatgptresponse:"",
        generating: true,
        preview:false,
        previewcontent:"",
        generatingpreview:true,
        previewname:"",
        previewurl:"",
        previewcitation:"",
        generatingcitation:true,
        cite:false,
        previewcitationMLA:"",
        previewcitationHarvard:"",
        generatingcitationHarvard:true,
        generatingcitationMLA:true
    }

/*-----Copy results link------*/
    handleCopyLink = (result)=>{
        copyLink(result, (copystatus)=>{
            this.props.handleCopy(copystatus)
        })

    }
    /*-----Bookmark Result------*/
    handleSaveSearch = (result, bookmarktype)=>{
      saveBookmark(result, bookmarktype, (savestatus)=>{

          this.props.handleSave(savestatus, "")
      }, (error)=>{
          this.props.handleSave(false, error)
      })
    }
    handleModalOpen = (result, logtype)=>{
        this.props.handleMod(result, logtype)
    }
    saveEvent = ()=> {
        EventsHandler(`Search results link clicked`, document.URL)
           }

    handlePreview = async (url, name)=>{
        this.setState({
            preview:true,
            previewname:name,
            previewurl:url
        })
        const openai = new OpenAI({
            apiKey:process.env.REACT_APP_CHATGPT_SECRET,
            dangerouslyAllowBrowser: true
        })
        const completion = await openai.chat.completions.create({
            messages:[{
                "role":"user", "content":`Please summarize the content of this URL in a list of 4 paragraphs without numbering: ${url}`
            }],
            model:'gpt-4',
            temperature: 1,
            max_tokens: 256,
        })
        this.setState({
            previewcontent: completion.choices[0].message["content"],
            generatingpreview:false
        })
        EventsHandler(`Search results link clicked`, document.URL)

        /*const completion = await openai.chat.completion.create({
            messages:[{
                "role":"user", "content":`Please summarize the content of this URL in a list of 4 paragraphs without numbering: ${url}`
            }],
            model:'gpt-3.5-turbo',
        })*/

       /* await axios.post('https://api.openai.com/v1/engines/text-davinci-002/completions',
            {
                prompt: `Please summarize the content of this URL in a list of 4 paragraphs without numbering: ${url}`,
                max_tokens: 500
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.chatgptsecret}`
                }
            }).then((res)=>{

            setTimeout(()=>{
                this.setState({
                    previewcontent: res.data.choices[0].text.slice(0,res.data.choices[0].text.length + 1),
                    generatingpreview:false
                })
            }, 100)


        });*/
    }
    //Hide content preview
    reghandleClose = ()=>{
        this.setState({
            preview:false
        })
    }
    //Hide Citation preview
    citehandleClose = ()=>{
        this.setState({
            cite:false,
            previewname:"",
            previewurl:""
        })
    }
    handleCitation = async (url, name)=>{
        this.setState({
            cite:true,
            previewname:name,
            previewurl:url
        })
        //Get response from chat gpt
        await axios.post('https://api.openai.com/v1/engines/text-davinci-002/completions',
            {
                prompt: `Please generate an accurate APA citation from: ${url}`,
                max_tokens: 500
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.chatgptsecret}`
                }
            }).then((res)=>{
            setTimeout(()=>{
                this.setState({
                    previewcitation: res.data.choices[0].text.slice(0,res.data.choices[0].text.length + 1),
                    generatingcitation:false
                })
            }, 100)


        });
        //Get response from chat gpt
        await axios.post('https://api.openai.com/v1/engines/text-davinci-002/completions',
            {
                prompt: `Please generate an accurate MLA citation from: ${url}`,
                max_tokens: 500
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.chatgptsecret}`
                }
            }).then((res)=> {
            setTimeout(() => {
                this.setState({
                    previewcitationMLA: res.data.choices[0].text.slice(0, res.data.choices[0].text.length + 1),
                    generatingcitationMLA:false
                })
            }, 100)
        })
        //Get response from chat gpt
        await axios.post('https://api.openai.com/v1/engines/text-davinci-002/completions',
            {
                prompt: `Please generate an accurate Harvard citation from: ${url}`,
                max_tokens: 500
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.chatgptsecret}`
                }
            }).then((res)=> {
            setTimeout(() => {
                this.setState({
                    previewcitationHarvard: res.data.choices[0].text.slice(0, res.data.choices[0].text.length + 1),
                    generatingcitationHarvard:false
                })
            }, 100)
        })
    }
    render() {
        return (
            <>
                <Modal size="lg" style={{borderRadius:"2px"}} centered show={this.state.cite}  onHide={this.citehandleClose} >

                <Modal.Body style={{overflowX:"hidden", overflowY:"scroll", padding:"2rem"}}>
                    <div className={"row w-100"}>
                        <div className={"col-md-10"}>
                            <h6 style={{fontWeight:"600", lineHeight:"150%", color:"#00944D"}}>{this.state.previewname.substr(0, 100)}</h6>
                            <h6 style={{fontWeight:"400", lineHeight:"150%"}}>Citation</h6>
                        </div>
                        <div className={"col-md-2 col-2 d-flex justify-content-end"}>
                            <span onClick={this.citehandleClose} className={"d-flex justify-content-center align-items-center"} style={{color:"#A69FAD", borderRadius:"8px", height:"34px", width:"34px", border:"#A69FAD 1px solid", cursor:"pointer"}}><MdClear /></span>
                        </div>
                    </div>
                    <div className="row content-preview" style={{borderRadius:"2px", marginTop:"2rem", background:"#F7F5FF", padding:"1rem"}}>
                        <div className={"container"}>
                        {
                            this.state.generatingcitation ? <>
                                <p>Generating APA citation...</p>
                            </> : <>
                                <h6>APA</h6>
                                {
                                    this.state.previewcitation.length > 0 ? <>
                                            <div style={{color:"black", lineHeight:"150%"}} dangerouslySetInnerHTML={{__html: this.state.previewcitation}}>

                                            </div>
                                        </>
                                : <>
                                        <p>Sorry. No citation generated</p>
                                    </>
                                }
                                    </>
                                }
                        </div>
                        <div className={"container mt-5"}>
                        {
                            this.state.generatingcitationMLA ? <>
                                <p>Generating MLA citations...</p>
                            </> : <>
                                <h6>MLA</h6>
                                {
                                    this.state.previewcitationMLA.length > 0 ? <>
                                            <div style={{color: "black", lineHeight: "150%"}}
                                                 dangerouslySetInnerHTML={{__html: this.state.previewcitationMLA}}>

                                            </div>
                                        </>
                                        : <>
                                            <p>Sorry. No citation generated</p>
                                        </>
                                }
                            </>
                        }
                        </div>
                        <div className={"container mt-5"}>
                            {
                                this.state.generatingcitationHarvard ? <>
                                    <p>Generating Harvard citation...</p>
                                </> : <>
                                    <h6>Harvard</h6>
                                    {
                                        this.state.previewcitationHarvard.length > 0 ? <>
                                                <div style={{color:"black", lineHeight:"150%"}} dangerouslySetInnerHTML={{__html: this.state.previewcitationHarvard}}>

                                                </div>
                                            </>
                                            : <>
                                                <p>Sorry. No citation generated</p>
                                            </>
                                    }</>
                            }
                        </div>
                        <p className={"mt-5"}>
                            please note that citation style guide may differ based on your institution or organization, so it is important to consult the appropriate guidelines
                        </p>

                    </div>
                </Modal.Body>
                <ModalFooter style={{border:"none"}}>

                </ModalFooter>

            </Modal>
                <Modal size="lg" style={{borderRadius:"2px"}} centered show={this.state.preview}  onHide={this.reghandleClose} >

                    <Modal.Body style={{overflowX:"hidden", overflowY:"hidden", padding:"2rem"}}>
                        <div className={"row w-100"}>
                            <div className={"col-md-12 col-2 d-flex justify-content-end"}>
                                <span onClick={this.reghandleClose} className={"d-flex justify-content-center align-items-center"} style={{color:"#000000", borderRadius:"8px", height:"34px", width:"34px",cursor:"pointer", fontSize:"34px"}}><MdClear /></span>
                            </div>
                            <div className={"col-md-12 d-flex justify-content-center"}>
                                <h6 className={"summary-title text-center"} style={{fontWeight:"400", fontSize:"28px", lineHeight:"110%", color:"#00944D",  marginTop:"1rem"}}>{this.state.previewname.substr(0, 100)}</h6>
                            </div>

                        </div>
                        <div className="row content-preview" style={{borderRadius:"2px", padding:"1rem"}}>
                            {
                                this.state.generatingpreview ? <>
                                    <div className={"container d-flex justify-content-center text-center"}>
                                        <div>
                                            <MdSettings style={{fontSize:"45px"}} />
                                            <p className={"generating"} style={{marginTop:"1rem"}}>Generating a summary...</p>
                                        </div>
                                    </div>

                                </> : <>
                                    <div style={{color:"black", lineHeight:"150%"}}>
                                       <p className={"summary"} style={{whiteSpace:"pre-line", fontSize:"16px", lineHeight:"22px", marginTop:"-2.5rem"}}>{this.state.previewcontent}</p>
                                    </div>
                                    <div className={"container-fluid mt-3 d-flex justify-content-end"}>
                                        <a href={this.state.previewurl} target={"_blank"} className={"btn"} style={{background:"#522FFF", color:"white"}}>
                                            Go to site <MdOpenInNew />
                                        </a>
                                    </div>
                                </>
                            }
                        </div>
                    </Modal.Body>
                    <ModalFooter style={{border:"none"}}>

                    </ModalFooter>

                </Modal>
                {this.props.type==="News" ?
                    <div className={this.props.position==="first" ? "bg-white first-result-component" : "bg-white result-component" }>
                        <a onClick={()=>this.saveEvent()} href={this.props.result.url} target="_blank"><h6
                            className="result-url mt-2 mb-2">{this.props.result.url.substr(0, 100)}</h6>
                        </a>
                        <a onClick={()=>this.saveEvent()} href={this.props.result.url} target="_blank"><h6
                            className="result-name mt-2 mb-2">
                            <ContentEditable
                                innerRef={this.props.result.name}
                                html={this.props.result.name.substr(0, 150).toLowerCase().replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>")} // innerHTML of the editable div
                                disabled={true}       // use true to disable editing
                                onChange={this.handleChange} // handle innerHTML change
                                tagName='article' // Use a custom HTML tag (uses a div by default)
                            />
                        </h6>
                        </a>
                        <p className="result-snippet">
                            <ContentEditable
                                innerRef={this.props.result.description}
                                html={this.props.result.description.substr(0, 250).replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>") ||
                                    <Skeleton/>} // innerHTML of the editable div
                                disabled={true}       // use true to disable editing
                                onChange={this.handleChange} // handle innerHTML change
                                tagName='article' // Use a custom HTML tag (uses a div by default)
                            />
                        </p>
                        <span
                            className="date-crawled">{new Date(this.props.result.datePublished).toLocaleDateString()}</span>
                        {
                            this.state.token ? <span className="save save-icon-onboard"
                                                     onClick={() => this.handleSaveSearch(this.props.result, this.props.type)}> Save <FaRegBookmark
                                    className="save-icon"/></span> :
                                <span className="save save-icon-onboard"
                                      onClick={() => this.handleModalOpen(this.props.result, "1")}> Save <FaRegBookmark/> </span>
                        }

                        <span className="save copy-section" onMouseDown={() => {
                            this.handleCopyLink(this.props.result.url)
                        }}> Copy link <FaRegCopy className="save-icon"/></span>
                    </div>
                    :
                    <div className={this.props.position==="first" ? "bg-white first-result-component" : "bg-white result-component" }>
                        <div className={"row"}>
                            <div className={"col-md-9"}>
                                <a href={this.props.result.url}  target="_blank"><h6
                                    className="result-url mt-2 mb-2">{this.props.result.url.substr(0, 100)}</h6>
                                </a>
                            </div>
                            <div className={"col-md-3"}>
                                <div className={"container-fluid d-flex justify-content-end d-flex align-items-center"}>

                                </div>
                            </div>
                        </div>

                        <a href={this.props.result.url} target="_blank"><h6
                            className="result-name mt-2 mb-2">
                            <ContentEditable
                                innerRef={this.props.result.name}
                                html={this.props.result.name.substr(0, 150).toLowerCase().replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>")} // innerHTML of the editable div
                                disabled={true}       // use true to disable editing
                                onChange={this.handleChange} // handle innerHTML change
                                tagName='article' // Use a custom HTML tag (uses a div by default)
                            />
                        </h6>
                        </a>
                        <p className="result-snippet">
                            <ContentEditable
                                innerRef={this.props.result.snippet}
                                html={this.props.result.snippet.substr(0, 150).replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>") ||
                                    <Skeleton/>} // innerHTML of the editable div
                                disabled={true}       // use true to disable editing
                                onChange={this.handleChange} // handle innerHTML change
                                tagName='article' // Use a custom HTML tag (uses a div by default)
                            />
                        </p>
                        <span
                            className="date-crawled">{new Date(this.props.result.dateLastCrawled).toLocaleDateString()}</span>
                        {
                            this.state.token ? <span className="save save-icon-onboard"
                                                     onClick={() => this.handleSaveSearch(this.props.result, this.props.type)}> Save <FaRegBookmark
                                    className="save-icon"/></span> :
                                <span className="save save-icon-onboard"
                                      onClick={() => this.handleModalOpen(this.props.result, "1")}> Save <FaRegBookmark/> </span>
                        }

                        <span className="save copy-section" onMouseDown={() => {
                            this.handleCopyLink(this.props.result.url)
                        }}> Copy link <FaRegCopy className="save-icon"/></span>
                       {/* <span className={"save copy-section"} onClick={()=>{
                            this.handleCitation(this.props.result.url, this.props.result.name)
                        }}>
                           Cite  <MdFormatQuote className="save-icon" />
                        </span>*/}
                    </div>
                }

            </>
        );
    }
}
export default ResultComponent