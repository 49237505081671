import React, {useEffect, useState} from 'react'
import Header from "./Header";
import {HiSearch} from "react-icons/hi";
import {Link} from "react-router-dom";
import axios from "axios";
import buildAPIUrl from "../Services/UrlBuilder";
import * as XLSX from 'xlsx';
import cards from "../assets/Group 23.png";
import SkeletonSearchResult from "../User/Components/Landing/Skeletons/SkeletonSearchResult";

export default function SignedUpUsers () {
    const [loading, setLoading]=useState(true)
    const [allUsers, setAllUsers] = useState([])
    const [q, setQ] = useState("")
    const [userToken, setUserToken]=useState(localStorage.getItem('token'))
    const handleSearch = async (e) =>{
       setQ(e.target.value)
    }

    const handleInput = () => {

    }
    useEffect (()=>{
        const response2 = axios.get(buildAPIUrl('v1/users/me/?users=all'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log("users", res1.data.all_users)
            setAllUsers(res1.data.all_users)
            setLoading(false)
        })
    }, [])
   console.log(allUsers)
    const data = allUsers.filter(function( element ) {
        return element.first_name !== null;
    });

    const filtereditems = data.filter((result)=> {
            if (q === "") {
                return result
            } else if (result.first_name.toLowerCase().includes(q.toLowerCase())) {
                return result

            }
    })
    const handleExport = ()=>{
        var wb = XLSX.utils.book_new()
        var ws = XLSX.utils.json_to_sheet(allUsers)
        XLSX.utils.book_append_sheet(wb,ws,"userslist")
        XLSX.writeFile(wb, "Userlist.xlsx")
    }
    return (
        <>
            <Header />
            <div className="row" style={{paddingLeft: "1rem", paddingRight:"1rem", marginLeft:"17.125rem", marginRight:"8.5rem"}}>
                <div className="col-md-12 col-12">
                    <div className="search-section col-12 col-md-12 ">
                        <div className="row d-flex align-items-center ">
                            <div className=" col-12 col-md-12 d-flex justify-content-center">
                                <form onKeyUp={handleSearch} style={{width:"100%"}} autoComplete="off">
                                    <div className="form-input">
                                                             <span className="input-group-addon d-flex align-items-center"> <HiSearch
                                                                 className="search-icon"/></span>
                                        <input className="search-field-text1 mob" type="text" name="q"
                                               style={{width: "100%", paddingLeft:"8%"}} placeholder="Search for user"/>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid breadcrumbs p-0" style={{marginTop:"1.5rem"}}>
                        <div className="">
                            <div className="row">
                                <div className="col-8 d-flex align-items-center">
                                    <h6><Link to="/admin-dashboard" style={{textDecoration: "none"}}> <span
                                        style={{color: "#00944D", cursor: "pointer"}}> Dashboard > </span>
                                    </Link> All signed up users</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:"1.75rem"}}>
                            <div className="col-md-6 col-6 d-flex align-items-center">
                                <h5>All signed up users</h5>
                            </div>
                            <div className="col-md-6 col-6 d-flex justify-content-end">
                                    <button className="btn btn-fav" onClick={handleExport}>Export data</button>
                            </div>
                        </div>
                    </div>
                    <div className="saved-sources mt-3">
                        {
                            loading ? <>

                                {
                                    [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                }

                            </> :
                                <>
                                    {
                                        filtereditems.length > 0 ? <table className="history-table mt-3">
                                                <thead className="">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    {/*<th>Source</th>*/}

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    allUsers ?
                                                        <>
                                                            {
                                                                filtereditems.map((user)=>(
                                                                        <tr>
                                                                            <td>{ user.first_name || user.last_name ? user.first_name + " " + user.last_name : ".. "}</td>
                                                                            <td>{user.email}</td>
                                                                        </tr>
                                                                    )

                                                                )
                                                            }


                                                        </>

                                                        : "There are no users"
                                                }


                                                </tbody>
                                            </table> :
                                        <>
                                        <div className="container-fluid d-flex justify-content-center">
                                            <div className="text-center">
                                            <img
                                                src={cards}/><br/>
                                            <h6>No results found for this keyword</h6>
                                            <p>This user is not available in users list.</p>
                                            </div>
                                        </div>
                                        </>

                                    }
                                </>


                        }
                    </div>

                </div>
            </div>
        </>
    )
}