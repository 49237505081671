import React, {Component} from "react";
import MediaQuery from "react-responsive";

class LeftImageComponent extends Component{
    render() {
        return (
            <>

                <div className={"row items-container"}>
                    <div className={"col-md-6"}>
                        <div className={"container-fluid d-flex align-items-center"}>
                        <img src={this.props.image}/>
                        </div>
                    </div>
                    <div className={"col-md-6 d-flex align-items-center"}>
                        <div className={"container-fluid"} style={this.props.device==="mobile" ? {marginTop:"1.563rem"} : {marginTop:"0"}}>
                            <h4 style={this.props.type==="white" ? {color:this.props.type, width:"80%"} : {width:"80%"}}>{this.props.title}</h4>
                            <p style={this.props.type==="white" ? {color:this.props.type, width:"80%"} : {width:"80%"}}>
                                {this.props.text}
                            </p>
                            {this.props.type==="white"?
                                <>
                                    <a href={this.props.url} target={"_blank"}>
                                        <button className={"btn"}>{this.props.buttontext}</button>
                                    </a>

                                </> :
                                <>
                                    {
                                        this.props.type==="blue" ? <>
                                                <a href={this.props.url} target={"_blank"}>
                                                    {this.props.urltext}
                                                </a>
                                            </> :
                                            <>

                                            </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default LeftImageComponent