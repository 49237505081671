import React, {Component} from "react";
import {nextPage} from "./CategoriesFunctions";
import {FaAngleDoubleLeft, FaAngleDoubleRight} from "react-icons/fa";

class Pagination extends Component{
    state={
        offset: this.props.paginateoff,
        offsetvalue: 0,
        loading:true,
    }
    componentDidMount() {
        this.setState({
            offset:this.props.paginateoff
        })
    }

    handleNext = async (direction, page) =>{
        const nextdirection = direction // Helps in using one function to move to next or previous.
                                        //Positive means next, while negative means previous
        const currentoffsetvalue = this.state.offsetvalue
        const currentoffset = this.state.offset
        const offsetincreament = this.props.increment
         if (nextdirection==="positive"){
             await this.setState({
                offsetvalue: parseFloat(currentoffsetvalue) + offsetincreament,
                offset: parseFloat(currentoffset) + 1
            })
        } else if(nextdirection==="negative") {
            await this.setState({
                offsetvalue: parseFloat(currentoffsetvalue) - offsetincreament,
                offset: parseFloat(currentoffset) - 1
            })
        } else {
            //Handles actual page number clicks

             await this.setState({
                offset: parseFloat(page),
                offsetvalue: parseFloat(page) * offsetincreament
            })
        }
    await  nextPage(this.props.type,this.props.resulttype, this.state.offsetvalue, this.props.paginateoff,this.props.count, this.props.website, (successCallBack)=>{
            //The videos response is different from other responses.
            //This condition checks if the pagination request is coming from the videos section
            if(this.props.resulttype==="videos") {
              this.setState({
                    results: successCallBack.value,
                    total: successCallBack.totalEstimatedMatches,
                    /*images: res.data.videos.value,*/
                    loading: false,
                })
                this.props.handleNext(this.state.results, this.state.total, this.state.pagination,this.state.loading, this.state.offset)

            } else if(this.props.resulttype==="news"){
                this.setState({
                    results: successCallBack.value,
                    total: successCallBack.totalEstimatedMatches,
                    pagination: successCallBack.rankingResponse.mainline.items,
                    /*images: res.data.videos.value,*/
                    loading: false,
                })
            } else {
                this.setState({
                    results: successCallBack.webPages.value,
                    total: successCallBack.webPages.totalEstimatedMatches,
                    pagination: successCallBack.rankingResponse.mainline.items,
                    /*images: res.data.videos.value,*/
                    loading: false,
                })
                this.props.handleNext(this.state.results, this.state.total, this.state.pagination,this.state.loading, this.state.offset)

            }



        }, (error)=>{

        })

    }
    render() {
        console.log(this.props.paginateoff, this.state.offset)
        return (
            <div>
                <nav className="d-flex justify-content-center">
                    {
                        this.state.notfound ? " " :
                            <ul className="pagination">
                                <li className="page-item"><a
                                    className={this.state.offset > 0 ? "page-link" : "d-none"}
                                    onClick={()=>this.handleNext("negative")}>
                                   <FaAngleDoubleLeft />
                                </a></li>
                                <li className={this.props.paginateoff === 0  ? "page-item active" : "page-item"}>
                                    <a className="page-link"
                                       onClick={() => this.handleNext("page", "0")}>1</a>
                                </li>
                                <li className={this.props.paginateoff  === 1 ? "page-item active" : "page-item"}>
                                    <a className="page-link"
                                       onClick={() => this.handleNext("page", "1")}>2</a>
                                </li>
                                <li className={this.props.paginateoff === 2 ? "page-item active" : "page-item"}>
                                    <a className="page-link"
                                       onClick={() => this.handleNext("page", "2")}>3</a>
                                </li>
                                <li className={this.props.paginateoff  === 3 ? "page-item active" : "page-item"}>
                                    <a className="page-link"
                                       onClick={() => this.handleNext("page", "3")}>4</a>
                                </li>
                                <li className={this.props.paginateoff  === 4 ? "page-item active" : "page-item"}>
                                    <a className="page-link"
                                       onClick={() => this.handleNext("page", "4")}>5</a>
                                </li>
                                <li className="page-item"><a
                                    className={this.props.paginateoff < 4 ? "page-link" : "d-none"}
                                    onClick={()=>this.handleNext("positive")}><FaAngleDoubleRight /></a></li>
                            </ul>
                    }
                </nav>
            </div>
        );
    }
}

export default Pagination