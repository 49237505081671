import React, {Component} from "react";
import axios from "axios";
import {secondLevelFilter} from "./CategoriesFunctions";
import {FaLinkedin} from "react-icons/fa";
import MediaQuery from "react-responsive";

class SocialMediaFilters extends Component{
    state={
        url:"1",
        searchterm: localStorage.getItem('searchquery'),
        socialmedia: "linkedin.com",
        configID:`${process.env.REACT_APP_BING_CONFIG_ID}`,
        subscrKey:`${process.env.REACT_APP_BING_SUBSCRIPTION_ID}`,
        social:null
    }
    handleSocialMedia = (company, urlid, response) => {
        secondLevelFilter(company, urlid, (successcallback)=>{
            this.setState({
                results: successcallback.webPages.value,
                total: successcallback.webPages.totalEstimatedMatches,
                pagination:successcallback.rankingResponse.mainline.items,
                url: urlid,
                social:company
            })
            this.props.handleSocial(this.state.results, this.state.total, this.state.pagination, this.state.url, this.state.social)
        }, (error)=>{
            console.log(error)
        })
    }
    render() {
        return (
            <>
                <MediaQuery minWidth={1200} >
                    <div className="col-md-11 col-12 p-0">
                        <div className={this.props.device==="desktop"  ? "container desktop": this.props.device==="mobile"  ? "container mobile": "container tablet" }>
                            <ul style={{listStyleType: "none"}} className="filter-level2 p-0">
                                <li  className={this.state.url === "1" ? "active" : "notactive"} onClick={() => this.handleSocialMedia("linkedin.com ", "1")}><a
                                    style={{paddingLeft: "0"}}>LinkedIn </a></li>
                                <li  className={this.state.url === "2" ? "active" : ""} onClick={() => this.handleSocialMedia("twitter.com ", "2")}><a
                                >Twitter</a>
                                </li>
                                <li  className={this.state.url === "3" ? "active" : ""} onClick={() => this.handleSocialMedia("reddit.com ", "3")}><a
                                >Reddit</a>
                                </li>
                                <li className={this.state.url === "4" ? "active" : ""} onClick={() => this.handleSocialMedia("quora.com ", "4")}><a
                                >Quora</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className="col-md-11 col-12 p-0">
                        <div className={this.props.device==="desktop"  ? "container desktop": this.props.device==="mobile"  ? "container mobile": "container tablet" }>
                            <ul style={{listStyleType: "none"}} className="filter-level2 p-0">
                                <li  className={this.state.url === "1" ? "active" : "notactive"} onClick={() => this.handleSocialMedia("linkedin.com ", "1")}><a
                                    style={{paddingLeft: "0"}}>LinkedIn </a></li>
                                <li  className={this.state.url === "2" ? "active" : ""} onClick={() => this.handleSocialMedia("twitter.com ", "2")}><a
                                >Twitter</a>
                                </li>
                                <li  className={this.state.url === "3" ? "active" : ""} onClick={() => this.handleSocialMedia("reddit.com ", "3")}><a
                                >Reddit</a>
                                </li>
                                <li className={this.state.url === "4" ? "active" : ""} onClick={() => this.handleSocialMedia("quora.com ", "4")}><a
                                >Quora</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461} >
                    <div className="col-md-11 col-12 p-0">
                        <div className={this.props.device==="desktop"  ? "container desktop": this.props.device==="mobile"  ? "container mobile": "container tablet" }>
                            <ul style={{listStyleType: "none"}} className="filter-level2 p-0">
                                <li  className={this.state.url === "1" ? "active" : "notactive"} onClick={() => this.handleSocialMedia("linkedin.com ", "1")}><a
                                    style={{paddingLeft: "0"}}>LinkedIn </a></li>
                                <li  className={this.state.url === "2" ? "active" : ""} onClick={() => this.handleSocialMedia("twitter.com ", "2")}><a
                                >Twitter</a>
                                </li>
                                <li  className={this.state.url === "3" ? "active" : ""} onClick={() => this.handleSocialMedia("reddit.com ", "3")}><a
                                >Reddit</a>
                                </li>
                                <li className={this.state.url === "4" ? "active" : ""} onClick={() => this.handleSocialMedia("quora.com ", "4")}><a
                                >Quora</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </MediaQuery>
            </>

        );
    }
}
export default SocialMediaFilters