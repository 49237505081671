
import {MdAdd, MdClose, MdKeyboardBackspace, MdPeople} from "react-icons/md";
import {FaTrashAlt} from "react-icons/fa";
import React, {useEffect, useState} from "react";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder"
import Modal from 'react-bootstrap/Modal';
import {useParams, Link} from "react-router-dom";
import Header from "../../Landing/Header";

export default function CommunitySubCollections(){
    const [collections, setCollections]=useState([])
    const[loading, setLoading]=useState(true)
    const [show, setShow] = useState(false);
    const [emails, setEmails]=useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [email, setEmail] = useState("")
    const[wrongEmail, setWrongEmail]=useState(false)
    const [inviteSent, setInviteSent] = useState(false)
    const [userDetails, setUserDetails] = useState([])
    const {id}=useParams()
    const getCollections = async () =>{
       const collect = await axios.get(buildAPIUrl(`v1/collections/sub_group/?group=${id}`))
        setCollections(collect.data)
        setLoading(false)
    }
    const handleBack = ()=>{
        window.location.assign("/special-collections")
    }
    const handleViewDetails = (name, id) => {
        localStorage.setItem('colid', id)
        localStorage.setItem('colname', name)

        window.location.assign(`/special-collections-details/${name.replaceAll(" ", "-")}`)
    }
    const handleAddEmailsToList = async (e) => {
        const emailitem = e.target.value
        if (e.code==="Enter"){
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
                setWrongEmail(true)
                setTimeout(()=>{
                    setWrongEmail(false)
                }, 3000)
            } else {
                await setEmails([...emails, emailitem])
                setEmail("")
            }

        }
    }
    const handleInput = async (e) => {
        setEmail(e.target.value)
    }
    const handleSendInvite = async (e)=>{
        const sendinvite = await axios.post(buildAPIUrl('v1/collections/organisations/'), {
            organisation_id:parseInt(localStorage.getItem('orgid')),
            member_email:emails,
            is_admin:false
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })

        if (sendinvite.status===201){
            setInviteSent(true)
            setTimeout(()=>{
                setInviteSent(false)
                setEmails([])
            }, 3000)
        }

    }

    //Check if the user is a collections admin
    const checkAdmin = async ()=>{
        const admin = await axios.get(buildAPIUrl('v1/collections/organisations/'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        setUserDetails(admin.data)
    }
    useEffect(()=>{
        if(!localStorage.getItem('token')){
            window.location.assign("/")
        }
        getCollections()
        checkAdmin()
    }, [])
    return (
        <>
            <div className={"pb-5"}  style={{backgroundColor:"#F9F8FF"}}>
                <Modal show={show} onHide={handleClose} className={"text-center"}>
                    <Modal.Body className={"px-5"}>
                        <div className={"row"} style={{marginTop:"1.625rem"}}>
                            <div className={"col-md-12 d-flex justify-content-end"}>
                                <MdClose onClick={handleClose}  style={{fontSize:"20px", cursor:"pointer"}}/>
                            </div>
                            <div className={"col-md-12"}>
                                <h6  style={{fontSize:"22px", lineHeight:"27px"}}>
                                    Invite Members
                                </h6>
                            </div>

                        </div>
                        {inviteSent ? <>
                            <h6 style={{fontSize:"14px", color:"#00944D", fontWeight:"400"}}>Invitation sent to </h6>
                            {
                                emails.length > 0 ? <>
                                    {
                                        emails.map((email)=>(
                                            <div className={"col-md-6 mt-2"} style={{ marginRight:"1rem", color: " #00944D", fontSize:"18px", fontWeight:"500"}}> {email}</div>
                                        ))
                                    }
                                </> : <>

                                </>
                            }
                        </> : <>


                            <div className={"mb-3"}>
                                {
                                    wrongEmail ? <>
                                        <p className={"text-danger"} style={{fontSize:"14px", fontWeight:"400"}}>Invalid Email</p>
                                    </> : <></>
                                }
                                <label className={"text-center px-5"} style={{lineHeight:"17px", fontSize:"14px", fontWeight:"400"}}>Enter the email of your team members to grant them access.</label>

                                <input type={"email"} onChange={handleInput} value={email} onKeyPress={handleAddEmailsToList} className={"form-control mt-2"} placeholder={"user1@email.com"} style={{height:"47px", borderRadius:"8px"}} />
                            </div>
                            <div className={"container-fluid"} style={emails.length > 3 ? {height:"150px", overflowX:"scroll"} :{height:"auto"}}>
                                {
                                    emails.length > 0 ? <>
                                        {
                                            emails.map((email)=>(
                                                <div className={"col-md-12 mt-2"} style={{background:"#e8e8e8", padding:"5px 30px", borderRadius:"200px", border:"1px solid silver", marginRight:"1rem"}}> {email}</div>
                                            ))
                                        }
                                    </> : <>

                                    </>
                                }
                            </div>
                            <button onClick={handleSendInvite} className={"btn w-100 mt-3"} style={{height:"47px", backgroundColor:"#333333", color:"white", borderRadius:"200px", marginBottom:"1.375rem"}}>
                                Invite
                            </button>
                        </>}

                    </Modal.Body>
                </Modal>
        <Header />
                <div className={"container-fluid bg-image   "} style={{ height:"14.625rem", marginTop:"4rem"}}>
                   <div className={"container-fluid d-flex justify-content-center align-items-center"} style={{height:"80%"}}>
                       <h6 style={{fontSize:"30px", color:"#FFFFFF", fontWeight:"600"}}>{localStorage.getItem('orgname')}</h6>
                   </div>
                    <div className={"container d-flex justify-content-end align-items-end"}>
                        {
                            userDetails.some((o)=>o.is_admin===true) ? <>

                                <button className={"btn"} onClick={handleShow} style={{backgroundColor:"white", borderRadius:"200px", marginRight:"2rem"}}>
                                    <MdAdd /> Invite Users
                                </button>
                                <Link className={"btn"} to={"/sub-collections-members"}  style={{backgroundColor:"#522FFF", borderRadius:"200px", color:"white"}}>
                                    <MdPeople /> Members
                                </Link>
                            </> : <>

                            </>
                        }

                    </div>
                </div>
                <div className={"container-fluid"} style={{ paddingLeft:"4.563rem"}}>
                    <div className={"row"}>
                        <div className={"col-md-2"}>
                            <h6 onClick={handleBack} style={{color:"#522FFF", cursor:"pointer", marginTop:"2rem"}}><MdKeyboardBackspace /> Back to collections</h6>
                        </div>
                        <div className={"col-md-8"}>
                            <div className={"image-holder d-flex justify-content-center align-items-center"} style={{width:"10.125rem", height:"10.125rem", background:"#F9F8FF", marginTop:"-5.063rem", borderRadius:"50%"}}>
                                <div className={"image-holder-inner"} style={{margin:"1rem"}}>
                                    <img src={localStorage.getItem('orgimg')} style={{width:"8.188rem", height:"8.188rem", borderRadius:"50%"}}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"container"} style={{marginTop:"1rem"}} >
                    <div className={"row d-flex justify-content-center"}>
                        <div className={"col-md-8"}>
                            <h6 style={{fontSize:"24px", fontWeight:"700", color:"#A69FAD"}}>Sources</h6>
                        </div>
                    </div>
                </div>
                <div className={"container"} >

                    <div className={"row d-flex justify-content-center"}>
                        <div className={"col-md-8"}>
                            {
                                loading ?  <>
                                    <p>Loading....</p>
                                </> : <>

                                    {
                                        collections.length > 0 ? <>
                                                {
                                                    collections.map((collection)=>(
                                                        <>
                                                            <div className={"row bg-white mt-3"} style={{boxShadow:"0px 3px 4px rgba(0, 0, 0, 0.05)", borderRadius:"8px", padding:"1.313rem 1.313rem 1.313rem 2.5rem"}} >
                                                                <div className={"col-md-10"}>
                                                                    <h1 onClick={()=>handleViewDetails(collection.name, collection.id)} style={{color:"#000000", fontWeight:"500", fontSize:"18px", cursor:"pointer"}}>{collection.name}</h1>
                                                                </div>
                                                                <div className={"col-md-2 d-flex justify-content-end"}>
                                                                    <FaTrashAlt />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                }
                                            </> :
                                            <>
                                                This category does not have any sub categories
                                            </>
                                    }
                                </>
                            }


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}