import React, {Component} from "react";
import Header from "../Landing/Header";
import MediaQuery from "react-responsive";
import LoginModal from "../Modals/LoginModal";
import SearchBox from "../Landing/SearchBox";
import Filters from "../Landing/Filters";
import notfound from "../../../assets/notfound.png";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import ResultComponent from "./ResultComponent";
import Pagination from "./Pagination";
import AlertComponent from "./AlertComponent";
import Sidebar from "../Landing/Sidebar";
import {MdOutlineFilterAlt} from "react-icons/md";
import Footer from "../Landing/Footer";
import axios from "axios";

class ChromeExtensionSearchResults extends Component{
    state = {
        results: [],
        results1: [],
        pagination: [],
        siteid: "",
        social: false,
        total: "",
        search: "",
        images: [],
        title: "",
        link: "",
        success: "",
        token: localStorage.getItem('token'),
        show: false,
        clientID: "425793011868-1s6v97ajh0m70dhcvb7oejp3qup4qb5e.apps.googleusercontent.com",
        related: [],
        count: "15",
        offSet: "0",
        currentItems: null,
        pageCount: "0",
        itemsPerPage: "50",
        copy: false,
        saved: false,
        activePage: 15,
        pager: {},
        perPage: 16,
        page: 0,
        pages: 0,
        searcht: localStorage.getItem('searchquery'),
        configID: "d434f7b5-c57e-4e98-b6f0-27ddd337c829",
        subscrKey: "a02f905e19004306b6271197a7864c12",
        active: false,
        showfilter: false,
        loading: true,
        oldest: "",
        topv: "",
        logintype: "",
        exists: "",
        offsetvalue: "0",
        relatedbing: [],
        isTourOpen: false,
        notfound: false,
        q:""
    }
    componentDidMount() {
        const searchterm = this.props.match.params.id
        localStorage.setItem('searchquery', searchterm)
        this.setState({
            q: searchterm
        })
       axios.get(`https://api.bing.microsoft.com/v7.0/search?q=${searchterm}&customconfig=${this.state.configID}&count=${this.state.count}&offset=${this.state.offsetvalue}`, {
            headers: {
                "Ocp-Apim-Subscription-Key": this.state.subscrKey,
                "token": this.state.token
            }
        }).then((res) => {
            console.log("web", res)
            this.setState({
                results: res.data.webPages.value,
                total: res.data.webPages.totalEstimatedMatches,
                pagination: res.data.rankingResponse.mainline.items,
                loading: false,
            })
        })
        if (this.state.results === null) {
            this.setState({
                notfound: true
            })
        }
    }

    render() {
        const {page, perPage} = this.state;
        let items = this.state.results.slice(page * perPage, (page + 1) * perPage);
        return (
            <>
                <Header/>

                <MediaQuery minWidth={1200} >
                    <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem", marginTop:"7rem"}}>
                        <div className="col-md-2"></div>
                        <div
                            className="col-md-8 col-12">
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-12 col-md-11"><SearchBox web={true}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                {
                                    this.state.notfound ? " " :
                                        <Filters web={true}/>
                                }

                            </div>
                            <div className="row">
                                <div className=" col-12 p-0 col-md-10" style={{overflow: "hidden"}}>
                                    <div className="container mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            {
                                                this.state.notfound ? <h6>0 results found</h6> :
                                                    <h6>About {
                                                        this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                    } results </h6>
                                            }
                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img alt={"not found"} src={notfound}/>
                                                            </div>

                                                            <div className="search-message">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in tools list, try our search
                                                                    engine instead</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div>
                                                                {
                                                                    items.slice(0,1).map((result) => (
                                                                        <>
                                                                            <ResultComponent handleTour={(closetou)=>(this.setState({isTourOpen:closetou}))} tooltip={this.state.isTourOpen} handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                                {
                                                                    items.map((result) => (
                                                                        <>
                                                                            <ResultComponent  handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                    }
                                                </>
                                        }

                                        <Pagination increment={15} type={"configID"} count={this.state.count}
                                                    handleNext={(response, totalcount, paginationcount,loadingstate)=>
                                                        this.setState({
                                                            results:response,
                                                            total:totalcount,
                                                            pagination:paginationcount,
                                                            loading:loadingstate
                                                        })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 side-filters" style={{marginTop: "6.8rem"}}>
                            <div className="container-fluid">
                                <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                existstatus={this.state.exists} type={"General"}/>
                            </div>


                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                        <div className={this.state.active ? "col-md-2 col-2  " : "col-md-1 col-2 hidden-mob"}>
                            <Sidebar closeSideBar={this.handleClose} openSideBar={this.handleOpen}
                                     status={this.state.active}/>
                        </div>

                        <div
                            className={this.state.active ? "col-md-8 col-12  narrowcontainer" : "col-md-8 col-12  widecontainer"}>
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-2 col-md-1 hidden-mob"></div>
                                    <div className=" col-12 col-md-11"><SearchBox web={true} device={"mobile"}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                {
                                    this.state.notfound ? " " :
                                        <Filters device={"mobile"} web={true}/>
                                }


                            </div>
                            <div className="row">
                                <div className=" col-0 col-md-1 hidden-mob"></div>
                                <div className=" col-12 p-0 col-md-10" style={{overflow: "hidden"}}>
                                    <div className="container mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                            existstatus={this.state.exists} type={"General"}/>
                                            <div className="row">
                                                <div className="col-8">
                                                    {
                                                        this.state.notfound ? <h6>0 results found</h6> :
                                                            <h6>About {
                                                                this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                            } results </h6>
                                                    }

                                                </div>
                                                <div className="col-4">

                                                    <a style={{
                                                        color: "#757D8A",
                                                        cursor: "pointer"
                                                    }} onClick={this.handleFilterDiv}><h6 className="h6-filter">Filter <MdOutlineFilterAlt/>
                                                    </h6></a>
                                                </div>
                                            </div>

                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img alt={"not found"} src={notfound}/>
                                                            </div>

                                                            <div className="search-message">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in tools list, try our search
                                                                    engine instead</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div>
                                                                {
                                                                    items.slice(0,1).map((result) => (
                                                                        <>
                                                                            <ResultComponent handleTour={(closetou)=>(this.setState({isTourOpen:closetou}))}  tooltip={this.state.isTourOpen} handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                                {
                                                                    items.map((result) => (
                                                                        <>
                                                                            <ResultComponent handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                    }
                                                </>
                                        }
                                        <div>
                                        </div>
                                        <Pagination increment={15} type={"configID"} count={this.state.count}
                                                    handleNext={(response, totalcount, paginationcount,loadingstate)=>
                                                        this.setState({
                                                            results:response,
                                                            total:totalcount,
                                                            pagination:paginationcount,
                                                            loading:loadingstate
                                                        })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-12 col-md-12"><SearchBox web={true} device={"tablet"}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                {
                                    this.state.notfound ? " " :
                                        <Filters device={"tablet"} web={true}/>
                                }


                            </div>
                            <div className="row">
                                <div className=" col-12 p-0 col-md-12" style={{overflow: "hidden"}}>
                                    <div className="container mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                            existstatus={this.state.exists} type={"General"}/>
                                            <div className="row">
                                                <div className="col-12">
                                                    {
                                                        this.state.notfound ? <h6>0 results found</h6> :
                                                            <h6>About {
                                                                this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                            } results </h6>
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img alt={"not found"} src={notfound}/>
                                                            </div>

                                                            <div className="search-message">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in tools list, try our search
                                                                    engine instead</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div>
                                                                {
                                                                    items.slice(0,1).map((result) => (
                                                                        <>
                                                                            <ResultComponent handleTour={(closetou)=>(this.setState({isTourOpen:closetou}))}  tooltip={this.state.isTourOpen} handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                                {
                                                                    items.map((result) => (
                                                                        <>
                                                                            <ResultComponent handleMod={(result, logtype)=>this.setState({show: true,
                                                                                siteid:result,
                                                                                logintype:logtype})} type={"General"} result={result} handleSave={(savedstatus, existstatus)=>{this.setState({
                                                                                saved:savedstatus,
                                                                                exists:existstatus,
                                                                            })}} handleCopy={(copystatus)=>{this.setState({copy:copystatus})}}/>
                                                                        </>
                                                                    ))
                                                                }
                                                            </div>
                                                    }
                                                </>
                                        }

                                        <div>
                                        </div>
                                        <div className="container p-0 mt-4">
                                            <Pagination increment={15} type={"configID"} count={this.state.count}
                                                        handleNext={(response, totalcount, paginationcount,loadingstate)=>
                                                            this.setState({
                                                                results:response,
                                                                total:totalcount,
                                                                pagination:paginationcount,
                                                                loading:loadingstate
                                                            })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>

                <Footer/>
            </>
        );
    }
}
export default ChromeExtensionSearchResults