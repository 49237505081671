import { useState, useCallback } from 'react';

const useHttpFetch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isError, setIsError] = useState(false);

  const sendRequest = useCallback(async (requestConfig, applyData, is_form_data=false) => {
    setIsLoading(true);
    setError(null);
    let body = null;

    if(requestConfig.body){
      if(is_form_data){
        body = requestConfig.body;
      }
      else{
        body = JSON.stringify(requestConfig.body);
      }
    }

    try {
      const response = await fetch(requestConfig.url, {
        method: requestConfig.method ? requestConfig.method : 'GET',
        headers: requestConfig.headers ? requestConfig.headers : {},
        body: body,
      });
      if (!response.ok) {
        throw new Error('Request failed!');
      }

      if(response.status === 204){
        applyData({});
      }else{
        const data = await response.json();
        applyData(data);
      }

      
    } catch (err) {
      setIsError(true);
      setError(err.message || 'Something went wrong!');
    }
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    error,
    isError,
    sendRequest,
  };
};

export default useHttpFetch;