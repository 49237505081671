import React, { useState, useEffect } from 'react';
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";
import { Link } from "react-router-dom";
import Header from "../Header";

function ListAllTools() {
  const [listOfTools, setlistOfTools] = useState([]);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const { isLoading, error, isError, sendRequest: fetchTools } = useHttpFetch();


  if (!userToken) {
    window.location.assign('/');
  }

  useEffect(() => {
    const setTools = (data) => {
      setlistOfTools(data.reverse());
    }
   
    fetchTools(
      {
        url: buildAPIUrl('v1/research_tools/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setTools
    );
  }, [fetchTools, userToken]);

  const setRefreshedCourses = (data) => {
    setlistOfTools(data.reverse());
  }

  async function refreshCourses (data) {
    fetchTools(
      {
        url: buildAPIUrl('v1/research_tools/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setRefreshedCourses
    );
  }

  async function deleteToolHandler(tool) {
    fetchTools(
      {
        url: buildAPIUrl(`v1/research_tools/${tool.id}/`),
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${userToken}`
        }
      },
      refreshCourses
    );

  }
  async function confirmDeleteToolHandler(tool) {

    await deleteToolHandler(tool);
    // let confirmDelete = confirm(`Are you sure you want to DELETE ${community.name}?`);
    // if (confirmDelete != null) {
    //   await deleteCommunityHandler(community);
    // }

  }

  let content = <p></p>;

  if (listOfTools.length > 0) {
    content = listOfTools.map((tool) => (
   

      <tr key={tool.id}>
        <td>{tool.name}</td>
        <td>{tool.tags.map((tag) => (
            tag.name
        ))}</td>
        <td>{tool.url}</td>
  
        <td>
          <Link className={"btn btn-primary w-100"} to={`/research_tools/edit/${tool.id}`}>
            Edit
          </Link>

          <button className={"btn btn-danger w-100 mt-2"} onClick={() => confirmDeleteToolHandler(tool)}>Delete</button>
        </td>
      </tr>

    ));
  }
  else {
    content = <p>Found no Research Tools.</p>;
  }

  return (
    <>
      <Header />
      <div className={"container-fluid mt-5"}>
        <div className={"container-fluid"} style={{ padding: "0 5rem" }}>
          <div className={"card border"}>
            <div className={"card-header"}>
              <div className={"row"}>
                <div className={"col-md-10"}>
                  <h4>List Of All Tools</h4>
                </div>
                <div className={"col-md-2"}>
                  <Link to="/research_tools/create">Create Research Tool</Link>
                </div>
              </div>

            </div>
            <div className={"card-body"}>
              {isLoading && <h3>Loading.....</h3>}
              {isError && <h3>{error}</h3>}
              <table className={"table table-striped table-hover"}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Tags</th>
                    <th>Url</th>
                  </tr>
                </thead>
                <tbody>
                  {content}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export default ListAllTools;