import React, { useState} from 'react';
import buildAPIUrl from "../../Services/UrlBuilder";
import Header from "../Header";


function CreateResearchCommunity() {
    const [newCommunityDetails, setnewCommunityDetails] = useState({
        name: "",
        url: "",
        platform: "",
        audience: ""
    });
    const [userToken, setUserToken]=useState(localStorage.getItem('token'))
    const [isLoading, setisLoading] = useState(false);
    const [isError, setisError] = useState(false);
    const [error, seterror] = useState("");


    if(!userToken){
        window.location.assign('/');
    }
    function onChangeHandler(event){
        setnewCommunityDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }

    
    async function submitHandler (event){
        event.preventDefault();
        const new_community = {
            name: newCommunityDetails.name,
            url: newCommunityDetails.url,
            platform: newCommunityDetails.platform,
            audience: newCommunityDetails.audience
        }
        setisLoading(true);
        const response = await fetch(buildAPIUrl('v1/research_communities/'), {
            method: 'POST',
            body: JSON.stringify(new_community),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`
            }
        });
        //const data = await response.json();
        setisLoading(false);
        if(response.status === 201){
            setnewCommunityDetails({
                name: "",
                url: "",
                platform: "",
                audience: ""
            })
            window.location.assign('/uxr-communities/all');

        }else{
            setisError(true);
            seterror(response.statusText); 
        }
        
    }
  return(
      <>
          <Header />
            {isLoading && <p>Loading.....</p>}
            {isError && <p>{error}</p>}
          <div className={"container-fluid mt-5"}>
              <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                  <div className={"row"}>
                      <div className={"col-md-6"}>
                          <div className={"card border"}>
                              <div className={"card-header"}>
                                  <h4>Create Research Community</h4>
                              </div>
                              <div className={"card-body"}>
                                  <form onSubmit={submitHandler}>
                                      <div className={"mb-3"}>
                                          <label htmlFor='name'>Name</label>
                                          <input
                                              className={"form-control"}
                                              type='text'
                                              id='name'
                                              name='name'
                                              value={newCommunityDetails.name}
                                              onChange={onChangeHandler}
                                              required
                                          />
                                      </div>
                                      <div className={"mb-3"}>
                                          <label htmlFor='platform'>Platform</label>
                                          <input
                                              className={"form-control"}
                                              type='text'
                                              id='platform'
                                              name='platform'
                                              value={newCommunityDetails.platform}
                                              onChange={onChangeHandler}
                                              required
                                          />
                                      </div>
                                      <div className={"mb-3"}>
                                          <label htmlFor='url'>Url</label>
                                          <input
                                              className={"form-control"}
                                              type='url'
                                              id='url'
                                              name='url'
                                              value={newCommunityDetails.url}
                                              onChange={onChangeHandler}
                                              required
                                          />
                                      </div>
                                      <br/>
                                      <div className={"mb-3"}>
                                          <label htmlFor='audience'>Audience</label>
                                          <textarea
                                              className={"form-control"}
                                              rows="4"
                                              cols="50"
                                              id='audience'
                                              name='audience'
                                              value={newCommunityDetails.audience}
                                              onChange={onChangeHandler}
                                              required
                                          />
                                      </div>
                                      <button className={"btn btn-success"}>Add New Community</button>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          
      </>
  )
}
export default CreateResearchCommunity;