import React, {Component} from "react";
import Header from "../Landing/Header";
import axios from "axios";
import {HiSearch} from "react-icons/hi";
import buildAPIUrl from "../../../Services/UrlBuilder";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import MediaQuery from "react-responsive";
import searchicon from "../../../assets/Frame 39.png";
import ResourcesNav from "./ResourcesNav";
import MetaTags from "react-meta-tags";
import LoginModal from "../Modals/LoginModal";
import NotFound from "./NotFound";
import cards from "../../../assets/Group 23.png";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import SeoMetaData from "./SeoMetaData";
import ReactPaginate from "react-paginate";
class Vocabulary extends Component{
    state = {
        items:[],
        token: localStorage.getItem('token'),
        filter:"",
        loading:true,
        show:false,
        itemscount:15,
        pages:0,
        page:0,
    }
    componentDidMount() {

        EventsHandler("Vocabulary Section Opened,  document.URL")
        window.scrollTo(0,0)
        const response = axios.get(buildAPIUrl('v1/vocabularies/')).then((res)=>{
            this.setState({
                items: res.data,
                loading: false,
                pages:Math.floor(res.data.length / this.state.itemscount),
            })
            setTimeout(()=>{
                window.addEventListener('scroll', this.handleScroll);
            }, 1000)
        }).catch((err)=>{

        })
        //Increase views by 1
        axios.get(buildAPIUrl('v1/collections/3/')).then(()=>{

        }).catch((error)=>{

        })
    }
    //Get the filter value from the search box
handleSearch = async (e) => {
   await this.setState({
        filter:e.target.value
    })
}
    handlePageClick =  async (event) => {
        console.log(event.selected)
        await window.scrollTo(0,0)
        let page = event.selected;
        await this.setState({
            page:page
        })

    }
//Search for a missing term when the user clicks "Use our search engine"
handleSearchVoc = (e) => {
        localStorage.setItem('searchquery', this.state.filter)
    window.location.assign('/all-results')
}

//Search for a term when the user clicks on the vocabulary term
handleSearchVocubalryTerm = (term, id) =>{
    EventsHandler("Vocabulary Link Clicked",  document.URL)
    //Increase views by 1
    axios.get(buildAPIUrl(`v1/vocabularies/${id}/`)).then(()=>{

    }).catch((error)=>{

    })
    localStorage.setItem('searchquery', term)
    window.location.assign('/all-results')
}
//Open and close modal
    handleModalOpen = () =>{
        this.setState({
            show:true
        })
    }
    handleModalClose = () =>{
        this.setState({
            show:false
        })
        window.location.assign('/collections')
    }
//Display login modal when the user tries to scroll
    handleScroll = () => {
        if(!localStorage.getItem('token')) {
            this.handleModalOpen()
        }

    }

    render() {
        function compareStrings(a, b) {
            // Assuming you want case-insensitive comparison
            a = a.toLowerCase();
            b = b.toLowerCase();

            return (a < b) ? -1 : (a > b) ? 1 : 0;
        }

        const filtereditems = this.state.items.filter((result)=> {
           if (this.state.filter === "") {
               return result
           } else if (result.definition.toLowerCase().includes(this.state.filter.toLowerCase()) || result.name.toLowerCase().replace("/", "").replace("-", " ").includes(this.state.filter.toLowerCase())) {
               return result
           }
       })
        return(
            <>
                <Header pageType={"Free"} />
                <SeoMetaData title={"Vocabulary"} />
                <LoginModal openLoginModal={this.handleModalOpen}
                            closeLoginModal={this.handleModalClose}
                            show={this.state.show} type={this.state.logintype}/>
                <MediaQuery minWidth={1200} >
                    <div className="row" style={{paddingLeft: "1rem", paddingRight:"1rem", marginTop:"7rem"}}>
                    <div className="col-md-2 "></div>
                    <div className="col-md-8 col-12 px-4">
                        <div className="search-section col-12 col-md-12 mt-4 ">
                            <div className="row d-flex align-items-center ">
                                <div className=" col-12 col-md-12 d-flex justify-content-center">
                                    <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                        <div className="form-input input-group border"  style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                            <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                   style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search for vocabulary"/>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                        <div className="saved-sources">
                            <div className="row">
                               <ResourcesNav />
                                <div className="col-md-12 col-6 border p-0 rounded-3" style={{marginTop:"1rem"}}>
                                    <div className={"container py-4 d-flex justify-content-between align-items-center"}>
                                        <h1 style={{fontSize:"18px", marginLeft:"0"}}> Mastering UX Jargon: A Comprehensive Glossary of UX Terms</h1>
                                    </div>
                                    {
                                        this.state.loading ? <>
                                                {
                                                    [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                }

                                            </> :
                                            <table className="history-table">
                                                <thead className="">
                                                <tr>
                                                    <th>Vocabulary</th>
                                                    <th>Definition</th>
                                                    <th>Link</th>
                                                    {/*<th>Source</th>*/}

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    filtereditems.length > 0 ? <>
                                                        {
                                                            this.state.filter.length > 0 ? <>
                                                                {
                                                                    filtereditems.sort(function (a,b){
                                                                        return compareStrings(a.name, b.name)
                                                                    }).map((item, index)=>(
                                                                        <>
                                                                            <tr key={index}>
                                                                                <td>{item.name}</td>
                                                                                <td style={{padding:"1rem 0"}}>{item.definition}</td>
                                                                                <td style={{cursor:"pointer", color:"#522FFF", textDecoration:"underline"}} onClick={()=>this.handleSearchVocubalryTerm(item.name, item.id)}>Learn More</td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                            </> : <>
                                                                {
                                                                    filtereditems.sort(function (a, b) {
                                                                        return compareStrings(a.name, b.name)
                                                                    }).slice(this.state.page * this.state.itemscount, (this.state.page + 1) * this.state.itemscount).map((item, index) => (
                                                                        <>
                                                                            <tr key={index}>
                                                                                <td>{item.name}</td>
                                                                                <td style={{padding:"1rem 0"}}>{item.definition}</td>
                                                                                <td style={{cursor:"pointer", color:"#522FFF", textDecoration:"underline"}} onClick={()=>this.handleSearchVocubalryTerm(item.name, item.id)}>Learn More</td>
                                                                            </tr>
                                                                        </>
                                                                    ))
                                                                }
                                                            </>
                                                        }
                                                    </> : <>
                                                        <tr>
                                                            <td colSpan="3" className="search-message"> <img src={cards}/><br/>
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in tools list, try our search engine instead</p>
                                                                <NotFound term={this.state.filter} />
                                                            </td>
                                                        </tr>
                                                    </>
                                                }

                                                </tbody>
                                            </table>
                                    }
                                    {
                                        filtereditems.length > 0 ?
                                            <div className={"container d-flex justify-content-center"}>
                                                <ReactPaginate
                                                    pageCount={this.state.pages}
                                                    previousLabel={'Previous'}
                                                    nextLabel={'Next'}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={'pagination'}
                                                    activeClassName={'active'}
                                                />
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className="row mobile">
                    <div className="col-md-8 col-12">
                        <div className="search-section col-12 col-md-12 mt-4 mb-2 ">
                            <div className="row d-flex align-items-center ">
                                <div className=" col-12 col-md-12 d-flex justify-content-center">
                                    <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                        <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                            <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                            <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                   style={{width: "100%", border:"none"}} placeholder="Search for vocabulary"/>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                        <div className="saved-sources">

                                <ResourcesNav />
                                <div className="col-md-8 col-6 d-flex align-items-center">
                                    <h1 style={{fontWeight:"600", fontSize:"20px", margin:"15px"}}>Mastering UX Jargon: A Comprehensive Glossary of UX Terms</h1>
                                </div>

                            {
                                this.state.loading ? <>
                                        {
                                            [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                        }

                                    </> :
                                    <>
                                        {
                                            filtereditems.length > 0 ?
                                                filtereditems.sort( function (a,b){return compareStrings(a.name, b.name)}).map((item) => (
                                                    <div className={"container-fluid p-0"}>
                                                        <h6 style={{cursor:"pointer",  color:"rgb(0, 148, 77)", textDecoration:"underline", marginTop:"2.375rem"}}  onClick={()=>this.handleSearchVocubalryTerm(item.name, item.id)}>{item.name}</h6>
                                                        <p style={{marginLeft:"1rem", marginTop:"0.813rem", marginRight:"1rem"}}>
                                                            {item.definition}
                                                        </p>
                                                    </div>
                                                )) : <>
                                                    <div>
                                                        <div className="search-message"><img src={cards}/><br/>
                                                            <h6>No results found for this keyword</h6>
                                                            <p>This word is not available in vocabulary, try our search
                                                                engine instead</p>
                                                            <NotFound term={this.state.filter} />
                                                        </div>
                                                    </div>

                                                </>
                                        }

                                    </>


                            }
                        </div>

                    </div>
                </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461} >
                    <div className="row mobile" style={{paddingLeft: "1rem", paddingRight:"1rem"}}>
                        <div className="col-md-8 col-12 px-4">
                            <div className="search-section col-12 col-md-12 mb-2 ">
                                <div className="row d-flex align-items-center ">
                                    <div className=" col-12 col-md-12 d-flex justify-content-center">
                                        <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                            <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                       style={{width: "100%", border:"none"}} placeholder="Search for vocabulary"/>

                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            <div className="saved-sources">
                                <div className="row">
                                    <ResourcesNav />
                                    <div className="col-md-8 col-6 d-flex align-items-center mt-3">
                                        <h1>Mastering UX Jargon: A Comprehensive Glossary of UX Terms</h1>
                                    </div>
                                    <div className="col-md-4 col-6 d-flex justify-content-end">

                                    </div>
                                </div>
                                {
                                    this.state.loading ? <>
                                            {
                                                [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                            }

                                        </> :
                                        <table className="history-table mt-3">
                                            <thead className="">
                                            <tr>
                                                <th>Vocabulary</th>
                                                <th>Definition</th>
                                                {/*<th>Source</th>*/}

                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                filtereditems.length > 0 ?
                                                    filtereditems.sort( function (a,b){return compareStrings(a.name, b.name)}).map((item) => (

                                                        <tr>
                                                            <td style={{cursor:"pointer",  color:"rgb(0, 148, 77)", textDecoration:"underline"}} onClick={()=>this.handleSearchVocubalryTerm(item.name, item.id)}>{item.name}</td>
                                                            <td>{item.definition}</td>
                                                            {/*<td>{item.source}</td>*/}
                                                        </tr>
                                                    )) : <>
                                                        <tr>
                                                            <td colSpan="3" className="search-message"><img src={cards}/><br/>
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in vocabulary, try our search
                                                                    engine instead</p>
                                                                <NotFound term={this.state.filter} />
                                                            </td>
                                                        </tr>

                                                    </>
                                            }
                                            </tbody>
                                        </table>
                                }
                            </div>

                        </div>
                    </div>
                </MediaQuery>

                <Footer/>
            </>
        )
    }
}
export default Vocabulary