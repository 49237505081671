import React, {useState} from 'react'
import Header from "./Header";
import Statistics from "./Statistics";
import Impressions from "./Impressions";
import {Link} from "react-router-dom";

function AdminMain () {
    const [userToken, setUserToken]=useState(localStorage.getItem('token'))
    if(!userToken){
        window.location.assign('/');
    }
  return(
      <>
          <div className="container-fluid p-0 m-0" style={{background:"#f8f8fa"}}>
            
            
              <Header />
              
              <Statistics />
              <Impressions />
              <div className="container-fluid p-0 m-0" style={{background:"#f8f8fa", height:"10rem"}}>
              

              </div>
          </div>
      </>
  )
}
export default AdminMain