import React, {Component} from "react";
import Header from "../Landing/Header";
import {Link} from "react-router-dom";
import Footer from "../Landing/Footer";
import {MdArrowForward, MdArrowRight, MdClose, MdKeyboardArrowRight, MdOutlineRemoveRedEye} from "react-icons/md";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import MediaQuery from "react-responsive";
import PopularBanner from "./PopularBanner";
import conducting from "../../../assets/Group 89899.png"
import planning from "../../../assets/Group 898990.png"
import analyzing from "../../../assets/Group 898991.png"
import presenting from "../../../assets/Group 898992.png"
import padlock from "../../../assets/Group 89910.png"
import logo from "../../../assets/RB LOGO1.png"
import bluearrow from "../../../assets/Group 89940.png";
import {Modal} from "react-bootstrap";
import gem1 from "../../../assets/Gemstone Pink.H031.png"
import NotFound from "./NotFound";
import cards from "../../../assets/Group 23.png";
import Recordings from "../Webinar/Recordings";
import YouTube from "react-youtube";
import Feb from "../../../assets/February Webinar.png";
import January from "../../../assets/January Webinar (1).png";
import skillshop from "../../../assets/Skillshop Fall 2022 (3).png";
import skillshop1 from "../../../assets/Skillshop Fall 2022 (4).png";
import April from "../../../assets/RB April webinar.jpg";
import May from "../../../assets/RB May webinar.jpg";
import September from "../../../assets/RB June webinar.png";
import June from "../../../assets/RB Sept Webinar.png";
import how from "../../../assets/how to grow.jpg";

class ResourcesMain extends Component{
    state={
        q:"",
        collections:[],
        popular: [],
        notfound:false,
        starting:"",
        presenting:"",
        analyzing:"",
        conducting:"",
        links:[],
        communitycollections:[],
        waitlistsuccess:false,
        waitlist:[],
        filter:"",
        userorganizations:[]
    }
    handleGetCommunityCollections = ()=>{
        axios.get(buildAPIUrl('v1/collections/group/'))
            .then((res)=>{
                console.log(res.data, "Special Collections")
                this.setState({
                    communitycollections:res.data
                })
            }).catch((error)=>{
        })
    }
    componentDidMount() {
        if (!localStorage.getItem('token')) {
            window.location.assign("/")
        }
        setTimeout(()=>{
            if (window.location.hash) {
                if (window.location.hash === "#ux-foundations-collections") {
                    const element = document.getElementById('ux-foundations-collections');
                    if (element) {
                        // 👇 Will scroll smoothly to the top of the next section
                        element.scrollIntoView({behavior: 'smooth'});
                    }
                } else if (window.location.hash === "#everyday-collections") {
                    const element = document.getElementById('everyday-collections');
                    if (element) {
                        // 👇 Will scroll smoothly to the top of the next section
                        element.scrollIntoView({behavior: 'smooth'});
                    }
                } else if (window.location.hash === "#special-collections") {
                const element = document.getElementById('special-collections');
                if (element) {
                    // 👇 Will scroll smoothly to the top of the next section
                    element.scrollIntoView({behavior: 'smooth'});
                }
            }
            }
        }, 1000)

            axios.get(buildAPIUrl('v1/collections/'))
                .then((res) => {

                    this.setState({
                        collections: res.data
                    })
                    window.scrollTo(0, 0)
                }).catch((error) => {
            })

            axios.get(buildAPIUrl(`v1/featured_post/?category_id=2`)).then((res) => {
                if (res.data === undefined) {
                    this.setState({
                        notfound: true
                    })
                } else {
                    this.setState({
                        popular: res.data
                    })
                }

            }).catch((error) => {

            })
            //Get article phases
            axios.get(buildAPIUrl('v1/article_phases/')).then((res) => {
                this.setState({
                    links: res.data,
                    starting: res.data[1],
                    presenting: res.data[0],
                    analyzing: res.data[2],
                    conducting: res.data[3],
                })
            })

            //Check user organization
            //Check user organization
            axios.get(buildAPIUrl('v1/collections/organisations/'), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                console.log(res.data)
                this.setState({
                    userorganizations: res.data
                })
            })

            this.handleGetCommunityCollections()
            this.getWaitList()
    }

    //Add users to waitlist
    handleWaitlist = (id)=>{
        axios.post(buildAPIUrl('v1/collections/waitlist/'), {
            collection_group:id
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(()=>{
            this.handleGetCommunityCollections()
            this.setState({
                waitlistsuccess:true
            })
        }).catch((error)=>{

        })
    }
    hideWaitListModal = ()=>{
        this.setState({
            waitlistsuccess:false
        })
        this.getWaitList()
    }
    getWaitList = ()=>{
        axios.get(buildAPIUrl('v1/collections/waitlist/'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
            this.setState({
                waitlist:res.data
            })
        })
    }
    handleInput = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    handleSearch = (e)=>{
        e.preventDefault()
        localStorage.setItem('searchQ', this.state.filter)
        window.location.assign("/search-results")
    }
    handleSubCollection = (org, img, name) =>{
        localStorage.setItem('orgname', name)
        localStorage.setItem('orgid', org)
        localStorage.setItem('orgimg', img)
    }
    render() {
        const _onReady = (event) => {
            // access to player in all event handlers via event.target
            event.target.pauseVideo();
        }
        const filtereditems = this.state.communitycollections.filter((result)=> {
            if (this.state.filter === "") {
                return result
            } else if (result.name.toLowerCase().includes(this.state.filter.toLowerCase())) {
                return result
            }
        })
        const filtereditems1 = this.state.collections.filter((result)=> {
            if (this.state.filter === "") {
                return result
            } else if (result.header.toLowerCase().includes(this.state.filter.toLowerCase())) {
                return result
            }
        })
        return (
            <>
                <Header pageType={"Free"} />
                <Modal show={this.state.waitlistsuccess} size={"sm"} onHide={this.hideWaitListModal} >
                   <Modal.Body>
                       <div className={"container d-flex justify-content-end"}>
                           <MdClose onClick={this.hideWaitListModal} style={{fontSize:"18px"}} />
                       </div>
                       <div className={"container d-flex justify-content-center"}>
                           <img src={gem1} style={{height:"4.688rem", width:"4.688rem"}}/>

                       </div>
                       <div>
                           <h6 className={"text-center"}>You’ve been added to the waitlist!</h6>
                       </div>

                   </Modal.Body>
                </Modal>
                <MediaQuery minWidth={1200}>
                    <div className={"row"}>
                        <div className={"col-md-2"}>

                        </div>
                        <div className={"col-md-9"}>
                            <div className={"container resources-search-section"}>
                                <h4>
                                    Explore our Collections
                                </h4>
                            </div>
                            <div className={"container"}>
                                <PopularBanner name={"Quick links"} />
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-2 position-relative"}>

                        </div>
                        <div className={"col-md-9 position-relative"}>
                            <div className={"container"} style={{marginTop:"2rem"}}>
                                <form onSubmit={this.handleSearch} autoComplete="off">
                                <div className={"row"} style={{zIndex:"1000", position:"relative"}}>
                                    <div className={"col-md-10 col-10 p-0 m-0"}>
                                        <input className="search-field-text" type="text" name="q" onChange={this.handleInput} onClick={this.handleInput} value={this.state.filter} onBlur={this.handleLeaveInput}
                                               placeholder="Search for sources in our collection" style={{borderRadius:"100px 0px 0px 100px"}}/>
                                    </div>
                                    <div className={"col-md-2 col-2 p-0 m-0"}>
                                        <button onTouchStart={this.handleSearch} onClick={this.handleSearch} className="btn btn-search" style={{borderRadius:"0px 6px 6px 0px"}}>Search</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-2 position-relative"}>
                            <div className={"card border static-nav"} style={{marginTop:"1.25rem"}}>
                                <h6>
                                    Navigation
                                </h6>
                                <ul>
                                    <li> <a href={"#everyday-collections"}> Everyday Collections</a></li>
                                    <li> <a href={"#special-collections"}>Special Collections </a></li>
                                    <li> <a href={"#ux-foundations-collections"}>Ux Foundations Collections </a></li>

                                </ul>
                            </div>
                        </div>
                        <div className={"col-md-9"}>
                            <div className={"container"}>
                                {
                                    this.state.filter.length > 0 ? <>
                                        <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                            {
                                                filtereditems1.length > 0 || filtereditems.length > 0 ? <>
                                                {
                                                    filtereditems1.map((collection)=>(
                                                        <>
                                                            <div className="col-md-3" style={{marginTop:"1.625rem"}}>
                                                                <a href={collection.url} style={{textDecoration:"none"}}>
                                                                    <div className={"container h-100 d-flex justify-content-center pb-3 bg-white"}>
                                                                        <div className="categories-content-main h-100 text-center">
                                                                            <div >
                                                                                <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem"}}/>
                                                                                <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                    {collection.header}
                                                                                </h6>
                                                                                <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", lineHeight:"150%" }}>{collection.description}</p>
                                                                            </div>

                                                                            <span style={{backgroundColor:"#F0EEFF", padding:"0.125rem .5rem", borderRadius:"1.438rem", color:"#000000", fontSize:"10px", height:"1.375rem"}}><span style={{fontSize:"18px"}}><MdOutlineRemoveRedEye /> </span>{collection.number_of_views} views</span>
                                                                            <div className={"container-fluid"} style={{marginTop:"1.25rem", marginBottom:".875rem"}}>
                                                                                <a style={{ color:"#00944D"}} href={collection.url}>Explore <MdArrowForward /></a>
                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </>

                                                    ))
                                                }
                                                    {
                                                        filtereditems.length > 0 ? <>
                                                            {
                                                                filtereditems.map((collection)=>(
                                                                    <>
                                                                        //parseInt(o.organisation.id)===collection.organisation ||
                                                                        {
                                                                            this.state.userorganizations.length > 0  ? <>
                                                                                {
                                                                                    this.state.userorganizations.some((o)=>parseInt(o.organisation.id)===collection.organisation || o.type==="GENERAL") ? <>
                                                                                        <>
                                                                                            <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                                <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                                    <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                        <div className={"row w-100"}>
                                                                                                            <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                                <img src={logo} />
                                                                                                            </div>

                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <Link onClick={()=>this.handleSubCollection(collection.organisation, collection.image, collection.name)}  to={`/sub-collections/${collection.id}`} style={{textDecoration:"none"}}>
                                                                                                        <div className={"container-fluid d-flex justify-content-center bg-white"}>
                                                                                                            <div className="categories-content-main text-center">
                                                                                                                <div>
                                                                                                                    <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                                    <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                        {collection.name}
                                                                                                                    </h6>
                                                                                                                    <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                                </div>

                                                                                                                <div className={"container-fluid p-0 collection-footer text-center d-flex align-items-center justify-content-center"}>
                                                                                                                    <h6>View Collection  </h6>

                                                                                                                </div>


                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </Link>
                                                                                                </div>

                                                                                            </div>
                                                                                        </>
                                                                                    </> : <>
                                                                                        {
                                                                                            this.state.waitlist.some((o)=>o.collection_group===collection.id) ? <>
                                                                                                <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                                    <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                                        <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                            <div className={"row w-100"}>
                                                                                                                <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                                    <img src={logo} />
                                                                                                                </div>
                                                                                                                <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                                                    <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                                                        <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>

                                                                                                        <div className={"container d-flex justify-content-center bg-white"}>
                                                                                                            <div className="categories-content-main text-center">
                                                                                                                <div>
                                                                                                                    <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                                    <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                        {collection.name}
                                                                                                                    </h6>
                                                                                                                    <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                                </div>

                                                                                                                <div className={"container-fluid  collection-footer p-0 text-center d-flex align-items-center justify-content-center"}>
                                                                                                                    <h6 >Added to waitlist  </h6>

                                                                                                                </div>


                                                                                                            </div>

                                                                                                        </div>

                                                                                                    </div>

                                                                                                </div>
                                                                                            </> : <>
                                                                                                <div className="col-md-3 d-flex justify-content-center"  onClick={()=>this.handleWaitlist(collection.id)} style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                                    <div className={"container community-container bg-white"} style={{width:"15.625rem", borderRadius:"8px"}}>
                                                                                                        <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                            <div className={"row w-100"}>
                                                                                                                <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                                    <img src={logo} />
                                                                                                                </div>
                                                                                                                <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                                                    <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                                                        <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>

                                                                                                        <div className={"container d-flex justify-content-center bg-white"}>
                                                                                                            <div className="categories-content-main text-center">
                                                                                                                <div>
                                                                                                                    <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                                    <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                        {collection.name}
                                                                                                                    </h6>
                                                                                                                    <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                                </div>

                                                                                                                <div className={"container-fluid p-0 text-center d-flex  collection-footer align-items-center justify-content-center"}>
                                                                                                                    <h6>Coming soon  </h6>

                                                                                                                </div>


                                                                                                            </div>

                                                                                                        </div>

                                                                                                    </div>

                                                                                                </div>
                                                                                            </>
                                                                                        }

                                                                                    </>
                                                                                }
                                                                            </> : <>
                                                                                <>
                                                                                </>


                                                                            </>
                                                                        }

                                                                    </>
                                                                ))
                                                            }

                                                        </> : <>

                                                        </>
                                                    }

                                                </> : <>
                                                        <div className={"container d-flex justify-content-center text-center"}>
                                                            <div>
                                                                <img src={cards}/><br/>
                                                                <h6 className={"mt-3"}>No results found for this keyword</h6>
                                                                <p>This word is not available in out collections list</p>
                                                            </div>
                                                        </div>
                                                </>
                                            }

                                        </div>
                                    </> : <>
                                        <div className={"container-fluid"} style={{background:"#FBFBFF", padding:"3.5rem 2.875rem", marginTop:"1.25rem"}} id={"everyday-collections"}>
                                            <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}} >
                                                Everyday collections
                                            </h6>
                                            <p style={{fontSize:"14px", fontWeight:"400"}}>
                                                Everything you need to  stay current in your career
                                            </p>
                                            <div className="container-fluid row3 p-0">
                                                <div className="categories border-0">
                                                    <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                                        {
                                                            this.state.collections.map((collection)=>(
                                                                <>
                                                                    <div className="col-md-3" style={{marginTop:"1.625rem"}}>
                                                                        <a href={collection.url} style={{textDecoration:"none"}}>
                                                                            <div className={"container h-100 d-flex justify-content-center pb-3 bg-white"}>
                                                                                <div className="categories-content-main h-100 text-center">
                                                                                    <div >
                                                                                        <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem"}}/>
                                                                                        <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                            {collection.header}
                                                                                        </h6>
                                                                                        <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", lineHeight:"150%" }}>{collection.description}</p>
                                                                                    </div>

                                                                                    <span style={{backgroundColor:"#F0EEFF", padding:"0.125rem .5rem", borderRadius:"1.438rem", color:"#000000", fontSize:"10px", height:"1.375rem"}}><span style={{fontSize:"18px"}}><MdOutlineRemoveRedEye /> </span>{collection.number_of_views} views</span>
                                                                                    <div className={"container-fluid"} style={{marginTop:"1.25rem", marginBottom:".875rem"}}>
                                                                                        <a style={{ color:"#00944D"}} href={collection.url}>Explore <MdArrowForward /></a>
                                                                                    </div>


                                                                                </div>

                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </>

                                                            ))
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className={"container"} style={{marginTop:"2rem"}}>
                                                <Link to={"/everyday-collections"} style={{textDecoration:"none"}}>
                                                    <img src={bluearrow}/> <span style={{color:"#522FFF", fontSize:"14px", fontWeight:"500", marginLeft:"0.75rem"}}>View all collections</span>
                                                </Link>
                                            </div> */}
                                        </div>


                                        {
                                            this.state.communitycollections.length > 0 ? <>
                                                <div  className={"container-fluid"} style={{ padding:"3.5rem 2.875rem", marginTop:"4.75rem", background:"#FBFBFF"}} id={"special-collections"}>

                                                    <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}} >
                                                        Special Collections
                                                    </h6>
                                                    <p style={{fontSize:"14px", fontWeight:"400"}}>
                                                        Industry and subject focused collections to give you the edge you need in your career
                                                    </p>


                                                    <div className="container row3 p-0">
                                                        <div className=" border-0">
                                                            <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                                                {
                                                                    filtereditems.length > 0 ? <>
                                                                        {
                                                                            //.filter((result)=>{return result.is_active})
                                                                            filtereditems.map((collection)=>(
                                                                                <>
                                                                                    <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                                        <div className={"container community-container bg-white"} style={{ borderRadius:"8px"}}>
                                                                                            <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                                <div className={"row w-100"}>
                                                                                                    <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                                        <img src={logo} />
                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>
                                                                                            <Link onClick={()=>this.handleSubCollection(collection.organisation, collection.image, collection.name)}  to={`/special-collections/${collection.id}/${collection.name.replaceAll(" ", "-")}`} style={{textDecoration:"none"}}>
                                                                                                <div className={"container-fluid d-flex justify-content-center bg-white"}>
                                                                                                    <div className="categories-content-main text-center">
                                                                                                        <div>
                                                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem", borderRadius:"50%"}}/>
                                                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                                {collection.name}
                                                                                                            </h6>
                                                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                                        </div>

                                                                                                        <div className={"container-fluid p-0 collection-footer text-center d-flex align-items-center justify-content-center"}>
                                                                                                            <h6>View Collection  </h6>

                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>
                                                                                            </Link>
                                                                                        </div>

                                                                                    </div>
                                                                                </>
                                                                            ))
                                                                        }

                                                                    </> : <>

                                                                    </>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {
                                                        this.state.communitycollections.length > 4 ? <>
                                                            <div className={"container"} style={{marginTop:"2rem"}}>
                                                                <Link to={"/special-collections"} style={{textDecoration:"none"}}>
                                                                    <img src={bluearrow}/> <span style={{color:"#522FFF", fontSize:"14px", fontWeight:"500", marginLeft:"0.75rem"}}>View all collections</span>
                                                                </Link>
                                                            </div>
                                                        </> : <></>
                                                    } */}


                                                </div>
                                            </> : <></>
                                        }
                                        <div id={"ux-foundations-collections"} className={"container-fluid"} style={{background:"#FFF8FD", padding:"3.5rem 3.063rem", marginTop:"4.75rem", marginBottom:"2rem"}}>
                                            <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                                Foundations of User Experience
                                            </h6>
                                            <p style={{fontSize:"14px", fontWeight:"400"}}>
                                                Learn the fundamentals of User experience by starting from research
                                            </p>
                                            <div className={"row"}>
                                                {
                                                    this.state.starting ? <>
                                                        <div className="col-md-3 mt-5 categories">
                                                            <Link to={"/articles/4/planning-research"} style={{textDecoration:"none"}}>
                                                                <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                                    <div className="categories-content-main h-100 text-center">

                                                                        <img src={conducting}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                                        <h1 className={"text-center"} style={{marginTop:"30px", fontSize: "18px"}}>
                                                                            Planning Research
                                                                        </h1>
                                                                        <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                                            <Link to={"/articles/4/planning-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </> : <>

                                                    </>
                                                }
                                                {
                                                    this.state.conducting ? <>
                                                        <div className="col-md-3 mt-5 categories">
                                                            <Link to={"/articles/4/conducting-research"} style={{textDecoration:"none"}}>
                                                                <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                                    <div className="categories-content-main h-100 text-center">

                                                                        <img src={conducting}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                                        <h1 className={"text-center"} style={{marginTop:"30px", fontSize: "18px"}}>
                                                                            Conducting Research
                                                                        </h1>
                                                                        <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                                            <Link to={"/articles/4/conducting-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </> : <>

                                                    </>
                                                }

                                                {
                                                    this.state.analyzing ? <>
                                                        <div className="col-md-3 mt-5 categories">
                                                            <Link to={"/articles/3/analyzing-research"} style={{textDecoration:"none"}}>
                                                                <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                                    <div className="categories-content-main h-100 text-center">

                                                                        <img src={analyzing}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                                        <h1 className={"text-center"} style={{marginTop:"30px", fontSize: "18px"}}>
                                                                            Analyzing Research
                                                                        </h1>
                                                                        {/*  <p style={{fontSize:"14px", fontWeight:"400", margin:"0"}}>
                                                            Lorem ipsum dolor
                                                        </p>*/}
                                                                        <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                                            <Link to={"/articles/3/analyzing-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </> : <>

                                                    </>
                                                }
                                                {
                                                    this.state.presenting ? <>
                                                        <div className="col-md-3 mt-5 categories">
                                                            <Link to={"/articles/1/presenting-research"}
                                                                  style={{textDecoration: "none"}}>
                                                                <div
                                                                    className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                                    <div className="categories-content-main h-100 text-center">

                                                                        <img src={presenting} style={{
                                                                            marginTop: "30px",
                                                                            width: "4.063rem",
                                                                            height: "4.063rem"
                                                                        }}/>
                                                                        <h1 className={"text-center"} style={{marginTop: "30px", fontSize: "18px"}}>
                                                                            Presenting Research
                                                                        </h1>

                                                                        <div className={"container-fluid"} style={{marginTop: "30px",}}>
                                                                            <Link to={"/articles/1/presenting-research"}
                                                                                  style={{color: "#00944D"}}>Explore <MdArrowForward/></Link>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </> : <>

                                                    </>
                                                }

                                            </div>
                                        </div>

                                    </>
                                }

                            </div>
                        </div>
                    </div>


                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"mobile"}>
                        <div className={"container resources-search-section"}>
                            <h4>
                                Explore our Collections
                            </h4>
                        </div>
                        <div className={"container"}>
                            <PopularBanner name={"Quick links"} />
                        </div>
                        <div className={"container"}>

                            <div className={"container-fluid"} style={{background:"#FBFBFF", padding:"3.5rem 2.875rem", marginTop:"1.25rem"}}>

                                <div className="container-fluid row3 p-0">
                                    <div className="categories border-0">
                                        <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                            {
                                                this.state.collections.map((collection)=>(
                                                    <>
                                                        <div className="col-md-3" style={{marginTop:"1.625rem"}}>
                                                            <a href={collection.url} style={{textDecoration:"none"}}>
                                                                <div className={"container h-100 d-flex justify-content-center pb-3 bg-white"}>
                                                                    <div className="categories-content-main h-100 text-center">
                                                                        <div>
                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem"}}/>
                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                {collection.header}
                                                                            </h6>
                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", lineHeight:"150%" }}>{collection.description}</p>
                                                                        </div>

                                                                        <span style={{backgroundColor:"#F0EEFF", padding:"0.125rem .5rem", borderRadius:"1.438rem", color:"#000000", fontSize:"10px"}}><span style={{fontSize:"18px"}}><MdOutlineRemoveRedEye /> </span>{collection.number_of_views} views</span>
                                                                        <div className={"container-fluid"} style={{marginTop:"1.25rem", marginBottom:"2rem"}}>
                                                                            <a style={{ color:"#00944D"}} href={collection.url}>Explore <MdArrowForward /></a>
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </a>
                                                        </div>
                                                    </>

                                                ))
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"container-fluid"} style={{ padding:"3.5rem 2.875rem", marginTop:"1.25rem", background:"#FBFBFF"}}>
                                {
                                    this.state.communitycollections.length > 0 ? <>
                                        <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                            Special Collections
                                        </h6>
                                        <p style={{fontSize:"14px", fontWeight:"400"}}>
                                            Industry and subject focused collections to give you the edge you need in your career
                                        </p>
                                    </> : <></>
                                }
                                <div className="container row3 p-0">
                                    <div className=" border-0">
                                        <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                            {
                                                this.state.communitycollections.length > 0 ? <>
                                                    {
                                                        this.state.communitycollections.slice(0,4).map((collection)=>(
                                                            <>
                                                                {
                                                                    this.state.waitlist.some((o)=>o.main_collection===collection.id) ? <>
                                                                        <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                            <div className={"container community-container bg-white"} style={{width:"15.625rem", borderRadius:"8px"}}>
                                                                                <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                    <div className={"row w-100"}>
                                                                                        <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                            <img src={logo} />
                                                                                        </div>
                                                                                        <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                            <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                                <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <p style={{textDecoration:"none"}}>
                                                                                    <div className={"container d-flex justify-content-center pb-3 bg-white"}>
                                                                                        <div className="categories-content-main text-center">
                                                                                            <div style={{height:"70%"}}>
                                                                                                <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem"}}/>
                                                                                                <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                    {collection.name}
                                                                                                </h6>
                                                                                                <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                            </div>

                                                                                            <div className={"container text-center mb-5 d-flex align-items-center justify-content-center"} style={{marginTop:"1.25rem", borderRadius:"200px", padding:"0.438rem 1rem"}}>
                                                                                                <h6 style={{color:"#522FFF", fontSize:"14px", fontWeight:"500"}}>Added to waitlist  </h6>

                                                                                            </div>


                                                                                        </div>

                                                                                    </div>
                                                                                </p>
                                                                            </div>

                                                                        </div>
                                                                    </> : <>
                                                                        <div className="col-md-3 d-flex justify-content-center"  onClick={()=>this.handleWaitlist(collection.id)} style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                            <div className={"container community-container bg-white"} style={{width:"15.625rem", borderRadius:"8px"}}>
                                                                                <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                    <div className={"row w-100"}>
                                                                                        <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                            <img src={logo} />
                                                                                        </div>
                                                                                        <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                            <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                                <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <p style={{textDecoration:"none"}}>
                                                                                    <div className={"container d-flex justify-content-center pb-3 bg-white"}>
                                                                                        <div className="categories-content-main text-center">
                                                                                            <div style={{height:"70%"}}>
                                                                                                <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem"}}/>
                                                                                                <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                    {collection.name}
                                                                                                </h6>
                                                                                                <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                            </div>

                                                                                            <div className={"container text-center mb-5 d-flex align-items-center justify-content-center"} style={{marginTop:"1.25rem", borderRadius:"200px", padding:"0.438rem 1rem"}}>
                                                                                                <h6 style={{color:"#522FFF", fontSize:"14px", fontWeight:"500"}}>Coming soon  </h6>

                                                                                            </div>


                                                                                        </div>

                                                                                    </div>
                                                                                </p>
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                }


                                                            </>

                                                        ))
                                                    }

                                                </> : <>

                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                                {/* {
                                    this.state.communitycollections.length > 4 ? <>
                                        <div className={"container"} style={{marginTop:"2rem"}}>
                                            <Link to={"/special-collections"} style={{textDecoration:"none"}}>
                                                <img src={bluearrow}/> <span style={{color:"#522FFF", fontSize:"14px", fontWeight:"500", marginLeft:"0.75rem"}}>View all collections</span>
                                            </Link>
                                        </div>
                                    </> : <></>
                                } */}


                            </div>
                            <div className={"container-fluid"} style={{background:"#FFF8FD", padding:"3.5rem 3.063rem", marginTop:"4.75rem"}}>
                                <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                    Foundations of User Experience
                                </h6>
                                <p style={{fontSize:"14px", fontWeight:"400"}}>
                                    Learn the fundamentals of User experience by starting from research
                                </p>
                                <div className={"row"}>
                                    {
                                        this.state.starting ? <>
                                            <div className="col-md-3 mt-5 categories">
                                                <Link to={"/articles/4/planning-research"} style={{textDecoration:"none"}}>
                                                    <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                        <div className="categories-content-main h-100 text-center">

                                                            <img src={conducting}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                            <h1 className={"text-center"} style={{marginTop:"30px", fontSize: "18px"}}>
                                                                Planning Research
                                                            </h1>
                                                            <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                                <Link to={"/articles/4/planning-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </Link>
                                            </div>
                                        </> : <>

                                        </>
                                    }
                                    {
                                        this.state.conducting ? <>
                                            <div className="col-md-3 mt-5 categories">
                                                <Link to={"/articles/4/conducting-research"} style={{textDecoration:"none"}}>
                                                    <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                        <div className="categories-content-main h-100 text-center">

                                                            <img src={conducting}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                            <h1 className={"text-center"} style={{marginTop:"30px", fontSize: "18px"}}>
                                                                Conducting Research
                                                            </h1>
                                                            <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                                <Link to={"/articles/4/conducting-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </Link>
                                            </div>
                                        </> : <>

                                        </>
                                    }

                                    {
                                        this.state.analyzing ? <>
                                            <div className="col-md-3 mt-5 categories">
                                                <Link to={"/articles/3/analyzing-research"} style={{textDecoration:"none"}}>
                                                    <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                        <div className="categories-content-main h-100 text-center">

                                                            <img src={analyzing}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                            <h1 className={"text-center"} style={{marginTop:"30px", fontSize: "18px"}}>
                                                                Analyzing Research
                                                            </h1>
                                                            {/*  <p style={{fontSize:"14px", fontWeight:"400", margin:"0"}}>
                                                            Lorem ipsum dolor
                                                        </p>*/}
                                                            <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                                <Link to={"/articles/3/analyzing-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </Link>
                                            </div>
                                        </> : <>

                                        </>
                                    }
                                    {
                                        this.state.presenting ? <>
                                            <div className="col-md-3 mt-5 categories">
                                                <Link to={"/articles/1/presenting-research"}
                                                      style={{textDecoration: "none"}}>
                                                    <div
                                                        className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                        <div className="categories-content-main h-100 text-center">

                                                            <img src={presenting} style={{
                                                                marginTop: "30px",
                                                                width: "4.063rem",
                                                                height: "4.063rem"
                                                            }}/>
                                                            <h1 className={"text-center"} style={{marginTop: "30px", fontSize: "18px"}}>
                                                                Presenting Research
                                                            </h1>

                                                            <div className={"container-fluid"} style={{marginTop: "30px",}}>
                                                                <Link to={"/articles/1/presenting-research"}
                                                                      style={{color: "#00944D"}}>Explore <MdArrowForward/></Link>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </Link>
                                            </div>
                                        </> : <>

                                        </>
                                    }
                                    {/*   {
                                        this.state.links.map((link1, key)=>(
                                            <>
                                                <>
                                                    <div className="col-md-3 mt-5 categories">
                                                        <Link to={"/articles/2/planning-research"} style={{textDecoration:"none"}}>
                                                            <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                                <div className="categories-content-main h-100 text-center">

                                                                    <img src={planning}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                                    <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                        {link1.name}
                                                                    </h6>
                                                                    <p style={{fontSize:"14px", fontWeight:"400", margin:"0"}}>
                                                            Lorem ipsum dolor
                                                        </p>
                                                                    <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                                        <Link to={"/articles/2/planning-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </Link>
                                                    </div>
                                                </>
                                            </>
                                        ))
                                    }*/}

                                </div>
                            </div>
                        </div>

                    </div>


                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                <div className={"tablet"}>
                    <div className={"container resources-search-section"}>
                        <h4>
                            Explore our Collections
                        </h4>
                    </div>
                    <div className={"container"}>
                        <PopularBanner name={"Quick links"} />
                    </div>
                    <div className={"container"}>

                        <div className={"container-fluid"} style={{background:"#FBFBFF", padding:"3.5rem 2.875rem", marginTop:"1.25rem"}}>
                            <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                Everyday collections
                            </h6>
                            <p style={{fontSize:"14px", fontWeight:"400"}}>
                                Everything you need to  stay current in your career
                            </p>
                            <div className="container-fluid row3 p-0">
                                <div className="categories border-0">
                                    <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                        {
                                            this.state.collections.map((collection)=>(
                                                <>
                                                    <div className="col-md-6" style={{marginTop:"1.625rem"}}>
                                                        <a href={collection.url} style={{textDecoration:"none"}}>
                                                            <div className={"container h-100 d-flex justify-content-center pb-3 bg-white"}>
                                                                <div className="categories-content-main h-100 text-center">
                                                                    <div>
                                                                        <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem"}}/>
                                                                        <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                            {collection.header}
                                                                        </h6>
                                                                        <p  style={{fontSize:"14px", fontWeight:"400", margin:"0",  lineHeight:"150%" }}>{collection.description}</p>
                                                                    </div>

                                                                    <span style={{backgroundColor:"#F0EEFF", padding:"0.125rem .5rem", borderRadius:"1.438rem", color:"#000000", fontSize:"10px"}}><span style={{fontSize:"18px"}}><MdOutlineRemoveRedEye /> </span>{collection.number_of_views} views</span>
                                                                    <div className={"container-fluid"} style={{marginTop:"1.25rem", marginBottom:"2rem"}}>
                                                                        <a style={{ color:"#00944D"}} href={collection.url}>Explore <MdArrowForward /></a>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </a>
                                                    </div>
                                                </>

                                            ))
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"container-fluid"} style={{ padding:"3.5rem 2.875rem", marginTop:"1.25rem", background:"#FBFBFF"}}>
                            {
                                this.state.communitycollections.length > 0 ? <>
                                    <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                        Special Collections
                                    </h6>
                                    <p style={{fontSize:"14px", fontWeight:"400"}}>
                                        Industry and subject focused collections to give you the edge you need in your career
                                    </p>
                                </> : <></>
                            }
                            <div className="container row3 p-0">
                                <div className=" border-0">
                                    <div className="row h-100" style={{marginTop:"2.5rem"}}>
                                        {
                                            this.state.communitycollections.length > 0 ? <>
                                                {
                                                    this.state.communitycollections.slice(0,4).map((collection)=>(
                                                        <>
                                                            {
                                                                this.state.waitlist.some((o)=>o.main_collection===collection.id) ? <>
                                                                    <div className="col-md-3 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                        <div className={"container community-container bg-white"} style={{width:"15.625rem", borderRadius:"8px"}}>
                                                                            <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                <div className={"row w-100"}>
                                                                                    <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                        <img src={logo} />
                                                                                    </div>
                                                                                    <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                        <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                            <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <p style={{textDecoration:"none"}}>
                                                                                <div className={"container d-flex justify-content-center pb-3 bg-white"}>
                                                                                    <div className="categories-content-main text-center">
                                                                                        <div style={{height:"70%"}}>
                                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem"}}/>
                                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                {collection.name}
                                                                                            </h6>
                                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                        </div>

                                                                                        <div className={"container text-center mb-5 d-flex align-items-center justify-content-center"} style={{marginTop:"1.25rem", borderRadius:"200px", padding:"0.438rem 1rem"}}>
                                                                                            <h6 style={{color:"#522FFF", fontSize:"14px", fontWeight:"500"}}>Added to waitlist  </h6>

                                                                                        </div>


                                                                                    </div>

                                                                                </div>
                                                                            </p>
                                                                        </div>

                                                                    </div>
                                                                </> : <>
                                                                    <div className="col-md-3 d-flex justify-content-center"  onClick={()=>this.handleWaitlist(collection.id)} style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                        <div className={"container community-container bg-white"} style={{width:"15.625rem", borderRadius:"8px"}}>
                                                                            <div className={"container-fluid p-0 d-flex justify-content-end"} style={{marginTop:"1rem", }}>
                                                                                <div className={"row w-100"}>
                                                                                    <div className={"col-md-7 d-flex justify-content-end"}>
                                                                                        <img src={logo} />
                                                                                    </div>
                                                                                    <div className={"col-md-5 d-flex justify-content-end"}>
                                                                                        <div className={"container-holder d-flex justify-content-center align-items-center"} style={{boxShadow:"0px 2px 6px rgba(0, 0, 0, 0.2)", height:"2rem", width:"2rem", borderRadius:"50%"}}>
                                                                                            <img src={padlock} style={{height:"1rem", width:"1rem"}}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <p style={{textDecoration:"none"}}>
                                                                                <div className={"container d-flex justify-content-center pb-3 bg-white"}>
                                                                                    <div className="categories-content-main text-center">
                                                                                        <div style={{height:"70%"}}>
                                                                                            <img src={collection.image ? collection.image : ""} style={{marginTop:"30px", height:"4.063rem", width:"4.063rem"}}/>
                                                                                            <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                                                {collection.name}
                                                                                            </h6>
                                                                                            <p  style={{fontSize:"14px", fontWeight:"400", margin:"0", marginBottom:"1.25rem", lineHeight:"150%" }}>{collection.description}</p>
                                                                                        </div>

                                                                                        <div className={"container text-center mb-5 d-flex align-items-center justify-content-center"} style={{marginTop:"1.25rem", borderRadius:"200px", padding:"0.438rem 1rem"}}>
                                                                                            <h6 style={{color:"#522FFF", fontSize:"14px", fontWeight:"500"}}>Coming soon  </h6>

                                                                                        </div>


                                                                                    </div>

                                                                                </div>
                                                                            </p>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            }


                                                        </>

                                                    ))
                                                }

                                            </> : <>

                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                            {/* {
                                this.state.communitycollections.length > 4 ? <>
                                    <div className={"container"} style={{marginTop:"2rem"}}>
                                        <Link to={"/special-collections"} style={{textDecoration:"none"}}>
                                            <img src={bluearrow}/> <span style={{color:"#522FFF", fontSize:"14px", fontWeight:"500", marginLeft:"0.75rem"}}>View all collections</span>
                                        </Link>
                                    </div>
                                </> : <></>
                            } */}


                        </div>
                        <div className={"container-fluid"} style={{background:"#FFF8FD", padding:"3.5rem 3.063rem", marginTop:"4.75rem"}}>
                            <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                Foundations of User Experience
                            </h6>
                            <p style={{fontSize:"14px", fontWeight:"400"}}>
                                Learn the fundamentals of User experience by starting from research
                            </p>
                            <div className={"row"}>
                                {
                                    this.state.starting ? <>
                                        <div className="col-md-3 mt-5 categories">
                                            <Link to={"/articles/4/planning-research"} style={{textDecoration:"none"}}>
                                                <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                    <div className="categories-content-main h-100 text-center">

                                                        <img src={conducting}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                        <h1 className={"text-center"} style={{marginTop:"30px", fontSize: "18px"}}>
                                                            Planning Research
                                                        </h1>
                                                        <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                            <Link to={"/articles/4/planning-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                        </div>


                                                    </div>

                                                </div>
                                            </Link>
                                        </div>
                                    </> : <>

                                    </>
                                }
                                {
                                    this.state.conducting ? <>
                                        <div className="col-md-3 mt-5 categories">
                                            <Link to={"/articles/4/conducting-research"} style={{textDecoration:"none"}}>
                                                <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                    <div className="categories-content-main h-100 text-center">

                                                        <img src={conducting}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                        <h1 className={"text-center"} style={{marginTop:"30px", fontSize: "18px"}}>
                                                            Conducting Research
                                                        </h1>
                                                        <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                            <Link to={"/articles/4/conducting-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                        </div>


                                                    </div>

                                                </div>
                                            </Link>
                                        </div>
                                    </> : <>

                                    </>
                                }

                                {
                                    this.state.analyzing ? <>
                                        <div className="col-md-3 mt-5 categories">
                                            <Link to={"/articles/3/analyzing-research"} style={{textDecoration:"none"}}>
                                                <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                    <div className="categories-content-main h-100 text-center">

                                                        <img src={analyzing}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                        <h1 className={"text-center"} style={{marginTop:"30px", fontSize: "18px"}}>
                                                            Analyzing Research
                                                        </h1>
                                                        {/*  <p style={{fontSize:"14px", fontWeight:"400", margin:"0"}}>
                                                            Lorem ipsum dolor
                                                        </p>*/}
                                                        <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                            <Link to={"/articles/3/analyzing-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                        </div>


                                                    </div>

                                                </div>
                                            </Link>
                                        </div>
                                    </> : <>

                                    </>
                                }
                                {
                                    this.state.presenting ? <>
                                        <div className="col-md-3 mt-5 categories">
                                            <Link to={"/articles/1/presenting-research"}
                                                  style={{textDecoration: "none"}}>
                                                <div
                                                    className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                    <div className="categories-content-main h-100 text-center">

                                                        <img src={presenting} style={{
                                                            marginTop: "30px",
                                                            width: "4.063rem",
                                                            height: "4.063rem"
                                                        }}/>
                                                        <h1 className={"text-center"} style={{marginTop: "30px", fontSize: "18px"}}>
                                                            Presenting Research
                                                        </h1>

                                                        <div className={"container-fluid"} style={{marginTop: "30px",}}>
                                                            <Link to={"/articles/1/presenting-research"}
                                                                  style={{color: "#00944D"}}>Explore <MdArrowForward/></Link>
                                                        </div>


                                                    </div>

                                                </div>
                                            </Link>
                                        </div>
                                    </> : <>

                                    </>
                                }
                                {/*   {
                                        this.state.links.map((link1, key)=>(
                                            <>
                                                <>
                                                    <div className="col-md-3 mt-5 categories">
                                                        <Link to={"/articles/2/planning-research"} style={{textDecoration:"none"}}>
                                                            <div className={"container h-100 d-flex justify-content-center pb-5 bg-white"}>
                                                                <div className="categories-content-main h-100 text-center">

                                                                    <img src={planning}  style={{marginTop:"30px", width:"4.063rem", height:"4.063rem"}}/>
                                                                    <h6 className={"text-center"} style={{marginTop:"30px"}}>
                                                                        {link1.name}
                                                                    </h6>
                                                                    <p style={{fontSize:"14px", fontWeight:"400", margin:"0"}}>
                                                            Lorem ipsum dolor
                                                        </p>
                                                                    <div className={"container-fluid"} style={{marginTop:"30px",}}>
                                                                        <Link to={"/articles/2/planning-research"} style={{ color:"#00944D"}} >Explore <MdArrowForward /></Link>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </Link>
                                                    </div>
                                                </>
                                            </>
                                        ))
                                    }*/}

                            </div>
                        </div>
                    </div>
                </div>
                </MediaQuery>

                <Footer />
            </>
        );
    }
}
export default ResourcesMain