import React, {Component, useContext} from "react";
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import {
    FaBookmark,
    FaUser,
    FaRegBookmark,
    FaShareAlt,
    FaArrowRight,
    FaRegCopy,
    FaRegStar,
    FaStar, FaTrash
} from "react-icons/fa";
import SearchBox from "../Landing/SearchBox";
import Header from "../Landing/Header";
import {HiSearch, HiX} from "react-icons/hi";
import {Link} from "react-router-dom";
import cards from "../../../assets/Group 23.png";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Sidebar from "../Landing/Sidebar";
import {MdOutlineFilterAlt} from "react-icons/md";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import MediaQuery from "react-responsive";
import AlertComponent from "../ResultsCategories/AlertComponent";
import searchicon from "../../../assets/Frame 39.png";

class Favorite extends Component{
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);


    }
    state = {
        results: [],
        token:localStorage.getItem('token'),
        filter:"",
        filteritems:"",
        loading: true,
        showfilter:false
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    };
    handleSearchVoc = (e) => {
        if(this.state.filter===""){
            localStorage.setItem('searchquery', "Ux Research")
            window.location.assign('/all-results')
        } else {
            localStorage.setItem('searchquery', this.state.filter)
            window.location.assign('/all-results')
        }
    }
    handleClose = () => {
        this.setState({
            show: false
        })
    }
    onClearFilters = async (event) =>{
        this.setState({
            tags:""
        })
        window.location.reload()
    }
    handleRemove = async (result) =>{
        const response = axios.patch(buildAPIUrl(`v1/bookmarks/${result.id}/`), {
            type:"bookmark"
        }, {
            headers: {
                Authorization: `Bearer ${this.state.token}`
            }
        }).then((res)=>{

            this.setState({
                removemessage: true
            })
            setInterval(() => {
                this.setState({
                    removemessage: false
                })
                window.location.reload()
            }, 3000)

        })
    }
    componentDidMount() {
        let searchterm = localStorage.getItem('searchquery')
        const response  = axios.get(buildAPIUrl(`v1/bookmarks/?type=favourite`), {
            headers: {
                Authorization: `Bearer ${this.state.token}`
            }
        }).then((res)=>{
            this.setState({
                results:res.data[0],
                loading: false
            })
        })
    }

    handleCopyLink = (result) =>{
        navigator.clipboard.writeText(result.link).then(()=>{
            this.setState({
                copy: true
            })
            setInterval(() => {
                this.setState({
                    copy: false
                })
            }, 3000)
        })
    }
    handleFavorite = async (result) =>{
        console.log("favorite")
        const response = await axios.patch(buildAPIUrl(`v1/bookmarks/${result.id}/`), {
            type:"bookmark"
        }, {
            headers: {
                Authorization: `Bearer ${this.state.token}`
            }
        }).then((res)=>{
            this.setState({
                favorite: true
            })

            setTimeout(() => {
                this.setState({
                    favorite: false
                })
                window.location.reload()
            }, 2000)
        })
    }
    onChangeValue = async (event) => {

        const isChecked = event.target.checked
        if(isChecked){
            await this.setState({filteritems: [...this.state.filteritems, event.target.value]})
        } else {
            const index = this.state.filteritems.indexOf(event.target.value);
            this.state.filteritems.splice(index,1);
            await this.setState({filteritems: this.state.filteritems})
        }
        const response1 =  await  axios.get(buildAPIUrl(`v1/bookmarks/?type=favourite&tag=${this.state.filteritems}`), {
            headers: {
                Authorization: `Bearer ${this.state.token}`
            }}).then((res1)=>{
            console.log(res1.data)
            if(res1.data.length ===1) {
                this.setState({
                    results: res1.data[0]
                })
            } else if (res1.data.length ===2) {
                this.setState({
                    results: [...res1.data[0], ...res1.data[1]]
                })
            } else if (res1.data.length ===3) {
                this.setState({
                    results: [...res1.data[0], ...res1.data[1], ...res1.data[2]]
                })
            } else if (res1.data.length ===4) {
                this.setState({
                    results: [...res1.data[0], ...res1.data[1], ...res1.data[2], ...res1.data[3]]
                })
            }
        }).catch((err)=>{

        })
    }
    onChangeValueTime = async (event) => {
        this.setState({
            datesort:event.target.value
        })
        if(this.state.datesort === "new"){
            const oldest = [...this.state.results].sort((a,b)=> new Date(b.created_on) - new Date(a.created_on))
            console.log(oldest)
            await this.setState({
                results: oldest
            })

        } else if (this.state.datesort === "old"){
            const oldest = [...this.state.results].sort((a,b)=> new Date(a.created_on) - new Date(b.created_on))
            console.log(oldest)
            await this.setState({
                results: oldest
            })
        }
    }
    handleSearch = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    handleFilterShow = () => {
        document.addEventListener("mousedown", this.handleClickOutside);
        if(this.state.showfilter) {
            this.setState({
                showfilter:false
            })
        } else {
            this.setState({
                showfilter:true
            })
        }
    }
    handleClickOutside = (event) => {
        if (this.ref.current && !this.ref.current.contains(event.target)) {
            this.setState({
                showfilter:false
            })
        }

    }
    render() {
        const filtereditems = this.state.results.filter((result)=> {
            if (this.state.filter === "") {
                return result
            } else if (result.title.toLowerCase().includes(this.state.filter.toLowerCase())) {
                return result
            }
        })
        return (
            <>
                <Header />
                <MediaQuery minWidth={1200} >
                    <div className="row" style={{marginTop:"7rem"}}>
                        <div className="col-md-2 col-2 side-filters">
                            <div className="container-fluid border">
                                <h5>Filters <span onClick={this.onClearFilters} style={{
                                    color: "#00944D",
                                    fontSize: "10px",
                                    marginLeft: "1.5rem",
                                    right: "0",
                                    cursor: "pointer"
                                }}>Clear filters</span></h5>
                                <h6>by Category</h6>
                                <div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="General"
                                               id="articles" value="General" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="articles">
                                            Articles
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="Videos"
                                               id="category" value="Videos" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="videos">
                                            Videos
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="Social"
                                               id="category" value="Social Media" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="videos">
                                            Social media
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="Careers"
                                               id="category" value="Careers" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="careers">
                                            Jobs
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="Papers"
                                               id="category" value="Papers" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="careers">
                                            Papers
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="UxCourses"
                                               id="category" value="UxCourses" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="careers">
                                            UxCourses
                                        </label>
                                    </div>
                                </div>

                                <h6>by time</h6>
                                <div className="" onChange={this.onChangeValueTime}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault"
                                               id="flexRadioDefault1" value="new"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            New to Old
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault"
                                               id="flexRadioDefault1" value="old"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Old to New
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            className={this.state.active ? "col-md-8 col-12  narrowcontainer" : "col-md-8 col-12  widecontainer"}>
                            <div className="container-fluid">
                                <div className="row">

                                    <div className=" col-12 col-md-10">
                                        <div className="search-section col-12 col-md-12 mt-4 mb-5 ">
                                            <div className="row d-flex align-items-center ">
                                                <div className=" col-12 col-md-12 d-flex justify-content-center">
                                                    <form onKeyUp={this.handleSearch} style={{width: "100%"}}
                                                          autoComplete="off">
                                                        <div className="form-input input-group border" style={{borderRadius:"6px"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                            <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                                   style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search from your saved sources"/>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="container-fluid breadcrumbs">
                               <AlertComponent />
                                <div className="row">
                                    <div className="col-md-6 col-6 d-flex align-items-center"
                                         style={{marginTop: "1rem"}}>
                                        <h5>Favorite Searches</h5>
                                    </div>
                                    <div className="col-md-4 col-6 d-flex align-items-center justify-content-end">
                                        <Link to="/my-library" className="" style={{textDecoration: "none"}}>
                                            <button className="btn btn-fav">Go to all saved sources</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid ">
                                <div className="row ">
                                    <div className=" col-12 m-0 p-0 col-md-10">
                                        <div className="container-fluid mt-2 m-0" style={{paddingLeft: ".8rem"}}>
                                            {this.state.loading ? <>
                                                    {
                                                        [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                            key={n}/>)
                                                    }

                                                </> :
                                                <div>
                                                    {filtereditems.length > 0 ?
                                                        <>
                                                            {
                                                                this.state.datesort==="new" ?
                                                                    filtereditems.sort((a,b)=> new Date(b.created_on) - new Date(a.created_on)).map((result) => (
                                                                        <>

                                                                            <div className="saved-item p-3"
                                                                                 style={{marginTop: "1.5rem"}}>
                                                                                <div className="row">
                                                                                    <div className="col-md-8 col-10"><a
                                                                                        href={result.link}
                                                                                        target="_blank"><h6
                                                                                        className="result-url mt-2 mb-2">{result.link.substr(0, 30)}</h6>
                                                                                    </a></div>
                                                                                    {
                                                                                        result.type === "favourite" ? <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6  onClick={() => this.handleFavorite(result)} className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer",
                                                                                                    color: "#F9D103"
                                                                                                }}><FaStar/></h6></div> : <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 onClick={() => this.handleFavorite(result)}
                                                                                                className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer"
                                                                                                }}><span className="hidden-mob">Add to favorites</span>
                                                                                                <FaRegStar/>
                                                                                            </h6></div>
                                                                                    }

                                                                                </div>
                                                                                <a href={result.link} target="_blank"
                                                                                   style={{textDecoration: "none"}}><h6
                                                                                    className="result-name mt-2 mb-2">{result.title}</h6>
                                                                                </a>

                                                                                <p className="result-snippet">
                                                                                    {
                                                                                        result.snippet ? result.snippet.substr(0, 150) + " ..." : ""}

                                                                                </p>
                                                                                <span
                                                                                    className="date-crawled">{new Date(result.created_on).toLocaleDateString()}</span>
                                                                                <span className="cancel"
                                                                                      onClick={() => this.handleRemove(result)}> Remove <HiX
                                                                                    className="cancel-icon"/></span>
                                                                                <span className="save" onClick={() => {
                                                                                    this.handleCopyLink(result)
                                                                                }}> Copy link <FaRegCopy
                                                                                    className="save-icon"/></span>
                                                                            </div>
                                                                        </>
                                                                    ))
                                                                    :  filtereditems.sort((a,b)=> new Date(a.created_on) - new Date(b.created_on)).map((result) => (
                                                                        <>

                                                                            <div className="saved-item p-3"
                                                                                 style={{marginTop: "1.5rem"}}>
                                                                                <div className="row">
                                                                                    <div className="col-md-8 col-10"><a
                                                                                        href={result.link}
                                                                                        target="_blank"><h6
                                                                                        className="result-url mt-2 mb-2">{result.link.substr(0, 30)}</h6>
                                                                                    </a></div>
                                                                                    {
                                                                                        result.type === "favourite" ? <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 className="result-url mt-2 mb-2" onClick={() => this.handleFavorite(result)}
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer",
                                                                                                    color: "#F9D103"
                                                                                                }}><FaStar/></h6></div> : <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 onClick={() => this.handleFavorite(result)}
                                                                                                className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer"
                                                                                                }}><span className="hidden-mob">Add to favorites</span>
                                                                                                <FaRegStar/>
                                                                                            </h6></div>
                                                                                    }

                                                                                </div>
                                                                                <a href={result.link} target="_blank"
                                                                                   style={{textDecoration: "none"}}><h6
                                                                                    className="result-name mt-2 mb-2">{result.title}</h6>
                                                                                </a>

                                                                                <p className="result-snippet">
                                                                                    {
                                                                                        result.snippet ? result.snippet.substr(0, 150) + " ..." : ""}

                                                                                </p>
                                                                                <span
                                                                                    className="date-crawled">{new Date(result.created_on).toLocaleDateString()}</span>
                                                                                <span className="cancel"
                                                                                      onClick={() => this.handleRemove(result)}> Remove <HiX
                                                                                    className="cancel-icon"/></span>
                                                                                <span className="save" onClick={() => {
                                                                                    this.handleCopyLink(result)
                                                                                }}> Copy link <FaRegCopy
                                                                                    className="save-icon"/></span>
                                                                            </div>
                                                                        </>
                                                                    ))
                                                            }
                                                        </>
                                                        : <div className="search-message">

                                                            <img src={cards}/>
                                                            <h6 style={{fontSize: "20px", fontWeight: "500"}}>You
                                                                don’t
                                                                have any saved sources</h6>
                                                            <p>Saved sources allows you to keep sources you find
                                                                interesting for later</p>
                                                            <button onClick={this.handleSearchVoc}
                                                                    className="btn btn-fav">Try search
                                                            </button>

                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div
                                            className={this.state.favorite ? "alert alert-success position-fixed" : "d-none"}>
                                            <FaStar style={{color: "#F9D103"}}/> Article removed from favorites
                                            successfully
                                        </div>
                                        <div
                                            className={this.state.removemessage ? "alert alert-danger position-fixed" : "d-none"}>
                                            <FaTrash/> Article removed successfully
                                        </div>
                                        <div
                                            className={this.state.copy ? "alert alert-success position-fixed" : "d-none"}>
                                            <FaBookmark/> Link copied successfully
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div></MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className="row mobile">

                        <div className={this.state.active ? "col-md-8 col-12  narrowcontainer" : "col-md-8 col-12  widecontainer"}>
                            <div className="container-fluid">
                                <div className="row">

                                    <div className=" col-12 col-md-10">
                                        <div className="search-section col-12 col-md-12 mt-4 mb-5 ">
                                            <div className="row d-flex align-items-center ">
                                                <div className=" col-12 col-md-12 d-flex justify-content-center">
                                                    <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                        <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                            <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                            <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                                   style={{width: "100%", border:"none"}} placeholder="Search in your favorite sources"/>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="container-fluid breadcrumbs">
                                <div className="mobmodal">
                                    <div className="row">
                                        <div className="col-8 d-flex align-items-center">
                                            <h6><Link to="/saved-sources" style={{textDecoration: "none"}}><span
                                                style={{color: "#00944D", textDecoration: "none"}}> Saved sources > </span>
                                            </Link>All saved sources</h6>
                                        </div>
                                        <div className="col-4 d-flex justify-content-end align-items-center">
                                            <a className="mobfilter1" style={{color:"#757D8A", textDecoration:"none", cursor:"pointer"}} onClick={this.handleFilterShow}><h6 className="h6-filter">Filter <MdOutlineFilterAlt/> </h6></a>
                                            <div ref={this.ref} className={this.state.showfilter? "row mobfilter mobfilter1 d-flex justify-content-center position-absolute" : " d-none mobfilter1" } style={{right:"5%", top:"17rem", width:"50%"}}>
                                                <div className="container-fluid border bg-white">
                                                    <h5>Filters <span onClick={this.onClearFilters} style={{
                                                        color: "#00944D",
                                                        fontSize: "10px",
                                                        marginLeft: "1.8rem",
                                                        right: "0",
                                                        cursor: "pointer"
                                                    }}>Clear filters</span></h5>
                                                    <h6>by Category</h6>
                                                    <div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="General"
                                                                   id="articles" value="General" onChange={this.onChangeValue}/>
                                                            <label className="form-check-label" htmlFor="articles">
                                                                Articles
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="Videos"
                                                                   id="category" value="Videos" onChange={this.onChangeValue}/>
                                                            <label className="form-check-label" htmlFor="videos">
                                                                Videos
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="Social"
                                                                   id="category" value="Social Media" onChange={this.onChangeValue}/>
                                                            <label className="form-check-label" htmlFor="videos">
                                                                Social media
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="Careers"
                                                                   id="category" value="Careers" onChange={this.onChangeValue}/>
                                                            <label className="form-check-label" htmlFor="careers">
                                                                Jobs
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="Papers"
                                                                   id="category" value="Papers" onChange={this.onChangeValue}/>
                                                            <label className="form-check-label" htmlFor="careers">
                                                                Papers
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" name="UxCourses"
                                                                   id="category" value="UxCourses" onChange={this.onChangeValue}/>
                                                            <label className="form-check-label" htmlFor="careers">
                                                                UxCourses
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <h6>by time</h6>
                                                    <div className="" onChange={this.onChangeValueTime}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                   id="flexRadioDefault1" value="new"/>
                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                New to Old
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault"
                                                                   id="flexRadioDefault1" value="old"/>
                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                Old to New
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               <AlertComponent />
                                <div className="row">
                                    <div className="col-md-6 col-6 d-flex align-items-center"
                                         style={{marginTop: "1rem"}}>
                                        <h5>Favorite Searches</h5>
                                    </div>
                                    <div className="col-md-4 col-6 d-flex align-items-center justify-content-end">
                                        <Link to="/my-library" className="mobmodal" style={{textDecoration: "none", fontSize:"10px", color:"#00944D", fontWeight:"500"}}>
                                            Go to all saved sources
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid ">
                                <div className="row ">
                                    <div className=" col-12 m-0 p-0 col-md-10">
                                        <div className="container-fluid mt-2 m-0" style={{paddingLeft: ".8rem"}}>
                                            {this.state.loading ? <>
                                                    {
                                                        [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                            key={n}/>)
                                                    }

                                                </> :
                                                <div>
                                                    {filtereditems.length > 0 ?
                                                        <>
                                                            {
                                                                this.state.datesort==="new" ?
                                                                    filtereditems.sort((a,b)=> new Date(b.created_on) - new Date(a.created_on)).map((result) => (
                                                                        <>

                                                                            <div className="saved-item p-3"
                                                                                 style={{marginTop: "1.5rem"}}>
                                                                                <div className="row">
                                                                                    <div className="col-md-8 col-10"><a
                                                                                        href={result.link}
                                                                                        target="_blank"><h6
                                                                                        className="result-url mt-2 mb-2">{result.link.substr(0, 30)}</h6>
                                                                                    </a></div>
                                                                                    {
                                                                                        result.type === "favourite" ? <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer",
                                                                                                    color: "#F9D103"
                                                                                                }}><FaStar/></h6></div> : <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 onClick={() => this.handleFavorite(result)}
                                                                                                className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer"
                                                                                                }}><span className="hidden-mob">Add to favorites</span>
                                                                                                <FaRegStar/>
                                                                                            </h6></div>
                                                                                    }

                                                                                </div>
                                                                                <a href={result.link} target="_blank"
                                                                                   style={{textDecoration: "none"}}><h6
                                                                                    className="result-name mt-2 mb-2">{result.title}</h6>
                                                                                </a>

                                                                                <p className="result-snippet">
                                                                                    {
                                                                                        result.snippet ? result.snippet.substr(0, 150) + " ..." : ""}

                                                                                </p>
                                                                                <span
                                                                                    className="date-crawled">{new Date(result.created_on).toLocaleDateString()}</span>
                                                                                <span className="cancel"
                                                                                      onClick={() => this.handleRemove(result)}> Remove <HiX
                                                                                    className="cancel-icon"/></span>
                                                                                <span className="save" onClick={() => {
                                                                                    this.handleCopyLink(result)
                                                                                }}> Copy link <FaRegCopy
                                                                                    className="save-icon"/></span>
                                                                            </div>
                                                                        </>
                                                                    ))
                                                                    :  filtereditems.sort((a,b)=> new Date(a.created_on) - new Date(b.created_on)).map((result) => (
                                                                        <>

                                                                            <div className="saved-item p-3"
                                                                                 style={{marginTop: "1.5rem"}}>
                                                                                <div className="row">
                                                                                    <div className="col-md-8 col-10"><a
                                                                                        href={result.link}
                                                                                        target="_blank"><h6
                                                                                        className="result-url mt-2 mb-2">{result.link.substr(0, 30)}</h6>
                                                                                    </a></div>
                                                                                    {
                                                                                        result.type === "favourite" ? <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer",
                                                                                                    color: "#F9D103"
                                                                                                }}><FaStar/></h6></div> : <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 onClick={() => this.handleFavorite(result)}
                                                                                                className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer"
                                                                                                }}><span className="hidden-mob">Add to favorites</span>
                                                                                                <FaRegStar/>
                                                                                            </h6></div>
                                                                                    }

                                                                                </div>
                                                                                <a href={result.link} target="_blank"
                                                                                   style={{textDecoration: "none"}}><h6
                                                                                    className="result-name mt-2 mb-2">{result.title}</h6>
                                                                                </a>

                                                                                <p className="result-snippet">
                                                                                    {
                                                                                        result.snippet ? result.snippet.substr(0, 150) + " ..." : ""}

                                                                                </p>
                                                                                <span
                                                                                    className="date-crawled">{new Date(result.created_on).toLocaleDateString()}</span>
                                                                                <span className="cancel"
                                                                                      onClick={() => this.handleRemove(result)}> Remove <HiX
                                                                                    className="cancel-icon"/></span>
                                                                                <span className="save" onClick={() => {
                                                                                    this.handleCopyLink(result)
                                                                                }}> Copy link <FaRegCopy
                                                                                    className="save-icon"/></span>
                                                                            </div>
                                                                        </>
                                                                    ))
                                                            }
                                                        </>
                                                        : <div className="search-message">

                                                            <img src={cards}/>
                                                            <h6 style={{fontSize: "20px", fontWeight: "500"}}>You
                                                                don’t
                                                                have any saved sources</h6>
                                                            <p>Saved sources allows you to keep sources you find
                                                                interesting for later</p>
                                                            <button onClick={this.handleSearchVoc}
                                                                    className="btn btn-fav">Try search
                                                            </button>

                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div
                                            className={this.state.favorite ? "alert alert-success position-fixed" : "d-none"}>
                                            <FaStar style={{color: "#F9D103"}}/> Article added to favorites
                                            successfully
                                        </div>
                                        <div
                                            className={this.state.removemessage ? "alert alert-danger position-fixed" : "d-none"}>
                                            <FaTrash/> Article removed successfully
                                        </div>
                                        <div
                                            className={this.state.copy ? "alert alert-success position-fixed" : "d-none"}>
                                            <FaBookmark/> Link copied successfully
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div></MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className="row tablet">
                        <div className="col-md-3 col-2 side-filters">
                            <div className="container-fluid border" style={{marginLeft:"1rem"}}>
                                <h5>Filters <span onClick={this.onClearFilters} style={{
                                    color: "#00944D",
                                    fontSize: "10px",
                                    marginLeft: "1.5rem",
                                    right: "0",
                                    cursor: "pointer"
                                }}>Clear filters</span></h5>
                                <h6>by Category</h6>
                                <div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="General"
                                               id="articles" value="General" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="articles">
                                            Articles
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="Videos"
                                               id="category" value="Videos" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="videos">
                                            Videos
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="Social"
                                               id="category" value="Social Media" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="videos">
                                            Social media
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="Careers"
                                               id="category" value="Careers" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="careers">
                                            Jobs
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="Papers"
                                               id="category" value="Papers" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="careers">
                                            Papers
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="UxCourses"
                                               id="category" value="UxCourses" onChange={this.onChangeValue}/>
                                        <label className="form-check-label" htmlFor="careers">
                                            UxCourses
                                        </label>
                                    </div>
                                </div>

                                <h6>by time</h6>
                                <div className="" onChange={this.onChangeValueTime}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault"
                                               id="flexRadioDefault1" value="new"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            New to Old
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault"
                                               id="flexRadioDefault1" value="old"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Old to New
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            className={this.state.active ? "col-md-9 col-12  narrowcontainer" : "col-md-9 col-12  widecontainer"}>
                            <div className="container-fluid">

                                <div className="row">

                                    <div className=" col-12 col-md-10">
                                        <div className="search-section col-12 col-md-12 mt-4 mb-5 ">
                                            <div className="row d-flex align-items-center ">
                                                <div className=" col-12 col-md-12 d-flex justify-content-center">
                                                    <form onKeyUp={this.handleSearch} style={{width: "100%"}}
                                                          autoComplete="off">
                                                        <div className="form-input">
                                                             <span
                                                                 className="input-group-addon d-flex align-items-center"> <HiSearch
                                                                 className="search-icon"/></span>
                                                            <input className="search-field-text1 mob" type="text"
                                                                   name="q" onChange={this.handleInput}
                                                                   value={this.state.q}
                                                                   style={{width: "100%", paddingLeft: "8%"}}
                                                                   placeholder="Search for saved sources"/>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="container-fluid breadcrumbs">

                                <div className="row">
                                    <div className="col-md-6 col-6 d-flex align-items-center"
                                         style={{marginTop: "1rem"}}>
                                        <h5>Favorite Searches</h5>
                                    </div>
                                    <div className="col-md-4 col-6 d-flex align-items-center justify-content-end">
                                        <Link to="/my-library" className="" style={{textDecoration: "none"}}>
                                            <button className="btn btn-fav">Go to all saved sources</button>
                                        </Link>
                                    </div>
                                </div>
                                <AlertComponent />
                            </div>
                            <div className="container-fluid ">
                                <div className="row ">
                                    <div className=" col-12 m-0 p-0 col-md-10">
                                        <div className="container-fluid p-0 mt-2 m-0" style={{paddingLeft: ".8rem"}}>
                                            {this.state.loading ? <>
                                                    {
                                                        [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                            key={n}/>)
                                                    }

                                                </> :
                                                <div>
                                                    {filtereditems.length > 0 ?
                                                        <>
                                                            {
                                                                this.state.datesort==="new" ?
                                                                    filtereditems.sort((a,b)=> new Date(b.created_on) - new Date(a.created_on)).map((result) => (
                                                                        <>

                                                                            <div className="saved-item p-3"
                                                                                 style={{marginTop: "1.5rem"}}>
                                                                                <div className="row">
                                                                                    <div className="col-md-8 col-10"><a
                                                                                        href={result.link}
                                                                                        target="_blank"><h6
                                                                                        className="result-url mt-2 mb-2">{result.link.substr(0, 30)}</h6>
                                                                                    </a></div>
                                                                                    {
                                                                                        result.type === "favourite" ? <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer",
                                                                                                    color: "#F9D103"
                                                                                                }}><FaStar/></h6></div> : <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 onClick={() => this.handleFavorite(result)}
                                                                                                className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer"
                                                                                                }}><span className="hidden-mob">Add to favorites</span>
                                                                                                <FaRegStar/>
                                                                                            </h6></div>
                                                                                    }

                                                                                </div>
                                                                                <a href={result.link} target="_blank"
                                                                                   style={{textDecoration: "none"}}><h6
                                                                                    className="result-name mt-2 mb-2">{result.title}</h6>
                                                                                </a>

                                                                                <p className="result-snippet">
                                                                                    {
                                                                                        result.snippet ? result.snippet.substr(0, 150) + " ..." : ""}

                                                                                </p>
                                                                                <span
                                                                                    className="date-crawled">{new Date(result.created_on).toLocaleDateString()}</span>
                                                                                <span className="cancel"
                                                                                      onClick={() => this.handleRemove(result)}> Remove <HiX
                                                                                    className="cancel-icon"/></span>
                                                                                <span className="save" onClick={() => {
                                                                                    this.handleCopyLink(result)
                                                                                }}> Copy link <FaRegCopy
                                                                                    className="save-icon"/></span>
                                                                            </div>
                                                                        </>
                                                                    ))
                                                                    :  filtereditems.sort((a,b)=> new Date(a.created_on) - new Date(b.created_on)).map((result) => (
                                                                        <>

                                                                            <div className="saved-item p-3"
                                                                                 style={{marginTop: "1.5rem"}}>
                                                                                <div className="row">
                                                                                    <div className="col-md-8 col-10"><a
                                                                                        href={result.link}
                                                                                        target="_blank"><h6
                                                                                        className="result-url mt-2 mb-2">{result.link.substr(0, 30)}</h6>
                                                                                    </a></div>
                                                                                    {
                                                                                        result.type === "favourite" ? <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer",
                                                                                                    color: "#F9D103"
                                                                                                }}><FaStar/></h6></div> : <div
                                                                                            className="col-md-4 col-2 d-flex justify-content-end">
                                                                                            <h6 onClick={() => this.handleFavorite(result)}
                                                                                                className="result-url mt-2 mb-2"
                                                                                                style={{
                                                                                                    fontSize: "10px",
                                                                                                    cursor: "pointer"
                                                                                                }}><span className="hidden-mob">Add to favorites</span>
                                                                                                <FaRegStar/>
                                                                                            </h6></div>
                                                                                    }

                                                                                </div>
                                                                                <a href={result.link} target="_blank"
                                                                                   style={{textDecoration: "none"}}><h6
                                                                                    className="result-name mt-2 mb-2">{result.title}</h6>
                                                                                </a>

                                                                                <p className="result-snippet">
                                                                                    {
                                                                                        result.snippet ? result.snippet.substr(0, 150) + " ..." : ""}

                                                                                </p>
                                                                                <span
                                                                                    className="date-crawled">{new Date(result.created_on).toLocaleDateString()}</span>
                                                                                <span className="cancel"
                                                                                      onClick={() => this.handleRemove(result)}> Remove <HiX
                                                                                    className="cancel-icon"/></span>
                                                                                <span className="save" onClick={() => {
                                                                                    this.handleCopyLink(result)
                                                                                }}> Copy link <FaRegCopy
                                                                                    className="save-icon"/></span>
                                                                            </div>
                                                                        </>
                                                                    ))
                                                            }
                                                        </>
                                                        : <div className="search-message">

                                                            <img src={cards}/>
                                                            <h6 style={{fontSize: "20px", fontWeight: "500"}}>You
                                                                don’t
                                                                have any saved sources</h6>
                                                            <p>Saved sources allows you to keep sources you find
                                                                interesting for later</p>
                                                            <button onClick={this.handleSearchVoc}
                                                                    className="btn btn-fav">Try search
                                                            </button>

                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </MediaQuery>

                <Footer/>

            </>
        )
    }
}
export default Favorite