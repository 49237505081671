import React, { useState, useEffect } from 'react';
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";
import { Link } from "react-router-dom";
import Header from "../Header";

function ListAllPodcasts() {
  const [listOfPodcasts, setlistOfPodcasts] = useState([]);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const { isLoading, error, isError, sendRequest: fetchPodcasts } = useHttpFetch();


  if (!userToken) {
    window.location.assign('/');
  }

  useEffect(() => {
    const setPodcasts = (data) => {
      setlistOfPodcasts(data.reverse());
    }

    fetchPodcasts(
      {
        url: buildAPIUrl('v1/ux_podcasts/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setPodcasts
    );
  }, [fetchPodcasts, userToken]);

  const setRefreshedPodcasts = (data) => {
    setlistOfPodcasts(data.reverse());
  }

  async function refreshBooks (data) {
    fetchPodcasts(
      {
        url: buildAPIUrl('v1/ux_podcasts/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setRefreshedPodcasts
    );
  }
  async function deletePodcastsHandler(podcast) {
    fetchPodcasts(
      {
        url: buildAPIUrl(`v1/ux_podcasts/${podcast.id}/`),
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${userToken}`
        }
      },
      refreshBooks
    );

  }
  async function confirmDeletePodcastsHandler(podcast) {

    await deletePodcastsHandler(podcast);
    // let confirmDelete = confirm(`Are you sure you want to DELETE ${community.name}?`);
    // if (confirmDelete != null) {
    //   await deleteCommunityHandler(community);
    // }

  }

  let content = <p></p>;

  if (listOfPodcasts.length > 0) {
    content = listOfPodcasts.map((podcast) => (

      <tr key={podcast.id}>
        <td>{podcast.name}</td>
        <td>{podcast.host}</td>
        <td>{podcast.link}</td>
        <td><img src={podcast.image} height="50px" width="50px"/></td>

        <td>
          <Link className={"btn btn-primary w-100"} to={`/podcasts/edit/${podcast.id}`}>
            Edit
          </Link>

          <button className={"btn btn-danger w-100 mt-2"} onClick={() => confirmDeletePodcastsHandler(podcast)}>Delete</button>
        </td>
      </tr>

    ));
  }
  else {
    content = <p>Found no Podcasts.</p>;
  }

  return (
    <>
      <Header />
      <div className={"container-fluid mt-5"}>
        <div className={"container-fluid"} style={{ padding: "0 5rem" }}>
          <div className={"card border"}>
            <div className={"card-header"}>
              <div className={"row"}>
                <div className={"col-md-10"}>
                  <h4>List Of All Podcasts</h4>
                </div>
                <div className={"col-md-2"}>
                  <Link to="/podcasts/create">Create Podcast </Link>
                </div>
              </div>

            </div>
            <div className={"card-body"}>
              {isLoading && <p>Loading.....</p>}
              {isError && <p>{error}</p>}
              <table className={"table table-striped table-hover"}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Host</th>
                    <th>Link</th>
                    <th>Image</th>
                  </tr>
                </thead>
                <tbody>
                  {content}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export default ListAllPodcasts;