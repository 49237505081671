import React, {Component} from "react";
import MetaTags from "react-meta-tags";
import MediaQuery from "react-responsive";
import Header from "../Landing/Header";
import searchicon from "../../../assets/Frame 39.png";
import ResourcesNav from "./ResourcesNav";
import {MdFilterList, MdHighlightOff, MdKeyboardArrowDown} from "react-icons/md";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import LoginModal from "../Modals/LoginModal";
import Footer from "../Landing/Footer";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import cards from "../../../assets/Group 23.png";
import NotFound from "./NotFound";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import SeoMetaData from "./SeoMetaData";
import ReactPaginate from "react-paginate";
class UxCourses extends Component{
    state={
        items:[],
        loading:true,
        listview:false,
        showfilter:false,
        plantype: 0,
        filter:"",
        courses:[],
        tags:"",
        show:false,
        token:localStorage.getItem('token'),
        itemscount:15,
        pages:0,
        page:0,
    }
    componentDidMount() {

        if (window.location.hash.includes("#")){
            this.setState({
                filter:window.location.hash.replace("#", "").replaceAll("%20", " ")
            })
        } else {
            window.scrollTo(0,0)
        }
        EventsHandler("UxCourses Section Opened",  document.URL)
        const response = axios.get(buildAPIUrl(`v1/ux_research_courses/`)).then((res)=>{
            this.setState({
                courses:res.data,
                loading:false,
                pages:Math.floor(res.data.length / this.state.itemscount),
            })
        }).catch((error)=>{

        })

    }
    onChangeValue = async (event) => {
        if(this.state.token) {
            await this.setState({
                tags:event.target.value,
            })
            if(this.state.tags==="all"){
                const response1 =  await  axios.get(buildAPIUrl(`v1/ux_research_courses/`)).then((res)=>{
                    this.setState({
                        courses: res.data
                    })
                }).catch((err)=>{

                })
            } else {
                const response1 =  await  axios.get(buildAPIUrl(`v1/ux_research_courses/?query=${this.state.tags}`)).then((res)=>{
                    this.setState({
                        courses: res.data
                    })
                }).catch((err)=>{

                })
            }


        } else {
            await this.handleModalOpen()
        }

    }
    handleSearch = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    handleFilterShow = () => {
        if(this.state.showfilter) {
            this.setState({
                showfilter:false
            })
        } else {
            this.setState({
                showfilter:true
            })
        }
    }
    handleModalOpen = async ()=>{
        await this.setState({
            show: true,
        })
    }
    handleModalClose = ()=>{
        this.setState({
            show: false
        })

    }
    handlePageClick =  async (event) => {
        console.log(event.selected)
        await window.scrollTo(0,0)
        let page = event.selected;
        await this.setState({
            page:page
        })

    }
    saveEvent = (id)=> {
            EventsHandler("UxCourses Link Clicked",  document.URL, "none", id)
        //Increase views by 1
        axios.get(buildAPIUrl(`v1/ux_research_courses/${id}/`)).then(()=>{

        }).catch((error)=>{

        })
    }

    render() {
        const filtereditems = this.state.courses.filter((result)=> {
            if (this.state.filter === "") {
                return result
            } else if (result.name.toLowerCase().includes(this.state.filter.toLowerCase())) {
                return result
            }
        })
        function compareStrings(a, b) {
            // Assuming you want case-insensitive comparison
            a = a.toLowerCase();
            b = b.toLowerCase();

            return (a < b) ? -1 : (a > b) ? 1 : 0;
        }
        return (

            <>
              {/*  <MetaTags>
                    <title>Top Online Training Courses for User Experience (UX) - Research Bookmark</title>
                    <meta name="description" content="Top selected courses that teach about ux research" />
                    <meta property="og:title" content="Top Online Training Courses for User Experience (UX) - Research Bookmark" />
                    <meta property="og:url" content="https://www.researchbookmark.io/research-books" />
                </MetaTags>*/}
                <SeoMetaData title={"Courses"} />
                <LoginModal type={"2"} openLoginModal={this.handleModalOpen} closeLoginModal={this.handleModalClose} show = {this.state.show}/>
                <MediaQuery minWidth={1200}>
                    <Header pageType={"Free"} />
                    <div className={"row"} style={{marginTop:"7rem"}}>
                        <div className={"col-md-2"}></div>
                        <div className={"col-md-8"}>
                            <div className="container desktop">
                                <div className="search-section col-12 col-md-12 mt-4 ">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center">
                                            <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input input-group border"  style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                    <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search for courses"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                <div className={"saved-sources"}>
                                    <div className="row">
                                        <ResourcesNav />
                                        <div className="col-md-12 col-6 border p-0 rounded-3" style={{marginTop:"1rem"}}>
                                            <div className={"container py-4"}>
                                                <div className={"row"}>
                                                    <div className={"col-md-8 d-flex align-items-center px-0"}>
                                                        <h1 style={{fontSize:"18px", marginLeft:"0"}}>Top Online Training Courses for User Experience (UX)</h1>
                                                    </div>
                                                    <div className={"col-md-4 d-flex align-items-center justify-content-end"}>
                                                        <a className="text-end" target="_blank" style={{color:"black", fontSize:"14px", width: "100%", textDecoration:"none"}}>
                                                            <div className="dropdown-toggle originals-toggle"  id="navbarDropdown"
                                                                 role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}>
                                                                <h6 className={"m-0"}>
                                                        <span className={"p-0 m-0"}>
                                                             <MdFilterList className={"ori-icon"} />  Filter
                                                        </span>

                                                                </h6>
                                                            </div>
                                                            <div className="dropdown-menu originals-category-filter" aria-labelledby="navbarDropdown">
                                                                <div className="container-fluid" onChange={this.onChangeValue} >

                                                                    <>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio"
                                                                                   id="flexRadioDefault1" value="all" checked={this.state.tags==="all"} />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                                                All
                                                                            </label>
                                                                        </div>

                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio"
                                                                                   id="flexRadioDefault1" value="free" checked={this.state.tags==="free"} />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                                                Free
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="radio"
                                                                                   id="flexRadioDefault1" value="paid" checked={this.state.tags==="paid"} />
                                                                            <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                                                Paid
                                                                            </label>
                                                                        </div>
                                                                    </>

                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>


                                            </div>
                                                {
                                                    this.state.loading ?  <>
                                                            {
                                                                [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                            }

                                                        </>
                                                        :
                                                        <>
                                                            <table className="history-table" style={{overflow:"hidden"}}>
                                                                <thead>
                                                                <tr>
                                                                    <th>Tool name</th>
                                                                    <th>Used for</th>
                                                                    <th>Source</th>
                                                                </tr>
                                                                </thead>
                                                                {
                                                                    <tbody>

                                                                    {
                                                                        filtereditems.length > 0 ?
                                                                           <>
                                                                           {
                                                                               filtereditems.slice(this.state.page * this.state.itemscount, (this.state.page + 1) * this.state.itemscount).map((course)=>(
                                                                                   <tr>
                                                                                       <td>
                                                                                           {course.name}
                                                                                       </td>
                                                                                       <td>
                                                                                           {
                                                                                               course.price > 0 ? <>
                                                                                                   <h6 className={"course-price"}>
                                                                                                       {course.currency ? course.currency.code : ""}{course.price} {course.payment_plan==="NONE" ? "" : course.payment_plan}
                                                                                                   </h6>
                                                                                               </> : <>
                                                                                                   <h6 className={"free-course"}>FREE</h6>
                                                                                               </>
                                                                                           }
                                                                                       </td>
                                                                                       <td> <a onClick={()=>this.saveEvent(course.name)} href={course.url} target={"_blank"} style={{wordWrap:"anywhere"}}><p style={{color:"#00944D", textDecoration:"underline"}}>
                                                                                           {course.url}
                                                                                       </p></a></td>
                                                                                   </tr>
                                                                               ))
                                                                           }
                                                                           </>
                                                                            :<>

                                                                                <tr>
                                                                                    <td colSpan="3" className="search-message"> <img src={cards}/><br/>
                                                                                        <h6>No results found for this keyword</h6>
                                                                                        <p>This word is not available in tools list, try our search engine instead</p>
                                                                                        <NotFound term={this.state.filter} />
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                    }

                                                                    </tbody>
                                                                }

                                                            </table>

                                                        </>
                                                }
                                            {
                                                filtereditems.length > 0 ?
                                                    <div className={"container d-flex justify-content-center"}>
                                                        <ReactPaginate
                                                            pageCount={this.state.pages}
                                                            previousLabel={'Previous'}
                                                            nextLabel={'Next'}
                                                            onPageChange={this.handlePageClick}
                                                            containerClassName={'pagination'}
                                                            activeClassName={'active'}
                                                        />
                                                    </div>
                                                    : ""
                                            }

                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className="container-fluid p-0 mobile position-relative">

                        <div ref={this.ref} className={this.state.showfilter? "row  p-0 d-flex justify-content-center position-absolute" : " d-none mobfilter1"} style={{zIndex:"1000", height:"80vh", width:"100%", margin:"0"}} >
                            <div className=" bg-white border-top px-4" >
                                <div className={"row mt-3"} style={{paddingLeft:".9rem", paddingRight:"2rem"}}>
                                    <div className={"col-4"}>
                                        <span style={{color:"#00944D", fontSize:"14px",  right:"0", cursor:"pointer"}} onClick={this.clearFilters}>Reset</span>
                                    </div>
                                    <div className={"col-4"}>
                                        <h5 style={{fontSize:"20px", color:"#070707"}}>Filters </h5>
                                    </div>
                                    <div className={"col-4 d-flex justify-content-end"} style={{cursor:"pointer"}}>
                                        <MdHighlightOff onClick={this.handleFilterShow} style={{fontSize:"20px"}} />
                                    </div>
                                </div>
                                <h6 className={"filter-title1 text-center"} style={{marginTop:"1rem"}}>Sort by</h6>
                                <hr style={{marginLeft:"1rem", marginRight:"2rem"}}/>
                                <div className="container-fluid" onChange={this.onChangeValue} >

                                    <>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                   id="flexRadioDefault1" value="all" checked={this.state.tags==="all"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                All
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                   id="flexRadioDefault1" value="free" checked={this.state.tags==="free"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                Free
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio"
                                                   id="flexRadioDefault1" value="paid" checked={this.state.tags==="paid"} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                Paid
                                            </label>
                                        </div>
                                    </>

                                </div>

                            </div>
                            <button onClick={this.handleFilterShow} className={"btn btn-save-filter"}>
                                Save Filter
                            </button>
                        </div>
                        <Header pageType={"Free"} />
                        <div className={"row"}>
                            <div className={"col-md-8"}>
                                <div className="container-fluid p-0 mobile">
                                    <div className="search-section col-12 col-md-12 mb-2 mt-4">
                                        <div className="row d-flex align-items-center ">
                                            <div className=" col-12 col-md-12 d-flex justify-content-center">
                                                <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                    <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                        <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                        <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                               style={{width: "100%", border:"none"}} placeholder="Search for podcasts"/>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="saved-sources">
                                        <ResourcesNav />
                                        <div className={"row d-flex align-items-center p-0"}>
                                            <div  className="col-md-8 col-6 d-flex align-items-center">
                                                <h1 style={{fontWeight:"600"}}>Top Online Training Courses for User Experience (UX)</h1>
                                            </div>
                                            <div className="col-md-4 col-6 p-0 d-flex justify-content-end">
                                                <a className="mobfilter1 d-flex justify-content-end" style={{color:"#757D8A", marginRight:"1rem"}} onClick={this.handleFilterShow}><h6 className="h6-filter">Filter </h6></a>

                                            </div>
                                        </div>
                                        <div className={"row p-0"} style={{background:"rgba(237, 30, 152, 0.03)"}}>
                                            <div className={"col-7"}>
                                                <h6>Title</h6>
                                            </div>
                                            <div className={"col-5"}>
                                                <h6  style={{ marginLeft:"0"}}>Price</h6>
                                            </div>
                                        </div>
                                        {
                                            filtereditems.length > 0 ? <>

                                                {
                                                    filtereditems.map((course)=>(
                                                        <>
                                                            <div className={"row border-bottom"} style={{marginTop:"20px"}} id={course.id}>
                                                                <div className={"col-7"}>
                                                                    <a onClick={()=>this.saveEvent(course.name)} href={course.url} target={"_blank"}>
                                                                        <p style={{textDecoration:"underline", color:"rgb(0, 148, 77)"}}>{course.name}</p>
                                                                    </a>
                                                                </div>
                                                                <div className={"col-5"} style={{height:"fit-content", paddingLeft:"0"}}>
                                                                    {
                                                                        course.price > 0 ? <>
                                                                            <h6 className={"course-price"}>
                                                                                {course.currency ? course.currency.code : ""}{course.price} {course.payment_plan==="NONE" ? "" : course.payment_plan}
                                                                            </h6>
                                                                        </> : <>
                                                                            <h6 className={"free-course"}>FREE</h6>
                                                                        </>
                                                                    }
                                                                </div>

                                                            </div>

                                                        </>
                                                    ))
                                                }

                                            </> : <>
                                                <div className={"container d-flex justify-content-center"}>
                                                    <div className="search-message">
                                                        <img src={cards}/><br/>
                                                        <h6>No results found for this keyword</h6>
                                                        <p>This word is not available in the UXR courses list, try our search
                                                            engine instead</p>
                                                        <NotFound term={this.state.filter} />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <Header pageType={"Free"} />
                    <div className={"row"}>
                        <div className={"col-md-8"}>
                            <div className="container mobile">
                                <div className="search-section col-12 col-md-12 mb-2 mt-4">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center">
                                            <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                    <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                    <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "100%", border:"none"}} placeholder="Search for podcasts"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                <div className="row d-flex align-items-center">
                                    <ResourcesNav />
                                </div>
                                <div className={"row d-flex align-items-center"} style={{marginTop:"40px"}}>
                                    <div className="col-md-8 col-7 d-flex align-items-center">
                                        <h1 style={{fontSize:"16px"}}>Top Online Training Courses for User Experience (UX)</h1>
                                    </div>
                                    <div className={"col-5 "}>
                                        <div className="align-items-center dropdow mt-4 ">
                                            <a className="" target="_blank" style={{color:"black", fontSize:"14px", width: "100%", textDecoration:"none"}}>
                                                <div className="dropdown-toggle originals-toggle"  id="navbarDropdown"
                                                     role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{textDecoration:"none"}}>
                                                    <h6>
                                                        <span>
                                                              Filter <MdKeyboardArrowDown />
                                                        </span>

                                                    </h6>
                                                </div>
                                                <div className="dropdown-menu originals-category-filter" aria-labelledby="navbarDropdown" style={{overflow:"scroll"}}>
                                                    <div className="container-fluid" onChange={this.onChangeValue} >

                                                        <>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       id="flexRadioDefault1" value="all" checked={this.state.tags==="all"} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                                    All
                                                                </label>
                                                            </div>

                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       id="flexRadioDefault1" value="free" checked={this.state.tags==="free"} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                                    Free
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio"
                                                                       id="flexRadioDefault1" value="paid" checked={this.state.tags==="paid"} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1" style={{textTransform:"capitalize"}}>
                                                                    Paid
                                                                </label>
                                                            </div>
                                                        </>

                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {
                                    filtereditems.length > 0 ? <>

                                        {
                                            filtereditems.map((course)=>(
                                                <>
                                                    <div className={"row border-bottom"} style={{marginTop:"20px"}}>
                                                        <div className={"col-8"}>
                                                            <p>{course.name}</p>
                                                        </div>
                                                        <div className={"col-4 d-flex justify-content-end"} style={{height:"fit-content"}}>
                                                            {
                                                                course.price > 0 ? <>
                                                                    <h6 className={"course-price"}>
                                                                        {course.currency ? course.currency.code : ""}{course.price} {course.payment_plan==="NONE" ? "" : course.payment_plan}
                                                                    </h6>
                                                                </> : <>
                                                                    <h6 className={"free-course"}>FREE</h6>
                                                                </>
                                                            }
                                                        </div>

                                                        <div className={"col-12"}>
                                                            <a onClick={()=>this.saveEvent(course.name)} href={course.url} target={"_blank"}><p style={{color:"#00944D", textDecoration:"underline"}}>
                                                                {course.url}
                                                            </p></a>
                                                        </div>
                                                    </div>

                                                </>
                                            ))
                                        }

                                    </> : <>
                                        <div className={"container d-flex justify-content-center"}>
                                            <div className="search-message">
                                                <img src={cards}/><br/>
                                                <h6>No results found for this keyword</h6>
                                                <p>This word is not available in the UXR courses list, try our search
                                                    engine instead</p>
                                                <NotFound term={this.state.filter} />
                                            </div>
                                        </div>
                                    </>
                                }


                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <Footer />
            </>
        );
    }
}
export default UxCourses