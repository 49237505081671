import React, { useState, useEffect } from 'react';
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";
import { Link } from "react-router-dom";
import Header from "../Header";

function ListAllCourses() {
  const [listOfCourses, setlistOfCourses] = useState([]);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const { isLoading, error, isError, sendRequest: fetchCourses } = useHttpFetch();


  if (!userToken) {
    window.location.assign('/');
  }

  useEffect(() => {
    const setCourses = (data) => {
      setlistOfCourses(data.reverse());
    }
   
    fetchCourses(
      {
        url: buildAPIUrl('v1/ux_research_courses/'),
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${userToken}`
        }
      },
      setCourses
    );
  }, [fetchCourses, userToken]);

  const setRefreshedCourses = (data) => {
    setlistOfCourses(data.reverse());
  }

  async function refreshCourses (data) {
    fetchCourses(
      {
        url: buildAPIUrl('v1/ux_research_courses/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setRefreshedCourses
    );
  }
  async function deleteCourseHandler(course) {
    fetchCourses(
      {
        url: buildAPIUrl(`v1/ux_research_courses/${course.id}/`),
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${userToken}`
        }
      },
      refreshCourses
    );

  }
  async function confirmDeleteCourseHandler(course) {

    await deleteCourseHandler(course);
    // let confirmDelete = confirm(`Are you sure you want to DELETE ${community.name}?`);
    // if (confirmDelete != null) {
    //   await deleteCommunityHandler(community);
    // }

  }

  let content = <p></p>;

  if (listOfCourses.length > 0) {
    content = listOfCourses.map((course) => (
   

      <tr key={course.id}>
        <td>{course.name}</td>
        <td>{course.payment_plan}</td>
        <td>{course?.currency?.code}</td>
        <td>{course.price}</td>
        <td>{course.url}</td>
       

        <td>
          <Link className={"btn btn-primary w-100"} to={`/courses/edit/${course.id}`}>
            Edit
          </Link>

          <button className={"btn btn-danger w-100 mt-2"} onClick={() => confirmDeleteCourseHandler(course)}>Delete</button>
        </td>
      </tr>

    ));
  }
  else {
    content = <p>Found no Courses.</p>;
  }

  return (
    <>
      <Header />
      <div className={"container-fluid mt-5"}>
        <div className={"container-fluid"} style={{ padding: "0 5rem" }}>
          <div className={"card border"}>
            <div className={"card-header"}>
              <div className={"row"}>
                <div className={"col-md-10"}>
                  <h4>List Of All Courses</h4>
                </div>
                <div className={"col-md-2"}>
                  <Link to="/courses/create">Create Course</Link>
                </div>
              </div>

            </div>
            <div className={"card-body"}>
              {isLoading && <h3>Loading.....</h3>}
              {isError && <h3>{error}</h3>}
              <table className={"table table-striped table-hover"}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Payment Plan</th>
                    <th>Currency</th>
                    <th>Price</th>
                    <th>Url</th>
                  </tr>
                </thead>
                <tbody>
                  {content}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export default ListAllCourses;