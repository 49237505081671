import React, {Component} from "react";
import Header from "../Landing/Header";
import successimage from "../.././../assets/Group 90063.png"
import {Link} from "react-router-dom";
import {MdKeyboardArrowRight} from "react-icons/md";
class TrialSuccess extends Component{
    render() {
        return (
            <>
                <Header />
                <div className={"container d-flex justify-content-center success-page"} style={{marginTop:"8rem"}}>
                    <div style={{textAlign:"center"}}>
                        <img src={successimage}/>

                        <p style={{fontSize:"18px", lineHeight:"160%", fontWeight:"400"}}>
                            Thank you for signing up for our 7-days trial period! Please note that your card information has been saved and will be automatically charged at the end of the trial period. If you decide to cancel your subscription before the end of the trial period, you will not be charged.
                        </p>
                        <p style={{fontSize:"18px", lineHeight:"160%", fontWeight:"400"}}>
                            You can manage your subscription at any time by going to your <Link to={"/user-settings"}>settings. </Link>We hope you enjoy our service!
                        </p>
                        <div className={"container-fluid d-flex justify-content-center"}>
                            <Link to={"/"} className={"btn continue-searching"}>
                                <h6>Continue Searching </h6>
                            </Link>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}
export default TrialSuccess