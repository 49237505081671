import React, {useState, useEffect} from 'react'
import Header from "./Header";
import {HiSearch} from "react-icons/hi";
import {Link} from "react-router-dom";
import axios from "axios";
import buildAPIUrl from "../Services/UrlBuilder";
import * as XLSX from "xlsx";
import savedSources from "../User/Components/Personalization/SavedSources";
import {Pie} from "react-chartjs-2";

export default function AllSavedSources () {
    const [loading, setLoading]=useState(true)
    const [q, setQ] = useState("")
    const [articles, setArticles] = useState("")
    const [videos, setVideos] = useState("")
    const [social, setSocial] = useState("")
    const [papers, setPapers] = useState("")
    const [courses, setCourses] = useState("")
    const [jobs, setJobs] = useState("")
    const [allSavedSources, setSavedSources] = useState([])
    const [userToken, setUserToken]=useState(localStorage.getItem('token'))
    useEffect(()=>{
        const response1 = axios.get(buildAPIUrl('v1/bookmarks/?tag_count=videos'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log(res1.data[0].total_number_of_bookmarks)
            setVideos(res1.data[0].all_bookmarks)
            setLoading(false)
        })
        const response2 = axios.get(buildAPIUrl('v1/bookmarks/?tag_count=General'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log(res1.data[0].total_number_of_bookmarks)
            setArticles(res1.data[0].all_bookmarks)
            setLoading(false)
        })
        const response3 = axios.get(buildAPIUrl('v1/bookmarks/?tag_count=Careers'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log(res1.data[0].total_number_of_bookmarks)
            setJobs(res1.data[0].all_bookmarks)
            setLoading(false)
        })
        const response4 = axios.get(buildAPIUrl('v1/bookmarks/?tag_count=Papers'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log(res1.data[0].total_number_of_bookmarks)
            setPapers(res1.data[0].all_bookmarks)
            setLoading(false)
        })
        const response5 = axios.get(buildAPIUrl('v1/bookmarks/?tag_count=Courses'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log(res1.data[0].total_number_of_bookmarks)
            setCourses(res1.data[0].all_bookmarks)
            setLoading(false)
        })
        const response6 = axios.get(buildAPIUrl('v1/bookmarks/?tag_count=Social Media'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log(res1.data[0].total_number_of_bookmarks)
            setSocial(res1.data[0].all_bookmarks)
            setLoading(false)
        })
    }, [])
    const handleSearch = () =>{

    }
    const handleInput = () => {

    }
    const handleExport = ()=>{
        var wb = XLSX.utils.book_new()
        var ws = XLSX.utils.json_to_sheet(savedSources[0])
        XLSX.utils.book_append_sheet(wb,ws,"savedsources")
        XLSX.writeFile(wb, "Savedsources.xlsx")
    }

    const signdata = [videos.length,articles.length,papers.length,courses.length,jobs.length,social.length]
    console.log("sign", signdata)
    const signup =  {
        labels: ["Videos", "Articles", "Papers", "Courses", "Jobs", "Social Media"],
        datasets: [
            {
                label: "" ,
                data: signdata,
                fill: true,
                backgroundColor: ["#522FFF", "#ED1E98", "green", "yellow", "gray", "red"],
            },
        ]
    };
    return (
        <>
            <Header />
            <div className="row" style={{paddingLeft: "1rem", paddingRight:"1rem", marginLeft:"17.125rem", marginRight:"8.5rem"}}>
                <div className="col-md-12 col-12">
                    <div className="search-section col-12 col-md-12 ">
                        <div className="row d-flex align-items-center ">
                            <div className=" col-12 col-md-12 d-flex justify-content-center">
                                <form onKeyUp={handleSearch} style={{width:"100%"}} autoComplete="off">
                                    <div className="form-input">
                                                             <span className="input-group-addon d-flex align-items-center"> <HiSearch
                                                                 className="search-icon"/></span>
                                        <input className="search-field-text1 mob" type="text" name="q" onChange={handleInput} value={q}
                                               style={{width: "100%", paddingLeft:"8%"}} placeholder="Search for users"/>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid breadcrumbs p-0" style={{marginTop:"1.5rem"}}>
                        <div className="">
                            <div className="row">
                                <div className="col-8 d-flex align-items-center">
                                    <h6><Link to="/admin-dashboard" style={{textDecoration: "none"}}> <span
                                        style={{color: "#00944D", cursor: "pointer"}}> Dashboard > </span>
                                    </Link> All saved sources</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:"1.75rem"}}>
                            <div className="col-md-6 col-6 d-flex align-items-center">
                                <h5>All saved sources</h5>
                            </div>
                          {/*  <div className="col-md-6 col-6 d-flex justify-content-end">
                                <button className="btn btn-fav" onClick={handleExport}>Export data</button>

                            </div>*/}
                        </div>
                    </div>
                    <div className="container">
                        <Pie height={400} data={signup}
                             options={{legend: {
                                     labels: {
                                         generateLabels: (chart) => {
                                             const datasets = chart.data.datasets;
                                             return datasets[0].data.map((data, i) => ({
                                                 text: `${chart.data.labels[i]} ${data}`,
                                                 fillStyle: datasets[0].backgroundColor[i],
                                             }))
                                         }
                                     }}, maintainAspectRatio:false}} />
                    </div>

                  {/*  <div className="saved-sources mt-3">
                        {
                            loading ? <>
                                    <div className="container-fluid d-flex justify-content-center">
                                        <ClipLoader color={"green"} loading={ScaleLoader} size={100}/>
                                    </div>
                                </> :
                                <table className="history-table mt-3">
                                    {
                                        allSavedSources ?
                                            <>
                                                <thead className="">
                                                <tr>
                                                    <th>Title</th>
                                                    <th>URL</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    allSavedSources.map((source)=>(
                                                        <tr>
                                                            <td>
                                                                {source.title}
                                                            </td>
                                                            <td>
                                                                <a href={source.link} target="_blank">
                                                                    <p style={{textDecoration:"underline", color:"#00944D"}}>{source.link.replace("http://www.", "").replace("https://www.", "")}</p>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        )
                                                    )
                                                }


                                                </tbody>
                                            </>

                                        : ""
                                    }

                                </table>
                        }
                    </div>*/}

                </div>
            </div>
        </>
    )
}