import React, {Component} from "react";
import axios from "axios";
import Header from "../Landing/Header";
import {Link} from "react-router-dom";
import buildAPIUrl from "../../../Services/UrlBuilder";
import MediaQuery from "react-responsive";
import {FaStar} from "react-icons/fa";
class SavedSources extends Component{
    state={
        results:"",
        token:localStorage.getItem('token'),
        favorite: ""
    }
    async componentDidMount() {
        let searchterm = localStorage.getItem('searchquery')
        const response  = await axios.get(buildAPIUrl(`v1/bookmarks/`), {
            headers: {
                Authorization: `Bearer ${this.state.token}`
            }
        }).then((res)=>{
            console.log(res)
            this.setState({
                results:res.data[0].length
            })
        })
        const response1  = axios.get(buildAPIUrl(`v1/bookmarks/?type=favourite`), {
            headers: {
                Authorization: `Bearer ${this.state.token}`
            }
        }).then((res)=>{
            console.log(res)
            this.setState({
                favorite:res.data[0].length
            })
        })
    }
    render() {
        return (
            <>
                <Header />
                <MediaQuery minWidth={1200} >
                    <div className="row" style={{marginTop:"7rem"}}>
                        <div className="col-md-2"></div>
                        <div className="col-md-10">

                            <div className="saved-sources" style={{marginTop:"1.5rem"}}>

                                <div className="container">
                                    <h6>Saved sources</h6>
                                    <div className="row">

                                        <div className="col-md-3 col-6 ">
                                            <Link to="/my-library" style={{textDecoration:"none"}}>
                                                <div className="card h-100">
                                                    <div className="card-body d-flex justify-content-center" style={{paddingTop:"3rem", paddingBottom:"2.375rem"}}>
                                                        <h6>All saved sources</h6>
                                                    </div>
                                                    <div className="card-footer">
                                                        <h6>{this.state.results} sources</h6>
                                                    </div>
                                                </div>
                                            </Link>

                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <Link to="/favorites" style={{textDecoration:"none"}}>
                                                <div className="card h-100">
                                                    <div className="card-body d-flex justify-content-center" style={{paddingTop:"3rem", paddingBottom:"2.375rem"}}>
                                                        <h6>Favorite sources</h6>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col-md-8 col-8">
                                                                <h6>{this.state.favorite} sources</h6>
                                                            </div>
                                                            <div className="col-md-4 col-4 d-flex justify-content-end">
                                                                <h5 style={{color:"#F9D103", fontSize:"14px"}}><FaStar /></h5>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-10">

                            <div className="saved-sources" style={{marginTop:"1.5rem"}}>

                                <div className="container">
                                    <h6>Saved sources</h6>
                                    <div className="row">

                                        <div className="col-md-3 col-6 ">
                                            <Link to="/my-library" style={{textDecoration:"none"}}>
                                                <div className="card h-100">
                                                    <div className="card-body d-flex justify-content-center" style={{paddingTop:"3rem", paddingBottom:"2.375rem"}}>
                                                        <h6>All saved sources</h6>
                                                    </div>
                                                    <div className="card-footer">
                                                        <h6>{this.state.results} sources</h6>
                                                    </div>
                                                </div>
                                            </Link>

                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <Link to="/favorites" style={{textDecoration:"none"}}>
                                                <div className="card h-100">
                                                    <div className="card-body d-flex justify-content-center" style={{paddingTop:"3rem", paddingBottom:"2.375rem"}}>
                                                        <h6>Favorite sources</h6>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col-md-8 col-8">
                                                                <h6>{this.state.favorite} sources</h6>
                                                            </div>
                                                            <div className="col-md-4 col-4 d-flex justify-content-end">
                                                                <h5 style={{color:"#F9D103", fontSize:"14px"}}><FaStar /></h5>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div></MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-10">

                            <div className="saved-sources" style={{marginTop:"1.5rem"}}>

                                <div className="container">
                                    <h6>Saved sources</h6>
                                    <div className="row">

                                        <div className="col-md-3 col-6 ">
                                            <Link to="/my-library" style={{textDecoration:"none"}}>
                                                <div className="card h-100">
                                                    <div className="card-body d-flex justify-content-center" style={{paddingTop:"3rem", paddingBottom:"2.375rem"}}>
                                                        <h6>All saved sources</h6>
                                                    </div>
                                                    <div className="card-footer">
                                                        <h6>{this.state.results} sources</h6>
                                                    </div>
                                                </div>
                                            </Link>

                                        </div>
                                        <div className="col-md-3 col-6 ">
                                            <Link to="/favorites" style={{textDecoration:"none"}}>
                                                <div className="card h-100">
                                                    <div className="card-body d-flex justify-content-center" style={{paddingTop:"3rem", paddingBottom:"2.375rem"}}>
                                                        <h6>Favorite sources</h6>
                                                    </div>
                                                    <div className="card-footer">
                                                        <div className="row">
                                                            <div className="col-md-8 col-8">
                                                                <h6>{this.state.favorite} sources</h6>
                                                            </div>
                                                            <div className="col-md-4 col-4 d-flex justify-content-end">
                                                                <h5 style={{color:"#F9D103", fontSize:"14px"}}><FaStar /></h5>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div></MediaQuery>


            </>
        )
    }
}
export default SavedSources