import React, {Component} from "react";
import { loadStripe } from "@stripe/stripe-js";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import NewUserPayment from "./NewUserPayment";
const stripe = process.env.REACT_APP_STRIPE_SECRET
const promise = loadStripe(`${stripe}`);
const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d"
            }
        },
        invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};
export default function NewUserCheckout() {
    return (
        <Elements stripe={promise} >
           <NewUserPayment cardStyle={cardStyle} amount={localStorage.getItem('useramount')}  />
        </Elements>
    )
}