import React, {useEffect, useState} from "react";

export default function WebinarRegister(props){
   
    
    return (
        <>
            <div className={props.activeview===3 ? "row" : "d-none"}>
                <div id="eventbrite-widget-container-645206057687"></div>
                {/* <div id="eventbrite-widget-modal-trigger-645206057687"></div> */}
                {/* <button id="eventbrite-widget-modal-trigger-645206057687" type="button">Buy Tickets</button> */}

            </div>

        </>
    )
}