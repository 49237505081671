import React, { useState, useCallback, useEffect} from 'react';
import { fetchAllConferences, deleteConference } from './ConferenceApi';
import {Link} from "react-router-dom";
import Header from "../Header";

function ListOfConference() {
    const [listOfConferences, setlistOfConferences] = useState([]);
    const [userToken, setUserToken]=useState(localStorage.getItem('token'));
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");



    if(!userToken){
        window.location.assign('/');
    }

    const fetchConferencesHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
          let response = await fetchAllConferences();
          const data = await response.json();
          setlistOfConferences(data.reverse());
        } catch (error) {
          setError(error.message);
          setIsError(true);
        }
        setIsLoading(false);
      }, []);

      useEffect(() => {
        fetchConferencesHandler();
      }, [fetchConferencesHandler]);


      async function deleteConferenceHandler(conference) {

        setIsLoading(true);
        setError(null);
        try {
          await deleteConference(conference.id, userToken);
          fetchConferencesHandler();
    
        } catch (error) {
          setError(error.message);
        }
        setIsLoading(false);
    
    
      }
      async function confirmDeleteConferenceHandler(conference) {
    
        await deleteConferenceHandler(conference);
        // let confirmDelete = confirm(`Are you sure you want to DELETE ${community.name}?`);
        // if (confirmDelete != null) {
        //   await deleteCommunityHandler(community);
        // }
    
      }


    let content = <p></p>;

    if (listOfConferences.length > 0) {
        content = listOfConferences.map((conference)=>(
            
          <tr key={conference.id}>

                <td>{conference.name}</td>
                <td>{conference.url}</td>
                <td>{conference.location}</td>
                <td>{conference.cost}</td>
                <td>{conference.start_date}</td>
                <td>{conference.end_date}</td>
              <td>
                  <Link className={"btn btn-primary w-100"} to={`/conference/edit/${conference.id}`}>
                     Edit
                  </Link>

                  <button className={"btn btn-danger w-100 mt-2"} onClick={() => confirmDeleteConferenceHandler(conference)}>Delete</button>
              </td>
        </tr>

        ));
    }
    else{
        content = <p>Found no conference.</p>;
    }

  return(
      <>
            <Header />
          <div className={"container-fluid mt-5"}>
              <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                  <div className={"card border"}>
                      <div className={"card-header"}>
                          <div className={"row"}>
                              <div className={"col-md-10"}>
                                  <h4>List of Conference</h4>
                              </div>
                              <div className={"col-md-2"}>
                                  <Link to="/conference/create">Create Conference </Link>
                              </div>
                          </div>

                      </div>
                      <div className={"card-body"}>
                          {isLoading && <p>Loading.....</p>}
                          {isError && <p>{error}</p>}
                          <table className={"table table-striped table-hover"}>
                              <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Url</th>
                                        <th>Location</th>
                                        <th>Cost</th>
                                        <th>Start date</th>
                                        <th>End date</th>
                                        <th>Options</th>
                                    </tr>
                              </thead>
                              <tbody>
                              {content}
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>


      </>
  )
}
export default ListOfConference;