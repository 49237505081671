import React, { useState, useCallback, useEffect} from 'react';
import buildAPIUrl from "../../Services/UrlBuilder";
import {useParams} from "react-router-dom";
import Header from "../Header";


function EditResearchCommunity() {

    const params = useParams();
    
    const [newCommunityDetails, setnewCommunityDetails] = useState({
        name: "",
        url: "",
        platform: "",
        audience: ""
    });
    const [userToken, setUserToken]=useState(localStorage.getItem('token'))
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");
    


    if(!userToken){
        window.location.assign('/');
    }

    const fetchCommunityHandler = useCallback(async () => {
        setIsLoading(true);
        try {
          const response = await fetch(buildAPIUrl(`v1/research_communities/${params.community_id}`));
          if (!response.ok) {

            throw new Error('Something went wrong!');
          }
    
          const data = await response.json();
          
          setnewCommunityDetails(data);
        } catch (error) {
          setError(error.message);
        }
        setIsLoading(false);
      }, []);

      useEffect(() => {
        fetchCommunityHandler();
      }, [fetchCommunityHandler]);



    function onChangeHandler(event){
        setnewCommunityDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }

    
    async function submitHandler (event){
        event.preventDefault();
        const new_community = {
            name: newCommunityDetails.name,
            url: newCommunityDetails.url,
            platform: newCommunityDetails.platform,
            audience: newCommunityDetails.audience
        }

        setIsLoading(true);
        const response = await fetch(buildAPIUrl(`v1/research_communities/${params.community_id}/`), {
            method: 'PATCH',
            body: JSON.stringify(new_community),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userToken}`
            }
        });

        setIsLoading(false);
        if(response.status === 200){
            setnewCommunityDetails({
                name: "",
                url: "",
                platform: "",
                audience: ""
            })
            window.location.assign('/uxr-communities/all');

        }else{
            setIsError(true);
            setError(response.statusText);
           
        }
        
    }

  return(
      <>
            <Header />
          <div className={"container-fluid mt-5"}>
              <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                  <div className={"row"}>
                      <div className={"col-md-6"}>
                          {isLoading && <p>Loading.....</p>}
                          {isError && <p>{error}</p>}
                          <div className={"card border"}>
                              <div className={"card-header"}>
                                  <h4>Edit Research Community</h4>
                              </div>
                              <div className={"card-body"}>
                                  <form onSubmit={submitHandler}>
                                      <div className={"mb-3"}>
                                          <label htmlFor='name'>Name</label>
                                          <input
                                              type='text'
                                              id='name'
                                              name='name'
                                              value={newCommunityDetails.name}
                                              onChange={onChangeHandler}
                                              required
                                              className={"form-control"}
                                          />
                                      </div>
                                      <div className={"mb-3"}>
                                          <label htmlFor='platform'>Platform</label>

                                          <input
                                              type='text'
                                              id='platform'
                                              name='platform'
                                              value={newCommunityDetails.platform}
                                              onChange={onChangeHandler}
                                              required
                                              className={"form-control"}
                                          />
                                      </div>

                                      <div className={"mb-3"}>
                                          <label htmlFor='url'>Url</label>
                                          <br/>
                                          <input
                                              className={"form-control"}
                                              type='url'
                                              id='url'
                                              name='url'
                                              value={newCommunityDetails.url}
                                              onChange={onChangeHandler}
                                              required
                                          />
                                      </div>
                                      <div className={"mb-3"}>
                                          <label htmlFor='audience'>Audience</label>
                                          <br/>
                                          <textarea
                                              className={"form-control"}
                                              rows="4"
                                              cols="50"
                                              id='audience'
                                              name='audience'
                                              value={newCommunityDetails.audience}
                                              onChange={onChangeHandler}
                                              required
                                          />
                                      </div>
                                      <br/>
                                      <button className={"btn btn-success"}>Update Community</button>

                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>



      </>
  )
}
export default EditResearchCommunity;