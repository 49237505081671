import Header from "../Header";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import React, {useEffect,useState} from "react";
import {FaTrash} from "react-icons/fa";

export default function Organizations(){
    const[organizations, setOrganizations]=useState("")
    const[users, setUsers]=useState([])
    const getOrganizations = async ()=>{
        const orgs = await axios.get(buildAPIUrl('v1/collections/organisations/'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        setOrganizations(orgs.data)
        console.log(orgs.data)
    }
    const getUsers = async (id)=>{
        const userlist = await axios.get(buildAPIUrl(`v1/collections/organisations?organisation_id=${id}`), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        setUsers(userlist.data)
    }
    const removeMember = async(id, type, status)=>{
        if(type===1){
            await axios.patch(buildAPIUrl(`v1/collections/organisations/${id}/`), {
                is_admin:!status
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
        } else {
            await axios.patch(buildAPIUrl(`v1/collections/organisations/${id}/`), {
                is_active:false
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }

            })

        }

        getUsers()
    }

    useEffect(()=>{
        getOrganizations()
    }, [])
    return(
        <>
            <Header />
            <div className={"container m-5"}>
                <div  className={"row"}>
                    <div className={"col-md-6 border p-3"}>
                        <h5>
                            Organizations
                        </h5>
                        <table className={"table table-active mt-5"}>
                            <tr>
                                <th>Name</th>
                                <th>View</th>
                            </tr>
                            {
                                organizations.length > 0 ? <>
                                    {
                                        organizations.map((org)=>(
                                            <tr>
                                                <td>
                                                    {org.name}
                                                </td>
                                                <td onClick={()=>getUsers(org.id)}>
                                                    View
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </> : <>
                                    <tr>
                                        <td colSpan={2}>No organizations found</td>
                                    </tr>
                                </>
                            }
                        </table>
                    </div>
                    {
                        users.length > 0 ? <>
                            <div className={"col-md-6"}>
                                <table className={"w-100 table table-bordered table-striped"}>
                                    <thead>
                                    <tr >

                                        <th >Email</th>
                                        <th >User Type</th>
                                        <th>Remove</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        users.length > 0 ? <>
                                            {
                                                users.map((user)=>(
                                                    <tr key={user.id}>
                                                        <td>{user.member}</td>
                                                        {
                                                            localStorage.getItem('email') === user.member ? <>
                                                                <td style={{cursor:"pointer"}} className={"text-success"}>{user.is_admin ? "Admin" : "Normal User"}</td>
                                                            </> : <>
                                                                <td style={{cursor:"pointer"}} className={"text-success"} onClick={()=>removeMember(user.id, 1, user.is_admin)}>{user.is_admin ? "Admin" : "Normal User"}</td>
                                                            </>
                                                        }

                                                        <td>
                                                            {
                                                                localStorage.getItem('email')===user.member ? <> Me </> : <>
                                                                    {
                                                                        user.is_active ? <>
                                                                            <p className={"text-danger"} style={{cursor:"pointer"}} onClick={()=>removeMember(user.id, 2, true)}> <FaTrash /> Remove</p>
                                                                        </> : <>
                                                                            <p className={"text-danger"} > Removed</p></>
                                                                    }
                                                                </>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </> : <></>
                                    }


                                    </tbody>
                                </table>
                            </div>
                        </> : <></>
                    }

                </div>
            </div>



        </>
    )
}