import React, {Component} from "react";
import axios from "axios";
import { FaRegBookmark, FaRegCopy, FaUser} from "react-icons/fa";

import Filters from "../Landing/Filters";
import SearchBox from "../Landing/SearchBox";
import LoginModal from "../Modals/LoginModal";
import Header from "../Landing/Header";
import buildAPIUrl from "../../../Services/UrlBuilder";
import MediaQuery from "react-responsive";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import Footer from "../Landing/Footer";
import notfound from "../../../assets/notfound.png";
import Pagination from "./Pagination";
import AlertComponent from "./AlertComponent";
import Sidebar from "../Landing/Sidebar";

class Videos extends Component{
    state = {
        results: [],
        filter:"",
        siteid:"",
        social: false,
        total:null,
        search:"",
        web:true,
        imgs:false,
        vids:false,
        news:false,
        images:[],
        token: localStorage.getItem('token'),
        pager:{},
        perPage: 15,
        page: 0,
        pages: 0,
        count:10,
        copy:false,
        saved:false,
        configID:`${process.env.REACT_APP_BING_CONFIG_ID}`,
        subscrKey:`${process.env.REACT_APP_BING_SUBSCRIPTION_ID}`,
        loading: true,
        notfound:false,
        offset:0,
        exists: "",
    }
    componentWillMount() {
        if(!localStorage.getItem('token')){
            window.location.assign("/")
        }
        if(window.location.search) {
            localStorage.setItem('searchquery', window.location.search.replace('?q=', " ").replaceAll('%20', " "))
        }

    }
    componentDidMount() {

        let searchterm = localStorage.getItem('searchquery').replace("+", " ")
        axios.get(`https://api.bing.microsoft.com/v7.0/custom/videos/search?q=${searchterm} ux research&customconfig=${this.state.configID}&count=15`, {
            headers:{
                "Ocp-Apim-Subscription-Key":this.state.subscrKey
            }
        }).then((res)=>{
            console.log(res.data)
            if (res.data.value===undefined){
                this.setState({
                    notfound:true
                })
            } else {
                this.setState({
                    images: res.data.value,
                    loading: false,
                    total:res.data.totalEstimatedMatches
                })
            }

        })
        this.setState({
            pages: Math.floor(this.state.images.length / this.state.perPage)
        });
    }
    handleSaveSearch = async ( result) => {
        await axios.post(buildAPIUrl('v1/bookmarks/'), {
                title: result.name,
                link: result.contentUrl,
                snippet:result.description,
                tag:"Videos"
            },
            {
                headers: {
                    Authorization: `Bearer ${this.state.token}`
                }
            } ).then((res) => {
            this.setState({
                saved:true,
                show: false,
            })

            setInterval(() => {
                this.setState({
                    saved: false
                })
            }, 20000)
        }).catch((error)=>{
            this.setState({
                exists:error
            })
            setTimeout(()=>{
                this.setState({
                    exists:""
                })
            }, 5000)
        })
    }
    handleCopyLink = (result) =>{
        navigator.clipboard.writeText(result.url).then(()=>{
            this.setState({
                copy: true
            })
            setInterval(() => {
                this.setState({
                    copy: false
                })
            }, 3000)
        })
    }
    handlePageClick = (event) => {
        let page = event.selected;
        this.setState({page})
    }
    handleModalClose = ()=>{
        this.setState({
            show: false
        })
    }
    handleModalOpen = (result)=>{

        this.setState({
            show: true,
            siteid:result
        })
    }
    handleOpen = ()=>{
        this.setState({
            active: false
        })
    }
    handleClose = () => {
        this.setState({
            active: true
        })
    }
    handleImages = () => {
        console.log("images")
        this.setState({
            search: "/images",
            social:false,
            web:false,
            imgs:true,
            vids:false,
            news:false,
            results:"",
        })
    }
    render() {
        const {page, perPage, pages} = this.state;
        let items = this.state.images.slice(page * perPage, (page + 1) * perPage);
        return (
            <>
                <Header />
                <MediaQuery minWidth={1200} >
                    <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem", marginTop:"7rem"}}>
                        <div className="col-md-1"></div>
                        <div className="col-md-8 col-12">
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-12 col-md-11"><SearchBox videos={true}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                {
                                    this.state.notfound ? " " :
                                        <Filters videos={true}/>
                                }

                            </div>
                            <div className="row">
                                <div className=" col-12 p-0 col-md-10" style={{overflow: "hidden"}}>
                                    <div className="container-fluid">
                                        <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                        existstatus={this.state.exists} type={"Videos"}/>
                                    </div>
                                    <div className="container mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            {
                                                this.state.notfound ? <h6>0 results found</h6> :
                                                    <h6>About {
                                                        this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                    } results </h6>
                                            }
                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img src={notfound}/>
                                                            </div>

                                                            <div className="search-message text-center">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>We couldn’t find results for this keyword, try another term</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div>
                                                                {
                                                                    this.state.images.map((result, index) => (
                                                                        <div style={{marginBottom: "2.563rem"}}>
                                                                            <div className="row ">
                                                                                <div
                                                                                    className="col-md-3 col-6 d-flex align-items-center">

                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none"}}><img
                                                                                        src={result.thumbnailUrl}
                                                                                        className="video-img"/></a>
                                                                                </div>
                                                                                <div className="col-md-9 col-6">
                                                                                    {/* <a href={result.contentUrl} target="_blank" style={{textDecoration:"none"}}><h6 className="result-url">{result.contentUrl}</h6></a>*/}
                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none"}}><h6
                                                                                        className="result-name mt-2 mb-2">{result.name}</h6>
                                                                                    </a>
                                                                                    <p className="result-snippet">
                                                                                        {result.description ? result.description.substr(0,200) + "..." : ""}
                                                                                    </p>
                                                                                    {
                                                                                        this.state.token ? <span className="save"
                                                                                                                 onClick={() => this.handleSaveSearch(result)}> Save <FaRegBookmark
                                                                                                className="save-icon"/></span> :
                                                                                            <span className="save "
                                                                                                  onClick={() => this.handleModalOpen(result)}> Save <FaRegBookmark/> </span>
                                                                                    }
                                                                                    <span className="save" onClick={() => {
                                                                                        this.handleCopyLink(result)
                                                                                    }}> Copy link <FaRegCopy
                                                                                        className="save-icon"/></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    ))
                                                                }
                                                            </div>
                                                    }
                                                </>
                                        }
                                        <div className={this.state.notfound ? "d-none" : ""}>
                                            <Pagination paginateoff={this.state.offset} resulttype={"videos"}  increment={15} type={"configID"} count={this.state.count}
                                                         handleNext={(response, totalcount, paginationcount,loadingstate,offset)=>
                                                             this.setState({
                                                                 images:response,
                                                                 total:totalcount,
                                                                 pagination:paginationcount,
                                                                 loading:loadingstate,
                                                                 offset:offset
                                                             })}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 side-filters" style={{marginTop: "6.8rem"}}>


                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className="row" >
                        <div className={this.state.active ? "col-md-2 col-2  " : "col-md-1 col-2 hidden-mob"}>
                            <Sidebar closeSideBar={this.handleClose} openSideBar={this.handleOpen}
                                     status={this.state.active}/>
                        </div>

                        <div
                            className={this.state.active ? "col-md-8 col-12  narrowcontainer" : "col-md-8 col-12  widecontainer"}>
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid mt-3">
                                <div className="row">

                                    <div className=" col-12 col-md-11"><SearchBox videos={true} device={"mobile"}/></div>
                                </div>

                            </div>
                            <div className="row p-0">
                                {
                                    this.state.notfound ? " " :
                                        <>
                                            <div className={"row m-0 d-flex align-items-center"}>
                                                <div className={"col-12 p-0"}>
                                                    <Filters device={"mobile"} videos={true}/>
                                                </div>
                                            </div>
                                        </>

                                }


                            </div>
                            <div className="row">
                                <div className=" col-0 col-md-1 hidden-mob"></div>
                                <div className=" col-12 p-0 col-md-10" style={{overflow: "hidden"}}>
                                    <div className="container mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                            existstatus={this.state.exists} type={"General"}/>
                                            <div className="row">
                                                <div className="col-8">
                                                    {
                                                        this.state.notfound ? <h6>0 results found</h6> :
                                                            <h6>About {
                                                                this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                            } results </h6>
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img src={notfound}/>
                                                            </div>

                                                            <div className="search-message text-center">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>We couldn’t find results for this keyword, try another term</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div>
                                                                {
                                                                    this.state.images.map((result, index) => (

                                                                            <div className="row border-bottom mb-3 pb-3 ">
                                                                                <div
                                                                                    className="col-md-3 col-6 d-flex align-items-center">

                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none", width:"100%"}}><img
                                                                                        src={result.thumbnailUrl}
                                                                                        className="video-img" /></a>
                                                                                </div>
                                                                                <div className="col-md-9 col-6">
                                                                                    {/* <a href={result.contentUrl} target="_blank" style={{textDecoration:"none"}}><h6 className="result-url">{result.contentUrl}</h6></a>*/}
                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none", color:"#333333", fontSize:"14px", fontWeight:"400",lineHeight:"118.52%" }}><h6
                                                                                        className="mt-2 mb-2">{result.name.substr(0,30)}...</h6>
                                                                                    </a>
                                                                                    <p style={{color:"#767676", fontWeight:"400", fontSize:"12px", lineHeight:"15px"}}>
                                                                                        {new Date(result.datePublished).toLocaleDateString('default', { day:'numeric', month: 'short', year:'numeric' })}
                                                                                    </p>
                                                                                   {/* <p className="result-snippet">
                                                                                        {result.description ? result.description.substr(0,200) + "..." : ""}
                                                                                    </p>*/}
                                                                                    {
                                                                                        this.state.token ? <span className="save"
                                                                                                                 onClick={() => this.handleSaveSearch(result)}> Save <FaRegBookmark
                                                                                                className="save-icon"/></span> :
                                                                                            <span className="save "
                                                                                                  onClick={() => this.handleModalOpen(result)}> Save <FaRegBookmark/> </span>
                                                                                    }
                                                                                    <span className="save" onClick={() => {
                                                                                        this.handleCopyLink(result)
                                                                                    }}> Copy link <FaRegCopy
                                                                                        className="save-icon"/></span>
                                                                                </div>
                                                                            </div>


                                                                    ))
                                                                }
                                                            </div>
                                                    }
                                                </>
                                        }
                                        <div>
                                        </div>
                                        <div className={this.state.notfound ? "d-none" : ""}>
                                            <Pagination paginateoff={this.state.offset} resulttype={"videos"}  increment={15} type={"configID"} count={this.state.count}
                                                        handleNext={(response, totalcount, paginationcount,loadingstate, offset)=>
                                                            this.setState({
                                                                images:response,
                                                                total:totalcount,
                                                                pagination:paginationcount,
                                                                loading:loadingstate,
                                                                offset:offset
                                                            })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            {
                                //Check if user is logged in. If not, show login modal when they click save
                                this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                    closeLoginModal={this.handleModalClose}
                                                                    show={this.state.show} type={this.state.logintype}/>
                            }

                            <div className="container-fluid">
                                <div className="row">
                                    <div className=" col-12 col-md-12"><SearchBox web={true}/></div>
                                </div>

                            </div>
                            <div className="row ">
                                {
                                    this.state.notfound ? " " :
                                        <Filters device={"tablet"} videos={true}/>
                                }


                            </div>
                            <div className="row">
                                <div className=" col-12 p-0 col-md-12" style={{overflow: "hidden"}}>
                                    <div className="container mt-2">
                                        <div className="container p-0 mt-3 mb-3 results">
                                            <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                            existstatus={this.state.exists} type={"General"}/>
                                            <div className="row">
                                                <div className="col-12">
                                                    {
                                                        this.state.notfound ? <h6>0 results found</h6> :
                                                            <h6>About {
                                                                this.state.total > 1000000 ? (this.state.total / 10000).toFixed(0) : this.state.total
                                                            } results </h6>
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                        {
                                            this.state.notfound ? <>


                                                    <div className="container d-flex justify-content-center">
                                                        <div>
                                                            <div className="container d-flex justify-content-center">
                                                                <img src={notfound}/>
                                                            </div>

                                                            <div className="search-message text-center">
                                                                <h6>No results found for this keyword</h6>
                                                                <p>We couldn’t find results for this keyword, try another term</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                <>
                                                    {
                                                        this.state.loading ? <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> :
                                                            <div>
                                                                {
                                                                    this.state.images.map((result, index) => (
                                                                        <div style={{marginBottom: "2.563rem"}}>
                                                                            <div className="row ">
                                                                                <div
                                                                                    className="col-md-3 col-12 d-flex align-items-center">

                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none", width:"100%"}}><img
                                                                                        src={result.thumbnailUrl}
                                                                                        className="video-img" /></a>
                                                                                </div>
                                                                                <div className="col-md-9 col-12">
                                                                                    {/* <a href={result.contentUrl} target="_blank" style={{textDecoration:"none"}}><h6 className="result-url">{result.contentUrl}</h6></a>*/}
                                                                                    <a href={result.contentUrl} target="_blank"
                                                                                       style={{textDecoration: "none"}}><h6
                                                                                        className="result-name mt-2 mb-2">{result.name}</h6>
                                                                                    </a>
                                                                                    <p className="result-snippet">
                                                                                        {result.description ? result.description.substr(0,200) + "..." : ""}
                                                                                    </p>
                                                                                    {
                                                                                        this.state.token ? <span className="save"
                                                                                                                 onClick={() => this.handleSaveSearch(result)}> Save <FaRegBookmark
                                                                                                className="save-icon"/></span> :
                                                                                            <span className="save "
                                                                                                  onClick={() => this.handleModalOpen(result)}> Save <FaRegBookmark/> </span>
                                                                                    }
                                                                                    <span className="save" onClick={() => {
                                                                                        this.handleCopyLink(result)
                                                                                    }}> Copy link <FaRegCopy
                                                                                        className="save-icon"/></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    ))
                                                                }
                                                            </div>
                                                    }
                                                </>
                                        }

                                        <div>
                                        </div>
                                        <div className="container p-0 mt-4">
                                            <div className={this.state.notfound ? "d-none" : ""}>
                                                <Pagination paginateoff={this.state.offset} resulttype={"videos"}  increment={15} type={"configID"} count={this.state.count}
                                                            handleNext={(response, totalcount, paginationcount,loadingstate, offset)=>
                                                                this.setState({
                                                                    images:response,
                                                                    total:totalcount,
                                                                    pagination:paginationcount,
                                                                    loading:loadingstate,
                                                                    offset:offset
                                                                })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <Footer />
            </>
        )
    }
}
export default Videos