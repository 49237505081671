import React, {useParams} from "react-router-dom";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import {useEffect, useState} from "react"
import Header from "../Header";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function EditCollections(){
    let paramid = useParams()
    const[collections, setCollections]=useState([])
    const [groupDetails, setGroupDetails] = useState({
        groupname:localStorage.getItem('groupname1'),
        groupid:"",
        subgroupname:"",
        subgroupid:null
    })
    const [saveSubGroupSuccess, setSaveSubGroupSuccess]=useState(false)
    const [saveGroupSuccess, setSaveGroupSuccess]=useState(false)
    const [show, setShow] = useState(false);
    const[type, setType]=useState("")
    const[organization, setOrganization]=useState("")
    const[organizations, setOrganizations]=useState([])
    const[groupid, setGroupid]=useState(null)
    const[subGroups, setSubGroups]=useState([])
    const handleChangeGroup = async (event)=> {
        await setGroupid(event.target.value)
        const getSubgroups = await axios.get(buildAPIUrl(`v1/collections/sub_group/?group=${event.target.value}`))
        setSubGroups(getSubgroups.data)

    }
    const handleChangeOrg = async (e)=>{
        await setOrganization(e.target.value)
    }
    const handleChangeType = async (e) =>{
        await setType(e.target.value)
    }
    const handleClose = () => setShow(false);

    const[selectedImage, setSelectedImage]=useState(null)
    async function onChangeHandler (event){
        await setGroupDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }
    const getGroup = async ()=>{
       const groups = await axios.get(buildAPIUrl(`v1/collections/group/${localStorage.getItem('groupid')}`))
        console.log(groups.data)
    }

    const handleUpdateGroup = async (e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append("name", groupDetails.groupname)
        formData.append("type", type)
        formData.append("organization", organization)
        if (selectedImage){
            formData.append('image', selectedImage);
        }
        axios({
            method: "patch",
            url: buildAPIUrl( `v1/collections/group/${parseInt(paramid.id)}/`),
            data: formData,
            headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${localStorage.getItem('token')}` },

        }).then((res)=>{
            if(res.status===201){
                setSaveGroupSuccess(true)
                setGroupDetails({
                    groupname: "",
                })
                setType("")
                setOrganization("")
                setInterval(()=>{
                    setSaveGroupSuccess(false)
                    window.location.reload()
                }, 3000)
            }
        }).catch((error)=>{
            console.log(error)

        })

    }
    const getOrganizations = async ()=>{
        const organizations = await axios.get(buildAPIUrl('v1/collections/organisations/'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        setOrganizations(organizations.data)
    }
    const handleUpdateSubGroup = async ()=>{
     const updateSubGroup =  await  axios.patch(buildAPIUrl(`v1/collections/sub_group/${groupDetails.subgroupid}/`), {
          name:groupDetails.subgroupname
      }, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
          },
      })
        if (updateSubGroup.status===200){
            getGroup()
            setSaveSubGroupSuccess(true)
            setTimeout(()=>{
                setSaveSubGroupSuccess(false)
                handleClose()
            }, 3000)
        }


    }
    useEffect(()=>{
        getGroup()
    }, [])
    return (
        <>
            <Header />
            <div className={"container mt-3"}>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <div className={"card border"}>
                            <div className={"card-header"}>
                                Edit Group Name
                                {saveGroupSuccess ? <>
                                        <h6 style={{fontSize:"14px"}} className={"text-success"}>Group Updated Successfully</h6>
                                    </> :

                                    <></>}
                            </div>
                            <div className={"card-body"}>
                                <form onSubmit={handleUpdateGroup}>
                                    <div className={"card-body"}>
                                        <div className={"mb-3"}>
                                            <label>Group Name</label>
                                            <input type={"text"} className={"form-control"} name={"groupname"} onChange={onChangeHandler} value={groupDetails.groupname}/>
                                        </div>

                                        <div className={"mb-3"} >
                                            <label>Type</label>
                                            <select className={"form-control"} onChange={handleChangeType}>
                                                <option>---Select Type---</option>
                                                <option value={"GENERAL"}>General</option>
                                                <option value={"ORGANISATION"}>Organisation</option>
                                            </select>
                                        </div>
                                        <div className={type==="GENERAL" ?  "d-none" : "mb-3"} >
                                            <label>Organization</label>
                                            <select className={"form-control"} value={organization} onChange={handleChangeOrg}>

                                                {
                                                    organizations.length > 0 ? <>
                                                        <option>---Select Organization---</option>
                                                        {
                                                            organizations.map((org)=>(
                                                                <option value={org.id}>{org.name}</option>
                                                            ))
                                                        }
                                                    </> :<></>
                                                }
                                            </select>
                                        </div>
                                        <div className={"mb-3"}>
                                            <p style={{textAlign:"left", textDecoration:"none"}}>
                                                Image
                                            </p>
                                            <input onChange={(event) => {
                                                setSelectedImage(event.target.files[0]);
                                            }} type="file"
                                                   id='image'
                                                   name='image'/>
                                        </div>
                                        <div className={"mb-3 d-flex justify-content-end"}>
                                            <button className={"btn btn-primary"}>
                                               Update
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                    <div className={"col-md-6"}>

                    </div>
                </div>

            </div>


        </>
    )
}