import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Header from "../Landing/Header";
import {HiArrowLeft, HiSearch} from "react-icons/hi";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import {Link} from "react-router-dom";
import ContentEditable from "react-contenteditable";
import Skeleton from "react-loading-skeleton";
import {FaBookmark, FaRegBookmark, FaRegCopy} from "react-icons/fa";

import {copyLink, saveNotionArticle} from "../ResultsCategories/CategoriesFunctions";
import notfound from "../../../assets/notfound.png";
import searchicon from "../../../assets/Frame 39.png";

class NotionResults extends Component{
    state= {
        articles:[],
        loading:true,
        offset:0,
        searcht:localStorage.getItem('article_query'),
        q:localStorage.getItem('article_query'),
        copy:false,
        saved:false,
        token:localStorage.getItem('token'),
        exists:"",
        filter:""
    }
    handleSearch = (e) => {
        e.preventDefault()
        localStorage.setItem('article_query', this.state.filter)
        window.location.assign('/articles-search-results')
    }
    handleSearchVoc = (e) => {
        localStorage.setItem('searchquery', this.state.searcht)
        window.location.assign('/all-results')
    }
    handleInput = (e) =>{
        this.setState({
            filter:e.target.value,
            [e.target.name]: e.target.value
        })
    }
    componentDidMount() {
        const article_query = localStorage.getItem('article_query')
        const response1  = axios.get(buildAPIUrl(`v1/articles/?content=${article_query}`)).then((res)=>{
            console.log(typeof res.data)
            this.setState({
                articles:Object.values(res.data),
                loading:false
            })
            console.log(typeof Object.values(this.state.articles))
        })
    }
    /*-----Copy results link------*/
    handleCopyLink = (result)=>{
        copyLink(result, (copystatus)=>{
            this.setState({
                copy:copystatus
            })
        })

    }
    /*-----Bookmark Result------*/
    handleSaveSearch = (result, bookmarktype)=>{
        console.log(result)
        saveNotionArticle(result, bookmarktype, (savestatus)=>{
            this.setState({
                saved:savestatus
            })

        }, (error)=>{
            this.setState({
                exists:error
            })
            setTimeout(()=>{
                this.setState({
                    exists:""
                })
            }, 3000)
            this.props.handleSave(false, error)
        })
    }
    handleGoBack=()=>{
        window.location.assign(`/notion-articles/2/Starting Research`)
    }

    render() {
        return (
            <div>
                <Header />
                <div className={"container"}>
                    <h6 style={{cursor:"pointer"}} onClick={this.handleGoBack}> <HiArrowLeft /> Back</h6>
                </div>
                <div className={"row"}>
                    <div className={"col-md-2"}>

                    </div>
                    <div className={"col-md-8"}>
                        {/*  Search box*/}
                        <div className="search-section col-11 col-md-12 mt-4 ">
                            <div className="row d-flex align-items-center">
                                <div className=" col-12 col-md-12 d-flex justify-content-center" style={{paddingLeft:"2.5rem"}}>
                                    <form onSubmit={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                        <div className="form-input form-input-group border" style={{borderRadius:"6px"}}>
                                            <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                            <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                   style={{width: "100%", border:"none"}} placeholder="Search for tools"/>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={"row" }>
                    <div className={"col-md-2"}>

                    </div>
                    <div className={"col-md-8"}>
                        <div className={"col-md-12"}>
                            <div className={"row"}>
                                <div className={"col-md-12"} style={{paddingLeft:"3rem"}}>
                                    {
                                                this.state.loading ?
                                                    <>
                                                {
                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                        key={n}/>)
                                                }

                                            </> :
                                                    <>
                                                        {
                                                            this.state.articles.length > 0 ?
                                                                <>
                                                                    {
                                                                        this.state.articles.slice(this.state.offset, this.state.offset + 20).map((result)=>(
                                                                            <>
                                                                                <div style={{marginBottom: "2.563rem", marginTop:"2.188rem"}}>
                                                                                    <Link  to={`notion-article/${result.id}`}><h6
                                                                                        className="result-url mt-2 mb-2">{result.url.substr(0, 100)}</h6>
                                                                                    </Link>
                                                                                    <a href={result.url} target="_blank"><h6
                                                                                        className="result-name mt-2 mb-2">
                                                                                        <ContentEditable
                                                                                            innerRef={result.title}
                                                                                            html={result.title.substr(0, 150).replace("[", " ").toLowerCase().replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>")} // innerHTML of the editable div
                                                                                            disabled={true}       // use true to disable editing
                                                                                            onChange={this.handleChange} // handle innerHTML change
                                                                                            tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                        />
                                                                                    </h6>
                                                                                    </a>
                                                                                    <p className="result-snippet">
                                                                                        <ContentEditable
                                                                                            innerRef={result.content}
                                                                                            html={result.content.substr(0, 150).replace("[", " ").replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>") ||
                                                                                                <Skeleton/>} // innerHTML of the editable div
                                                                                            disabled={true}       // use true to disable editing
                                                                                            onChange={this.handleChange} // handle innerHTML change
                                                                                            tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                        />
                                                                                        ...
                                                                                    </p>
                                                                                    {
                                                                                        this.state.token ? <span className="save save-icon-onboard"
                                                                                                                 onClick={() => this.handleSaveSearch(result, "Notion Tags")}> Save <FaRegBookmark
                                                                                                className="save-icon"/></span> :
                                                                                            <span className="save save-icon-onboard"
                                                                                                  onClick={() => this.handleModalOpen(result, "1")}> Save <FaRegBookmark/> </span>
                                                                                    }

                                                                                    <span className="save" onMouseDown={() => {
                                                                                        this.handleCopyLink(result.url)
                                                                                    }}> Copy link <FaRegCopy className="save-icon"/></span>
                                                                                </div>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </> :
                                                                <>
                                                                    <div className="container mt-5 d-flex justify-content-center">
                                                                        <div>
                                                                            <div className="container d-flex justify-content-center">
                                                                                <img src={notfound}/>
                                                                            </div>

                                                                            <div className="search-message text-center">
                                                                                <h6>No results found for this keyword</h6>
                                                                                <p>We couldn’t find results for this keyword, try another term</p>
                                                                                <button onClick={this.handleSearchVoc} className="btn btn-fav" style={{borderRadius:"0", fontSize:"14px", padding:"10px 20px", height:"auto"}}>Try search engine</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }

                                            </>
                                    }
                                    <nav className="d-flex justify-content-center">
                                        {
                                            !this.state.loading ? " " :
                                                <ul className="pagination">

                                                    <li className={this.state.offset === "0" || this.state.offset === 0  ? "page-item active" : "page-item"}>
                                                        <a className="page-link"
                                                           onClick={() => this.handleNext("page", 0)}>1</a>
                                                    </li>
                                                    <li className={this.state.offset === 20 ? "page-item active" : "page-item"}>
                                                        <a className="page-link"
                                                           onClick={() => this.handleNext("page", 20)}>2</a>
                                                    </li>
                                                    <li className={this.state.offset === 40 ? "page-item active" : "page-item"}>
                                                        <a className="page-link"
                                                           onClick={() => this.handleNext("page", 40)}>3</a>
                                                    </li>
                                                    <li className={this.state.offset === 60 ? "page-item active" : "page-item"}>
                                                        <a className="page-link"
                                                           onClick={() => this.handleNext("page", 60)}>4</a>
                                                    </li>
                                                    <li className={this.state.offset === 80 ? "page-item active" : "page-item"}>
                                                        <a className="page-link"
                                                           onClick={() => this.handleNext("page", 80)}>5</a>
                                                    </li>
                                                    {/*  <li className="page-item"><a
                                                        className={this.state.offset < 4 ? "page-link" : "d-none"}
                                                        onClick={()=>this.handleNext("positive")}>Next</a></li>*/}
                                                </ul>
                                        }
                                    </nav>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={"col-md-2"}>
                        <div
                            className={this.state.copy ? "alert alert-success position-fixed" : "d-none"}>
                            <FaBookmark/> Link copied successfully
                        </div>
                        <div
                            className={this.state.saved ? "alert alert-success position-fixed " : "d-none"}>
                            <FaBookmark/> Article saved successfully
                        </div>
                        <div
                            className={this.state.exists ? "alert alert-warning position-fixed" : "d-none"}>
                            <FaBookmark/> Bookmark already exists
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default NotionResults