import React, { useState, useEffect} from 'react';
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";
import Header from "../Header";
import {useParams} from "react-router-dom";


function EditBook() {
    const params = useParams();

    const [newBookDetails, setnewBookDetails] = useState({
        name: "",
        author: "",
        summary: "",
        url: "",
        image: "",
        uploaded_image: ""
    });
    const [userToken, setUserToken] = useState(localStorage.getItem('token'))
    const { isLoading, error, isError, sendRequest: sendBookDataHandler } = useHttpFetch();
    


    if (!userToken) {
      
        window.location.assign('/');
    }

    useEffect(() => {
        const setOpportunities = (data) => {
            setnewBookDetails(data);
        }
    
        sendBookDataHandler(
          {
            url: buildAPIUrl(`v1/ux_books/${params.book_id}/`),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userToken}`
            }
          },
          setOpportunities
        );
      }, [sendBookDataHandler, userToken, params.book_id]);

    function onChangeHandler(event) {
        setnewBookDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }

    function onChangeImageHandler(event){
        setnewBookDetails((prevState) => {
            return { ...prevState, uploaded_image: event.target.files[0] };
        });
    }

    function setBookdata(data){
        setnewBookDetails({
            name: "",
            author: "",
            summary: "",
            url: "",
            image: "",
        })
        window.location.assign('/books/all');
    }
    async function submitHandler(event) {
        event.preventDefault();

        let formData = new FormData();
        formData.append("name", newBookDetails.name);
        formData.append('author', newBookDetails.author);
        formData.append('summary', newBookDetails.summary);
        formData.append('url', newBookDetails.url);
        if (newBookDetails.uploaded_image){
            formData.append('image', newBookDetails.uploaded_image);
        }
        
        
        sendBookDataHandler({
            method: 'PATCH',
            url: buildAPIUrl(`v1/ux_books/${params.book_id}/`),
            headers: {
                Authorization: `Bearer ${userToken}`
            },
            body: formData,
        }, setBookdata, true);

    }


    return (
        <>
           <Header />
            <div className={"container-fluid mt-5"}>
                <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            {isLoading && <p>Loading.....</p>}
                            {isError && <p>{error}</p>}
                            <div className={"card border"}>
                                <div className={"card-header"}>
                                    <h4>Edit Book</h4>
                                </div>
                                <div className={"card-body"}>
                                    <form onSubmit={submitHandler}>
                                        <div className={"mb-3"}>
                                            <label htmlFor='name'>Name</label>
                                            <input
                                                type='text'
                                                id='name'
                                                name='name'
                                                value={newBookDetails.name}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />
                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='author'>Author</label>
                                            <input
                                                type='text'
                                                id='author'
                                                name='author'
                                                value={newBookDetails.author}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />
                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='summary'>Summary</label>
                                            <input
                                                type='text'
                                                id='summary'
                                                name='summary'
                                                value={newBookDetails.summary}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />
                                        </div>
                                        
                                        <div className={"mb-3"}>
                                            <label htmlFor='url'>Url</label>
                                            <input
                                                className={"form-control"}
                                                type='url'
                                                id='url'
                                                name='url'
                                                value={newBookDetails.url}
                                                onChange={onChangeHandler}
                                                required
                                            />
                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='image'>Image</label>
                                            <input
                                                className={"form-control"}
                                                type="file"
                                                id='image'
                                                name='image'
                                                onChange={onChangeImageHandler}
                                            />
                                        </div>
                                        
                                        <button className={"btn btn-success"}>Edit Book</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
export default EditBook;