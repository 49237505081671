import React, {useEffect, useState} from "react";
import Header from "../Header";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import useHttpFetch from "../../hooks/use-http-fetch";
import {Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
export default function AddCollections() {
    const [groupDetails, setGroupDetails] = useState({
        groupname:"",
        groupid:null,
        subgroupname:"",
        subgroupid:null
    })
    const [editGroupDetails, setEditGroupDetails] = useState({
        groupname:localStorage.getItem('groupname1'),
        grouptype:localStorage.getItem('grptype'),
        organization:localStorage.getItem('grporg'),
        groupid:localStorage.getItem('groupid')
    })
    const [editOrg,setEditOrg]=useState(localStorage.getItem('grporg'))
    const [editType,setEditType]=useState(localStorage.getItem('grptype'))
    const[selectedImage, setSelectedImage]=useState(null)
    const[groups, setGroups]=useState([])
    const { isLoading, error, isError, sendRequest: createSubGroupHandler } = useHttpFetch();
    const[successGroup, setSuccessGroup]=useState(false)
    const[successSubGroup, setSuccessSubGroup]=useState(false)
    const[majorGroup, setMajorGroup] = useState([])
    const [field, setField] = useState([]);
    const[formType, setFormType]=useState(1)
    const[groupid, setGroupid]=useState(null)
    const[subGroups, setSubGroups]=useState([])
    const [show, setShow] = useState(false);
    const [saveSubGroupSuccess, setSaveSubGroupSuccess]=useState(false)
    const[organizations, setOrganizations]=useState([])
    const[type, setType]=useState("GENERAL")
    const[organization, setOrganization]=useState("")
    const[deleteSuccess, setDeleteSuccess]=useState(false)
    const[deleteSubSuccess, setDeleteSubSuccess]=useState(false)
    const[saveError, setSaveError]=useState("")
    const[groupModal, setGroupModal]=useState(false)
    const[updateGroupModal, setUpdateGroupModal] = useState(false)
    async function onChangeHandler (event){
        await setGroupDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }
    async function onChangeHandlerupdate (event){
        await setEditGroupDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }
    async function getGroups(){
        setTimeout(async ()=>{
            const groups = await axios.get(buildAPIUrl('v1/collections/sub_group/'))
            setGroups(groups.data)
            setSuccessSubGroup(false)
            setGroupDetails({
                subgroupname: ""
            })
        },3000)
    }
    const deleteGroup= async (id)=>{
       await axios.delete(buildAPIUrl(`v1/collections/group/${id}`), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        setDeleteSuccess(true)
        setTimeout(()=>{
            setDeleteSuccess(false)
            getMainGroups()
        }, 3000)
    }
    const deleteSubGroup= async (id)=>{
        await axios.delete(buildAPIUrl(`v1/collections/sub_group/${id}`), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        setDeleteSubSuccess(true)
        setTimeout(()=>{
            setDeleteSubSuccess(false)
            getGroups()
        }, 3000)
    }
    async function getMainGroups(){
        const groups = await axios.get(buildAPIUrl('v1/collections/group/'),{
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        setMajorGroup(groups.data)
    }
    async function changeGroupId(e){
       await setGroupid(e.target.value)

    }

    async function getGroupsMain(){
        setSuccessGroup(true)
        setSelectedImage(null)
        setTimeout(async ()=>{
           getMainGroups()
            setSuccessGroup(false)
            setGroupDetails({
                groupname: ""
            })
            setSelectedImage("")
        },3000)
    }
    const handleSaveGroup = (e) =>{
        e.preventDefault()
        const formData = new FormData()
        formData.append("name", groupDetails.groupname)
        formData.append("type", type)
        formData.append("organisation", organization)
        if (selectedImage){
            formData.append('image', selectedImage);
        }
        axios({
            method: "post",
            url: buildAPIUrl('v1/collections/group/'),
            data: formData,
            headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${localStorage.getItem('token')}` },

        }).then((res)=>{
            if(res.status===201){
                setSuccessGroup(true)
                setGroupDetails({
                    groupname: "",
                })
                setType("")
                setOrganization("")
               getMainGroups()
                setInterval(()=>{
                    setSuccessGroup(false)

                }, 3000)
            }
        }).catch((error)=>{

        })

    }
    const handleSubGroup = (e) => {
        e.preventDefault()
        let formData = new FormData();
        formData.append("name", groupDetails.subgroupname);
        formData.append("group", parseInt(groupid));
        createSubGroupHandler({
            method: 'POST',
            url: buildAPIUrl(`v1/collections/sub_group/`),
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: formData,
        }, getGroups(), true);


    }
    const getSubgroup = async () =>{
        const subgroups = await axios.get(buildAPIUrl('v1/collections/sub_group/'))
       await setGroups(subgroups.data)

    }
    const handleChangeGroup = async (event)=> {
       await setGroupid(event.target.value)
        const getSubgroups = await axios.get(buildAPIUrl(`v1/collections/sub_group/?group=${event.target.value}`))
        setSubGroups(getSubgroups.data)
    }
    const handleShow = (name, id) => {
        setShow(true)
        setGroupDetails({
            subgroupname: name,
            subgroupid: id
        })

    };
    const handleShowGroup = async (name, id, org, type) => {
        console.log(name, id, org, type)
        await setEditGroupDetails({
            groupname:name,
            grouptype:type,
            organization:org,
            groupid:id
        })
        setEditType(type)
        setEditOrg(org)
        await setGroupModal(true)
    };
    const handleClose = async () => {
        setShow(false)
        const getSubgroups = await axios.get(buildAPIUrl(`v1/collections/sub_group/?group=${groupid}`))
        setSubGroups(getSubgroups.data)
    };
    const handleCloseGroup = async () => {
        setGroupModal(false)
        getMainGroups()
    };


    const handleUpdateSubGroup = async ()=>{
        const updateSubGroup =  await  axios.patch(buildAPIUrl(`v1/collections/sub_group/${groupDetails.subgroupid}/`), {
            name:groupDetails.subgroupname
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        if (updateSubGroup.status===200){
            setSaveSubGroupSuccess(true)
            setTimeout(()=>{
                setSaveSubGroupSuccess(false)
                handleClose()
            }, 2000)
        }


    }
    const getOrganizations = async ()=>{
        const organizations = await axios.get(buildAPIUrl('v1/collections/organisations/?all_organisation=1'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
        setOrganizations(organizations.data)
    }
    const handleChangeOrg = async (e)=>{
        await setOrganization(e.target.value)
    }
    const handleChangeOrgupdate = async (e)=>{
        await setEditOrg(e.target.value)
    }
    const handleChangeType = async (e) =>{
        await setType(e.target.value)
    }
    const handleChangeTypeupdate = async (e) =>{
        await setEditType(e.target.value)
    }
    const handleUpdateGroup = async (e)=>{
        e.preventDefault()
        const formData = new FormData()
        formData.append("name", editGroupDetails.groupname)
        formData.append("type", editType)
        if(editType==="GENERAL") {
            formData.append("organisation", "")
        } else {
            formData.append("organisation", editOrg)
        }

        if (selectedImage){
            formData.append('image', selectedImage);
        }
        axios({
            method: "patch",
            url: buildAPIUrl( `v1/collections/group/${editGroupDetails.groupid}/`),
            data: formData,
            headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${localStorage.getItem('token')}` },

        }).then((res)=>{
            if(res.status===200){
                setUpdateGroupModal(true)
                getMainGroups()
                setGroupDetails({
                    groupname: "",
                })
                setTimeout(()=>{
                    setUpdateGroupModal(false)
                    handleCloseGroup()
                }, 3000)
            }
        }).catch((error)=>{


        })

    }
    useEffect(()=>{
        getSubgroup()
        getMainGroups()
        getOrganizations()
        getGroups()
    }, [])

    return (

        <>
            <Header />
            <Modal show={groupModal} onHide={handleCloseGroup}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Group
                        {updateGroupModal ? <>
                                <h6  style={{fontSize:"14px"}} className={"text-success"}> Group Updated Successfully</h6>
                            </> :

                            <></>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleUpdateGroup}>
                        <div className={"card-body"}>
                            <div className={"mb-3"}>
                                <label>Group Name</label>
                                <input type={"text"} className={"form-control"} name={"groupname"} onChange={onChangeHandlerupdate} value={editGroupDetails.groupname}/>
                            </div>

                            <div className={"mb-3"} >
                                <label>Type</label>
                                <select className={"form-control"} onChange={handleChangeTypeupdate} value={editType}>
                                    <option>---Select Type---</option>
                                    <option value={"GENERAL"}>General</option>
                                    <option value={"ORGANISATION"}>Organisation</option>
                                </select>
                            </div>
                            <div className={editType==="GENERAL" ?  "d-none" : "mb-3"} >
                                <label>Organization</label>
                                <select className={"form-control"} value={editOrg} onChange={handleChangeOrgupdate}>
                                    {
                                        organizations.length > 0 ? <>
                                            <option>---Select Organization---</option>
                                            {
                                                organizations.map((org)=>(
                                                    <option value={org.id}>{org.name}</option>
                                                ))
                                            }
                                        </> :<></>
                                    }
                                </select>
                            </div>
                            <div className={"mb-3"}>
                                <p style={{textAlign:"left", textDecoration:"none"}}>
                                    Image
                                </p>
                                <input onChange={(event) => {
                                    setSelectedImage(event.target.files[0]);
                                }} type="file"
                                       id='image'
                                       name='image'/>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>handleCloseGroup()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdateGroup}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Sub Group
                        {saveSubGroupSuccess ? <>
                                <h6  style={{fontSize:"14px"}} className={"text-success"}>Sub Group Updated Successfully</h6>
                            </> :

                            <></>}
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className={"mb-2"}>
                        <label>Sub Group Name</label>
                        <input type={"text"} className={"form-control"} name={"subgroupname"} value={groupDetails.subgroupname} onChange={onChangeHandler}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>handleClose()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdateSubGroup}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className={"container mt-3"}>
                <div className={"col-md-12 d-flex justify-content-end"}>
                    <Link to={"/admin-add-collections-manually"} className={"btn btn-secondary"}>Add Manually</Link>
                </div>
                <div className={"row mt-3"}>

                    <div className={"col-md-6"}>
                        <div className={"card border"}>

                            <div className={"card-header"}>Add  Group</div>

                            {successGroup ? <>
                                <p className={"text-success text-center"}>Uploading group....</p>
                            </> : <></>}
                            <form onSubmit={handleSaveGroup}>
                                        <div className={"card-body"}>
                                            <div className={"mb-3"}>
                                                <label>Group Name</label>
                                                <input type={"text"} className={"form-control"} name={"groupname"} onChange={onChangeHandler} value={groupDetails.groupname}/>
                                            </div>
                                            <div className={"mb-3"} >
                                                <label>Type</label>
                                                <select className={"form-control"} onChange={handleChangeType}>
                                                    <option>---Select Type---</option>
                                                    <option value={"GENERAL"}>General</option>
                                                    <option value={"ORGANISATION"}>Organisation</option>
                                                </select>
                                            </div>
                                            <div className={type==="GENERAL" ?  "d-none" : "mb-3"} >
                                                <label>Organization</label>
                                                <select className={"form-control"} value={organization} onChange={handleChangeOrg}>

                                                    {
                                                        organizations.length > 0 ? <>
                                                            <option>---Select Organization---</option>
                                                            {
                                                                organizations.map((org)=>(
                                                                    <option value={org.id}>{org.name}</option>
                                                                ))
                                                            }
                                                        </> :<></>
                                                    }
                                                </select>
                                            </div>
                                            <div className={"mb-3"}>
                                                <p style={{textAlign:"left", textDecoration:"none"}}>
                                                    Image
                                                </p>
                                                <input onChange={(event) => {
                                                    setSelectedImage(event.target.files[0]);
                                                }} type="file"
                                                       id='image'
                                                       name='image'/>
                                            </div>

                                            <div className={"mb-3 d-flex justify-content-end"}>
                                                <button className={"btn btn-primary"}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                            </form>

                        </div>
                    </div>
                    <div className={"col-md-6"}>
                        <div className={"card border"}>
                            <div className={"card-header"}>
                                <div className={"row"}>
                                    <div className={"col-md-8"}>
                                        Add Sub Group
                                    </div>
                                    <div className={"col-md-4 d-flex justify-content-end"}>
                                    </div>
                                </div>
                            </div>
                            {successSubGroup ? <>
                                <p className={"text-success text-center"}>Sub Group added successfully</p>
                            </> : <></> }
                            <form onSubmit={handleSubGroup}>

                                        <div className={"card-body"}>
                                            <div className={"mb-3"}>
                                                <label>Group Name</label>
                                                <select className={"form-control"} name={"groupid"} value={groupid} onChange={handleChangeGroup}>
                                                    <option>---Select Group---</option>
                                                    {
                                                        majorGroup.map((group)=>(
                                                            <option value={group.id}>{group.name}</option>
                                                            ))
                                                    }
                                                </select>
                                            </div>
                                            <div className={"mb-3"}>
                                                <label>Sub Group Name</label>
                                                <input type={"text"} className={"form-control"} name={"subgroupname"} onChange={onChangeHandler} value={groupDetails.subgroupname}/>

                                            </div>
                                            <div className={"mb-3 d-flex justify-content-end"}>
                                                <button className={"btn btn-primary"}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>

                            </form>


                        </div>
                    </div>
                </div>
                <div className={"row mt-5"}>
                    <div className={"col-md-6"}>
                       <div className={"card border"}>
                           <div className={"card-header"}>
                               Groups
                               {
                                   deleteSuccess ? <>
                                   <p className={"text-success"}>Group deleted successfully</p>
                                   </> : <></>
                               }
                           </div>
                           <div className={"card-body"}>
                               <table className={"table"}>
                                   <tr>
                                       <th>Name</th>
                                       <th>Edit</th>
                                       <th>Delete</th>
                                   </tr>
                                   {
                                       majorGroup.map((group)=>(
                                           <tr>
                                               <td>{group.name}</td>
                                               <td> <a onClick={()=>handleShowGroup(group.name, group.id, group.organisation, group.type)} style={{cursor:"pointer"}}>Edit</a></td>
                                               <td onClick={()=>deleteGroup(group.id)} style={{cursor:"pointer"}}> Delete</td>
                                           </tr>
                                       ))
                                   }

                               </table>
                           </div>
                       </div>
                    </div>
                    <div className={"col-md-6"}>
                        <div className={"card border"}>
                            <div className={"card-header"}>
                                Sub Groups
                                {
                                    deleteSubSuccess ? <>
                                        <p className={"text-success"}>Sub group deleted successfully</p>
                                    </> : <></>
                                }
                            </div>
                            <div className={"card-body"}>
                                <table className={"table"}>
                                    <tr>
                                        <th>Name</th>
                                        <th>Edit</th>
                                    </tr>
                                    {
                                        subGroups.length > 0 ? <>
                                            {
                                                subGroups.map((group)=>(
                                                    <tr>
                                                        <td>{group.name}</td>
                                                        <td onClick={()=>handleShow(group.name, group.id)} style={{cursor:"pointer"}}>Edit</td>
                                                        <td onClick={()=>deleteSubGroup(group.id)} style={{cursor:"pointer"}}>Delete</td>
                                                    </tr>
                                                ))
                                            }
                                        </> : <>
                                        <p>No subgroups</p>
                                        </>
                                    }

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}