import React, {useEffect, useState} from 'react';
import useEventbrite from 'react-eventbrite-popup-checkout';

function WebinarEventModal (props) {
    const [modalId, setModalId] = useState("");
    const [idValue, setIdValue] = useState("");
    useEffect(() => {
        setModalId("eventbrite-widget-modal-trigger-"+props.link)
        setIdValue(props.link)
        const script = document.createElement('script');
        script.src = "https://www.eventbrite.com/static/widgets/eb_widgets.js";
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            window.EBWidgets.createWidget({
                widgetType: 'checkout',
                eventId: idValue,
                modal: true,
                modalTriggerElementId: modalId,
                iframeContainerHeight: 425,
            });
        };

        return () => {
            document.body.removeChild(script);
        };
    }, [props.link]);

    return (
    <div id="my-app">
        {modalId} {idValue}
        {
            modalId && idValue && (
                <button  id={modalId} className={"btn"} style={{color:"white", display:"block",background:"#070707", borderRadius:"200px", padding:"0.5rem 1.75rem"}}>Register now</button>
            )
        }
    </div>
  )
}

export default WebinarEventModal
