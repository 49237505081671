/*--Alert displayed when the user clicks save or copy---*/
import React, {Component} from "react";
import {FaBookmark} from "react-icons/fa";
import {Link} from "react-router-dom";
class AlertComponent extends Component{
    state={
        copy:false
    }

    render() {
        return (
            <div>
                <div
                    className={this.props.copystatus ? "alert alert-success" : "d-none"}>
                    <FaBookmark/> Link copied successfully
                </div>
                <div
                    className={this.props.savestatus ? "alert alert-success  " : "d-none"}>
                    <div className={"row"}>
                        <div className={"col-1 col-md-1"}>
                            <FaBookmark/>
                        </div>
                        <div className={"col-10 col-md-10"}>
                            Saved to <br/><Link className={"border-bottom"} style={{color:"white", fontSize:"13px"}} to={"/my-library"}>
                                Saved Searches
                            </Link>
                        </div>
                    </div>

                </div>
                <div
                    className={this.props.existstatus ? "alert alert-warning position-fixed" : "d-none"}>
                    <FaBookmark/> Bookmark already exists
                </div>
            </div>
        );
    }
}
export default AlertComponent