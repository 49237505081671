import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import Header from "../Header";
import LoginModal from "../../User/Components/Modals/LoginModal";
import SearchBox from "../../User/Components/Landing/SearchBox";
import Filters from "../../User/Components/Landing/Filters";
import AlertComponent from "../../User/Components/ResultsCategories/AlertComponent";
import notfound from "../../assets/notfound.png";
import ContentEditable from "react-contenteditable";
import SkeletonSearchResult from "../../User/Components/Landing/Skeletons/SkeletonSearchResult";
import ResultComponent from "../../User/Components/ResultsCategories/ResultComponent";
import {FaRegBookmark, FaRegCopy} from "react-icons/fa";
import Pagination from "../../User/Components/ResultsCategories/Pagination";
import searchicon from "../../assets/Frame 39.png";
import {
    MdAccessTime,
    MdBookmark,
    MdClose,
    MdFormatQuote,
    MdKeyboardArrowDown,
    MdOpenInNew,
    MdOutlinePreview
} from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import {Modal, Accordion} from "react-bootstrap";
import {getSuggestions} from "../../User/Components/Landing/MainFile/MainFunctions";
import {copyLink} from "../../User/Components/ResultsCategories/CategoriesFunctions";
import {Link} from "react-router-dom";

class Collections extends Component{
    state = {
        results: [],
        results1: [],
        pagination: [],
        siteid: "",
        social: false,
        total: "",
        search: "",
        images: [],
        title: "",
        link: "",
        success: "",
        token: localStorage.getItem('token'),
        show: false,
        clientID: "425793011868-1s6v97ajh0m70dhcvb7oejp3qup4qb5e.apps.googleusercontent.com",
        related: [],
        count: 15,
        offSet: 0,
        currentItems: null,
        pageCount: 0,
        itemsPerPage: 50,
        copy: false,
        saved: false,
        activePage: 15,
        pager: {},
        perPage: 16,
        page: 0,
        pages: 0,
        searcht: localStorage.getItem('searchquery'),
        configID: "3ba3f781-a855-457c-87b7-b25884d98c58",
        subscrKey: "a02f905e19004306b6271197a7864c12",
        active: false,
        showfilter: false,
        loading: true,
        oldest: "",
        topv: "",
        logintype: "",
        exists: "",
        offsetvalue: "0",
        relatedbing: [],
        isTourOpen: false,
        notfound: false,
        reddit:[],
        norelated:false,
        showmodal:false,
        result:[],
        chatgptsecret:"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",
        chatgptresponse:"",
        generating: true,
        q:localStorage.getItem('searchquery'),
        suggestion:[],
        userHistory: [],
        regshow:false,
        collections:[],
        resultname:"",
        resulturl:"",
        filteritems:[],
        savesuccess:false,
        resultsnippet:"",
        collectionid:null,
        errormessage:false,
        subgroups:[]

    }
    async componentDidMount() {
        //Reset the offset value when the user changes from one category to another
        this.setState({
            offset: parseFloat(0)
        })
        //Get search query from the url and add it to the local storage
        let searchterm = localStorage.getItem('searchquery')
        //Get search results from Bing API
        axios.get(`https://api.bing.microsoft.com/v7.0/search?q=${searchterm}&count=${this.state.count}&offset=${this.state.offsetvalue}&setLang=en-US`, {
            headers: {
                "Ocp-Apim-Subscription-Key": this.state.subscrKey,
                "token": this.state.token
            }
        }).then((res) => {
            if (res.data.webPages.value === undefined) {
                const response1 = axios.get(`https://api.bing.microsoft.com/v7.0/search?q=${searchterm}&customconfig=${this.state.configID}&count=${this.state.count}&offset=${this.state.offsetvalue}&setLang=en-US`, {
                    headers: {
                        "Ocp-Apim-Subscription-Key": this.state.subscrKey,
                        "token": this.state.token
                    }
                }).then((respo) => {
                    if (respo.data.webPages.value === undefined) {
                        this.setState({
                            notfound: true
                        })
                    } else {
                        if (respo.data.relatedSearches === undefined) {
                            this.setState({
                                notrelated: true
                            })
                            this.setState({
                                results: respo.data.webPages.value,
                                total: respo.data.webPages.totalEstimatedMatches,
                                pagination: respo.data.rankingResponse.mainline.items,
                                /*images: res.data.videos.value,*/
                                loading: false,
                            })
                        } else {
                            this.setState({
                                results: respo.data.webPages.value,
                                total: respo.data.webPages.totalEstimatedMatches,
                                pagination: respo.data.rankingResponse.mainline.items,
                                /*images: res.data.videos.value,*/
                                loading: false,
                                relatedbing: respo.data.relatedSearches.value
                            })
                        }
                    }

                })

            } else {
                //Check if related searches exists. If they do not exists, change the notrelated state to true.
                //The notrelated state is used to hide the not related section if they do not exist.
                if (res.data.relatedSearches === undefined) {
                    this.setState({
                        notrelated: true
                    })
                    this.setState({
                        results: res.data.webPages.value,
                        total: res.data.webPages.totalEstimatedMatches,
                        pagination: res.data.rankingResponse.mainline.items,
                        /*images: res.data.videos.value,*/
                        loading: false,
                    })
                } else {
                    this.setState({
                        results: res.data.webPages.value,
                        total: res.data.webPages.totalEstimatedMatches,
                        pagination: res.data.rankingResponse.mainline.items,
                        /*images: res.data.videos.value,*/
                        loading: false,
                        relatedbing: res.data.relatedSearches.value
                    })
                }
            }

        }).catch((error) => {

            this.setState({
                notfound: true
            })
        })

        this.setState({
            pages: Math.floor(this.state.results.length / this.state.perPage)
        });
        //Get Related Searches from the DB
        axios.get(buildAPIUrl(`v1/save_search_queries/suggest?query=${searchterm}`)).then((rest2) => {
            this.setState({
                related: rest2.data
            })
        })
        if (this.state.results === null) {
            this.setState({
                notfound: true
            })
        }
        //Get collections
        axios.get(buildAPIUrl('v1/collections/group/')).then((res)=>{
           this.setState({
               collections:res.data
           })
        }).catch((error)=>{

        })

    }

    handleGetSubGroups = (id) => {

        axios.get(buildAPIUrl(`v1/collections/sub_group/?group=${id}`)).then((res)=>{
            this.setState({
                subgroups:res.data
            })
            console.log(res.data)
        }).catch((error)=>{

        })
    }
    handleSearch = () => {
        localStorage.setItem('searchquery', this.state.q)
        let searterm = localStorage.getItem('searchquery')
        this.handleSuggestion(this.state.q)

    }
    handleSuggestion = async (item)=>{
        localStorage.setItem('searchquery', item)
        this.setState({
            q:item,
            suggestion:[]
        })
        window.location.reload()
    }
    reghandleOpen = (name, url, snippet, subgroup) => {

        this.setState({
            regshow:true,
            resultname:name,
            resulturl:url,
            resultsnippet:snippet,

        })
    }
    reghandleClose = () => {
        this.setState({
            regshow: false,
            savesuccess:false,
            filteritems:[]
        })
    }
    //Save collection when user clicks on the checkbox
    handleSaveCollection = async (sub_category,group) => {
        axios.post(buildAPIUrl('v1/collections/main/'), {
            title: this.state.resultname,
            link: this.state.resulturl,
            group: localStorage.getItem('groupid'),
            sub_group: this.state.filteritems,
            snippet:this.state.resultsnippet,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
            this.setState({
                savesuccess:true
            })
        }).catch((error)=>{

            this.setState({
                errormessage:true
            })
            setTimeout(()=>{
                this.setState({
                    errormessage:false
                })
            }, 5000)
        })
    }

    //Save collection when user clicks on the checkbox
    handleCheckCollection = async (sub_category,group) => {
        localStorage.setItem('groupid',group )
        this.setState({
            collectionid:group
        })
    }

    handleInput = async (e) => {

        //Get search suggestions as the user types
        this.setState({
            activeinput:true
        })
        if(e.target.value.length > 0){
            await this.setState({
                [e.target.name]: e.target.value,
                q:e.target.value
            })
            //Search from RB collections
            getSuggestions(e,this.state.token,   async (res)=>{
                await this.setState({
                    suggestion:res.data.suggestionGroups[0].searchSuggestions,
                    showsuggest:true
                })
            }, (error)=>{
            })
        } else {
            await this.setState({
                showsuggest:false,
                q:""
            })
        }



    }
    handleCopyLink = (result)=>{
        copyLink(result, (copystatus)=>{
            this.setState({
                copy:copystatus
            })
            setTimeout(()=>{
                this.setState({
                    copy:false
                })
            }, 5000)
        })

    }
    handleCheck = async (e) => {
        const isChecked = e.target.checked
        if (isChecked) {
            await this.setState({filteritems: [...this.state.filteritems, e.target.value]})
        } else {
            const index = this.state.filteritems.indexOf(e.target.value);
            this.state.filteritems.splice(index, 1);
            await this.setState({filteritems: this.state.filteritems})
        }
    }
    render() {
        const {page, perPage, pages} = this.state;
        let items = this.state.results.slice(page * perPage, (page + 1) * perPage);
        return (
            <div>
                <Header />
                <Modal size="md" centered show={this.state.regshow}  onHide={this.reghandleClose} style={{borderRadius:"10px", boxShadow:"0px 4px 37px rgba(0, 0, 0, 0.15)"}}>
                    <Modal.Body>
                        <div className="row border-bottom" style={{padding:"1.25rem 2rem ", color:"#767676"}}>
                            <div className={"col-md-10"}>
                                <MdBookmark />  Save to collection

                            </div>
                            <div className={"col-md-2 d-flex justify-content-end px-3"}>
                                <MdClose onClick={this.reghandleClose} style={{fontSize:"18px", cursor:"pointer"}} />
                            </div>
                        </div>
                        {
                            this.state.errormessage ? <>
                                <div className={"container alert alert-danger text-center p-0 mt-2"}>
                                    <h6 style={{fontSize:"14px", fontWeight:"400"}}>
                                        Article already exists
                                    </h6>
                                </div>
                            </> : <>

                            </>
                        }
                        <div className={"container-fluid "} style={{padding:"1.25rem 2rem ", color:"#767676"}}>
                            <Accordion>
                                {
                                    this.state.collections.length > 0 ? <>
                                            {
                                                this.state.collections.map((collection,key)=>(
                                                    <>
                                                        <Accordion.Item eventKey={key}>
                                                            <Accordion.Header >
                                                                <div className={"row"}>
                                                                    <div className={"col-md-6"} onClick={()=>this.handleGetSubGroups(collection.id)}>
                                                                        <h6 style={{color:"#333333"}}>{collection.name}<MdKeyboardArrowDown /></h6>
                                                                    </div>
                                                                    <div className={"col-md-6 d-flex justify-content-end"}>
                                                                        {
                                                                            this.state.savesuccess ? <>
                                                                                  <span className={this.state.collectionid === collection.id ? "" : "d-none"} style={{background:" #522FFF", color:"white", borderRadius:"200px", padding:"4px 11px"}}>
                                                                            Added
                                                                        </span>
                                                                            </> : <>

                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <ul>
                                                                    {
                                                                        this.state.subgroups.length > 0 ? <>
                                                                            {
                                                                                this.state.subgroups.map((sub)=>(
                                                                                    <li style={{marginTop:"0.688rem"}}>
                                                                                        <div className="form-check">
                                                                                            <div className={"row"}>

                                                                                                <div className={"col-md-10 "}>
                                                                                                    <label  className="form-check-label collec-check-label" htmlFor={sub.id}>
                                                                                                        {sub.name}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className={"col-md-2 d-flex justify-content-end"}>
                                                                                                    <input  onChange={this.handleCheck} onClick={()=>this.handleCheckCollection(sub.id, collection.id)} className="form-check-input collec-check" type="checkbox" value={sub.id}
                                                                                                            id={sub.id}   />
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                    </li>
                                                                                ))
                                                                            }
                                                                        </> : <></>
                                                                    }


                                                                </ul>


                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </>
                                                ))
                                            }
                                        </> :
                                        <></>
                                }


                            </Accordion>
                        </div>
                        <div className={"container d-flex justify-content-end"}>
                            <button onClick={()=>this.handleSaveCollection()} className={this.state.filteritems.length > 0 ? "btn btn-primary" : "d-none"}>
                                Save
                            </button>
                        </div>
                    </Modal.Body>

                </Modal>
                <div className="row" style={{paddingLeft: "1rem", paddingRight: "1rem", paddingTop:"1rem", background:"#F9F8FF"}}>
                    <div className="col-md-1"></div>
                    <div className="col-md-10 col-12">
                        {
                            //Check if user is logged in. If not, show login modal when they click save
                            this.state.token ? "" : <LoginModal openLoginModal={this.handleModalOpen}
                                                                closeLoginModal={this.handleModalClose}
                                                                show={this.state.show} type={this.state.logintype}/>
                        }

                        <div className="container-fluid">

                            <div className="row">
                                <div className=" col-12 col-md-11">
                                    <div className="search-section col-12 col-md-9">
                                        <div className="row d-flex align-items-center ">
                                            <div className=" col-12 col-md-12 d-flex justify-content-center position-relative p-0">
                                                <form onSubmit={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                    <div className="form-input border" style={this.props.device==="mobile" ? {border: " 1px solid rgba(13, 0, 76, 0.08)", borderRadius:"200px", paddingLeft:"1rem", paddingRight:"1.5rem", boxShadow:"0px 3px 4px rgba(0, 0, 0, 0.07)"} : { borderRadius:"100px", paddingLeft:"40px", paddingRight:"1.5rem", boxShadow:"rgba(0, 0, 0, 0.07) 0px 3px 4px"}}>
                                                        <span className="input-group-addon d-flex align-items-center">  <img src={searchicon}/></span>
                                                        <input className={this.props.device==="mobile" ? "search-field-text1mob" : this.props.device==="tablet" ? "search-field-text1tablet" :  "search-field-text1"} type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                               style={this.state.suggestion.length > 0 ? {borderRadius:"35px 35px 10px 10px", width:"100%"} : {borderRadius:"6px", width:"100%"}} placeholder="Search for anything UX research eg use cases, medium articles, papers, videos"/>

                                                    </div>
                                                    <div className="form-input1 position-absolute" style={{zIndex:"1000", width:"100%", marginTop:"-10px"}}>
                                                        {
                                                            this.state.suggestion && this.state.suggestion.map((suggest, i)=>(
                                                                <div className="suggest-option" style={{cursor:"pointer"}} key={i} onClick={ ()=>this.handleSuggestion(suggest.displayText)}> {suggest.displayText}</div>
                                                            ))
                                                        }
                                                    </div>
                                                </form>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="row mt-5">
                            <div className=" col-12 p-0 col-md-9" style={{overflow: "hidden"}}>
                                <div className={"container"}>
                                    <div className={"row"}>
                                        <div className={"col-md-6"}>
                                            <p>Bing</p>
                                        </div>
                                        <div className={"col-md-6 d-flex justify-content-end"}>
                                            <Link to={"/google-search"} className={"btn btn-primary"}>Switch to Google
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="container mt-2">
                                    <div className={"col-md-3"}>
                                        <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                                        existstatus={this.state.exists} type={"General"}/>
                                    </div>
                                    {
                                        this.state.notfound ? <>


                                                <div className="container d-flex justify-content-center">
                                                    <div>
                                                        <div className="container d-flex justify-content-center">
                                                            <img src={notfound}/>
                                                        </div>

                                                        <div className="search-message text-center">
                                                            <h6>No results found for this keyword</h6>
                                                            <p>We couldn’t find results for this keyword, try another term</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> :
                                            <>

                                                {
                                                    this.state.loading ? <>
                                                            {
                                                                [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                    key={n}/>)
                                                            }

                                                        </> :
                                                        <div>

                                                            {
                                                                items.slice(0,20).map((result) => (
                                                                    <>

                                                                        <div className={"bg-white"} style={{boxShadow:"0px 1px 3px rgba(0, 0, 0, 0.25)", padding:"1.563rem", marginTop:"0.813rem"}}>
                                                                            <div className={"row bg-white"}>
                                                                                <div className={"col-md-9 bg-white"}>
                                                                                    <a onClick={()=>this.saveEvent()} href={result.url} target="_blank"><h6
                                                                                        className="result-url mt-2 mb-2">{result.url.substr(0, 100)}</h6>
                                                                                    </a>
                                                                                </div>

                                                                            </div>

                                                                            <a onClick={()=>this.saveEvent()} href={result.url} target="_blank"><h6
                                                                                className="result-name mt-2 mb-2">
                                                                                <ContentEditable
                                                                                    innerRef={result.name}
                                                                                    html={result.name.substr(0, 150).toLowerCase().replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>")} // innerHTML of the editable div
                                                                                    disabled={true}       // use true to disable editing
                                                                                    onChange={this.handleChange} // handle innerHTML change
                                                                                    tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                />
                                                                            </h6>
                                                                            </a>
                                                                            <p className="result-snippet">
                                                                                <ContentEditable
                                                                                    innerRef={result.snippet}
                                                                                    html={result.snippet.substr(0, 150).replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>") ||
                                                                                        <Skeleton/>} // innerHTML of the editable div
                                                                                    disabled={true}       // use true to disable editing
                                                                                    onChange={this.handleChange} // handle innerHTML change
                                                                                    tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                />
                                                                            </p>
                                                                            <div className={"container-fluid p-0"} style={{marginTop:"0.813rem"}}>

                                                                                <span onClick={()=>this.reghandleOpen(result.name, result.url, result.snippet,result.sub_group)} style={{cursor:"pointer",backgroundColor:"#EBEBEB", borderRadius:"200px", color:"#333333", padding:"0.375rem 0.75rem", fontSize:"13px", fontWeight:"400"}}>Add to collection +</span>
                                                                                <span className="" style={{color:"#767676", fontSize:"13px", marginLeft:"0.75rem", display:"inline-block", cursor:"pointer"}} onMouseDown={() => {
                                                                                    this.handleCopyLink(result.url)
                                                                                }}> Copy link <FaRegCopy className="save-icon"/></span>


                                                                            </div>

                                                           </div>
                                                                    </>
                                                                ))
                                                            }


                                                        </div>
                                                }
                                            </>
                                    }
                                    <div className={this.state.notfound ? "d-none" : ""}>
                                        <Pagination paginateoff={this.state.offset}  increment={15} type={"configID"} count={this.state.count}
                                                    handleNext={(response, totalcount, paginationcount,loadingstate, offset)=>
                                                        this.setState({
                                                            results:response,
                                                            total:totalcount,
                                                            pagination:paginationcount,
                                                            loading:loadingstate,
                                                            offset:offset
                                                        })}
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className={"col-md-1"}></div>
                            <div className={"col-md-4 "} style={{marginTop:"3.125rem"}}>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
export default Collections