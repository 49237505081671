import React, { useState,useCallback,  useEffect} from 'react';
import {getConference, editConference} from './ConferenceApi';
import {useParams} from "react-router-dom";
import Header from "../Header";


function EditConference() {
    const params = useParams();
    const [newConferenceDetails, setnewConferenceDetails] = useState({
        name: "",
        url: "",
        start_date: "",
        end_date: "",
        location: "",
        cost: "",
    });

    const [userToken, setUserToken] = useState(localStorage.getItem('token'))
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");


    if (!userToken) {
        window.location.assign('/');
    }

    const fetchConferenceHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
          let response = await getConference(params.conference_id, userToken);
         
          const data = await response.json();
          
          setnewConferenceDetails(data);
        } catch (error) {
          setIsError(true);
          setError(error.message);
        }
        setIsLoading(false);
      }, []);

      useEffect(() => {
        fetchConferenceHandler();
      }, [fetchConferenceHandler]);


    function onChangeHandler(event) {
        setnewConferenceDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }


    async function submitHandler(event) {
        event.preventDefault();
        const new_conference = {
            name: newConferenceDetails.name,
            url: newConferenceDetails.url,
            start_date: newConferenceDetails.start_date,
            end_date: newConferenceDetails.end_date,
            location: newConferenceDetails.location,
            cost: newConferenceDetails.cost
        }
        setIsLoading(true);
        try {
            let response = await editConference(params.conference_id, new_conference, userToken);
            
            if (response.status === 200) {
                setnewConferenceDetails({
                    name: "",
                    url: "",
                    start_date: "",
                    end_date: "",
                    location: "",
                    cost: "",
                })
                window.location.assign('/conference/all');

            } else {
                setIsError(true);
                setError(response.statusText);
            }
        }catch (error) {
            setIsError(true);
            setError(error.message);
        }
        setIsLoading(false);

    }


    return (
        <>
            <Header />
            <div className={"container-fluid mt-5"}>
                <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            {isLoading && <p>Loading.....</p>}
                            {isError && <p>{error}</p>}
                            <div className={"card border"}>
                                <div className={"card-header"}>
                                    <h4>Update Conference</h4>
                                </div>
                                <div className={"card-body"}>
                                    <form onSubmit={submitHandler}>
                                        <div className={"mb-3"}>
                                            <label htmlFor='name'>name</label>
                                            <input
                                                className={"form-control"}
                                                type='text'
                                                id='name'
                                                name='name'
                                                value={newConferenceDetails.name}
                                                onChange={onChangeHandler}
                                                required
                                            />
                                        </div>
                                        <div className={"mb-3"}>

                                            <label htmlFor="location">Location:</label>
                                            <select className={"form-control"} name="location" id="location" value={newConferenceDetails.location} onChange={event => onChangeHandler(event)}>
                                                <option value="">----</option>
                                                <option value="onsite">Onsite</option>
                                                <option value="virtual">Virtual</option>
                                                <option value="onsite and virtual">Onsite And Virtual</option>
                                            </select>

                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='url'>Url</label>
                                            <input
                                                className={"form-control"}
                                                type='url'
                                                id='url'
                                                name='url'
                                                value={newConferenceDetails.url}
                                                onChange={onChangeHandler}
                                                required
                                            />
                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='start_date'>Start Date</label>
                                            <input
                                                className={"form-control"}
                                                type='date'
                                                id='start_date'
                                                name='start_date'
                                                value={newConferenceDetails.start_date}
                                                onChange={onChangeHandler}
                                                required
                                            />

                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='end_date'>End Date</label>
                                            <input
                                                className={"form-control"}
                                                type='date'
                                                id='end_date'
                                                name='end_date'
                                                value={newConferenceDetails.end_date}
                                                onChange={onChangeHandler}
                                                required
                                            />

                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor="cost">Cost:</label>
                                            <select className={"form-control"} name="cost" id="cost" value={newConferenceDetails.cost} onChange={event => onChangeHandler(event)}>
                                                <option value="">----</option>
                                                <option value="free">Free</option>
                                                <option value="paid">Paid</option>
                                                <option value="paid and free">Paid and Free</option>
                                            </select>

                                        </div>
                                        <button className={"btn btn-success"}>Update Conference</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditConference;