import React, { useState, useEffect } from 'react';
import useHttpFetch from '../../hooks/use-http-fetch';
import buildAPIUrl from "../../Services/UrlBuilder";
import { Link } from "react-router-dom";
import Header from "../Header";

function ListAllBooks() {
  const [listOfBooks, setlistOfBooks] = useState([]);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const { isLoading, error, isError, sendRequest: fetchBooks } = useHttpFetch();


  if (!userToken) {
    window.location.assign('/');
  }

  useEffect(() => {
    const setBooks = (data) => {
      setlistOfBooks(data.reverse());
    }

    fetchBooks(
      {
        url: buildAPIUrl('v1/ux_books/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setBooks
    );
  }, [fetchBooks, userToken]);

  const setRefreshedBooks = (data) => {
    setlistOfBooks(data.reverse());
  }

  async function refreshBooks (data) {
    fetchBooks(
      {
        url: buildAPIUrl('v1/ux_books/'),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`
        }
      },
      setRefreshedBooks
    );
  }
  async function deleteBookHandler(book) {
    fetchBooks(
      {
        url: buildAPIUrl(`v1/ux_books/${book.id}/`),
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${userToken}`
        }
      },
      refreshBooks
    );

  }
  async function confirmDeleteBookHandler(book) {

    await deleteBookHandler(book);
    // let confirmDelete = confirm(`Are you sure you want to DELETE ${community.name}?`);
    // if (confirmDelete != null) {
    //   await deleteCommunityHandler(community);
    // }

  }

  let content = <p></p>;

  if (listOfBooks.length > 0) {
    content = listOfBooks.map((book) => (

      <tr key={book.id}>
        <td>{book.name}</td>
        <td>{book.author}</td>
        <td>{book.summary}</td>
        <td>{book.url}</td>
        <td><img src={book.image} height="50px" width="50px"/></td>

        <td>
          <Link className={"btn btn-primary w-100"} to={`/books/create/edit/${book.id}`}>
            Edit
          </Link>

          <button className={"btn btn-danger w-100 mt-2"} onClick={() => confirmDeleteBookHandler(book)}>Delete</button>
        </td>
      </tr>

    ));
  }
  else {
    content = <p>Found no Books.</p>;
  }

  return (
    <>
      <Header />
      <div className={"container-fluid mt-5"}>
        <div className={"container-fluid"} style={{ padding: "0 5rem" }}>
          <div className={"card border"}>
            <div className={"card-header"}>
              <div className={"row"}>
                <div className={"col-md-10"}>
                  <h4>List Of All Books</h4>
                </div>
                <div className={"col-md-2"}>
                  <Link to="/books/create">Create Book </Link>
                </div>
              </div>

            </div>
            <div className={"card-body"}>
              {isLoading && <p>Loading.....</p>}
              {isError && <p>{error}</p>}
              <table className={"table table-striped table-hover"}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Author</th>
                    <th>Summary</th>
                    <th>Url</th>
                    <th>Image</th>
                  </tr>
                </thead>
                <tbody>
                  {content}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}
export default ListAllBooks;