import React, {Component} from "react";
import {secondLevelFilter} from "./CategoriesFunctions";
import MediaQuery from "react-responsive";

class CareerFilters extends Component{
    state = {
        website: "indeed.com",
        url:"1",
        searchterm: localStorage.getItem('searchquery'),
        configID:`${process.env.REACT_APP_BING_CONFIG_ID}`,
        subscrKey:`${process.env.REACT_APP_BING_SUBSCRIPTION_ID}`,
    }
    handleSocialMedia = (company, urlid, response) => {
        secondLevelFilter(company, urlid, (successcallback)=>{
            this.setState({
                results: successcallback.webPages.value,
                total: successcallback.webPages.totalEstimatedMatches,
                pagination:successcallback.rankingResponse.mainline.items,
                url: urlid,
                website:company
            })
            this.props.handleSocial(this.state.results, this.state.total, this.state.pagination, this.state.url, this.state.website)
        }, (error)=>{
            console.log(error)
        })
    }
    render() {
        return (
            <>
                <MediaQuery minWidth={1200}>
                    <div className="col-12 p-0 col-md-11">
                        <div className="container">
                            <ul style={{listStyleType: "none"}} className="filter-level2 p-0">
                            <li className={this.state.url === "1" ? "active" : ""} onClick={() => this.handleSocialMedia("linkedin.com/jobs ", "1")}>
                                    <a >LinkedIn
                                        Jobs</a></li>
                                <li className={this.state.url === "2" ? "active" : ""} onClick={() => this.handleSocialMedia("indeed.com ", "2")}><a

                                    style={{paddingLeft: "0"}}>Indeed</a></li>
    
                                <li className={this.state.url === "3" ? "active" : ""} onClick={() => this.handleSocialMedia("jobs.google.com ", "3")}>
                                    <a >Google</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className="col-12 p-0 col-md-11">
                        <div className="container mobile">
                            <ul style={{listStyleType: "none"}} className="filter-level2 p-0">
                                <li className={this.state.url === "1" ? "active" : ""} onClick={() => this.handleSocialMedia("linkedin.com/jobs ", "1")}>
                                    <a >LinkedIn
                                        Jobs</a></li>
                                <li onClick={() => this.handleSocialMedia("indeed.com ", "2")}  className={this.state.url === "2" ? "active" : ""}><a

                                    style={{paddingLeft: "0"}}>Indeed</a></li>
                                
                                <li className={this.state.url === "3" ? "active" : ""} onClick={() => this.handleSocialMedia("jobs.google.com ", "3")}>
                                    <a >Google</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className="col-12 p-0 col-md-11">
                        <div className="container">
                            <ul style={{listStyleType: "none"}} className="filter-level2 p-0">
                                <li className={this.state.url === "1" ? "active" : ""} onClick={() => this.handleSocialMedia("linkedin.com/jobs ", "1")}>
                                    <a >LinkedIn
                                        Jobs</a></li>
                                <li  className={this.state.url === "2" ? "active" : ""} onClick={() => this.handleSocialMedia("indeed.com ", "2")}><a

                                    style={{paddingLeft: "0"}}>Indeed</a></li>
                               
                                <li className={this.state.url === "3" ? "active" : ""} onClick={() => this.handleSocialMedia("jobs.google.com ", "3")}>
                                    <a >Google</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </MediaQuery>
            </>


        );
    }
}

export default CareerFilters