import React, {Component} from "react";
import Header from "../Header";
import {
    MdBookmark,
    MdCancel,
    MdCheckCircle,
    MdClose,
    MdEdit,
    MdKeyboardArrowDown,
    MdKeyboardBackspace
} from "react-icons/md";
import {FaRegCopy, FaTrash, FaTrashAlt} from "react-icons/fa";
import "./collections.css"
import axios from "axios";
import buildAPIUrl from "../../Services/UrlBuilder";
import SkeletonSearchResult from "../../User/Components/Landing/Skeletons/SkeletonSearchResult";
import Delete from "../../assets/Delete.png"
import DeleteM from "../../assets/exc.png"
import {Accordion, Dropdown, Modal} from "react-bootstrap";
import {copyLink} from "../../User/Components/ResultsCategories/CategoriesFunctions";
import AlertComponent from "../../User/Components/ResultsCategories/AlertComponent";

class subCollectionDetails extends Component {
    state = {
        results: [],
        results1: [],
        pagination: [],
        siteid: "",
        social: false,
        total: "",
        search: "",
        images: [],
        title: "",
        link: "",
        success: "",
        token: localStorage.getItem('token'),
        show: false,
        clientID: "425793011868-1s6v97ajh0m70dhcvb7oejp3qup4qb5e.apps.googleusercontent.com",
        related: [],
        count: 15,
        offSet: 0,
        currentItems: null,
        pageCount: 0,
        itemsPerPage: 50,
        copy: false,
        saved: false,
        activePage: 15,
        pager: {},
        perPage: 16,
        page: 0,
        pages: 0,
        searcht: localStorage.getItem('searchquery'),
        configID: "d434f7b5-c57e-4e98-b6f0-27ddd337c829",
        subscrKey: "a02f905e19004306b6271197a7864c12",
        active: false,
        showfilter: false,
        loading: true,
        oldest: "",
        topv: "",
        logintype: "",
        exists: "",
        offsetvalue: "0",
        relatedbing: [],
        isTourOpen: false,
        notfound: false,
        reddit:[],
        norelated:false,
        showmodal:false,
        result:[],
        chatgptsecret:"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",
        chatgptresponse:"",
        generating: true,
        q:localStorage.getItem('searchquery'),
        suggestion:[],
        userHistory: [],
        regshow:false,
        filterstatus:"",
        delete:false,
        collections:[],
        filteritems:[],
        item_id:0
    }
    handleGetMainCollections = () =>{
        axios.get(buildAPIUrl(`v1/collections/main/?sub_group=${localStorage.getItem('subcat')}`), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
            this.setState({
                results:res.data,
                loading:false
            })
        }).catch((error)=>{

        })
    }
    async componentDidMount() {
        //Get collections
        axios.get(buildAPIUrl('v1/collections/group/')).then((res)=>{
            this.setState({
                collections:res.data
            })
        }).catch((error)=>{

        })
        this.handleGetMainCollections()
    }
    reghandleOpen = (subgroup,id ) => {
        this.setState({
            regshow:true,
            item_id: id
        })
    }
    deleteOpen = () => {
        this.setState({
            delete:true
        })
    }
    reghandleClose = () => {
        this.setState({
            regshow: false
        })
    }
    deleteClose = () => {
        this.setState({
            delete: false
        })
    }
    handleBack = ()=>{
        window.location.assign("/admin-subcollections")
    }
    handleUpdateCollection = async (id, collectionid) =>{
        //if the id is equal to 1, then the user is rejecting the article
        //if the id is equal to 2, then the user is accepting the article
       if (id===1){
           await axios.patch(buildAPIUrl(`v1/collections/main/${collectionid}/`), {
               status:"REJECTED"
           }, {
               headers: {
                   'Content-Type': 'application/json',
                   'Authorization': `Bearer ${localStorage.getItem('token')}`
               }
           }).then((res)=>{
               this.handleGetMainCollections()
           }).catch((error)=>{

           })
       } else {
           await axios.patch(buildAPIUrl(`v1/collections/main/${collectionid}/`), {
               status:"ACTIVE"
           }, {
               headers: {
                   'Content-Type': 'application/json',
                   'Authorization': `Bearer ${localStorage.getItem('token')}`
               }
           }).then((res)=>{
               this.handleGetMainCollections()
           }).catch((error)=>{

           })
       }
    }
    handleUpdateCollectionNew = async (id, collectionid) =>{
        //if the id is equal to 1, then the user is rejecting the article
        //if the id is equal to 2, then the user is accepting the article
        await axios.patch(buildAPIUrl(`v1/collections/main/${collectionid}/`), {
            status:"PENDING"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
            this.handleGetMainCollections()
        }).catch((error)=>{

        })
    }
    handleFilterCollections = async (status)=>{
       if (status==="All") {
          await this.setState({
               filterstatus:""
           })
       } else  {
          await this.setState({
               filterstatus:status
           })
       }
    }
    //Save collection when user clicks on the checkbox
    handleSaveCollection = async (sub_category,group) => {
        axios.patch(buildAPIUrl(`v1/collections/main/${this.state.item_id}/`), {
            sub_group: this.state.filteritems,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
            this.setState({
                savesuccess:true
            })
            this.handleGetMainCollections()
        }).catch((error)=>{

        })
    }
    handleCheck = async (e) => {
        const isChecked = e.target.checked
        if (isChecked) {
            await this.setState({filteritems: [...this.state.filteritems, e.target.value]})
        } else {
            const index = this.state.filteritems.indexOf(e.target.value);
            this.state.filteritems.splice(index, 1);
            await this.setState({filteritems: this.state.filteritems})
        }
        console.log(this.state.filteritems)
    }
    handleCopyLink = (result)=>{
        copyLink(result, (copystatus)=>{
            this.setState({
                copy:copystatus
            })
            setTimeout(()=>{
                this.setState({
                    copy:false
                })
            }, 5000)
        })

    }
    //Save collection when user clicks on the checkbox
    handleCheckCollection = async (sub_category,group) => {
        localStorage.setItem('groupid',group )
        this.setState({
            collectionid:group
        })
    }
    render() {
        const {page, perPage, pages} = this.state;
        let items = this.state.results.slice(page * perPage, (page + 1) * perPage);
        const filtereditems = this.state.results.filter((result)=> {
            if (this.state.filterstatus === "") {
                return result
            } else if (result.status === this.state.filterstatus ) {
                return result
            }
        })

        return (
            <div className={"pb-5"}  style={{backgroundColor:"#F9F8FF"}}>
                <Modal size="md" centered show={this.state.delete}  onHide={this.deleteClose} style={{borderRadius:"10px", boxShadow:"0px 4px 37px rgba(0, 0, 0, 0.15)"}}>
                    <Modal.Body>
                        <div className={"container d-flex justify-content-center"}>
                            <img src={DeleteM} style={{height:"40px", width:"7.78px", textAlign:"center"}}/>
                        </div>
                            <div className={"container d-flex justify-content-center"} style={{marginTop:"1.25rem"}}>

                                <h6>
                                    Are you sure you want to delete
                                </h6>
                            </div>
                        <div className={"container d-flex justify-content-end"}>
                            <div>
                                <button onClick={this.deleteClose} className={"btn"} style={{borderRadius:"200px", border:"1px solid rgba(0, 0, 0, 0.2)", marginRight:"0.563rem"}}>Cancel</button>
                                <button className={"btn"} style={{borderRadius:"200px", backgroundColor:"#FD3434", color:"white"}}>Delete</button>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
                <Modal size="md" centered show={this.state.regshow}  onHide={this.reghandleClose} style={{borderRadius:"10px", boxShadow:"0px 4px 37px rgba(0, 0, 0, 0.15)"}}>
                    <Modal.Body>
                        <div className="row border-bottom" style={{padding:"1.25rem 2rem ", color:"#767676"}}>
                            <div className={"col-md-10"}>
                                <MdBookmark />  Save to collection
                            </div>
                            <div className={"col-md-2 d-flex justify-content-end px-3"}>
                                <MdClose onClick={this.reghandleClose} style={{fontSize:"18px", cursor:"pointer"}} />
                            </div>
                        </div>
                        <div className={"container-fluid "} style={{padding:"1.25rem 2rem ", color:"#767676"}}>
                            <Accordion>
                                {
                                    this.state.collections.length > 0 ? <>
                                            {
                                                this.state.collections.filter((result) => result.id===parseInt(localStorage.getItem('collectionid'))).map((collection,key)=>(
                                                    <>
                                                        <Accordion.Item eventKey={key}>
                                                            <Accordion.Header >
                                                                <div className={"row"}>
                                                                    <div className={"col-md-6"}>
                                                                        <h6 style={{color:"#333333"}}>{collection.name}<MdKeyboardArrowDown /></h6>
                                                                    </div>
                                                                    <div className={"col-md-6 d-flex justify-content-end"}>
                                                                        {
                                                                            this.state.savesuccess ? <>
                                                                                  <span className={this.state.collectionid === collection.id ? "" : "d-none"} style={{background:" #522FFF", color:"white", borderRadius:"200px", padding:"4px 11px"}}>
                                                                            Added
                                                                        </span>
                                                                            </> : <>

                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                <ul>
                                                                    {
                                                                        collection.sub_group.map((sub)=>(
                                                                            <li style={{marginTop:"0.688rem"}}>
                                                                                <div className="form-check">
                                                                                    <div className={"row"}>

                                                                                        <div className={"col-md-10 "}>
                                                                                            <label  className="form-check-label collec-check-label" htmlFor={sub.id}>
                                                                                                {sub.name}
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className={"col-md-2 d-flex justify-content-end"}>
                                                                                            <input  onChange={this.handleCheck} onClick={()=>this.handleCheckCollection(sub.id, collection.id)} className="form-check-input collec-check" type="checkbox" value={sub.id}
                                                                                                    id={sub.id}  />
                                                                                        </div>
                                                                                    </div>


                                                                                </div>
                                                                            </li>
                                                                        ))
                                                                    }


                                                                </ul>


                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </>
                                                ))
                                            }
                                        </> :
                                        <></>
                                }


                            </Accordion>
                        </div>
                        <div className={"container d-flex justify-content-end"}>
                            <button onClick={()=>this.handleSaveCollection()} className={this.state.filteritems.length > 0 ? "btn btn-primary" : "d-none"}>
                              Update
                            </button>
                        </div>
                    </Modal.Body>

                </Modal>
                <Header />
                <div className={"container-fluid bg-image d-flex justify-content-center align-items-center "} style={{ height:"14.625rem"}}>
                    <h6 style={{fontSize:"30px", color:"#FFFFFF", fontWeight:"600"}}>{localStorage.getItem('collectionname')}</h6>
                </div>
                <div className={"container"} style={{marginTop:"6.313rem"}}>
                    <AlertComponent copystatus={this.state.copy} savestatus={this.state.saved}
                                    existstatus={this.state.exists} type={"General"}/>
                    <div className={"row d-flex justify-content-center"}>
                        <div className={"col-md-8"}>
                            <div className={"row"}>
                                <div className={"col-md-4"}>
                                    <h6 onClick={this.handleBack} style={{color:"#522FFF", cursor:"pointer"}}><MdKeyboardBackspace /> Back</h6>
                                </div>
                                <div className={"col-md-6"}>
                                    <h6 style={{color:"#00944D", fontSize:"25px", fontWeight:"500"}}>
                                        {localStorage.getItem('subname')}
                                    </h6>
                                </div>
                                <div className={"col-md-2 d-flex justify-content-end"}>
                                    <Dropdown className="d-inline" autoClose={true} >
                                        <Dropdown.Toggle className={"bg-transparent border-0 d-flex justify-content-center"} id="dropdown-autoclose-false">
                                            <span style={{border:"1px solid #00944D", borderRadius:"200px", color:"#00944D", padding:"6px 13px", cursor:"pointer" }}> Sort By <MdKeyboardArrowDown /> </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{marginTop:".2rem", width:"10.125rem", boxShadow:"0px 4px 34px rgba(0, 0, 0, 0.15)"}} drop={"down-centered"}>
                                            <div className={"container p-0"}>

                                                <div className={"container-fluid p-0 coll-filters "}>
                                                    <ul className={"p-0 mt-3 mx-0"} style={{listStyleType:"none"}}>
                                                        <li className={this.state.filterstatus==="" ? "active" : ""} onClick={()=>this.handleFilterCollections("All")} >All</li>
                                                        <li className={this.state.filterstatus==="PENDING" ? "active" : ""} onClick={()=>this.handleFilterCollections("PENDING")} >Pending</li>
                                                        <li className={this.state.filterstatus==="ACTIVE" ? "active" : "mt-1"} onClick={()=>this.handleFilterCollections("ACTIVE")} >Approved</li>
                                                        <li className={this.state.filterstatus==="REJECTED" ? "active" : "mt-1"} onClick={()=>this.handleFilterCollections("REJECTED")}>Rejected</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={"container"} style={{marginTop:"2rem"}}>
                    <div className={"row d-flex justify-content-center"}>
                        <div className={"col-md-9"}>
                            {
                                this.state.loading ? <>
                                        {
                                            [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                key={n}/>)
                                        }

                                    </> :
                                    <div>
                                        {
                                            filtereditems.length > 0 ? <>
                                                {
                                                    filtereditems.sort(function (a,b){return b.id-a.id}).map((result) => (
                                                        <>

                                                            <div className={"bg-white"} style={{boxShadow:"0px 1px 3px rgba(0, 0, 0, 0.25)", padding:"1.563rem", marginTop:"0.813rem"}}>
                                                                <div className={"row bg-white"}>
                                                                    <div className={"col-md-7 bg-white"}>
                                                                        <a onClick={()=>this.saveEvent()} href={result.link} target="_blank"><h6
                                                                            className="result-url mt-2 mb-2">{result.link.substr(0, 100)}</h6>
                                                                        </a>
                                                                    </div>

                                                                    <div className={"col-md-5 d-flex justify-content-end align-items-center"}>
                                                                        {
                                                                            result.status==="PENDING" ?  <>
                                                                                <Dropdown className="d-inline bg-transparent" autoClose={true} >
                                                                                    <Dropdown.Toggle className={"bg-transparent border d-flex justify-content-center"} id="dropdown-autoclose-false">
                                                                                        <span style={{border:"1px solid gray", borderRadius:"200px", color:"gray", padding:"6px 13px", cursor:"pointer" }}> Under Review <MdKeyboardArrowDown /> </span>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu  style={{marginTop:".2rem", width:"10.125rem", boxShadow:"0px 4px 34px rgba(0, 0, 0, 0.15)"}} drop={"down-centered"}>
                                                                                        <div className={"container p-0"}>

                                                                                            <div className={"container-fluid p-0 coll-filters "}>
                                                                                                <ul className={"p-0 mt-3 mx-0"} style={{listStyleType:"none"}}>
                                                                                                    <li  onClick={()=>this.handleUpdateCollection(0, result.id)} style={{color:"#00944D"}}>Approve</li>
                                                                                                    <li  onClick={()=>this.handleUpdateCollection(1, result.id)} style={{color:"#FD3434"}}>Reject</li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>

                                                                            </> : <>
                                                                                {
                                                                                    result.status==="ACTIVE" ? <>
                                                                                        <Dropdown className="d-inline" autoClose={true} >
                                                                                            <Dropdown.Toggle className={"bg-transparent border-0 d-flex justify-content-center"} id="dropdown-autoclose-false">
                                                                                                <span style={{border:"1px solid #00944D", borderRadius:"200px", color:"#00944D", padding:"6px 13px", cursor:"pointer" }}> Approved <MdKeyboardArrowDown /> </span>
                                                                                            </Dropdown.Toggle>

                                                                                            <Dropdown.Menu style={{marginTop:".2rem", width:"10.125rem", boxShadow:"0px 4px 34px rgba(0, 0, 0, 0.15)"}} drop={"down-centered"}>
                                                                                                <div className={"container p-0"}>

                                                                                                    <div className={"container-fluid p-0 coll-filters "}>
                                                                                                        <ul className={"p-0 mt-3 mx-0"} style={{listStyleType:"none"}}>
                                                                                                            <li  onClick={()=>this.handleUpdateCollection(1, result.id)} style={{color:"#FD3434"}}>Reject</li>
                                                                                                            <li  onClick={()=>this.handleUpdateCollectionNew(1, result.id)} style={{color:"gray"}}>Under Review</li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </> : <>
                                                                                        <Dropdown className="d-inline" autoClose={true} >
                                                                                            <Dropdown.Toggle className={"bg-transparent border-0 d-flex justify-content-center"} id="dropdown-autoclose-false">
                                                                                                <span style={{border:"1px solid #FD3434", borderRadius:"200px", color:"#FD3434", padding:"6px 13px", cursor:"pointer" }}> Rejected <MdKeyboardArrowDown /> </span>
                                                                                            </Dropdown.Toggle>

                                                                                            <Dropdown.Menu style={{marginTop:".2rem", width:"10.125rem", boxShadow:"0px 4px 34px rgba(0, 0, 0, 0.15)"}} drop={"down-centered"}>
                                                                                                <div className={"container p-0"}>

                                                                                                    <div className={"container-fluid p-0 coll-filters "}>
                                                                                                        <ul className={"p-0 mt-3 mx-0"} style={{listStyleType:"none"}}>
                                                                                                            <li  onClick={()=>this.handleUpdateCollection(0, result.id)} style={{color:"#00944D"}}>Approve</li>
                                                                                                            <li  onClick={()=>this.handleUpdateCollectionNew(1, result.id)} style={{color:"gray"}}>Under Review</li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Dropdown.Menu>
                                                                                        </Dropdown>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }

                                                                        <img onClick={this.deleteOpen} src={Delete} style={{width:"24px", height:"24px", marginLeft:"1rem"}}/>
                                                                    </div>
                                                                </div>

                                                                <a onClick={()=>this.saveEvent()} href={result.url} target="_blank"><h6
                                                                    className="result-name mt-2 mb-2">
                                                                    {result.title}
                                                                </h6>
                                                                </a>
                                                                <p className="result-snippet">
                                                                    {result.snippet ?  result.snippet.substr(0, 100) : result.snippet}
                                                                </p>
                                                                <div className={"container-fluid p-0"} style={{marginTop:"0.813rem"}}>
                                                                    <span onClick={()=>this.reghandleOpen(result.sub_group, result.id)} style={{cursor:"pointer",backgroundColor:"#E4DFFF", borderRadius:"200px", color:"#522FFF", padding:"0.375rem 0.75rem", fontSize:"13px", fontWeight:"400"}}> {result.group.name} <MdKeyboardArrowDown /></span>
                                                                    <span className="" style={{color:"#767676", fontSize:"13px", marginLeft:"0.75rem", display:"inline-block", cursor:"pointer"}} onMouseDown={() => {
                                                                        this.handleCopyLink(result.link)
                                                                    }}> Copy link <FaRegCopy className="save-icon"/></span>
                                                                </div>

                                                            </div>
                                                        </>
                                                    ))
                                                }
                                            </> : <>
                                            <p> There are no articles</p>
                                            </>
                                        }



                                    </div>
                            }

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export  default subCollectionDetails