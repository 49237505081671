import React, {Component} from "react";
import { loadStripe } from "@stripe/stripe-js";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET);

export default function Checkout() {
    return (
            <Elements stripe={stripePromise} >
                <PaymentForm />
            </Elements>
    )
}