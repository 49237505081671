import React, {useEffect, useState} from "react";
import Header from "../Landing/Header";
import "./events.css"
import eventsimg from "../../../assets/Rectangle 816.png"
import Calendar from "../../../assets/Calendar.png";
import {MdAccessTime} from "react-icons/md";
import MediaQuery from "react-responsive";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
export default function EventsList(){
    const [today, setToday]=useState(new Date().toLocaleDateString('en-US',{year: 'numeric', month: 'short', day: 'numeric'}))
    const[events, setEvents]=useState([])
    async function getEvents () {
        const events = await axios.get(buildAPIUrl('v1/events/?other_events=1'))
        setEvents(events.data)
    }
    const handleClickEventLink = (name) =>{
        EventsHandler("Events Link Clicked",  document.URL, "", name)
    }
    useEffect( ()=>{
        EventsHandler("Events Page Opened",  document.URL)
         getEvents()
    }, [])
    return(
        <>
            <Header />
            <MediaQuery minWidth={1200} >
                <div className={"row d-flex justify-content-center events"}>
                    <div className={"col-md-8"}>
                        <div className={"container"} style={{marginTop:"7rem", marginBottom:"2rem"}}>
                            <h5 className={"events-title"}>RB Events Calendar</h5>
                            <h5 className={"events-date"} style={{marginTop:"3.375rem"}}>
                                Today, {today}
                            </h5>
                            {
                                events.filter((eve)=>{return new Date(eve.date).toISOString()===new Date(today).toISOString()}).length > 0 ?
                                    <>
                                        {events.filter((eve)=>{return new Date(eve.date).toISOString()===new Date(today).toISOString()}).map((event)=>(
                                            <>
                                                <div className={"container-fluid p-0"} style={{marginTop:"3.313rem"}}>
                                                    <div className={"row"}>
                                                        <div className={"col-md-4"}>
                                                            <div className={""}>
                                                                <img src={event.thumbnail} style={{width:"100%"}} className={"h-100"}/>
                                                            </div>
                                                        </div>
                                                        <div className={"col-md-8"}>
                                                            <h6 className={"events-item-title"}>
                                                                {event.name}
                                                            </h6>
                                                            <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                                <div className={"col-md-2 d-flex align-items-center "}>
                                                                    <div>
                                                                        <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#00944D", fontWeight:"400", fontSize:"13px"}}>Today</span>
                                                                    </div>

                                                                </div>
                                                                <div className={"col-md-6"}>
                                                                    <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>{event.time_zone}</span>
                                                                </div>
                                                            </div>

                                                            <p className={"events-item-description"} style={{marginTop:"0.563rem"}}>
                                                                <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                                            </p>
                                                            <a onClick={()=>handleClickEventLink(event.name)} href={event.link}>
                                                                <h6 className={"events-register"} style={{marginTop:"0.563rem"}}>
                                                                    Register
                                                                </h6>
                                                            </a>

                                                        </div>
                                                    </div>
                                                </div>
                                                <hr style={{border:" 1px solid #E1E1E1", marginTop:"1.875rem"}} />
                                            </>
                                        ))}
                                    </> :

                                        "There are no events today"

                            }

                            <h6 className={"events-date upcoming"} style={{marginTop:"5.188rem"}}><span>Upcoming Events</span></h6>
                            {
                                events.filter((eve)=>{return new Date(eve.date) > new Date(today)}).length > 0 ?
                                    <>
                                        {events.filter((eve)=>{return new Date(eve.date) > new Date(today)}).sort(function (a,b){return new Date(a.date) - new Date(b.date)}).map((event)=>(
                                            <>
                                                <div className={"container-fluid p-0"} style={{marginTop:"3.313rem"}}>
                                                    <div className={"row"}>
                                                        <div className={"col-md-4"}>
                                                            <div className={""}>
                                                                <img src={event.thumbnail} style={{width:"100%"}} className={"h-100"}/>
                                                            </div>
                                                        </div>
                                                        <div className={"col-md-8 px-5"}>
                                                            <h6 className={"events-item-title"}>
                                                                {event.name}
                                                            </h6>
                                                            <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                                <div className={"col-md-3 d-flex align-items-center "}>
                                                                    <div>
                                                                        <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>{event.date}</span>
                                                                    </div>

                                                                </div>
                                                                <div className={"col-md-6"}>
                                                                    <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>{event.time_zone}</span>
                                                                </div>
                                                            </div>

                                                            <p className={"events-item-description"} style={{marginTop:"0.563rem"}}>
                                                                <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                                            </p>
                                                            <a href={event.link}>
                                                                <h6 className={"events-register"} style={{marginTop:"0.563rem"}}>
                                                                    Register
                                                                </h6>
                                                            </a>

                                                        </div>
                                                    </div>
                                                </div>
                                                <hr style={{border:" 1px solid #E1E1E1", marginTop:"1.875rem"}} />
                                            </>
                                        ))}
                                    </> :

                                    "There are no upcoming events"

                            }


                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={460} >
                <div className={"mobile"}>
                    <div className={"row d-flex justify-content-center"}>
                        <div className={"col-md-8"}>
                            <div className={"container"} style={{marginTop:"3rem", marginBottom:"2rem"}}>
                                <h5 className={"events-title"}>RB Events Calendar</h5>
                                <h5 className={"events-date"} style={{marginTop:"1.375rem"}}>
                                    Today,  January 11, 2022
                                </h5>
                                {
                                    events.filter((eve)=>{return new Date(eve.date).toISOString()===new Date(today).toISOString()}).length > 0 ?
                                        <>
                                            {events.filter((eve)=>{return new Date(eve.date).toISOString()===new Date(today).toISOString()}).map((event)=>(
                                                <>
                                                    <div className={"container-fluid p-0"} style={{marginTop:"3.313rem"}}>
                                                        <div className={"row"}>
                                                            <div className={"col-md-4"}>
                                                                <div className={"h-100"}>
                                                                    <img src={event.thumbnail} style={{width:"100%"}} className={"h-100"}/>
                                                                </div>
                                                            </div>
                                                            <div className={"col-md-8"}>
                                                                <h6 className={"events-item-title"}>
                                                                    {event.name}
                                                                </h6>
                                                                <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                                    <div className={"col-md-3 d-flex align-items-center "}>
                                                                        <div>
                                                                            <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#00944D", fontWeight:"400", fontSize:"13px"}}>Today</span>
                                                                        </div>

                                                                    </div>
                                                                    <div className={"col-md-3"}>
                                                                        <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>{event.time_zone}</span>
                                                                    </div>
                                                                </div>

                                                                <p className={"events-item-description"} style={{marginTop:"0.563rem"}}>
                                                                    <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                                                </p>
                                                                <a href={event.link}>
                                                                    <h6 className={"events-register"} style={{marginTop:"0.563rem"}}>
                                                                        Register
                                                                    </h6>
                                                                </a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr style={{border:" 1px solid #E1E1E1", marginTop:"1.875rem"}} />
                                                </>
                                            ))}
                                        </> :

                                        "There are no events today"

                                }

                                <h6 className={"events-date upcoming"} style={{marginTop:"5.188rem"}}><span>Upcoming Events</span></h6>
                                {
                                    events.length > 0 ?
                                        <>
                                            {events.filter((eve)=>{return new Date(eve.date) > new Date(today)}).sort(function (a,b){return new Date(a.date) - new Date(b.date)}).map((event)=>(
                                                <>
                                                    <div className={"container-fluid p-0"} style={{marginTop:"3.313rem"}}>
                                                        <div className={"row"}>
                                                            <div className={"col-md-4"}>
                                                                <div className={"h-100"}>
                                                                    <img src={event.thumbnail} style={{width:"100%"}} className={"h-100"}/>
                                                                </div>
                                                            </div>
                                                            <div className={"col-md-8 mt-3"}>
                                                                <h6 className={"events-item-title"}>
                                                                    {event.name}
                                                                </h6>
                                                                <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                                    <div className={"col-md-3 d-flex align-items-center "}>
                                                                        <div>
                                                                            <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>{event.date}</span>
                                                                        </div>

                                                                    </div>
                                                                    <div className={"col-md-3"}>
                                                                        <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>{event.time_zone}</span>
                                                                    </div>
                                                                </div>

                                                                <p className={"events-item-description"} style={{marginTop:"0.563rem"}}>
                                                                    <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                                                </p>
                                                                <a href={event.link}>
                                                                    <h6 className={"events-register"} style={{marginTop:"0.563rem"}}>
                                                                        Register
                                                                    </h6>
                                                                </a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr style={{border:" 1px solid #E1E1E1", marginTop:"1.875rem"}} />
                                                </>
                                            ))}
                                        </> :

                                        "There are no upcoming events"

                                }

                            </div>
                        </div>
                    </div>
                </div>

            </MediaQuery>
            <MediaQuery maxWidth={1200} minWidth={461} >
                <div className={"tablet"}>
                    <div className={"row d-flex justify-content-center events"}>
                        <div className={"col-md-10"}>
                            <div className={"container"} style={{marginTop:"2rem", marginBottom:"2rem"}}>
                                <h5 className={"events-title"}>RB Events Calendar</h5>
                                <h5 className={"events-date"} style={{marginTop:"1.375rem"}}>
                                    Today,  January 11, 2022
                                </h5>
                                {
                                    events.filter((eve)=>{return new Date(eve.date).toISOString()===new Date(today).toISOString()}).length > 0 ?
                                        <>
                                            {events.filter((eve)=>{return new Date(eve.date).toISOString()===new Date(today).toISOString()}).map((event)=>(
                                                <>
                                                    <div className={"container-fluid p-0"} style={{marginTop:"3.313rem"}}>
                                                        <div className={"row"}>
                                                            <div className={"col-md-4"}>
                                                                <div className={""}>
                                                                    <img src={event.thumbnail} style={{width:"100%"}} className={"h-100"}/>
                                                                </div>
                                                            </div>
                                                            <div className={"col-md-8"}>
                                                                <h6 className={"events-item-title"}>
                                                                    {event.name}
                                                                </h6>
                                                                <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                                    <div className={"col-6 d-flex align-items-center "}>
                                                                        <div>
                                                                            <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#00944D", fontWeight:"400", fontSize:"13px"}}>Today</span>
                                                                        </div>

                                                                    </div>
                                                                    <div className={"col-6"}>
                                                                        <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>{event.time_zone}</span>
                                                                    </div>
                                                                </div>

                                                                <p className={"events-item-description"} style={{marginTop:"0.563rem"}}>
                                                                    <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                                                </p>
                                                                <a href={event.link}>
                                                                    <h6 className={"events-register"} style={{marginTop:"0.563rem"}}>
                                                                        Register
                                                                    </h6>
                                                                </a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr style={{border:" 1px solid #E1E1E1", marginTop:"1.875rem"}} />
                                                </>
                                            ))}
                                        </> :

                                        "There are no events today"

                                }

                                <h6 className={"events-date upcoming"} style={{marginTop:"5.188rem"}}><span>Upcoming Events</span></h6>
                                {
                                    events.length > 0 ?
                                        <>
                                            {events.filter((eve)=>{return new Date(eve.date) > new Date(today)}).sort(function (a,b){return new Date(a.date) - new Date(b.date)}).map((event)=>(
                                                <>
                                                    <div className={"container-fluid p-0"} style={{marginTop:"3.313rem"}}>
                                                        <div className={"row"}>
                                                            <div className={"col-md-4"}>
                                                                <div className={""}>
                                                                    <img src={event.thumbnail} style={{width:"100%"}} className={"h-100"}/>
                                                                </div>
                                                            </div>
                                                            <div className={"col-md-8 px-5"}>
                                                                <h6 className={"events-item-title"}>
                                                                    {event.name}
                                                                </h6>
                                                                <div className={"row "} style={{marginTop:"0.563rem"}}>
                                                                    <div className={"col-6 d-flex align-items-center "}>
                                                                        <div>
                                                                            <img style={{height:"16px", width:"16px"}} alt={"calendar icon"}  className={"calendar-icon"} src={Calendar} /> <span style={{color:"#333333", fontWeight:"400", fontSize:"13px"}}>{event.date}</span>
                                                                        </div>

                                                                    </div>
                                                                    <div className={"col-6"}>
                                                                        <MdAccessTime style={{fontSize:"16px"}} /> <span style={{fontWeight:"400", fontSize:"13px"}}>{event.time_zone}</span>
                                                                    </div>
                                                                </div>

                                                                <p className={"events-item-description"} style={{marginTop:"0.563rem"}}>
                                                                    <div dangerouslySetInnerHTML={ {__html: event.description} } />
                                                                </p>
                                                                <a href={event.link}>
                                                                    <h6 className={"events-register"} style={{marginTop:"0.563rem"}}>
                                                                        Register
                                                                    </h6>
                                                                </a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr style={{border:" 1px solid #E1E1E1", marginTop:"1.875rem"}} />
                                                </>
                                            ))}
                                        </> :

                                        "There are no upcoming events"

                                }

                            </div>
                        </div>
                    </div>
                </div>

            </MediaQuery>


        </>
    )
}