import React, {Component} from "react";
import Header from "../Landing/Header";
import Logo from "../../../assets/research-bookmark-logotype-one-color-rgb.svg";
import {GoogleLogin} from "react-google-login";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import swal from "sweetalert";
import MediaQuery from "react-responsive";
import GoogleButton from "./GoogleButton";

class Signup extends Component{
    state = {
        show: this.props.show,
        clientID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        regshow:false,
        showloginmodal: localStorage.getItem('showloginmodal'),
        token:localStorage.getItem('token'),
        showmodal:false,
        communityid:this.props.match.params.id
    }
    componentDidMount() {
        let id = this.props.match.params.id
    }

    render() {
        const onSuccess = async (res) => {
            console.log(res)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            localStorage.setItem('calendar_token',res.accessToken)
            localStorage.setItem('token', res.accessToken)
            localStorage.setItem('username', res.profileObj.givenName)
            localStorage.setItem('image', res.profileObj.imageUrl)
            localStorage.setItem('email', res.profileObj.email)
            const resp = await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: localStorage.getItem('token'),
                client_id: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_ID,
                client_secret: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_SECRET,
                grant_type:"convert_token",
                backend: "google-oauth2",
            }, requestOptions).then((response)=>{
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                window.location.assign("/")
            }).catch((err)=>{
                swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.assign("/")
                    localStorage.clear()
                });
                console.log(err)
            })
        }
        const onFailure = (res) => {
            console.log(res)
            console.log("Login Failed")
        }
        const facebookLogin = async (res) => {
            console.log(res)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            localStorage.setItem('token', res.accessToken)
            localStorage.setItem('username', res.name)
            localStorage.setItem('image', res.picture.data.url)
            localStorage.setItem('email', res.email)
            const resp = await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: localStorage.getItem('token'),
                client_id: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_ID,
                client_secret: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_SECRET,
                grant_type:"convert_token",
                backend: "facebook"
            }, requestOptions).then((response)=>{
                console.log("new token",response)
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                window.location.assign("/")
            }).catch((err)=>{
                swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.reload()
                    localStorage.clear()
                });
                console.log(err)
            })
        }
        return (
            <div>
                <MediaQuery minWidth={1200}>
                    <div className={"container-fluid h-100"}>
                        <div className={"row d-flex justify-content-center"} style={{marginTop:"7rem"}}>
                            <div className={"col-md-4 col-12 border"} style={{borderRadius:"15px"}}>
                                <div className={"card"}>
                                    <div className="row">
                                        <div className={"col-md-12"}>
                                            <div className="card" style={{marginLeft:"3rem", marginRight:"2.25rem", marginTop:"2.563rem", marginBottom:"0.313rem"}}>
                                                <div className={"container d-flex justify-content-center"}>
                                                    <img src={Logo} style={{width:"100px", height:"50px"}}/>
                                                </div>
                                                <h6 className="text-center" style={{fontSize:"35px", fontWeight:"600"}}>Welcome to Research Bookmark</h6>

                                                <div className="container-fluid p-0 flex-column d-flex justify-content-center">
                                                    <div className=" mb-2 pt-2" style={{marginTop:"4.188rem"}} >
                                                        <GoogleButton/>

                                                    </div>
                                                    <div className=" mb-3 d-flex justify-content-center facebook-login">
                                                        <FacebookLogin
                                                            textButton="Continue with Facebook"
                                                            appId="714881769793978"
                                                            autoLoad={false}
                                                            fields="name,email,picture"
                                                            callback={facebookLogin}
                                                            onSuccess={facebookLogin}
                                                            cssClass="my-facebook-button-class1 mt-3"
                                                            icon={<span className="social-media-facebook">
                                                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/1200px-Facebook_f_logo_%282021%29.svg.png" style={{width:"25px", height:"25px"}}/>
                                                        </span>}
                                                        >
                                                        </FacebookLogin>
                                                    </div>
                                                </div>

                                                <div className="mt-3 mb-2 text-center" style={{marginTop:"1rem", paddingBottom:"4.813rem"}}>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} className="mobile">
                    <div className={"container-fluid h-100"}>
                        <div className={"row d-flex justify-content-center"} style={{marginTop:"7rem"}}>
                            <div className={"col-md-4 col-12 border"} style={{borderRadius:"15px"}}>
                                <div className={"card"}>
                                    <div className="row">
                                        <div className={"col-md-12"}>
                                            <div className="card" style={{marginLeft:"1rem", marginRight:"1rem", marginTop:"2.563rem", marginBottom:"0.313rem"}}>
                                                <div className={"container d-flex justify-content-center"}>
                                                    <img src={Logo} style={{width:"100px", height:"50px"}}/>
                                                </div>
                                                <h6 className="text-center" style={{fontSize:"30px", fontWeight:"600", marginTop:"4.563rem",}}>Welcome to Research Bookmark</h6>

                                                <div className="container-fluid p-0 flex-column d-flex justify-content-center">
                                                    <div className=" mb-2 pt-2" style={{marginTop:"4.188rem"}} >
                                                    <GoogleButton/>

                                                    </div>
                                                    <div className=" mb-3 d-flex justify-content-center facebook-login">
                                                        <FacebookLogin
                                                            textButton="Continue with Facebook"
                                                            appId="714881769793978"
                                                            autoLoad={false}
                                                            fields="name,email,picture"
                                                            callback={facebookLogin}
                                                            onSuccess={facebookLogin}
                                                            cssClass="my-facebook-button-class1 mt-3"
                                                            icon={<span className="social-media-facebook">
                                                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/1200px-Facebook_f_logo_%282021%29.svg.png" style={{width:"15px"}}/>
                                                        </span>}
                                                        >
                                                        </FacebookLogin>
                                                    </div>
                                                </div>

                                                <div className="mt-3 mb-2 text-center" style={{marginTop:"1rem", paddingBottom:"4.813rem"}}>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} className="tablet" minWidth={461}>
                    <div className={"container-fluid h-100"}>
                        <div className={"row d-flex justify-content-center"} style={{marginTop:"7rem"}}>
                            <div className={"col-md-4 col-12 border"} style={{borderRadius:"15px"}}>
                                <div className={"card"}>
                                    <div className="row">
                                        <div className={"col-md-12"}>
                                            <div className="card" style={{marginLeft:"3rem", marginRight:"2.25rem", marginTop:"2.563rem", marginBottom:"0.313rem"}}>
                                                <div className={"container d-flex justify-content-center"}>
                                                    <img src={Logo} style={{width:"100px", height:"50px"}}/>
                                                </div>
                                                <h6 className="text-center" style={{fontSize:"35px", fontWeight:"600"}}>Welcome to Research Bookmark</h6>

                                                <div className="container-fluid p-0 flex-column d-flex justify-content-center">
                                                    <div className=" mb-2 pt-2" style={{marginTop:"4.188rem"}} >
                                                    <GoogleButton/>
                                                    </div>
                                                    <div className=" mb-3 d-flex justify-content-center facebook-login">
                                                        <FacebookLogin
                                                            textButton="Continue with Facebook"
                                                            appId="714881769793978"
                                                            autoLoad={false}
                                                            fields="name,email,picture"
                                                            callback={facebookLogin}
                                                            onSuccess={facebookLogin}
                                                            cssClass="my-facebook-button-class1 mt-3"
                                                            icon={<span className="social-media-facebook">
                                                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/1200px-Facebook_f_logo_%282021%29.svg.png" style={{width:"15px"}}/>
                                                        </span>}
                                                        >
                                                        </FacebookLogin>
                                                    </div>
                                                </div>

                                                <div className="mt-3 mb-2 text-center" style={{marginTop:"1rem", paddingBottom:"4.813rem"}}>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}
export default Signup