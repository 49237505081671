import React, {Component} from "react";
import Logo from "../../../assets/research-bookmark-logotype-one-color-rgb.svg";
import {GoogleLogin} from "react-google-login";
import MediaQuery from "react-responsive";
import GoogleButton from "./GoogleButton";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import swal from "sweetalert";
import { LinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
class Login extends Component{
    state = {
        show: this.props.show,
        clientID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        regshow:false,
        showloginmodal: localStorage.getItem('showloginmodal'),
        token:localStorage.getItem('token'),
        showmodal:false,
    }
    render() {
        const onSuccess = async (res) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            localStorage.setItem('token', res.accessToken)
            localStorage.setItem('username', res.profileObj.givenName)
            localStorage.setItem('image', res.profileObj.imageUrl)
            localStorage.setItem('email', res.profileObj.email)
            const resp = await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: localStorage.getItem('token'),
                client_id: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_ID,
                client_secret:process.env.REACT_APP_SOCIAL_AUTH_CLIENT_SECRET,
                grant_type:"convert_token",
                backend: "google-oauth2"
            }, requestOptions).then((response)=>{
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                EventsHandler(`Logged in using Google`, document.URL)
                axios.get(buildAPIUrl('v1/users/me/'), {
                    headers:{
                        Authorization: `Bearer ${response.data.access_token}`
                    }
                }).then((res)=>{
                    if (res.data.company){
                        localStorage.setItem('profileupdate', "1")
                    }
                    window.location.assign("/")
                })
            }).catch((err)=>{
                swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.assign("/")
                    localStorage.clear()
                });
            })
        }
        const onFailure = (res) => {

        }

        const  handelLinkedin = async (code) => {
            var myHeaders = new Headers();
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            const resp = await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: code,
                SOCIAL_AUTH_LINKEDIN_OAUTH2_KEY:"773sbg6fthy5p2",
                SOCIAL_AUTH_LINKEDIN_OAUTH2_SECRET:"DBQF3r4JnjKOOcVr",
                client_id: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_ID,
                client_secret:process.env.REACT_APP_SOCIAL_AUTH_CLIENT_SECRET,
                grant_type:"convert_token",
                backend: "linkedin-oauth2"
            }, requestOptions).then((response)=>{
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                EventsHandler(`Logged in using Linkedin`, document.URL)
                axios.get(buildAPIUrl('v1/users/me/'), {
                    headers:{
                        Authorization: `Bearer ${response.data.access_token}`
                    }
                }).then((res)=>{
                    if (res.data.company){
                        localStorage.setItem('profileupdate', "1")
                    }
                    window.location.assign("/")
                })
            }).catch((err)=>{
                swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.assign("/")
                    localStorage.clear()
                });
            })
          console.log(code)
        }
        return (
            <div>
                <MediaQuery minWidth={1200}>
                    <div className={"container-fluid h-100"}>
                        <div className={"row d-flex justify-content-center"} style={{marginTop:"7rem"}}>
                            <div className={"col-md-4 col-12 border"} style={{borderRadius:"15px"}}>
                                <div className={"card"}>
                                    <div className="row">
                                        <div className={"col-md-12"}>
                                            <div className="card" style={{marginLeft:"3rem", marginRight:"2.25rem", marginTop:"2.563rem", marginBottom:"0.313rem"}}>
                                                <div className={"container d-flex justify-content-center"}>
                                                    <img src={Logo} style={{width:"100px", height:"50px"}}/>
                                                </div>
                                                <h6 className="text-center" style={{fontSize:"35px", fontWeight:"600"}}>Welcome to Research Bookmark</h6>

                                                <div className="container-fluid p-0 flex-column d-flex justify-content-center">
                                                    <div className=" mb-2 pt-2" style={{marginTop:"4.188rem"}} >
                                                    <GoogleButton/>

                                                    </div>
                                                </div>

                                                <div className="mt-3 mb-2 text-center" style={{marginTop:"1rem", paddingBottom:"4.813rem"}}>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} className="mobile">
                    <div className={"container-fluid h-100"}>
                        <div className={"row d-flex justify-content-center px-2"} style={{marginTop:"7rem"}}>
                            <div className={"col-md-12 col-12 border"} style={{borderRadius:"15px"}}>
                                <div className={"card"}>
                                    <div className="row">
                                        <div className={"col-md-12"}>
                                            <div className="card" style={{marginLeft:"1rem", marginRight:"1rem", marginTop:"2.563rem", marginBottom:"0.313rem"}}>
                                                <div className={"container d-flex justify-content-center"}>
                                                    <img src={Logo} style={{width:"100px", height:"50px"}}/>
                                                </div>
                                                <h6 className="text-center" style={{fontSize:"30px", fontWeight:"600", marginTop:"4.563rem",}}>Welcome to Research Bookmark</h6>

                                                <div className="container-fluid p-0 flex-column d-flex justify-content-center">
                                                    <div className=" mb-2 pt-2" style={{marginTop:"4.188rem"}} >
                                                        <GoogleButton />

                                                    </div>

                                                </div>

                                                <div className="mt-3 mb-2 text-center" style={{marginTop:"1rem", paddingBottom:"4.813rem"}}>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} className="tablet " minWidth={461}>
                    <div className={"container-fluid h-100"}>
                        <div className={"row d-flex justify-content-center"} style={{marginTop:"7rem"}}>
                            <div className={"col-md-10 col-12 border"} style={{borderRadius:"15px"}}>
                                <div className={"card"}>
                                    <div className="row">
                                        <div className={"col-md-12"}>
                                            <div className="card" style={{marginLeft:"3rem", marginRight:"2.25rem", marginTop:"2.563rem", marginBottom:"0.313rem"}}>
                                                <div className={"container d-flex justify-content-center"}>
                                                    <img src={Logo} style={{width:"100px", height:"50px"}}/>
                                                </div>
                                                <h6 className="text-center" style={{fontSize:"35px", fontWeight:"600"}}>Welcome to Research Bookmark</h6>

                                                <div className="container-fluid p-0 flex-column d-flex justify-content-center">
                                                    <div className=" mb-2 pt-2" style={{marginTop:"4.188rem"}} >
                                                    <GoogleButton/>

                                                    </div>
                                                </div>

                                                <div className="mt-3 mb-2 text-center" style={{marginTop:"1rem", paddingBottom:"4.813rem"}}>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}
export default Login