import Header from "../Landing/Header";
import MediaQuery from "react-responsive";
import PopularBanner from "./PopularBanner";
import React, {useEffect, useState} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Footer from "../Landing/Footer";
import {Link} from "react-router-dom";

export default function SearchResullts(){
    const [results, setResults]=useState([])
    const [results1, setResults1]=useState([])
    const [results2, setResults2]=useState([])
    const [results3, setResults3]=useState([])
    const [searchQ, setSearchQ]=useState(localStorage.getItem('searchQ'))
    const getResults = async () => {
      const results  = await axios.post(buildAPIUrl('v1/save_search_queries/search/'), {
          search_query:searchQ
      })
        setResults(results.data[0].ResearchCommunity)
        setResults1(results.data[3].UXResearchMethod)
        setResults2(results.data[1].UXBook)
        setResults3(results.data[2].UXResearchCourses)
    }
    const handleInput = async (e) => {
       await setSearchQ(e.target.value)
    }
    const handleRbSuggestion = (url) =>{
        window.location.assign(url)
    }
    useEffect(()=>{
        getResults()
    }, [])
    return (
        <>
            <Header />
            <MediaQuery minWidth={1200}>
                <div className={"row"}>
                    <div className={"col-md-2"}>

                    </div>
                    <div className={"col-md-9"}>
                        <div className={"container resources-search-section"}>
                            <h4>
                               Search Results
                            </h4>
                        </div>
                        <div className={"container"}>
                            <PopularBanner name={"Quick links"} />
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-2 position-relative"}>
                        <div className={"card border static-nav"}>
                            <h6>
                                Navigation
                            </h6>
                            <ul>
                                <li> <Link to={"/collections#everyday-collections"}> Everyday Collections</Link></li>
                                <li> <Link to={"/collections#ux-foundations-collections"}>Ux Foundations Collections </Link></li>
                                <li> <Link to={"/collections#special-collections"}>Special Collections </Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className={"col-md-9"}>
                        <div className={"container"} style={{marginTop:"2rem"}}>
                            <div className={"row"} style={{zIndex:"1000", position:"relative"}}>
                                <div className={"col-md-10 col-10 p-0 m-0"}>
                                    <input className="search-field-text" type="text" name="q" onChange={handleInput}  value={searchQ}
                                           placeholder="Search for sources in our collection" style={{borderRadius:"100px 0px 0px 100px"}}/>
                                </div>
                                <div className={"col-md-2 col-2 p-0 m-0"}>
                                    <button onTouchStart={getResults} className="btn btn-search" style={{borderRadius:"0px 6px 6px 0px"}}>Search</button>
                                </div>
                            </div>
                        </div>
                        <div className={"container mb-5"} style={{marginTop:"2rem"}}>
                            {
                                results.length > 0 ? <>
                                    {
                                        results.map((item)=>(
                                            <div className={"container border mt-2 pt-3 pb-2"} onClick={()=>handleRbSuggestion(`/uxr-communities#${item.name}`)}>
                                                {item.name} - UX Research Communities
                                            </div>
                                        ))
                                    }
                                </> : <></>
                            }
                            {
                                results1.length > 0 ? <>
                                    {
                                        results1.map((item)=>(
                                            <div className={"container border mt-2 pt-3 pb-2"} onClick={()=>handleRbSuggestion(`/research-books#${item.name}`)}>
                                                {item.name} - UX Books
                                            </div>
                                        ))
                                    }
                                </> : <></>
                            }
                            {
                                results2.length > 0 ? <>
                                    {
                                        results2.map((item)=>(
                                            <div className={"container border mt-2 pt-3 pb-2"} onClick={()=>handleRbSuggestion(`/uxr-courses#${item.name}`)}>
                                                {item.name} -  UX Research Courses
                                            </div>
                                        ))
                                    }
                                </> : <></>
                            }
                            {
                                results3.length > 0 ? <>
                                    {
                                        results3.map((item)=>(
                                            <div className={"container border mt-2 pt-3 pb-2 "} onClick={()=>handleRbSuggestion(`/uxr-methods#${item.research_method}`)}>
                                                {item.research_method} - UX Research Method
                                            </div>
                                        ))
                                    }
                                </> : <></>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </MediaQuery>
            <MediaQuery maxWidth={460}>
                <div className={"mobile"}>
                    <div className={"row"}>
                        <div className={"col-md-2"}>

                        </div>
                        <div className={"col-md-9"}>
                            <div className={"container resources-search-section"}>
                                <h4>
                                    Search Results
                                </h4>
                            </div>
                            <div className={"container"}>
                                <PopularBanner name={"Quick links"} />
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1200} minWidth={461}>
                <div className={"tablet"}>
                    <div className={"row"}>
                        <div className={"col-md-2"}>

                        </div>
                        <div className={"col-md-9"}>
                            <div className={"container resources-search-section"}>
                                <h4>
                                    Search Results
                                </h4>
                            </div>
                            <div className={"container"}>
                                <PopularBanner name={"Quick links"} />
                            </div>
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </>
    )
}