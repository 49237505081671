import React, {useEffect, useState} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {Modal} from "react-bootstrap";
import Logo from "../../../assets/rb-beta logo.svg";


export default function UpdateProfileModal(props){
    const [showProfile, setShowProfile]=useState(false)
    const [roles, setRoles]=useState([])
    const [saveprofilesuccess, setSaveProfileSuccess]=useState(false)
    const[error, setError]=useState(false)
    const[userDetails,setUserDetails]=useState({
        company:"",
        role:""
    })
    const getRoles = async ()=>{
        const roles = await axios.get(buildAPIUrl('v1/users/role/'), {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        setRoles(roles.data)
    }

    //Check if user has a filled their company and role details
    const checkUserRole = async ()=>{
        const userRole = await axios.get(buildAPIUrl('v1/users/me/'), {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        if (userRole.data.rb_role===null){
            //Show update profile modal after the user has started their free trial
            if( userRole.data.is_trial_period_over !=null){
                setShowProfile(true)
            }

        }
    }
    const handleSaveUserDetails = () => {
        if(userDetails.role) {
            axios.patch(buildAPIUrl('v1/users/me/'), {
                company:userDetails.company,
                rb_role:userDetails.role
            }, {
                headers:{
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res)=>{
               checkUserRole()
                setSaveProfileSuccess(true)
                setTimeout(()=>{
                    setSaveProfileSuccess(false)
                    setShowProfile(false)
                }, 3000)
            }).catch((error)=>{

            })
        } else  {
            setError(true)
            setTimeout(()=>{
                setError(false)
            }, 5000)
        }

    }
    const handleModalInput=(event)=>{
        setUserDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }

    useEffect(()=>{
        getRoles()
        checkUserRole()
    }, [])
    return (
        <>
            <Modal show={showProfile}  className={props.device==="mobile" ? "profile-update-modal-mobile" : "profile-update-modal"} centered={true}>
                <Modal.Body>
                    <img alt={"logo"} src={Logo} style={{width:"6.875rem"}} />
                    <div className={"container-fluid"} style={{paddingLeft:"1.438rem", paddingRight:"1.438rem"}}>
                        <div className={"container-fluid d-flex justify-content-center"}>

                            <div>
                                {saveprofilesuccess ? <>
                                    <h6 style={{fontSize:"16px", textDecoration:"none"}} className={"text-success text-center"}>
                                        Profile updated successfullly
                                    </h6>
                                </> : <></>
                                }
                                {error ? <>
                                    <h6 style={{fontSize:"16px", textDecoration:"none"}} className={"text-danger text-center"}>
                                       Please enter all the details
                                    </h6>
                                </> : <></>
                                }
                                <h6>
                                    Help Research Bookmark deliver the best experience
                                </h6>

                            </div>
                        </div>
                        <h5>
                            This information helps us source relevant content
                        </h5>
                        <div className={"mb-3"} style={{marginTop:"2.563rem"}}>
                            <label>
                                *Company or School <br/>
                                <span style={{fontSize:"12px", fontWeight:"400"}}>(Freelancing/Consulting?  Just put ‘Freelancer’ or ‘Consultant’)</span>
                            </label>
                            <input required type={"text"} className={"form-control"} name={"company"} value={userDetails.company} onChange={handleModalInput}/>
                        </div>
                        <div>
                            <label>
                                *Role
                            </label>
                            <select className={"form-control"} onChange={handleModalInput} name={"role"} value={userDetails.role}>
                                <option>---Select Role---</option>
                                {
                                    roles.length > 0 ? <>
                                        {
                                            roles.map((role)=>(
                                                <option value={role.id} key={role.id}>{role.role}</option>
                                            ))
                                        }
                                    </> : <></>
                                }

                                <option value={"Ux Researcher"}>Ux Researcher</option>
                            </select>
                        </div>
                        <div className={"mb-3"}>
                            <button className={"btn"} onClick={handleSaveUserDetails}>
                                Access Research Bookmark
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}