import React, {useEffect, useState} from "react";
import MetaTags from "react-meta-tags";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";

export default function SeoMetaData(props){
    const [seoContent, setSeoContent]=useState("")
    const getMetaData = async () =>{
       const response =  await axios.get(buildAPIUrl(`v1/seo_contents/?page=${props.title}`))
        if(response.data?.length>0){
            setSeoContent(response.data[0])
        }
       
    }

    useEffect(()=>{
        getMetaData()

    }, [])
    return (
        <>
            <MetaTags>
                <title>{seoContent.title}</title>
                {/* <meta name="description" content={seoContent.description} /> */}
                <meta property="og:description" content={seoContent.description} />
                <meta property="og:image" content={seoContent?.image} />
                <meta property="og:title" content={seoContent.title} />
                <meta property="og:url" content={seoContent.link} />
            </MetaTags>
        </>
    )
}