import React, { useState } from 'react';
import {createVocabulary} from './VocabulariesApi';
import Header from "../Header";


function CreateVocabulary() {
    const [newVocabularyDetails, setnewVocabularyDetails] = useState({
        name: "",
        definition: "",
        source: ""
    });

    const [userToken, setUserToken] = useState(localStorage.getItem('token'))
    const [isLoading, setisLoading] = useState(false);
    const [isError, setisError] = useState(false);
    const [error, seterror] = useState("");


    if (!userToken) {
        window.location.assign('/');
    }


    function onChangeHandler(event) {
        setnewVocabularyDetails((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
    }


    async function submitHandler(event) {
        event.preventDefault();
        const new_Vocabulary = {
            name: newVocabularyDetails.name,
            definition: newVocabularyDetails.definition,
            source: newVocabularyDetails.source
        }
        setisLoading(true);
        let response = await createVocabulary(new_Vocabulary, userToken);
        setisLoading(false);
        if (response.status === 201) {
            setnewVocabularyDetails({
                name: "",
                definition: "",
                source: ""
            })
            window.location.assign('/vocabulary/all');

        } else {
            setisError(true);
            seterror(response.statusText);
        }

    }


    return (
        <>
           <Header />
            <div className={"container-fluid mt-5"}>
                <div className={"container-fluid"} style={{padding:"0 5rem"}}>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            {isLoading && <p>Loading.....</p>}
                            {isError && <p>{error}</p>}
                            <div className={"card border"}>
                                <div className={"card-header"}>
                                    <h4>Create Vocabulary</h4>
                                </div>
                                <div className={"card-body"}>
                                    <form onSubmit={submitHandler}>
                                        <div className={"mb-3"}>
                                            <label htmlFor='name'>Name</label>
                                            <input
                                                type='text'
                                                id='name'
                                                name='name'
                                                value={newVocabularyDetails.name}
                                                onChange={onChangeHandler}
                                                required
                                                className={"form-control"}
                                            />
                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='definition'>Definition</label>
                                            <textarea
                                                className={"form-control"}
                                                rows="4"
                                                cols="50"
                                                id='definition'
                                                name='definition'
                                                value={newVocabularyDetails.definition}
                                                onChange={onChangeHandler}
                                                required
                                            />

                                        </div>
                                        <div className={"mb-3"}>
                                            <label htmlFor='source'>Source</label>
                                            <input
                                                className={"form-control"}
                                                type='url'
                                                id='source'
                                                name='source'
                                                value={newVocabularyDetails.source}
                                                onChange={onChangeHandler}
                                                required
                                            />
                                        </div>
                                        
                                        <button className={"btn btn-success"}>Add New Vocabulary</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
export default CreateVocabulary;