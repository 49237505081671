import React, {Component} from "react";
import MediaQuery from "react-responsive";
import {EventsHandler} from "./MainFile/MainFunctions";
class Filters extends Component{
    state= {
        news:false,
        videos:false,
        images:false,
        web:true,
        social:false,
        nielsen: false,
        careers: false,
        papers:false,
        courses:false,
        showfilter:false,
        filtertooltip:false
    }
    handleWebFilter = () => {
        EventsHandler("User clicked a results filter",  document.URL, "none", "All Results")
        this.setState({
            news:false,
            videos:false,
            images:false,
            web:true,
            nielsen: false,
            careers: false,
            social:false,
            papers:false,
            courses:false,
        })
        window.location.assign('/all-results')
    }
    handleImagesFilter = () => {
        EventsHandler("User clicked a results filter",  document.URL, "none", "Images")
        this.setState({
            news:false,
            videos:false,
            images:true,
            web:false,
            nielsen: false,
            careers: false,
            social:false,
            papers:false,
            courses:false,
        })
        window.location.assign('/images')
    }
    handleVideosFilter = () => {
        EventsHandler("User clicked a results filter",  document.URL, "none", "Videos")
        this.setState({
            news:false,
            videos:true,
            images:false,
            web:false,
            nielsen: false,
            careers: false,
            social:false,
            papers:false,
            courses:false,
        })
        window.location.assign('/videos')
    }
    handleNewsFilter = () => {
        EventsHandler("User clicked a results filter",  document.URL, "none", "News")
        this.setState({
            news:true,
            videos:false,
            images:false,
            web:false,
            nielsen: false,
            careers: false,
            social:false,
            papers:false,
            courses:false,
        })
        window.location.assign('/news')
    }
    handleSocialFilter = () => {
        EventsHandler("User clicked a results filter",  document.URL, "none", "Social Media")
        this.setState({
            news:false,
            videos:false,
            images:false,
            web:false,
            nielsen: false,
            careers: false,
            social:true,
            papers:false,
            courses:false,
        })
        window.location.assign('/social-media')
    }
    handlePapersFilter = () => {
        EventsHandler("User clicked a results filter",  document.URL, "", "Papers")
        this.setState({
            news:false,
            videos:false,
            images:false,
            web:false,
            nielsen: false,
            careers: false,
            social:false,
            papers:true,
            courses:false,
        })
        window.location.assign('/papers')
    }
    handleCoursesFilter = () => {
        EventsHandler("User clicked a results filter",  document.URL, "", "Courses")
        this.setState({
            news:false,
            videos:false,
            images:false,
            web:false,
            nielsen: false,
            careers: false,
            social:false,
            papers:false,
            courses:true,
        })
        window.location.assign('/courses')
    }
    handleCareersFilter = () => {
        EventsHandler("User clicked a results filter",  document.URL, "", "Careers")
        this.setState({
            news:false,
            videos:false,
            images:false,
            web:false,
            nielsen: false,
            careers: true,
            social:false,
            papers:false,
            courses:false,
        })
        window.location.assign('/careers')
    }
    handleFilterDiv = () =>{
        if(this.state.showfilter) {
            this.setState({
                showfilter:false
            })
        } else {
            this.setState({
                showfilter:true
            })
        }

    }
    componentDidMount() {
        if (localStorage.getItem('filtertooltip')){

        } else {
            this.setState({
                filtertooltip:true
            })
        }
    }
    componentWillUnmount() {
        localStorage.setItem('filtertooltip', '0')
    }
    handleFilterTooltip = data => {
        if(data === undefined) {

        } else {
            if (data.action ===  'close') {
                    this.setState({
                        filtertooltip: false,
                    })
                    localStorage.setItem('filtertooltip', '0')

            }
        }


    }
    render() {
        return(
            <>
                <MediaQuery minWidth={1200}>
                    <div className={"col-12 filternewdesktop col-md-11 mt-3"} style={ {whiteSpace:"nowrap", marginLeft:"1rem"}}>
                        <div className="mt-2 container-fluid p-0" >
                            <ul>
                                <li><a className={this.props.web? "active" : ""} style={{paddingLeft:"0"}} onClick={this.handleWebFilter}>All results</a></li>
                                <li><a className={this.props.social? "active" : ""} onClick={this.handleSocialFilter}>Social results</a>  </li>
                                <li><a  className={this.props.images? "active" : ""} onClick={this.handleImagesFilter}>Images</a></li>
                                <li><a className={this.props.videos? "active" : ""} onClick={this.handleVideosFilter}>Videos</a></li>
                                <li><a className={this.props.papers? "active" : ""} onClick={this.handlePapersFilter}>Papers</a></li>
                                <li><a className={this.props.courses? "active" : ""} onClick={this.handleCoursesFilter}>Courses</a></li>
                                <li><a className={this.props.careers? "active" : ""} onClick={this.handleCareersFilter}>Jobs</a></li>
                                <li><a className={this.props.news? "active" : ""} onClick={this.handleNewsFilter}>News</a></li>

                            </ul>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"p-0  col-12 filternewmobile col-md-11 mt-3"} style={ {whiteSpace:"nowrap"}}>
                        <div className="mt-2 container p-0" >
                            <ul >
                                <li><a className={this.props.web? "active" : ""} style={{paddingLeft:"0"}} onClick={this.handleWebFilter}>All results</a></li>
                                <li><a className={this.props.social? "active" : ""} onClick={this.handleSocialFilter}>Social results</a>  </li>
                                <li><a  className={this.props.images? "active" : ""} onClick={this.handleImagesFilter}>Images</a></li>
                                <li><a className={this.props.videos? "active" : ""} onClick={this.handleVideosFilter}>Videos</a></li>
                                <li><a className={this.props.papers? "active" : ""} onClick={this.handlePapersFilter}>Papers</a></li>
                                <li><a className={this.props.courses? "active" : ""} onClick={this.handleCoursesFilter}>Courses</a></li>
                                <li><a className={this.props.careers? "active" : ""} onClick={this.handleCareersFilter}>Jobs</a></li>
                                <li><a className={this.props.news? "active" : ""} onClick={this.handleNewsFilter}>News</a></li>
                            </ul>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className={" col-12 filternewmobile col-md-11 mt-3"} style={ {whiteSpace:"nowrap"}}>
                        <div className="mt-2 container p-0" >
                            <ul className={"d-flex justify-content-around"} >
                                <li><a className={this.props.web? "active" : ""} style={{paddingLeft:"0"}} onClick={this.handleWebFilter}>All results</a></li>
                                <li><a className={this.props.social? "active" : ""} onClick={this.handleSocialFilter}>Social results</a>  </li>
                                <li><a  className={this.props.images? "active" : ""} onClick={this.handleImagesFilter}>Images</a></li>
                                <li><a className={this.props.videos? "active" : ""} onClick={this.handleVideosFilter}>Videos</a></li>
                                <li><a className={this.props.papers? "active" : ""} onClick={this.handlePapersFilter}>Papers</a></li>
                                <li><a className={this.props.courses? "active" : ""} onClick={this.handleCoursesFilter}>Courses</a></li>
                                <li><a className={this.props.careers? "active" : ""} onClick={this.handleCareersFilter}>Jobs</a></li>
                                <li><a className={this.props.news? "active" : ""} onClick={this.handleNewsFilter}>News</a></li>
                            </ul>
                        </div>
                    </div>
                </MediaQuery>

            </>
        )
    }
}
export default Filters