import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Header from "../Landing/Header";
import {HiArrowLeft} from "react-icons/hi";


class NotionArticleDetails extends Component{
    state= {
        articleDetails:""
    }
    componentDidMount() {
        //Get article id from url
        const articleid =  this.props.match.params.id

        //Get article details using the id
         axios.get(buildAPIUrl(`v1/articles/${articleid}`), {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }}).then((res)=>{
            this.setState({
                articleDetails:res.data
            })
        }).catch((error)=>{

        })
    }
    handleGoBack=()=>{
        this.props.history.goBack()
    }

    render() {
        return (
            <>
                <Header />
                <div className={"container"}>
                    <h6 onClick={this.handleGoBack}> <HiArrowLeft /> Back to step by step</h6>
                </div>
                <div className={"row mt-5"}>
                    <div className={"col-md-2"}></div>
                    <div className={"col-md-8"}>
                        {
                            this.state.articleDetails.content
                        }
                    </div>
                    <div className={"col-md-2"}></div>
                </div>
            </>
        );
    }
}
export default NotionArticleDetails