import React, {Component} from "react";
import {Link} from "react-router-dom";
import "./landing.css"
import {MdKeyboardArrowRight} from "react-icons/md";
import MediaQuery from "react-responsive";
import blue from "../../../assets/Group 89971.png"
import {FaArrowRight} from "react-icons/fa";
import bluearrow from "../../../assets/Group 89940.png"
import peacharrow from "../../../assets/Group 89942.png"
import greenarrow from "../../../assets/Group 89944.png"
import grayarrow from "../../../assets/Group 89946.png"
import peach from "../../../assets/Group 89972.png"
import green from "../../../assets/Group 89973.png"
import gray from "../../../assets/Group 89974.png"
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import {EventsHandler} from "./MainFile/MainFunctions";
class ResourcesSection extends Component{
    state={
        item1:[],
        item2:[],
        item3:[],
        item4:[]
    }
    componentDidMount() {
        axios.get(buildAPIUrl('v1/collections/?is_top_collection=1')).then((res)=>{
            this.setState({
                item1:res.data[0],
                item2:res.data[1],
                item3:res.data[2],
                item4:res.data[3]
            })
        })
    }
    handleRBCollectionsLandingPage = (item)=>{
        EventsHandler("User clicked an RB Collection in the search bar",  document.URL, "", item)
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={1200}>
                    <div className="container row3">
                        <div className="categories" style={{background:"#F7F5FF", borderRadius:"10px", padding:"2.25rem 3.125rem"}}>
                            <h4 style={{fontSize:"30px", lineHeight:"127.52%", color:"#000000", fontWeight:"600", textAlign:"center", margin:"0 9.688rem"}}>Get into the
                                World of User Experience</h4>
                            <h6 style={{fontSize:"14px", lineHeight:"134.52%", fontWeight:"400", color:"#767676", textAlign:"center", marginTop:"0.75rem"}}>Browse books, podcasts, methods, communities, conferences, vocabularies, etc.</h6>
                            <div className="row" style={{marginTop:"2.188rem", marginBottom:"2rem"}}>
                                {this.state.item1 ?  <>
                                    <div className="col-md-6" style={{paddingRight:"1rem"}}>
                                        <div className={"card h-100"}>
                                            <a onClick={()=>this.handleRBCollectionsLandingPage(this.state.item1.header)} className={"h-100"} href={this.state.item1.url}>
                                                <div className="categories-item" style={{ borderRadius:"10px"}}>
                                                    <div className="categories-content" >

                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item1.header}
                                                        </h6>
                                                        <p>
                                                            {this.state.item1.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item1.url}>  <img src={bluearrow}/> Explore {this.state.item1.header}</a>
                                        </div>


                                    </div>
                                </> : <></>}

                                {this.state.item2 ?  <>
                                    <div className="col-md-6" style={{paddingLeft:"1rem"}}>
                                        <div className={"card h-100"}>
                                            <a onClick={()=>this.handleRBCollectionsLandingPage(this.state.item2.header)}  className={"h-100"} href={this.state.item2.url}>
                                                <div className="categories-item" style={{borderRadius:"10px"}}>
                                                    <div className="categories-content" >

                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item2.header}
                                                        </h6>
                                                        <p >
                                                            {this.state.item2.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item2.url}>  <img src={peacharrow}/> Explore {this.state.item2.header}</a>
                                        </div>
                                    </div>
                                </> : <></> }
                                {this.state.item3 ?  <>
                                    <div className="col-md-6 mt-4" style={{paddingRight:"1rem"}}>
                                        <div className={"card h-100"}>
                                            <a onClick={()=>this.handleRBCollectionsLandingPage(this.state.item3.header)} className={"h-100"} href={this.state.item3.url}>
                                                <div className="categories-item" style={{ borderRadius:"10px", marginTop:"1.875rem"}}>
                                                    <div className="categories-content" >

                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item3.header}
                                                        </h6>
                                                        <p >
                                                            {this.state.item3.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item3.url}>   <img src={greenarrow}/> Explore {this.state.item3.header}</a>
                                        </div>
                                    </div>
                                </> : <></> }
                                {this.state.item4 ?  <>
                                    <div className="col-md-6 mt-4 " style={{paddingLeft:"1rem"}}>
                                        <div className={"card h-100"}>
                                            <a onClick={()=>this.handleRBCollectionsLandingPage(this.state.item4.header)}  className={"h-100"} href={this.state.item4.url}>
                                                <div className="categories-item" style={{borderRadius:"10px", marginTop:"1.875rem"}}>
                                                    <div className="categories-content" >
                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item4.header}
                                                        </h6>
                                                        <p>
                                                            {this.state.item4.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item4.url}>   <img src={grayarrow}/> Explore {this.state.item4.header}</a>
                                        </div>
                                    </div>
                                </> : <></> }





                            </div>

                        </div>
                        <div className={"container-fluid"} style={{background:"#EBE6FF", padding:" 0.625rem 2.875rem", borderRadius:" 0px 0px 10px 10px"}}>
                           <Link to={"/collections"}>
                               <img src={bluearrow}/> <span style={{color:"#522FFF", fontSize:"14px", fontWeight:"500", marginLeft:"0.75rem"}}>View all collections</span>
                           </Link>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} className="tablet" minWidth={461}>
                    <div className="container row3">
                        <div className="categories" style={{background:"#F7F5FF", borderRadius:"6px", padding:"1.5rem 1.5rem"}}>
                            <h4 style={{fontSize:"30px", lineHeight:"127.52%", color:"#000000", fontWeight:"600", textAlign:"center", margin:"0 .688rem"}}>Get into the world of UX Research</h4>
                            <h6 style={{fontSize:"14px", lineHeight:"134.52%", fontWeight:"400", color:"#767676", textAlign:"center", marginTop:"0.75rem"}}>Browse books, podcasts, methods, communities, conferences, vocabularies, etc.</h6>
                            <div className="row" style={{marginTop:"2.188rem", marginBottom:"2rem"}}>
                                {this.state.item1 ?  <>
                                    <div className="col-md-12" style={{paddingRight:"1rem"}}>
                                        <div className={"card h-100"}>
                                            <a className={"h-100"} href={this.state.item1.url}>
                                                <div className="categories-item" style={{ borderRadius:"10px"}}>
                                                    <div className="categories-content" >
                                                        <img src={blue}/>
                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item1.header}
                                                        </h6>
                                                        <p>
                                                            {this.state.item1.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item1.url}>  <img src={bluearrow}/> Explore {this.state.item1.header}</a>
                                        </div>


                                    </div>
                                </> : <></>}

                                {this.state.item2 ?  <>
                                    <div className="col-md-12 mt-2">
                                        <div className={"card h-100"}>
                                            <a  className={"h-100"} href={this.state.item2.url}>
                                                <div className="categories-item" style={{borderRadius:"10px"}}>
                                                    <div className="categories-content" >
                                                        <img src={peach}/>
                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item2.header}
                                                        </h6>
                                                        <p >
                                                            {this.state.item2.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item2.url}>  <img src={peacharrow}/> Explore {this.state.item2.header}</a>
                                        </div>
                                    </div>
                                </> : <></> }
                                {this.state.item3 ?  <>
                                    <div className="col-md-12 mt-2" style={{paddingRight:"1rem"}}>
                                        <div className={"card h-100"}>
                                            <a  className={"h-100"} href={this.state.item3.url}>
                                                <div className="categories-item" style={{ borderRadius:"10px", marginTop:"1.875rem"}}>
                                                    <div className="categories-content" >
                                                        <img src={green}/>
                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item3.header}
                                                        </h6>
                                                        <p >
                                                            {this.state.item3.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item1.url}>   <img src={greenarrow}/> Explore {this.state.item3.header}</a>
                                        </div>
                                    </div>
                                </> : <></> }
                                {this.state.item4 ?  <>
                                    <div className="col-md-12 mt-2 " style={{paddingLeft:"1rem"}}>
                                        <div className={"card h-100"}>
                                            <a  className={"h-100"} href={this.state.item4.url}>
                                                <div className="categories-item" style={{borderRadius:"10px", marginTop:"1.875rem"}}>
                                                    <div className="categories-content" >
                                                        <img src={gray}/>
                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item4.header}
                                                        </h6>
                                                        <p>
                                                            {this.state.item4.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item1.url}>   <img src={grayarrow}/> Explore {this.state.item4.header}</a>
                                        </div>
                                    </div>
                                </> : <></> }





                            </div>

                        </div>
                        <div className={"container-fluid"} style={{background:"#EBE6FF", padding:" 0.625rem 2.875rem", borderRadius:" 0px 0px 10px 10px"}}>
                            <Link to={"/collections"}>
                                <img src={bluearrow}/> <span style={{color:"#522FFF", fontSize:"14px", fontWeight:"500", marginLeft:"0.75rem"}}>View all collections</span>
                            </Link>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className="container row3">
                        <div className="categories" style={{background:"#F7F5FF", borderRadius:"6px", padding:"1.5rem 1.5rem"}}>
                            <h4 style={{fontSize:"30px", lineHeight:"127.52%", color:"#000000", fontWeight:"600", textAlign:"center", margin:"0 .688rem"}}>Get into the world of UX Research</h4>
                            <h6 style={{fontSize:"14px", lineHeight:"134.52%", fontWeight:"400", color:"#767676", textAlign:"center", marginTop:"0.75rem"}}>Browse books, podcasts, methods, communities, conferences, vocabularies, etc.</h6>
                            <div className="row" style={{marginTop:"2.188rem", marginBottom:"2rem"}}>
                                {this.state.item1 ?  <>
                                    <div className="col-md-12" style={{paddingRight:"1rem"}}>
                                        <div className={"card h-100"}>
                                            <a className={"h-100"} href={this.state.item1.url}>
                                                <div className="categories-item" style={{ borderRadius:"10px"}}>
                                                    <div className="categories-content" >
                                                        <img src={blue}/>
                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem", textDecoration:"none"}}>
                                                            {this.state.item1.header}
                                                        </h6>
                                                        <p>
                                                            {this.state.item1.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item1.url}>  <img src={bluearrow}/> Explore {this.state.item1.header}</a>
                                        </div>


                                    </div>
                                </> : <></>}

                                {this.state.item2 ?  <>
                                    <div className="col-md-12 mt-2">
                                        <div className={"card h-100"}>
                                            <a  className={"h-100"} href={this.state.item2.url}>
                                                <div className="categories-item" style={{borderRadius:"10px"}}>
                                                    <div className="categories-content" >
                                                        <img src={peach}/>
                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item2.header}
                                                        </h6>
                                                        <p >
                                                            {this.state.item2.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item2.url}>  <img src={peacharrow}/> Explore {this.state.item2.header}</a>
                                        </div>
                                    </div>
                                </> : <></> }
                                {this.state.item3 ?  <>
                                    <div className="col-md-12 mt-2" style={{paddingRight:"1rem"}}>
                                        <div className={"card h-100"}>
                                            <a  className={"h-100"} href={this.state.item3.url}>
                                                <div className="categories-item" style={{ borderRadius:"10px", marginTop:"1.875rem"}}>
                                                    <div className="categories-content" >
                                                        <img src={green}/>
                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item3.header}
                                                        </h6>
                                                        <p >
                                                            {this.state.item3.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item1.url}>   <img src={greenarrow}/> Explore {this.state.item3.header}</a>
                                        </div>
                                    </div>
                                </> : <></> }
                                {this.state.item4 ?  <>
                                    <div className="col-md-12 mt-2 " style={{paddingLeft:"1rem"}}>
                                        <div className={"card h-100"}>
                                            <a  className={"h-100"} href={this.state.item4.url}>
                                                <div className="categories-item" style={{borderRadius:"10px", marginTop:"1.875rem"}}>
                                                    <div className="categories-content" >
                                                        <img src={gray}/>
                                                        <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                            {this.state.item4.header}
                                                        </h6>
                                                        <p>
                                                            {this.state.item4.more_description}
                                                        </p>


                                                    </div>
                                                </div>
                                            </a>
                                            <a className={"explore"} href={this.state.item1.url}>   <img src={grayarrow}/> Explore {this.state.item4.header}</a>
                                        </div>
                                    </div>
                                </> : <></> }





                            </div>

                        </div>
                        <div className={"container-fluid"} style={{background:"#EBE6FF", padding:" 0.625rem 2.875rem", borderRadius:" 0px 0px 10px 10px"}}>
                            <Link to={"/collections"} style={{textDecoration:"none"}}>
                                <img src={bluearrow}/> <span style={{color:"#522FFF", fontSize:"14px", fontWeight:"500", marginLeft:"0.75rem"}}>View all collections</span>
                            </Link>
                        </div>
                    </div>
                </MediaQuery>

            </>
        )
    }
}
export default ResourcesSection