import React, {useState,useEffect} from 'react'
import Header from "./Header";
import {HiSearch} from "react-icons/hi";
import {Link} from "react-router-dom";
import axios from "axios";
import buildAPIUrl from "../Services/UrlBuilder";
import arrow from "../assets/Verctor.png";
import * as XLSX from "xlsx";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import cards from "../assets/Group 23.png";
import SkeletonSearchResult from "../User/Components/Landing/Skeletons/SkeletonSearchResult";

export default function AllSearches () {
    const [loading, setLoading]=useState(true)
    const [q, setQ] = useState("")
    const [allSearches, setAllSearches] = useState([])
    const [allSearchesT, setAllSearchesT] = useState([])
    const [filter, setFilter]=useState("")
    const [userToken, setUserToken]=useState(localStorage.getItem('token'))
    const handleSearch = async (e) =>{
        await setFilter(e.target.value)
    }

    const handleInput = () => {

    }
    useEffect(()=>{
        const response1 = axios.get(buildAPIUrl('v1/save_search_queries/trending'),{headers: {Authorization: `Bearer ${userToken}`}}).then((res1)=>{
            console.log(res1)
            setAllSearches(res1.data)
            setAllSearchesT(res1.data)
            setLoading(false)
        })
    }, [])
    const filtereditems = Object.entries(allSearchesT).filter((key)=>{
        if (filter===""){
            return key
        } else if (key[0].includes(filter)){
            return key[0]
        }
    })
    console.log(filtereditems)
    const handleExport = ()=>{
        var wb = XLSX.utils.book_new()
        var ws = XLSX.utils.json_to_sheet(Object.entries(allSearches))
        XLSX.utils.book_append_sheet(wb,ws,"searchterms")
        XLSX.writeFile(wb, "Searchterms.xlsx")
    }
    return (
        <>
            <Header />
            <div className="row" style={{paddingLeft: "1rem", paddingRight:"1rem", marginLeft:"17.125rem", marginRight:"8.5rem"}}>
                <div className="col-md-12 col-12">
                    <div className="search-section col-12 col-md-12 ">
                        <div className="row d-flex align-items-center ">
                            <div className=" col-12 col-md-12 d-flex justify-content-center">
                                <form onKeyUp={handleSearch} style={{width:"100%"}} autoComplete="off">
                                    <div className="form-input">
                                                             <span className="input-group-addon d-flex align-items-center"> <HiSearch
                                                                 className="search-icon"/></span>
                                        <input className="search-field-text1 mob" type="text" name="q"
                                               style={{width: "100%", paddingLeft:"8%"}} placeholder="Search for search term"/>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid breadcrumbs p-0" style={{marginTop:"1.5rem"}}>
                        <div className="">
                            <div className="row">
                                <div className="col-8 d-flex align-items-center">
                                    <h6><Link to="/admin-dashboard" style={{textDecoration: "none"}}> <span
                                        style={{color: "#00944D", cursor: "pointer"}}> Dashboard > </span>
                                    </Link> All searches conducted</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:"1.75rem"}}>
                            <div className="col-md-6 col-6 d-flex align-items-center">
                                <h5>All searches conducted</h5>
                            </div>
                            <div className="col-md-6 col-6 d-flex justify-content-end">
                                <ReactHTMLTableToExcel
                                    className="btn btn-fav"
                                    table="table-to-xls"
                                    filename="tablexls"
                                    sheet="tablexls"
                                    buttonText="Export Data"/>
                               {/* <button  onClick={handleExport}>Export data</button>*/}
                            </div>
                        </div>
                    </div>
                    <div className="saved-sources mt-3">
                        {
                            loading ? <>
                                    {
                                        [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                    }

                                </> :
                                <>
                                    {
                                        filtereditems.length > 0 ?   <table className="history-table mt-3" id="table-to-xls">
                                            <thead className="">
                                            <tr>
                                                <th>Search term</th>
                                                <th>Number of times searched</th>
                                                {/*<th>Source</th>*/}

                                            </tr>
                                            </thead>
                                            <tbody>
                                            {filtereditems.sort(function (a,b){return b[1] - a[1]}).map((item) => {
                                                return (
                                                    <>


                                                        <tr>
                                                            <td>{item[0]}</td>
                                                            <td>{item[1]}</td>
                                                        </tr>


                                                    </>


                                                );
                                            })}


                                            </tbody>
                                        </table>
                                            :
                                            <div className="container-fluid d-flex justify-content-center">
                                               <div className="text-center">
                                                   <img
                                                       src={cards}/><br/>
                                                   <h6>No results found for this keyword</h6>
                                                   <p>This word is not available in all searches</p>
                                               </div>
                                            </div>
                                    }
                                </>

                        }
                    </div>

                </div>
            </div>
        </>
    )
}