import React, {Component} from 'react'

class RedirectPage extends Component{
componentDidMount() {
    window.location.assign("/")
}
    render() {
        return (
            <div>

            </div>
        );
    }
}
export default RedirectPage