import React, {Component} from "react";

class Analyzing extends Component{
    componentDidMount() {
        window.location.assign("articles/2/starting-research")
    }

    render() {
        return (
            <div>

            </div>
        );
    }
}
export default Analyzing